import React, { useEffect, useState } from "react";
import axios from 'axios';
import { Table, Row, Col, } from "antd";
import Auxiliary from "util/Auxiliary";
import { API_URL } from 'constants/APIConfig';
import IconWithTextCard from "components/Metrics/IconWithTextCard";
import { DesktopOutlined, GlobalOutlined, ReadOutlined, FormOutlined, EyeOutlined } from '@ant-design/icons';


// rowSelection objects indicates the need for row selection
const rowSelection = {
  onChange: (selectedRowKeys, selectedRows) => {
    console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
  },
  onSelect: (record, selected, selectedRows) => {
    console.log(record, selected, selectedRows);
  },
  onSelectAll: (selected, selectedRows, changeRows) => {
    console.log(selected, selectedRows, changeRows);
  },
};

const ProjectTasks = (props) => {
  const userDetails = JSON.parse(sessionStorage.getItem('user_details'));
  const token = sessionStorage.getItem('token');
  const [treeData, setTreeData] = useState([]);

  const getTaskLists = () => {
    axios.get(API_URL + `/api/task/getTasksWithSubTasksTree?userId=1&projectId=${props.id}&limit=100&offset=0`, 
    {headers: {
      'x-access-token': `${token}`, 
    'Access-Control-Allow-Origin': '*'}
   })
      .then(response => {
        if (response.data.data.length) {
          setTreeData(response.data.data);
        }
      })
  }

  useEffect(() => {
    getTaskLists();
  }, [])


  const columns = [
    {
      title: 'Task ID', dataIndex: 'key', key: 'id', fixed: 'left', width: 150,
      sorter: (a, b) => String(a.title).localeCompare(String(b.title)),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Name', dataIndex: 'title', key: 'name', width: 500,
      sorter: (a, b) => String(a.title).localeCompare(String(b.title)),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Description', dataIndex: 'descriptionTx', key: 'desc', width: 200,
      sorter: (a, b) => String(a.descriptionTx).localeCompare(String(b.descriptionTx)),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Start Date', dataIndex: 'startDateDt', key: 'sdt', width: 150,
      sorter: (a, b) => String(a.startDateDt).localeCompare(String(b.startDateDt)),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'End Date', dataIndex: 'dueDateDt', key: 'edt', width: 150,
      sorter: (a, b) => String(a.dueDateDt).localeCompare(String(b.dueDateDt)),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Owner', dataIndex: 'owner', key: 'owner', width: 200,
      sorter: (a, b) => String(a.owner).localeCompare(String(b.owner)),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Billable', dataIndex: 'billabilityNm', key: 'billable', width: 150,
      sorter: (a, b) => String(a.billabilityNm).localeCompare(String(b.billabilityNm)),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Estd. Hours', dataIndex: 'estimatedHoursNm', key: 'estdhrs', width: 150,
      sorter: (a, b) => String(a.estimatedHoursNm).localeCompare(String(b.estimatedHoursNm)),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Status', dataIndex: 'taskstatus', key: 'taskstatus', fixed: 'right', width: 150,
      sorter: (a, b) => String(a.taskstatus).localeCompare(String(b.taskstatus)),
      sortDirections: ['descend', 'ascend'],
    },
  ];
  return (
    <Auxiliary>
      <Row gutter={24}>
        <Col lg={24} md={24} sm={24} xs={24} >
          <Table className="gx-table-responsive" columns={columns} dataSource={treeData} size="small" scroll={{ x: 1300 }} />
        </Col>
      </Row>
    </Auxiliary>
  );
};
export default ProjectTasks;
