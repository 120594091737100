import React, { useEffect, useState } from "react";
import axios from 'axios';
import { Table, Row, Col, Select, Form, Modal, Switch, Tag } from 'antd';
import Auxiliary from "util/Auxiliary";
import { API_URL } from 'constants/APIConfig';

const { Option } = Select;

const ManageSkillBase = () => {
  const [tableForm] = Form.useForm();
  const token = sessionStorage.getItem('token');
  const [isModalOpen, setIsModalOpen] = useState([]);
  const [empSkills, setEmpSkills] = useState([])

  const [allUsersAllSkillList, setAllUsersAllSkillList] = useState([])
  const [allDepartment, setAllDepartment] = useState([])

  const [allSkills, setAllSkills] = useState([])
  const [allSkillsTemp, setAllSkillsTemp] = useState([])

  const [allSubSkills, setAllSubSkills] = useState([])
  const [allSubSkillsTemp, setAllSubSkillsTemp] = useState([])

  const onChangeDepartment = (id) => {
    let allSkills = []
    allSkillsTemp.forEach(s => {
      if (s.departmentIdNm === id) {
        allSkills.push(s)
      }
    })
    setAllSkills(allSkills)
    setAllSubSkills([])
    tableForm.setFieldsValue({
      skillId: undefined,
      subSkillId: undefined
    })
  }

  const onChangeSkill = (id) => {
    let subSkills = []
    allSubSkillsTemp.forEach(s => {
      if (s.skillId === id) {
        subSkills.push(s)
      }
    })

    setAllSubSkills(subSkills)
    tableForm.setFieldsValue({
      subSkillId: undefined
    })
  }

  const getAllUsersAllSkills = () => {
    axios.get(API_URL + `/api/user_skills/fetchAllUsersAllSkills?t=${new Date().getTime()}`,
      {
        headers: {
          'x-access-token': `${token}`,
          'Access-Control-Allow-Origin': '*'
        }
      })
      .then(response => {
        if (response.data && response.data.data) {
          response.data.data.forEach((d, i) => { d['key'] = i })
          setAllUsersAllSkillList(response.data.data)

          let depArr = []
          let depSet = new Set()

          let skillArr = []
          let skillSet = new Set()

          let subSkillArr = []
          let subSkillSet = new Set()

          response.data.data.forEach(s => {
            if (!depSet.has(s.assignedSkill.sb_department.id)) {
              depSet.add(s.assignedSkill.sb_department.id)
              depArr.push(s.assignedSkill.sb_department)
            }

            if (!skillSet.has(s.assignedSkill.sb_skill.id)) {
              skillSet.add(s.assignedSkill.sb_skill.id)
              skillArr.push(s.assignedSkill.sb_skill)
            }

            if (!subSkillSet.has(s.subSkillId)) {
              subSkillSet.add(s.subSkillId)
              subSkillArr.push({id: s.assignedSkill.id, nameTx: s.assignedSkill.nameTx, skillId: s.assignedSkill.skillIdNm})
            }

          })

          setAllDepartment(depArr)
          setAllSkillsTemp(skillArr)
          setAllSubSkillsTemp(subSkillArr)
        }
      })
      .catch(error => {
      });
  }

  useEffect(() => {
    getAllUsersAllSkills()
  }, [])

  const columns = [
    { title: 'Emp Id', dataIndex: 'empId', fixed: 'left' },
    { title: 'Name', dataIndex: 'name' },
    { title: 'Relevant Experience', dataIndex: 'relevantExp' }
  ]

  const modalColumns = [
    { title: 'Name', dataIndex: 'nameTx', fixed: 'left' },
    { title: 'Emp Id', dataIndex: 'id' },
  ]

  const filterTableData = () => {
    let subSkillId = tableForm.getFieldValue('subSkillId')
    let active = tableForm.getFieldValue('active')
    let empList = []

    allUsersAllSkillList.filter(e => e.subSkillId == subSkillId).forEach(emp => {
      if(active === true && emp.assignedUser.activateYnNm === 1) {
        empList.push({
          empId: emp.assignedUser.employeeId,
          name: emp.assignedUser.displayNameTx,
          relevantExp:  <Tag>{emp.relevantExp}</Tag> 
        })
      } else if(active === false && emp.assignedUser.activateYnNm === 0) {
        empList.push({
          empId: emp.assignedUser.employeeId,
          name: emp.assignedUser.displayNameTx,
          relevantExp:  <Tag>{emp.relevantExp}</Tag> 
        })
      }
      
    })
    setEmpSkills(empList)
  }

  return (
    <Auxiliary>
      <center><h4>Manage SkillBase</h4></center>
      <Row gutter={24}>
        <Form
          form={tableForm}
          name="tableForm"
          layout="inline"
          align="center"
        >
          <Col lg={6} md={6} sm={24} xs={24} className="spaceMt">
            <Form.Item
              name="departmentId" rules={[
                {
                  required: true,
                  message: 'Select the Department',
                },
              ]}>
              <Select
                showSearch
                style={{ width: '100%' }}
                placeholder="Select the Department"
                filterOption={(input, option) => option.name.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                onChange={onChangeDepartment}
              >
                {
                  allDepartment.length > 0 && allDepartment.map((dep, index) => (
                    <Option key={index} value={dep.id} name={dep.nameTx}>{dep.nameTx}</Option>
                  ))
                }
              </Select>
            </Form.Item>
          </Col>
          <Col lg={6} md={6} sm={24} xs={24} className="spaceMtb">
            <Form.Item
              name="skillId" rules={[
                {
                  required: true,
                  message: 'Select the Skill',
                },
              ]}>
              <Select
                showSearch
                style={{ width: '100%' }}
                placeholder="Select the Skill"
                filterOption={(input, option) => option.name.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                onChange={onChangeSkill}
              >
                {
                  allSkills.length > 0 && allSkills.map((dep, index) => (
                    <Option key={index} value={dep.id} name={dep.nameTx}>{dep.nameTx}</Option>
                  ))
                }
              </Select>
            </Form.Item>
          </Col>

          <Col lg={6} md={6} sm={24} xs={24} className="spaceMb">
            <Form.Item name="subSkillId" rules={[
              {
                required: true,
                message: 'Select the Sub Skill',
              },
            ]}>
              <Select
                showSearch
                style={{ width: '100%' }}
                placeholder="Select the Sub Skill"
                filterOption={(input, option) => option.name.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                onChange={filterTableData}
              >
                {
                  allSubSkills.length > 0 && allSubSkills.map((skill, index) => (
                    <Option key={index} value={skill.id} name={skill.nameTx}>{skill.nameTx}</Option>
                  ))
                }
              </Select>
            </Form.Item>
          </Col>
          <Col lg={6} md={6} sm={24} xs={24}>
            <Form.Item name="active" label="Active Emp: " valuePropName="checked" initialValue={true}>
              <Switch defaultChecked={true} checked={true} onChange={filterTableData} className="switchSpace"/>
            </Form.Item>
          </Col>
          <Col className="mDisplay-none" lg={6} md={6} sm={6} xs={6}>
          </Col>
        </Form>
      </Row>
      <br />
      <Table className="gx-table-responsive" columns={columns} dataSource={empSkills} rowKey={'empId'} />

      <Modal closable={true} centered maskClosable={true} title={"Employee List"} visible={isModalOpen.length !== 0} onOk={() => { setIsModalOpen([]); }}
        onCancel={() => { setIsModalOpen([]); }}
        wrapClassName={"modal-ticket-create"} footer={null} >
        <Table className="gx-table-responsive" columns={modalColumns} dataSource={isModalOpen} rowKey={'id'} />
      </Modal>
    </Auxiliary>
  );
};

export default ManageSkillBase;