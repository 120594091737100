import React, { useEffect, useState } from "react";
import axios from 'axios';
import { Row, Col, Form, Input, Select, Radio, Upload, message, Popconfirm, DatePicker, Button, Tabs, InputNumber, Table, Divider } from "antd";
import Auxiliary from "util/Auxiliary";
import { API_URL } from 'constants/APIConfig';
import moment from "moment";
import { useHistory } from "react-router-dom";

const Dragger = Upload.Dragger;
const FormItem = Form.Item;
const { Option } = Select;
const TabPane = Tabs.TabPane;
const { TextArea } = Input;

function handleChange(value) {
    console.log(`selected ${value}`);
}

function handleBlur() {
    console.log('blur');
}

function handleFocus() {
    console.log('focus');
}

const EditIssues = (props) => {
    let history = useHistory();
    const userDetails = JSON.parse(sessionStorage.getItem('user_details'));
    const token = sessionStorage.getItem('token');
    
    const [issues, setIssues] = useState([]);
    const [users, setUsers] = useState([]);
    const getUserList = () => {
        axios.get(API_URL + '/api/users/getall', 
        {headers: {
          'x-access-token': `${token}`, 
        'Access-Control-Allow-Origin': '*'}
       })
            .then(response => {
                console.log('User List', response.data.data)
                setUsers(response.data.data);
            })
            .catch(error => {
                console.log(error)
            });
    }

    useEffect(() => {
        getUserList();
    }, [])

    // UseForm Hook
    const [form] = Form.useForm();

    const getProjIssuesById = () => {
        axios.get(API_URL + `/api/project_issues_risks_actions/byId/${props.match.params.id}`, 
        {headers: {
          'x-access-token': `${token}`, 
        'Access-Control-Allow-Origin': '*'}
       })
          .then(response => {
            console.log("healthEntries by id", response.data.data);
            if (Object.keys(response.data.data).length > 0) {
                setIssues(response.data.data);
              let item =  response.data.data;    
              form.setFieldsValue({
                owner:item.assignedTo,
                type:item.type,
                due:moment(item.dueDate),
                status:item.status,
                comments:item.comments,
              }); 
            } 
          })
          .catch(error => {
            // Error
            if (error.response) {
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
            } else if (error.request) {
              console.log(error.request);
            } else {
              console.log('Error', error.message);
            }
            console.log(error.config);
          });
      }  
    
      useEffect(() => {
        if(props.match.params.id!=null)
            getProjIssuesById()
    
      }, [])

    //UI Functions
    const onFinish = values => {
        const newData = {
                projectId: issues.projectId,
                createdBy : 1,
                assignedTo: form.getFieldValue('owner'),
                type: form.getFieldValue('type'),
                dueDate: form.getFieldValue('due').format("YYYY-MM-DD"),
                status: form.getFieldValue('status'),
                comments: form.getFieldValue('comments')
            }
       // })
        axios.put(API_URL + `/api/project_issues_risks_actions/update/${props.match.params.id}`,newData, 
        {headers: {
          'x-access-token': `${token}`, 
        'Access-Control-Allow-Origin': '*'}
       })
            .then(response => {
                message.success("Issue updated sucessfully");
                history.push("/issues/manage");
            })
            .catch(error => {
                // Error
                if (error.response) {
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                } else if (error.request) {
                    console.log(error.request);
                } else {
                    console.log('Error', error.message);
                }
                console.log(error.config);
                message.error("Error In creating Cost", error)
            });
    }
    return (
        <Auxiliary>
            <Form
                form={form}
                initialValues={{ remember: true }}
                name="prjIssues"
                onFinish={onFinish}
                layout="inline"
                align="center"
            >
                <Row gutter={24}>
                    <Col lg={6} md={8} sm={12} xs={24} >
                        <div className="gx-form-row0">
                            <h6 align="left"> Type</h6>
                            <Form.Item
                                name="type"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Enter issue type',
                                    },
                                ]}
                            >
                                <Select style={{ width: '90%' }}>
                                    <Option value="Risk">Risk</Option>
                                    <Option value="Issue">Issue</Option>
                                    <Option value="Decision">Decision</Option>
                                    <Option value="Dependency">Dependency</Option>
                                    <Option value="Chgrequest<">ChgRequest</Option>
                                </Select>
                            </Form.Item>
                        </div>
                    </Col>
                    <Col lg={6} md={8} sm={12} xs={24} >
                        <div className="gx-form-row0">
                            <h6 align="left">Owner</h6>
                            <Form.Item
                                name="owner"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Assign issue to user',
                                    },
                                ]}
                            >
                                <Select
                                    showSearch
                                    style={{ width: '100%' }}
                                    placeholder="Select Owner"
                                    optionFilterProp="children"
                                    onChange={handleChange}
                                    onFocus={handleFocus}
                                    onBlur={handleBlur}
                                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                >
                                    {users.length ? users.map((user, index) => (
                                        <Option key={index} value={user.id}>{user.displayNameTx}</Option>
                                    )) : null}
                                </Select>
                            </Form.Item>
                        </div>
                    </Col>
                    <Col lg={6} md={8} sm={12} xs={24} >
                        <div className="gx-form-row0">
                            <h6 align="left">Due</h6>
                            <Form.Item
                                name="due"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Enter due date',
                                    },
                                ]}
                            >
                                <DatePicker />
                            </Form.Item>
                        </div>
                    </Col>
                    <Col lg={6} md={8} sm={12} xs={24} >
                        <div className="gx-form-row0">
                            <h6 align="left">Priority</h6>
                            <Form.Item
                                name="status"
                                // label="Project Name"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Enter priority',
                                    },
                                ]}
                            >
                                <Select style={{ width: '90%' }}>
                                    <Option value="Critical ">Critical</Option>
                                    <Option value="High">High</Option>
                                    <Option value="Medium">Medium</Option>
                                    <Option value="Low">Low</Option>
                                </Select>
                            </Form.Item>
                        </div>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col lg={24} md={8} sm={12} xs={24} >
                        <div className="gx-form-row0">
                            <h6 align="left">Comments</h6>
                            <Form.Item
                                name="comments"
                                // label="Project Name"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Enter comments',
                                    },
                                ]}
                            >
                                <TextArea rows={4} />
                            </Form.Item>
                        </div>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col lg={24} md={24} sm={24} xs={24} >
                        <div className="gx-form-row0">
                            <Form.Item
                                name="submit"
                            >
                                <Button type="primary" className="add-btn" htmlType="submit">Submit</Button>
                                <Button type="primary" className="add-btn gx-mr-2" onClick={() => history.push('/issues/manage')}>Back</Button>
                            </Form.Item>
                        </div>
                    </Col>
                </Row>

            </Form>
        </Auxiliary>
    );
};
export default EditIssues;
