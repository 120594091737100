import React, {useState } from "react";
import { Card, Select, Table, Row, Col, Tabs,Input, InputNumber, Divider, Form, Button, DatePicker } from "antd";
import Aux from "util/Auxiliary";
import Spend from "components/C4P/WeeklyStatus/Spend"
import Health from "components/C4P/WeeklyStatus/Health"
import Schedule from "components/C4P/WeeklyStatus/Schedule"
import Actions from "components/C4P/WeeklyStatus/Actions"

const WeeklyStatus = () => {
  
  const TabPane = Tabs.TabPane;
  const { Option } = Select;
  const { TextArea } = Input;
  const InputGroup = Input.Group;

  const [cost, setCost] = useState([]);

  const addCost = () => {
    let addCost = [...cost, ...[{
      key: cost.length + 1,
      type: 1,
      planSpend: 1040,
      actualSpend: 500,
      variance: 500
  }]]
    setCost(addCost);
    console.log("add cost", cost);
  };

  const costColumns = [{
    title: 'Spend Type',
    dataIndex: 'type',
    width: 150,
  }, {
    title: 'Planned Spend',
    dataIndex: 'planSpend',
    width: 150,
  }, {
    title: 'Actual Spend',
    dataIndex: 'actualSpend',
  }, {
    title: 'Variance',
    dataIndex: 'variance',
  }];

   return (
    <Aux>
      <Card title="Weekly Status Report">
        <Tabs defaultActiveKey="1" size='4'>
          <TabPane tab="Project Health" key="1">
            <Row gutter={24}>
              <Col lg={8} md={8} sm={12} xs={24} >
                <div className="gx-form-row0">
                  <Form.Item
                    name="scheTxt"
                    label="Schedule"
                  >
                  </Form.Item>
                </div>
              </Col>
              <Col lg={8} md={8} sm={12} xs={24} >
                <div className="gx-form-row0">
                  <Form.Item
                    name="status"
                    //label="Short Name"
                    rules={[
                      {
                        required: true,
                        message: 'Enter project code',
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      style={{ width: '100%' }}
                      placeholder="Select a Value"
                      optionFilterProp="children"
                      //onChange={handleChange}
                      //onFocus={handleFocus}
                      //onBlur={handleBlur}
                      filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    >
                      <Option value={1}>Planned</Option>
                      <Option value={2}>Yet to assign</Option>
                      <Option value={3}>Assigned</Option>
                      <Option value={4}>Hold</Option>
                      <Option value={5}>Unassign</Option>
                    </Select>
                  </Form.Item>
                </div>
              </Col>
              <Col lg={8} md={8} sm={12} xs={24} >
                <div className="gx-form-row0">
                  <Form.Item
                    name="prjtypt"
                    //label="Project Type"
                    rules={[
                      {
                        required: true,
                        message: 'Enter project type',
                      },
                    ]}
                  >
                    <TextArea rows={2} />
                  </Form.Item>
                </div>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col lg={8} md={8} sm={12} xs={24} >
                <div className="gx-form-row0">
                  <Form.Item
                    name="scheTxt"
                    label="Schedule"
                  >
                  </Form.Item>
                </div>
              </Col>
              <Col lg={8} md={8} sm={12} xs={24} >
                <div className="gx-form-row0">
                  <Form.Item
                    name="status"
                    //label="Short Name"
                    rules={[
                      {
                        required: true,
                        message: 'Enter project code',
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      style={{ width: '100%' }}
                      placeholder="Select a Value"
                      optionFilterProp="children"
                      //onChange={handleChange}
                      //onFocus={handleFocus}
                      //onBlur={handleBlur}
                      filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    >
                      <Option value={1}>SOW</Option>
                      <Option value={2}>Monthly</Option>
                      <Option value={3}>Quaterly</Option>
                      <Option value={4}>Annually</Option>
                      <Option value={5}>Milestone</Option>
                    </Select>
                  </Form.Item>
                </div>
              </Col>
              <Col lg={8} md={8} sm={12} xs={24} >
                <div className="gx-form-row0">
                  <Form.Item
                    name="prjtypt"
                    //label="Project Type"
                    rules={[
                      {
                        required: true,
                        message: 'Enter project type',
                      },
                    ]}
                  >
                    <TextArea rows={4} />
                  </Form.Item>
                </div>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col lg={8} md={8} sm={12} xs={24} >
                <div className="gx-form-row0">
                  <Form.Item
                    name="scheTxt"
                    label="Schedule"
                  >
                  </Form.Item>
                </div>
              </Col>
              <Col lg={8} md={8} sm={12} xs={24} >
                <div className="gx-form-row0">
                  <Form.Item
                    name="status"
                    //label="Short Name"
                    rules={[
                      {
                        required: true,
                        message: 'Enter project code',
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      style={{ width: '100%' }}
                      placeholder="Select a Value"
                      optionFilterProp="children"
                      //onChange={handleChange}
                      //onFocus={handleFocus}
                      //onBlur={handleBlur}
                      filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    >
                      <Option value={1}>SOW</Option>
                      <Option value={2}>Monthly</Option>
                      <Option value={3}>Quaterly</Option>
                      <Option value={4}>Annually</Option>
                      <Option value={5}>Milestone</Option>
                    </Select>
                  </Form.Item>
                </div>
              </Col>
              <Col lg={8} md={8} sm={12} xs={24} >
                <div className="gx-form-row0">
                  <Form.Item
                    name="prjtypt"
                    //label="Project Type"
                    rules={[
                      {
                        required: true,
                        message: 'Enter project type',
                      },
                    ]}
                  >
                    <TextArea rows={4} />
                  </Form.Item>
                </div>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col lg={8} md={8} sm={12} xs={24} >
                <div className="gx-form-row0">
                  <Form.Item
                    name="scheTxt"
                    label="Schedule"
                  >
                  </Form.Item>
                </div>
              </Col>
              <Col lg={8} md={8} sm={12} xs={24} >
                <div className="gx-form-row0">
                  <Form.Item
                    name="status"
                    //label="Short Name"
                    rules={[
                      {
                        required: true,
                        message: 'Enter project code',
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      style={{ width: '100%' }}
                      placeholder="Select a Value"
                      optionFilterProp="children"
                      //onChange={handleChange}
                      //onFocus={handleFocus}
                      //onBlur={handleBlur}
                      filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    >
                      <Option value={1}>SOW</Option>
                      <Option value={2}>Monthly</Option>
                      <Option value={3}>Quaterly</Option>
                      <Option value={4}>Annually</Option>
                      <Option value={5}>Milestone</Option>
                    </Select>
                  </Form.Item>
                </div>
              </Col>
              <Col lg={8} md={8} sm={12} xs={24} >
                <div className="gx-form-row0">
                  <Form.Item
                    name="prjtypt"
                    //label="Project Type"
                    rules={[
                      {
                        required: true,
                        message: 'Enter project type',
                      },
                    ]}
                  >
                    <TextArea rows={4} />
                  </Form.Item>
                </div>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col lg={8} md={8} sm={12} xs={24} >
                <div className="gx-form-row0">
                  <Form.Item
                    name="scheTxt"
                    label="Schedule"
                  >
                  </Form.Item>
                </div>
              </Col>
              <Col lg={8} md={8} sm={12} xs={24} >
                <div className="gx-form-row0">
                  <Form.Item
                    name="status"
                    //label="Short Name"
                    rules={[
                      {
                        required: true,
                        message: 'Enter project code',
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      style={{ width: '100%' }}
                      placeholder="Select a Value"
                      optionFilterProp="children"
                      //onChange={handleChange}
                      //onFocus={handleFocus}
                      //onBlur={handleBlur}
                      filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    >
                      <Option value={1}>SOW</Option>
                      <Option value={2}>Monthly</Option>
                      <Option value={3}>Quaterly</Option>
                      <Option value={4}>Annually</Option>
                      <Option value={5}>Milestone</Option>
                    </Select>
                  </Form.Item>
                </div>
              </Col>
              <Col lg={8} md={8} sm={12} xs={24} >
                <div className="gx-form-row0">
                  <Form.Item
                    name="prjtypt"
                    //label="Project Type"
                    rules={[
                      {
                        required: true,
                        message: 'Enter project type',
                      },
                    ]}
                  >
                    <TextArea rows={4} />
                  </Form.Item>
                </div>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col lg={8} md={8} sm={12} xs={24} >
                <div className="gx-form-row0">
                  <Form.Item
                    name="scheTxt"
                    label="Schedule"
                  >
                  </Form.Item>
                </div>
              </Col>
              <Col lg={8} md={8} sm={12} xs={24} >
                <div className="gx-form-row0">
                  <Form.Item
                    name="status"
                    //label="Short Name"
                    rules={[
                      {
                        required: true,
                        message: 'Enter project code',
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      style={{ width: '100%' }}
                      placeholder="Select a Value"
                      optionFilterProp="children"
                      //onChange={handleChange}
                      //onFocus={handleFocus}
                      //onBlur={handleBlur}
                      filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    >
                      <Option value={1}>SOW</Option>
                      <Option value={2}>Monthly</Option>
                      <Option value={3}>Quaterly</Option>
                      <Option value={4}>Annually</Option>
                      <Option value={5}>Milestone</Option>
                    </Select>
                  </Form.Item>
                </div>
              </Col>
              <Col lg={8} md={8} sm={12} xs={24} >
                <div className="gx-form-row0">
                  <Form.Item
                    name="prjtypt"
                    //label="Project Type"
                    rules={[
                      {
                        required: true,
                        message: 'Enter project type',
                      },
                    ]}
                  >
                    <TextArea rows={4} />
                  </Form.Item>
                </div>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col lg={8} md={8} sm={12} xs={24} >
                <div className="gx-form-row0">
                  <Form.Item
                    name="scheTxt"
                    label="Schedule"
                  >
                  </Form.Item>
                </div>
              </Col>
              <Col lg={8} md={8} sm={12} xs={24} >
                <div className="gx-form-row0">
                  <Form.Item
                    name="status"
                    //label="Short Name"
                    rules={[
                      {
                        required: true,
                        message: 'Enter project code',
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      style={{ width: '100%' }}
                      placeholder="Select a Value"
                      optionFilterProp="children"
                      //onChange={handleChange}
                      //onFocus={handleFocus}
                      //onBlur={handleBlur}
                      filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    >
                      <Option value={1}>SOW</Option>
                      <Option value={2}>Monthly</Option>
                      <Option value={3}>Quaterly</Option>
                      <Option value={4}>Annually</Option>
                      <Option value={5}>Milestone</Option>
                    </Select>
                  </Form.Item>
                </div>
              </Col>
              <Col lg={8} md={8} sm={12} xs={24} >
                <div className="gx-form-row0">
                  <Form.Item
                    name="prjtypt"
                    //label="Project Type"
                    rules={[
                      {
                        required: true,
                        message: 'Enter project type',
                      },
                    ]}
                  >
                    <TextArea rows={4} />
                  </Form.Item>
                </div>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col lg={8} md={8} sm={12} xs={24} >
                <div className="gx-form-row0">
                  <Form.Item
                    name="scheTxt"
                    label="Schedule"
                  >
                  </Form.Item>
                </div>
              </Col>
              <Col lg={8} md={8} sm={12} xs={24} >
                <div className="gx-form-row0">
                  <Form.Item
                    name="status"
                    //label="Short Name"
                    rules={[
                      {
                        required: true,
                        message: 'Enter project code',
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      style={{ width: '100%' }}
                      placeholder="Select a Value"
                      optionFilterProp="children"
                      //onChange={handleChange}
                      //onFocus={handleFocus}
                      //onBlur={handleBlur}
                      filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    >
                      <Option value={1}>SOW</Option>
                      <Option value={2}>Monthly</Option>
                      <Option value={3}>Quaterly</Option>
                      <Option value={4}>Annually</Option>
                      <Option value={5}>Milestone</Option>
                    </Select>
                  </Form.Item>
                </div>
              </Col>
              <Col lg={8} md={8} sm={12} xs={24} >
                <div className="gx-form-row0">
                  <Form.Item
                    name="prjtypt"
                    //label="Project Type"
                    rules={[
                      {
                        required: true,
                        message: 'Enter project type',
                      },
                    ]}
                  >
                    <TextArea rows={4} />
                  </Form.Item>
                </div>
              </Col>
            </Row>
          </TabPane>

          <TabPane tab="Project Costs" key="2">
            <Form
              initialValues={{ remember: true }}
              name="prjdetails"
              //onFinish={this.onFinish}
              //onFinishFailed={this.onFinishFailed}
              layout="inline"
              align="center"
            >

              <Row gutter={24}>
                <Col lg={8} md={8} sm={12} xs={24} >
                  <div className="gx-form-row0">
                    <h6 align="left">	Planned Spend</h6>
                    <Form.Item
                      name="resource"
                      // label="Project Name"
                      rules={[
                        {
                          required: true,
                          message: 'Enter resource',
                        },
                      ]}
                    >
                      <InputGroup compact className="gx-mb-3">
                        <Select style={{ width: '80%' }} defaultValue="">
                          <Option value="Resourcing">Resourcing Costs</Option>
                          <Option value="Software">Software Costs</Option>
                          <Option value="Hardware">Hardware Costs	</Option>
                          <Option value="Traning ">Traning Costs</Option>
                          <Option value="Others<">Others</Option>
                        </Select>
                        <InputNumber style={{ width: '20%' }} defaultValue= '0' />
                      </InputGroup>

                    </Form.Item>
                  </div>
                </Col>
                <Col lg={8} md={8} sm={12} xs={24} >
                  <div className="gx-form-row0">
                    <h6 align="left">Actual Spend</h6>
                    <Form.Item
                      name="name"
                      // label="Project Name"
                      rules={[
                        {
                          required: true,
                          message: 'Enter name',
                        },
                      ]}
                    >
                      <InputNumber placeholder="Name" />
                    </Form.Item>
                  </div>
                </Col> 
                <Col lg={8} md={8} sm={12} xs={24} >
                  <Button type="primary" className="add-btn" onClick={addCost}>
                    Add
              </Button>
                </Col>  
              </Row>
{/*               <Row gutter={24}>
                <Col lg={24} md={24} sm={24} xs={24} >
                  <Button type="primary" className="add-btn" onClick={() => this.add()}>
                    Add
              </Button>
                </Col>
              </Row> */}
              <Row gutter={24}>
                <Col lg={24} md={24} sm={24} xs={24} >

                  <Table className="gx-table-responsive" columns={costColumns} dataSource={cost} pagination={{ pageSize: 50 }}
                    scroll={{ y: 240 }} />

                </Col>
              </Row>
            </Form>
          </TabPane>


          <TabPane tab="Project Schedule" key="3">Content of tab 3</TabPane>

          <TabPane tab="Issues, Risks and Actions" key="4">


            {/* <Form
              initialValues={{ remember: true }}
              name="prjdetails"
              //onFinish={this.onFinish}
              //onFinishFailed={this.onFinishFailed}
              layout="inline"
              align="center"
            >

              <Row gutter={24}>
                <Col lg={8} md={8} sm={12} xs={24} >
                  <div className="gx-form-row0">
                    <h6 align="left">Resource</h6>
                    <Form.Item
                      name="resource"
                      // label="Project Name"
                      rules={[
                        {
                          required: true,
                          message: 'Enter resource',
                        },
                      ]}
                    >
                      <InputGroup compact className="gx-mb-3">
                        <Select style={{ width: '40%' }} defaultValue="Option1">
                          <Option value="Resourcing">Resourcing Costs</Option>
                          <Option value="Software">Software Costs</Option>
                          <Option value="Hardware">Hardware Costs	</Option>
                          <Option value="Traning ">Traning Costs</Option>
                          <Option value="Others<">Others</Option>
                        </Select>
                        <InputNumber style={{ width: '50%' }} defaultValue='Resource1' />
                      </InputGroup>

                    </Form.Item>
                  </div>
                </Col>
                <Col lg={8} md={8} sm={12} xs={24} >
                  <div className="gx-form-row0">
                    <h6 align="left">Name</h6>
                    <Form.Item
                      name="name"
                      // label="Project Name"
                      rules={[
                        {
                          required: true,
                          message: 'Enter name',
                        },
                      ]}
                    >
                      <Input placeholder="Name" />
                    </Form.Item>
                  </div>
                </Col>


                <Col lg={8} md={8} sm={12} xs={24} >
                  <div className="gx-form-row0">
                    <h6 align="left">Duration</h6>
                    <Form.Item
                      name="duration"
                      //label="Duration"
                      rules={[
                        {
                          required: true,
                          message: 'Enter Duration',
                        },
                      ]}
                    >

                      <RangePicker className="gx-mb-3 gx-w-100" />
                    </Form.Item>
                  </div>
                </Col>

                <Col lg={8} md={8} sm={12} xs={24} >
                  <div className="gx-form-row0">
                    <h6 align="left">Perctange of allocation</h6>
                    <Form.Item
                      name="name"
                      // label="Project Name"
                      rules={[
                        {
                          required: true,
                          message: 'Enter name',
                        },
                      ]}
                    >
                      <InputNumber placeholder="Perctange of allocation" />
                    </Form.Item>
                  </div>
                </Col>

                <Col lg={8} md={8} sm={12} xs={24} >
                  <h6 align="left">Status</h6>
                  <div className="gx-form-row0">
                    <Form.Item
                      name="status"
                      //label="Billable"
                      rules={[
                        {
                          required: true,
                          message: 'Status',
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        style={{ width: '100%' }}
                        placeholder="Select a Value"
                        optionFilterProp="children"
                        // onChange={handleChange}
                        // onFocus={handleFocus}
                        // onBlur={handleBlur}
                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                      >
                        <Option value={1}>SOW</Option>
                        <Option value={2}>Monthly</Option>
                        <Option value={3}>Quaterly</Option>
                        <Option value={4}>Annually</Option>
                        <Option value={5}>Milestone</Option>
                      </Select>
                    </Form.Item>
                  </div>
                </Col>


                <Col lg={8} md={8} sm={12} xs={24} >
                  <div className="gx-form-row0">
                    <Form.Item
                      name="comments"
                      // label="Project Name"
                      rules={[
                        {
                          required: true,
                          message: 'Enter comments',
                        },
                      ]}
                    >

                      <h6 align="left">Comments</h6>
                      <TextArea rows={4} />

                    </Form.Item>
                  </div>
                </Col>

              </Row>
              <Row gutter={24}>
                <Col lg={24} md={24} sm={24} xs={24} >
                  <Button type="primary" className="add-btn" onClick={() => this.add()}>
                    Add
              </Button>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col lg={24} md={24} sm={24} xs={24} >

                  <Table className="gx-table-responsive" columns={columns} dataSource={data} pagination={{ pageSize: 50 }}
                    scroll={{ y: 240 }} />

                </Col>
              </Row>
            </Form>
 */}

          </TabPane>
        </Tabs>



     
        <Row gutter={24}>
          <Col xl={12} lg={12} md={12} sm={24} xs={24}>
          <Divider orientation="left">Project Health</Divider>
            <Health />
          </Col>
          <Col xl={12} lg={12} md={12} sm={24} xs={24}>
          <Divider orientation="left">Project Finances</Divider>
            <Spend />
          </Col>
        </Row>
         <Divider orientation="left">Project Schedule</Divider>
        <Schedule />
        <Divider orientation="left">Issues, Risks and Actions</Divider>
        <Actions /> 

      </Card>

    </Aux >
  );
};
export default WeeklyStatus;
