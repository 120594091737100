import React, { useEffect, useState } from "react";
import axios from 'axios';
import { Table, Col, Row } from "antd";
import Auxiliary from "util/Auxiliary";
import { API_URL } from 'constants/APIConfig';
import moment from "moment";
import JSGanttComponent from 'react-jsgantt'

const PlanReport = (props) => {
  const userDetails = JSON.parse(sessionStorage.getItem('user_details'));
  const token = sessionStorage.getItem('token');
  
  const [projectPlan, setProjectPlan] = useState([]);

  const [planViewMode, setPlanViewMode] = useState("Week");

  const [planCols, setPlanCols] = useState([
    {
      title: 'Name', dataIndex: 'titleTx', fixed: 'left', width: 200,
      sorter: (a, b) => String(a.titleTx).localeCompare(String(b.titleTx)),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Start Date', dataIndex: 'startDateDt', width: 120,
      sorter: (a, b) => String(a.startDateDt).localeCompare(String(b.startDateDt)),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'End Date', dataIndex: 'endDateDt', width: 120,
      sorter: (a, b) => String(a.endDateDt).localeCompare(String(b.endDateDt)),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Duration', dataIndex: 'duration', width: 100,
      sorter: (a, b) => String(a.duration).localeCompare(String(b.duration)),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Assigned To', dataIndex: 'assignedTo', width: 150,
      sorter: (a, b) => String(a.assignedTo).localeCompare(String(b.assignedTo)),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Status', dataIndex: 'deliveryMilestoneStatus', width: 100,
      sorter: (a, b) => String(a.deliveryMilestoneStatus).localeCompare(String(b.deliveryMilestoneStatus)),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Comments',fixed: 'right',  dataIndex: 'comments', width: 200,
      sorter: (a, b) => String(a.comments).localeCompare(String(b.comments)),
      sortDirections: ['descend', 'ascend'],
    },
  ]);


 const data = [{
      'pID': 1,
      'pName': 'Define Chart API v1',
      'pStart': '',
      'pEnd': '',
      'pClass': 'ggroupblack',
      'pLink': '',
      'pMile': 0,
      'pRes': 'Brian',
      'pComp': 0,
      'pGroup': 1,
      'pParent': 0,
      'pOpen': 1,
      'pDepend': '',
      'pCaption': '',
      'pNotes': 'Some Notes text'
    },
    {
      'pID': 11,
      'pName': 'Chart Object',
      'pStart': '2017-02-20',
      'pEnd': '2017-02-20',
      'pClass': 'gmilestone',
      'pLink': '',
      'pMile': 1,
      'pRes': 'Shlomy',
      'pComp': 100,
      'pGroup': 0,
      'pParent': 1,
      'pOpen': 1,
      'pDepend': '',
      'pCaption': '',
      'pNotes': ''
    },
    {
      'pID': 12,
      'pName': 'Task Objects',
      'pStart': '',
      'pEnd': '',
      'pClass': 'ggroupblack',
      'pLink': '',
      'pMile': 0,
      'pRes': 'Shlomy',
      'pComp': 40,
      'pGroup': 1,
      'pParent': 1,
      'pOpen': 1,
      'pDepend': '',
      'pCaption': '',
      'pNotes': ''
    },
    {
      'pID': 121,
      'pName': 'Constructor Proc #1234 of February 2017',
      'pStart': '2017-02-21',
      'pEnd': '2017-03-09',
      'pClass': 'gtaskblue',
      'pLink': '',
      'pMile': 0,
      'pRes': 'Brian T.',
      'pComp': 60,
      'pGroup': 0,
      'pParent': 12,
      'pOpen': 1,
      'pDepend': '',
      'pCaption': '',
      'pNotes': ''
    },
    {
      'pID': 122,
      'pName': 'Task Variables',
      'pStart': '2017-03-06',
      'pEnd': '2017-03-11',
      'pPlanStart': '2017-03-03',
      'pPlanEnd': '2017-03-09',
      'pClass': 'gtaskred',
      'pLink': '',
      'pMile': 0,
      'pRes': 'Brian',
      'pComp': 60,
      'pGroup': 0,
      'pParent': 12,
      'pOpen': 1,
      'pDepend': 121,
      'pCaption': '',
      'pNotes': ''
    },
    {
      'pID': 123,
      'pName': 'Task by Minute/Hour',
      'pStart': '',
      'pEnd': '',
      'pPlanStart': '2017-03-01',
      'pPlanEnd': '2017-03-15 12:00',
      'pClass': 'gtaskyellow',
      'pLink': '',
      'pMile': 0,
      'pRes': 'Ilan',
      'pComp': 60,
      'pGroup': 0,
      'pParent': 12,
      'pOpen': 1,
      'pDepend': '',
      'pCaption': '',
      'pNotes': '',
      'pCost': 1000
    },
    {
      'pID': 124,
      'pName': 'Task Functions',
      'pStart': '2017-03-09',
      'pEnd': '2017-03-29',
      'pClass': 'gtaskred',
      'pLink': '',
      'pMile': 0,
      'pRes': 'Anyone',
      'pComp': 60,
      'pGroup': 0,
      'pParent': 12,
      'pOpen': 1,
      'pDepend': '123SS',
      'pCaption': 'This is a caption',
      'pNotes': null
    },
    {
      'pID': 2,
      'pName': 'Create HTML Shell',
      'pStart': '2017-03-24',
      'pEnd': '2017-03-24',
      'pClass': 'gtaskyellow',
      'pLink': '',
      'pMile': 0,
      'pRes': 'Brian',
      'pComp': 20,
      'pGroup': 0,
      'pParent': 0,
      'pOpen': 1,
      'pDepend': 122,
      'pCaption': '',
      'pNotes': ''
    },
    {
      'pID': 3,
      'pName': 'Code Javascript',
      'pStart': '',
      'pEnd': '',
      'pClass': 'ggroupblack',
      'pLink': '',
      'pMile': 0,
      'pRes': 'Brian',
      'pComp': 0,
      'pGroup': 1,
      'pParent': 0,
      'pOpen': 1,
      'pDepend': '',
      'pCaption': '',
      'pNotes': ''
    },
    {
      'pID': 31,
      'pName': 'Define Variables',
      'pStart': '2017-02-25',
      'pEnd': '2017-03-17',
      'pClass': 'gtaskpurple',
      'pLink': '',
      'pMile': 0,
      'pRes': 'Brian',
      'pComp': 30,
      'pGroup': 0,
      'pParent': 3,
      'pOpen': 1,
      'pDepend': '',
      'pCaption': '',
      'pNotes': ''
    },
    {
      'pID': 32,
      'pName': 'Calculate Chart Size',
      'pStart': '2017-03-15',
      'pEnd': '2017-03-24',
      'pClass': 'gtaskgreen',
      'pLink': '',
      'pMile': 0,
      'pRes': 'Shlomy',
      'pComp': 40,
      'pGroup': 0,
      'pParent': 3,
      'pOpen': 1,
      'pDepend': '',
      'pCaption': '',
      'pNotes': ''
    },
    {
      'pID': 33,
      'pName': 'Draw Task Items',
      'pStart': '',
      'pEnd': '',
      'pClass': 'ggroupblack',
      'pLink': '',
      'pMile': 0,
      'pRes': 'Someone',
      'pComp': 40,
      'pGroup': 2,
      'pParent': 3,
      'pOpen': 1,
      'pDepend': '',
      'pCaption': '',
      'pNotes': ''
    },
    {
      'pID': 332,
      'pName': 'Task Label Table',
      'pStart': '2017-03-06',
      'pEnd': '2017-03-09',
      'pClass': 'gtaskblue',
      'pLink': '',
      'pMile': 0,
      'pRes': 'Brian',
      'pComp': 60,
      'pGroup': 0,
      'pParent': 33,
      'pOpen': 1,
      'pDepend': '',
      'pCaption': '',
      'pNotes': ''
    },
    {
      'pID': 333,
      'pName': 'Task Scrolling Grid',
      'pStart': '2017-03-11',
      'pEnd': '2017-03-20',
      'pClass': 'gtaskblue',
      'pLink': '',
      'pMile': 0,
      'pRes': 'Brian',
      'pComp': 0,
      'pGroup': 0,
      'pParent': 33,
      'pOpen': 1,
      'pDepend': '332',
      'pCaption': '',
      'pNotes': ''
    },
    {
      'pID': 34,
      'pName': 'Draw Task Bars',
      'pStart': '',
      'pEnd': '',
      'pClass': 'ggroupblack',
      'pLink': '',
      'pMile': 0,
      'pRes': 'Anybody',
      'pComp': 60,
      'pGroup': 1,
      'pParent': 3,
      'pOpen': 0,
      'pDepend': '',
      'pCaption': '',
      'pNotes': ''
    },
    {
      'pID': 341,
      'pName': 'Loop each Task',
      'pStart': '2017-03-26',
      'pEnd': '2017-04-11',
      'pClass': 'gtaskred',
      'pLink': '',
      'pMile': 0,
      'pRes': 'Brian',
      'pComp': 60,
      'pGroup': 0,
      'pParent': 34,
      'pOpen': 1,
      'pDepend': '',
      'pCaption': '',
      'pNotes': ''
    },
    {
      'pID': 342,
      'pName': 'Calculate Start/Stop',
      'pStart': '2017-04-12',
      'pEnd': '2017-05-18',
      'pClass': 'gtaskpink',
      'pLink': '',
      'pMile': 0,
      'pRes': 'Brian',
      'pComp': 60,
      'pGroup': 0,
      'pParent': 34,
      'pOpen': 1,
      'pDepend': '',
      'pCaption': '',
      'pNotes': ''
    },
    {
      'pID': 343,
      'pName': 'Draw Task Div',
      'pStart': '2017-05-13',
      'pEnd': '2017-05-17',
      'pClass': 'gtaskred',
      'pLink': '',
      'pMile': 0,
      'pRes': 'Brian',
      'pComp': 60,
      'pGroup': 0,
      'pParent': 34,
      'pOpen': 1,
      'pDepend': '',
      'pCaption': '',
      'pNotes': ''
    },
    {
      'pID': 344,
      'pName': 'Draw Completion Div',
      'pStart': '2017-05-17',
      'pEnd': '2017-06-04',
      'pClass': 'gtaskred',
      'pLink': '',
      'pMile': 0,
      'pRes': 'Brian',
      'pComp': 60,
      'pGroup': 0,
      'pParent': 34,
      'pOpen': 1,
      'pDepend': '342,343',
      'pCaption': '',
      'pNotes': ''
    },
    {
      'pID': 35,
      'pName': 'Make Updates',
      'pStart': '2017-07-17',
      'pEnd': '2017-09-04',
      'pClass': 'gtaskpurple',
      'pLink': '',
      'pMile': 0,
      'pRes': 'Brian',
      'pComp': 30,
      'pGroup': 0,
      'pParent': 3,
      'pOpen': 1,
      'pDepend': '333',
      'pCaption': '',
      'pNotes': ''
    }];





  const editorOptions = {
    vCaptionType: 'Complete',  // Set to Show Caption : None,Caption,Resource,Duration,Complete,
    vQuarterColWidth: 36,
    vDateTaskDisplayFormat: 'day dd month yyyy', // Shown in tool tip box
    vDayMajorDateDisplayFormat: 'mon yyyy - Week ww', // Set format to display dates in the "Major" header of the "Day" view
    vWeekMinorDateDisplayFormat: 'dd mon'
  };

  let devMilestones = [];
  const getProjectPlan = () => {
   axios.get(API_URL + `/api/project_milestones/project/${props.id}/type/DELIVERY`, 
   {headers: {
     'x-access-token': `${token}`, 
   'Access-Control-Allow-Origin': '*'}
  })
      .then(response => {
        if (Object.keys(response.data.data).length > 0) {
          response.data.data.map((item, index) => {
            devMilestones.push({
              id: item.id,
              start: new Date(moment(item.startDateDt).format('YYYY'), moment(item.startDateDt).format('M'), moment(item.startDateDt).format('D')),
              end: new Date(moment(item.endDateDt).format('YYYY'), moment(item.endDateDt).format('M'), moment(item.endDateDt).format('D')),
              name: item.titleTx,
              progress: 45,
              isDisabled: true,
              styles: { progressColor: '#ffbb54', progressSelectedColor: '#ff9e0d' },
            })
          })
          setProjectPlan(response.data.data);
        }
      }).catch(error => {
        // Error
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log('Error', error.message);
        }
        console.log(error.config);
      });
  }
  useEffect(() => {
    getProjectPlan();
  }, [])

  return (
    <Auxiliary>
      <Row gutter={24}>
        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
         {(() => {
            if (projectPlan.length > 0) {
              return   <JSGanttComponent data={data} options={editorOptions} />
            }
          })()} 
        </Col>
      </Row>
    </Auxiliary>
  );
};

export default PlanReport;
