import React, { useEffect, useState } from "react";
import axios from 'axios';
import { List, Collapse, Descriptions, Select, Form, Input, Row, Col } from "antd";
import Auxiliary from "util/Auxiliary";
import { API_URL } from 'constants/APIConfig';
import { FormOutlined, EyeOutlined } from '@ant-design/icons';


const Panel = Collapse.Panel;
const { Option } = Select;

const ProjectIssues = (props) => {
  const userDetails = JSON.parse(sessionStorage.getItem('user_details'));
  const token = sessionStorage.getItem('token');

  const [issues, setIssues] = useState([]);
  const [projects, setProjects] = useState([]);
  const [items, setItems] = useState([]);
  const [form] = Form.useForm()

  function filterList(event) {
    var updatedList = issues;
    updatedList = updatedList.filter(item => {
      return item.prjname.toLowerCase().search(
        event.target.value.toLowerCase()) !== -1;
    });
    setItems(updatedList);
  }

  /*   const getProjectList = () => {
      axios.get(API_URL + `/api/project/v2/projectsByManager?userId=${userDetails.id}`)
        .then(response => {
          if (Object.keys(response.data.data).length > 0) {
            setProjects(response.data.data);
            form.setFieldsValue({
              prjname: response.data.data[0].id,
            })
            getProjIssueList(response.data.data[0].id)
          }
        })
        .catch(error => {
          // Error
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log('Error', error.message);
          }
          console.log(error.config);
        });
    }
    const onProjectChange = value => {
      getProjIssueList(value)
    }; */

  let i = 0;

  let issuesEntries = [];
  const getProjIssueList = (projId) => {
    let url = "";
    if (props.id !== undefined) {
      url = API_URL + `/api/project_issues_risks_actions/getDetails?projectId=${props.id}`;
    } else {
      //url = API_URL + `/api/project_issues_risks_actions/getDetails?projectId=${projId}`;
      url = API_URL + `/api/project_issues_risks_actions/getFullDetails`;

    }
    axios.get(url, 
      {headers: {
        'x-access-token': `${token}`, 
      'Access-Control-Allow-Origin': '*'}
     })
      .then(response => {
        if (Object.keys(response.data.data).length > 0) {
          response.data.data.map((item, index) => {
            issuesEntries.push({
              id: item.id,
              prjname: item.project && item.project.name,
              type: item.type,
              owner: item.assignedUser && item.assignedUser.displayNameTx,
              status: item.status,
              dueDate: item.dueDate,
              comments: item.comments,
            })
          })
          setIssues(issuesEntries);
          setItems(issuesEntries);
        }
        console.log("Issue List", response.data.data);
      })
      .catch(error => {
        // Error
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log('Error', error.message);
        }
        console.log(error.config);
      });
  }
  useEffect(() => {
    getProjIssueList();
    // getProjectList();
  }, [])
  return (
    <Auxiliary>
      {/*             <Form
        form={form}
        initialValues={{ remember: true }}
        name="ohealth"
       // onFinish={onFinish}
        layout="inline"
        align="center"
      >
      <Row gutter={24}>
       <Col lg={12} md={12} sm={24} xs={24}  >
          <div className="gx-form-row0" >
            <Form.Item
              name="prjname"
              // label="Project Name"
              rules={[
                {
                  required: true,
                  message: 'Enter project name',
                },
              ]}
            >
              <Select
                showSearch
                style={{ width: '60%' }}
                placeholder="Projects"
                optionFilterProp="children"
                onChange={onProjectChange}
                // size="small"
                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              >
                {
                  projects.length ? projects.map((project, index) => (
                    <Option key={index} value={project.id}>{project.name}</Option>
                  )) : null
                }
              </Select>
            </Form.Item>
          </div>
        </Col>
      </Row>
      </Form> */}


          <Input type="text" placeholder="Search" onChange={filterList} /> 
<hr></hr>
      <List
        itemLayout="vertical"
        pagination={{
          onChange: page => {
            console.log(page);
          },
          pageSize: 10,
        }}
        dataSource={items}
        renderItem={item => (
          <List.Item key={i + 1} className="ProjectListBlock">
            <Collapse bordered={false} expandIconPosition='right' className="mainAcc">
              <Panel header="" key={i + 1}
                extra={
                  <ul className="gx-list-inline gx-ml-auto gx-mb-0 gx-text-grey reportsPage">
                    <li className="projectText projectBlock">{item.prjname}</li>
                    <li className="statusBlock projectBlock">
                      <div>
                        <div><li> {item.type}</li></div>
                        <div><li> {item.owner}</li></div>
                        <div><li> {item.dueDate}</li></div>
                        <div><li> {item.status}</li></div>
                      </div>
                    </li>
                  </ul>
                }
              >
                <Descriptions size="small">
                  <Descriptions.Item label="Comments">{item.comments}</Descriptions.Item>
                </Descriptions>
              </Panel>
            </Collapse>

          </List.Item>
        )}
      />
    </Auxiliary>
  );
};

export default ProjectIssues;
