import React, { useEffect, useState } from "react";
import axios from 'axios';
import { } from "antd";
import {List, Col, Row,Tooltip,Collapse } from "antd";
import Auxiliary from "util/Auxiliary";
import { API_URL } from 'constants/APIConfig';
import { FormOutlined, EyeOutlined } from '@ant-design/icons';

const Panel = Collapse.Panel;
const ProjectHealth = (props) => {
  const userDetails = JSON.parse(sessionStorage.getItem('user_details'));
  const token = sessionStorage.getItem('token');
  
  const [health, setHealth] = useState([]);
  const [colors, SetColors] = useState(["red", "green", "orange", "blue"]);
  let i = 0;

  let healthEntries = [];
  const getProjHealthList = () => {
    axios.get(API_URL + `/api/project_health/getProjectHealth?projectId=${props.id}`, 
    {headers: {
      'x-access-token': `${token}`, 
    'Access-Control-Allow-Origin': '*'}
   })
   .then(response => {
        if (Object.keys(response.data.data).length > 0) {
          console.log("Health",response.data.data);
          response.data.data.map((item, index) => {
            healthEntries.push({
              id: item.id,
              weekNumber: item.weekNumber,
              year: item.year,
              budget: item.budget,
              changeMang: item.changeMang,
              quality: item.quality,
              resource: item.resource,
              schedule: item.schedule,
              scope: item.scope,
              budgetDesc: item.budgetDesc,
              changeMangDesc: item.changeMangDesc,
              qualityDesc: item.qualityDesc,
              resourceDesc: item.resourceDesc,
              scheduleDesc: item.scheduleDesc,
              scopeDesc: item.scopeDesc,
              comments: item.comments,
            })
          })
          setHealth(healthEntries);
        } else {
          setHealth(healthEntries);
        }
      })
      .catch(error => {
        // Error
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log('Error', error.message);
        }
        console.log(error.config);
      });
  }
  useEffect(() => {
    getProjHealthList();
  }, [])

  return (
    <Auxiliary>
      <List
        itemLayout="vertical"
        pagination={{
          onChange: page => {
            console.log(page);
          },
          pageSize: 10,
        }}
        dataSource= {health}
        renderItem={item => (
          <List.Item key={i + 1} className="ProjectListBlock">
            
                <Collapse bordered={false} expandIconPosition='right' className="mainAcc">
                  <Panel header="" key={i + 1}
                    extra={
                      <ul className="gx-list-inline gx-ml-auto gx-mb-0 gx-text-grey reportsPage">
                        <li className="projectText projectBlock">{item.project}</li>
                        <li className="statusBlock projectBlock">
                          <div>
                            <div><li style={{ color: colors[item.scope - 1] }}><i className="icon icon-circle" ></i><br></br><span>Scope</span></li></div>
                            <div><li style={{ color: colors[item.resource - 1] }}><i className="icon icon-circle" ></i><br></br><span>Resource</span></li></div>
                            <div><li style={{ color: colors[item.schedule - 1] }}><i className="icon icon-circle" ></i><br></br><span>Schedule</span></li></div>
                            <div><li style={{ color: colors[item.quality - 1] }}><i className="icon icon-circle" ></i><br></br><span>Quality</span></li></div>
                            <div><li style={{ color: colors[item.changeMang - 1] }}><i className="icon icon-circle" ></i><br></br><span>ChMang</span></li></div>
                            <div><li style={{ color: colors[item.budget - 1] }}><i className="icon icon-circle" ></i><br></br><span>Budget</span></li></div>
                          </div>
                        </li>
                      </ul>
                    }
                  >
                    <Row gutter={24}>
                      <Col xl={4} lg={4} md={4} sm={4} xs={4} className="font600">Scope:</Col>
                      <Col xl={20} lg={20} md={20} sm={20} xs={20}>{item.scopeDesc}</Col>
                    </Row>
                    <Row gutter={24}>
                      <Col xl={4} lg={4} md={4} sm={4} xs={4} className="font600">Resource:</Col>
                      <Col xl={20} lg={20} md={20} sm={20} xs={20}>{item.resourceDesc}</Col>
                    </Row>
                    <Row gutter={24}>
                      <Col xl={4} lg={4} md={4} sm={4} xs={4} className="font600">Schedule:</Col>
                      <Col xl={20} lg={20} md={20} sm={20} xs={20}>{item.scheduleDesc}</Col>
                    </Row>
                    <Row gutter={24}>
                      <Col xl={4} lg={4} md={4} sm={4} xs={4} className="font600">Quality:</Col>
                      <Col xl={20} lg={20} md={20} sm={20} xs={20}>{item.qualityDesc}</Col>
                    </Row>
                    <Row gutter={24}>
                      <Col xl={4} lg={4} md={4} sm={4} xs={4} className="font600">ChMang:</Col>
                      <Col xl={20} lg={20} md={20} sm={20} xs={20}>{item.changeMangDesc}</Col>
                    </Row>
                    <Row gutter={24}>
                      <Col xl={4} lg={4} md={4} sm={4} xs={4} className="font600">Budget:</Col>
                      <Col xl={20} lg={20} md={20} sm={20} xs={20}>{item.budgetDesc}</Col>
                    </Row>
                    <hr></hr>
                    <Row gutter={24}>
                      <Col xl={4} lg={4} md={4} sm={4} xs={4} className="font600">Comments:</Col>
                      <Col xl={20} lg={20} md={20} sm={20} xs={20}>{item.comments}</Col>
                    </Row>
                  </Panel>
                </Collapse>
              
          </List.Item>
        )}
      />

    </Auxiliary>
  );
};

export default ProjectHealth;
