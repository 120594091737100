import React from "react";
import axios from 'axios';
import { Select, Row, Col, Button, Form, Input, Breadcrumb, Switch, Spin } from "antd";
import Auxiliary from "util/Auxiliary";
import TaskStats from "../utils/TaskStats";
import { API_URL } from 'constants/APIConfig';
import { FormOutlined, SearchOutlined, FileAddTwoTone } from '@ant-design/icons';
import get from "lodash.get";
import isequal from "lodash.isequal";
import Highlighter from "react-highlight-words";
import { Table } from "ant-table-extensions";
import { Link } from "react-router-dom";

const { Option } = Select;

class ManageTasks extends React.Component {
  formRef = React.createRef();
  state = {
    filteredInfo: {},
    searchText: "",
    searchedColumn: "",
    projectId: "",
    projects: [],
    treeData: [],
    taskEditId: 0,
    taskEditPop: false,
    loading: false,
    userID: JSON.parse(sessionStorage.getItem('user_details')).id,
    token: sessionStorage.getItem('token'),
    selectedTaskCard: 'Tasks',
    allData: [],
    allProjects: [],
    projectManagers: [{ id: 0, displayNameTx: 'All  Project Managers' }],
    pmId: '',
    isLoadingData: false
  }

  editTaskRcrd = (id) => {
    this.setState({ taskEditId: id });
  }

  handleChange = (value) => {
    this.getTaskLists(value);
    this.setState({ projectId: value });
  }

  getTaskLists = (projID, selectedTaskCard) => {
    this.setState({ loading: true });
    axios.get(API_URL + '/api/task/getTasksWithSubTasksTree?userId=1&projectId=' + projID,
      {
        headers: {
          'x-access-token': `${this.state.token}`,
          'Access-Control-Allow-Origin': '*'
        }
      })
      .then(response => {
        var data1 = []
        if (Object.keys(response.data.data).length > 0) {
          response.data.data.map((item, index) => {
            var users = [];
            item.assignedUser.map((user, index) => {
              users.push(user.displayNameTx)
            })
            data1.push({
              ...item,
              billability: item.billabilityNm == 1 ? 'CR' : 'IS',
              totalassignees: item.assignedUser.length,
              completion: item.completion + "%",
              owner: item.assignedUser.length > 0 ? users.join(",") : "",
              taskstatus: item.taskstatus && item.taskstatus.statusValueTx,
              parentTaskNm: item.parentTaskNm || ''
            })
          })
        }
        this.setState({ treeData: data1, allData: data1, loading: false, filteredInfo: {} });
        this.onTaskCardSelected(selectedTaskCard || 'Tasks')
      })
  }
  getProjectList = () => {
    axios.get(API_URL + `/api/project/v2/allProjectNames?userId=${this.state.userID}`,
      {
        headers: {
          'x-access-token': `${this.state.token}`,
          'Access-Control-Allow-Origin': '*'
        }
      })
      .then(response => {
        if (Object.keys(response.data.data).length > 0) {

          let pmIdList = new Set()
          let pmList = [{ id: 0, displayNameTx: 'All  Project Managers' }]
          response.data.data.forEach(d => {
            if (d.ProjectManager && d.ProjectManager.id && !pmIdList.has(d.ProjectManager.id)) {
              pmIdList.add(d.ProjectManager.id)
              pmList.push(d.ProjectManager)
            }
          })
          let taskState = JSON.parse(sessionStorage.getItem('taskState') || '{}');
          sessionStorage.removeItem('taskState')
          if (taskState.projectId) {
            this.getTaskLists(taskState.projectId, taskState.selectedTaskCard);
            this.setState({ projects: response.data.data, projectId: taskState.projectId,
               projectManagers: pmList, allProjects: response.data.data,
               pmId: taskState.pmId });
            this.formRef.current.setFieldsValue({
              prjname: taskState.projectId,
              prjManager: taskState.pmId
            })
          } else {
            this.getTaskLists(response.data.data[0].id);
            this.setState({ projects: response.data.data, projectId: response.data.data[0].id,
               projectManagers: pmList, allProjects: response.data.data,
               pmId: 0 });
            this.formRef.current.setFieldsValue({
              prjname: response.data.data[0].id,
              prjManager: 0
            })
          }
        }
      })
      .catch(error => {
        console.log(error);
      });
  }

  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => (
      <div className="custom-filter-dropdown">
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}

          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon="search"
          size="small"
          style={{ width: 70, marginRight: 8, float: 'left' }}
        >
          {/*   Search */}
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="small"
          style={{ width: 70, float: 'right' }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined />
    ),
    onFilter: (value, record) => {
      return get(record, dataIndex)
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase());
    },

    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text => {
      return isequal(this.state.searchedColumn, dataIndex) ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      );
    }
  })

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex
    });
  }

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: "" });
  }

  componentDidMount() {
    this.getProjectList();
  }

  onTaskCardSelected = (v) => {
    if (this.state.selectedTaskCard !== v) {
      this.setState({ selectedTaskCard: v, filteredInfo: {} });
      if (v === 'Tasks') {
        this.setState({ treeData: this.state.allData });
      } else {
        let data1 = this.state.allData.filter(t => t.taskstatus === v)
        this.setState({ treeData: data1 });
      }
    }
  }

  setProjectData = () => {
    let pm = this.formRef.current.getFieldValue('prjManager')
    let status = this.formRef.current.getFieldValue('active')
    this.formRef.current.setFieldsValue({
      prjname: undefined,
    })

    if (pm === 0) {
      let prjArr = []
      this.state.allProjects.forEach(p => {
        if (status) {
          if (p.statusId !== 3) {
            prjArr.push(p)
          }
        } else {
          if (p.statusId === 3) {
            prjArr.push(p)
          }
        }
      })
      this.setState({ projects: prjArr, pmId: pm, treeData: [], selectedTaskCard: 'Tasks', filteredInfo: {}, projectId: "", allData: []})
    } else {
      let projectsArr = []
      this.state.allProjects.forEach(p => {
        if (p.projectManager === pm) {
          if (status) {
            if (p.statusId !== 3) {
              projectsArr.push(p)
            }
          } else {
            if (p.statusId === 3) {
              projectsArr.push(p)
            }
          }
        }
      })
      this.setState({ projects: projectsArr, pmId: pm, treeData: [], selectedTaskCard: 'Tasks', filteredInfo: {}, projectId: "", allData: []});
    }
    
  }

  handleChangeTable = (pagination, filters, sorter) => {
    this.setState({ filteredInfo: filters });
  }

  navigateManage = () => {
    sessionStorage.setItem('taskState', JSON.stringify({ projectId: this.state.projectId, selectedTaskCard: this.state.selectedTaskCard, pmId: this.state.pmId }))
  }

  render() {
    const columns = [
      {
        title: 'Task ID', dataIndex: 'key', key: 'id', fixed: 'left', width: 120,
        sorter: (a, b) => String(a.title).localeCompare(String(b.title)),
        sortDirections: ['descend', 'ascend'],
        ...this.getColumnSearchProps("key"),
        filteredValue: this.state.filteredInfo.id || null,
      },
      {
        title: 'Parent Task ID', dataIndex: 'parentTaskNm', key: 'parentTaskNm', width: 120,
        sorter: (a, b) => String(a.title).localeCompare(String(b.title)),
        sortDirections: ['descend', 'ascend'],
        ...this.getColumnSearchProps("parentTaskNm"),
        filteredValue: this.state.filteredInfo.parentTaskNm || null,
      },
      {
        title: 'Name', dataIndex: 'title', key: 'name', width: 200,
        sorter: (a, b) => String(a.title).localeCompare(String(b.title)),
        sortDirections: ['descend', 'ascend'],
        ...this.getColumnSearchProps("title"),
        filteredValue: this.state.filteredInfo.name || null,
      },
      {
        title: 'Start Date', dataIndex: 'startDateDt', key: 'sdt', width: 120,
        sorter: (a, b) => String(a.startDateDt).localeCompare(String(b.startDateDt)),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'End Date', dataIndex: 'dueDateDt', key: 'edt', width: 120,
        sorter: (a, b) => String(a.dueDateDt).localeCompare(String(b.dueDateDt)),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Owner', key: 'owner', dataIndex: 'owner', width: 200,
        sorter: (a, b) => String(a.owner).localeCompare(String(b.owner)),
        sortDirections: ['descend', 'ascend'],
        ...this.getColumnSearchProps("owner"),
        filteredValue: this.state.filteredInfo.owner || null,
      },
      {
        title: 'Users Count', dataIndex: 'totalassignees', key: 'tassigned', width: 120, ellipsis: true,
        sorter: (a, b) => String(a.totalassignees).localeCompare(String(b.totalassignees)),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Task Type', dataIndex: 'billability', key: 'billable', width: 120,
        sorter: (a, b) => String(a.billability).localeCompare(String(b.billability)),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Estd. Hours', dataIndex: 'estimatedHoursNm', key: 'estdhrs', width: 120,
        sorter: (a, b) => String(a.estimatedHoursNm).localeCompare(String(b.estimatedHoursNm)),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Actual. Hours', dataIndex: 'spentHoursNm', key: 'spenthrs', width: 150,
        sorter: (a, b) => String(a.spentHoursNm).localeCompare(String(b.spentHoursNm)),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Completion %', dataIndex: 'completion', key: 'completion', width: 150,
        sorter: (a, b) => String(a.completion).localeCompare(String(b.completion)),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Status', dataIndex: "taskstatus", key: "taskstatus", width: 120,
        sorter: (a, b) => String(a.taskstatus).localeCompare(String(b.taskstatus)),
        sortDirections: ['descend', 'ascend'],
        ...this.getColumnSearchProps("taskstatus"),
        filteredValue: this.state.filteredInfo.taskstatus || null,
      },
      {
        title: 'Action', fixed: 'right', width: 100, dataIndex: 'key', render: text => <div><span>
          <Link to={'/master/tasks/manage/' + text} onClick={this.navigateManage}><FormOutlined style={{ padding: '10px' }} /></Link></span> </div>
      },
    ];
    const search = this.props.location.search;
    const query = new URLSearchParams(search);
    return (
      <Auxiliary>
        <Form ref={this.formRef} name="taskfrm-ref" style={{ width: "100%" }}>
          <Row className="m_0 gx-pt-2">
            <Col xl={4} lg={4} md={4} sm={24} xs={24} className="flex_style gx-pl-0 ant-row-start">
              <Breadcrumb>
                <Breadcrumb.Item>{query.get("module")}</Breadcrumb.Item>
                <Breadcrumb.Item className="text-helight"><i className="icon icon-tasks" /> Tasks</Breadcrumb.Item>
              </Breadcrumb>
            </Col>
            <Col xl={4} lg={4} md={4} sm={24} xs={24} className="flex_style gx-pr-0">
            </Col>
            <Col xl={6} lg={6} md={6} sm={24} xs={24} className="flex_style gx-pr-0">
              <Form.Item
                name="prjManager" initialValue={0}>
                <Select
                  showSearch
                  style={{ width: '100%' }}
                  placeholder="Project Manager"
                  optionFilterProp="children"
                  onChange={this.setProjectData}
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  options={
                    this.state.projectManagers.length ? this.state.projectManagers.map((pm, index) => (
                      {value: pm.id, label: pm.displayNameTx}
                    )) : []
                  }
                >
                </Select>
              </Form.Item>
            </Col>
            <Col xl={3} lg={3} md={3} sm={24} xs={24} className="flex_style gx-pr-0">
              <Form.Item name="active" valuePropName="checked" initialValue={true}>
                <Switch defaultChecked={true} checkedChildren="Active" unCheckedChildren="Completed" checked={true} onChange={this.setProjectData} className="switchSpace" />
              </Form.Item>
            </Col>
            <Col xl={7} lg={7} md={7} sm={24} xs={24} className="gx-text-center gx-pt-1">
              <Form.Item
                name="prjname"
                rules={[
                  {
                    required: true,
                    message: 'Enter project name',
                  },
                ]}
              >
                <Select
                  showSearch
                  style={{ width: '100%' }}
                  placeholder="Select a Project"
                  optionFilterProp="children"
                  onChange={this.handleChange}
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  options={
                    this.state.projects.length ? this.state.projects.map((project, index) => (
                      {value: project.id, label: project.name}
                    )) : []
                  }
                >
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <div id="PMOTasks">
          <TaskStats
            projId={this.state.projectId} onTaskCardSelected={this.onTaskCardSelected} selectedTaskCard={this.state.selectedTaskCard}
          />
        </div>

        <Row gutter={24}>
          <Col lg={24} md={24} sm={24} xs={24} >
            <div className="newprojectBtn">
              <span><Link to="/master/tasks/new" onClick={this.navigateManage}><FileAddTwoTone style={{ padding: '10px' }} />Add Task</Link></span>
            </div>

            <Table className="gx-table-responsive" columns={columns} size="small" dataSource={this.state.treeData} scroll={{ x: 1300 }}
              loading={this.state.loading}
              exportable
              exportableProps={{ showColumnPicker: true }}
              onChange={this.handleChangeTable}
            />
          </Col>
        </Row>
      </Auxiliary>
    );
  }
};
export default ManageTasks;