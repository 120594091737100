import React from "react";
import { Button, Form, Input, message } from "antd";
import IntlMessages from "util/IntlMessages";
import axios from 'axios';
import { API_URL } from 'constants/APIConfig';
import { useDispatch } from "react-redux";
import UserOutlined from "@ant-design/icons/lib/icons/UserOutlined";
import { userSignOut } from "appRedux/actions/Auth";
import Auxiliary from "../../../util/Auxiliary";

const FormItem = Form.Item;


const ResetPasswordByAdmin = () => {
    const userDetails = JSON.parse(sessionStorage.getItem('user_details'));
    const token = sessionStorage.getItem('token');

    const dispatch = useDispatch();
    const [form] = Form.useForm();

    const onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
    };

    const onFinish = values => {
        let empId = form.getFieldValue('empid');
        let email = form.getFieldValue('email');

        axios.post(API_URL + '/api/users/resetUserPasswordByPmo', {
            employeeId: empId, 
            userEmail: email,   
        },
            {
                headers: {
                    'x-access-token': `${token}`,
                    'Access-Control-Allow-Origin': '*'
                }
            })
            .then(response => {
                form.resetFields();
                //console.log('Login Users', response.data.data)
                message.success(response.data.data);
                // dispatch(userSignOut());
            })
            .catch(error => {
                // Error
                if (error.response) {
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                    message.error(error.response.data.error)
                } else if (error.request) {
                    console.log(error.request);
                } else {
                    console.log('Error', error.message);
                    message.error("Error in changing user password", error)
                }
                console.log(error.config);
               
            });
            
    }

    return (
        <div className="gx-app-login-wrap loginBg">
            <div className="gx-app-login-container">
                <div className="gx-app-login-main-content">
                    <div className="gx-app-login-content">
                        <div className="loginLogo"><img alt="logo3" src="https://www.gemini-us.com/wp-content/uploads/2020/04/Gemini-Consulting-Services-logo-2-e1585743386167.png" /></div>
                        <div type="flex" align="middle" className="gx-mb-2" >
                            <h3 >Reset Password</h3>
                        </div>
                        <Form
                            form={form}
                            initialValues={{ remember: true }}
                            name="basic"
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                            className="gx-signin-form gx-form-row0"
                            wrapperCol={{ span: 24 }}>


                            <Form.Item
                                // initialValue="demo@example.com"
                                rules={[
                                    { required: true, message: 'Please input your Employee id!' },
                                    { whitespace: true },
                                    { min: 4 }

                                ]} hasFeedback name="empid">
                                <Input allowClear placeholder="Employee Id" />
                            </Form.Item>
                            <Form.Item rules={[
                                { required: true, message: 'Please input your E-mail!' },
                                { type: "email" },
                            ]} hasFeedback name="email" className="mt-9">

                                <Input allowClear
                                    placeholder="Email" />
                            </Form.Item>

                            <Form.Item wrapperCol={{ span: 24 }}>
                                <Button block type="primary" htmlType="submit">
                                    <IntlMessages id="app.userAuth.reset" />
                                </Button>
                            </Form.Item>
                        </Form>
                    </div>
                </div>
            </div>
        </div>

    );
};

export default ResetPasswordByAdmin;
