import React, { useState } from 'react';
import { Link, useHistory } from "react-router-dom";
import { Button, Table, Dropdown, Menu, Icon } from 'antd';
import { MoreOutlined } from '@ant-design/icons';
// import './index.scss';

function TeamManageTabel(props) {
    const [role, setRole] = useState(true)
    // const { sortedInfo, filteredInfo } = this.state;
    // sortedInfo = sortedInfo || {};
    // filteredInfo = filteredInfo || {};
    

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            // filteredValue: filteredInfo.name || null,
            onFilter: (value, record) => record.name.includes(value),
            sorter: (a, b) => a.name.length - b.name.length,
            // ellipsis: true,
        },
        {
            title: 'Target',
            dataIndex: 'target',
            key: 'target',
            sorter: (a, b) => a.age - b.age,
            // ellipsis: true,
        },
        {
            title: 'delegates',
            dataIndex: 'delegates',
            key: 'delegates',
            sorter: (a, b) => a.age - b.age,
            // ellipsis: true,
        },
        {
            title: 'User Selectable',
            dataIndex: 'userSelectable',
            key: 'userSelectable',
            sorter: (a, b) => a.age - b.age,
            // ellipsis: true,
        },
        {
            title: 'Skill Categories',
            dataIndex: 'skillCategories',
            key: 'skillCategories',
            sorter: (a, b) => a.age - b.age,
            // ellipsis: true,
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            key: 'actions',
        },
    ];
    

    const Data = [];
    if (props.teamDataPassing.length > 0 ){
        props.teamDataPassing.map((value, i) => {
            var obj = { key: i,
            name: value.name,
            target: "Not set",
            delegates:"spcific delegates",
            userSelectable:"10",
            skillCategories:"50",
            actions: <Dropdown getPopupContainer={triggerNode => triggerNode.parentNode}
            overlay={<Menu>
                <Menu.Item key="0" >
                    {role == true ? <a><Link to={{ pathname: `/skill/self-assessment` }}>View</Link></a> : null}
                </Menu.Item>
                <Menu.Item key="1">
                    {role == true ? <a><Link to={{ pathname: `./new`, state:value.id }} className="edittext">Edit</Link></a> : null}
                </Menu.Item>
            </Menu >} trigger={['click']} >
            <a className="ant-dropdown-link" href="">
                <span className="viewMore"><MoreOutlined /></span>
            </a>
        </Dropdown >
            }
            Data.push(obj)
        })
        console.log("data pushed", Data)
    }

    return (
        <div>
            <Table columns={columns} dataSource={Data} />
        </div>
    );
}

export default TeamManageTabel;
