import React, { useEffect, useState } from "react";
import axios from 'axios';
import {Col, Row} from "antd";
import {Anchor,Card, Button, Table} from "antd";
import Auxiliary from "util/Auxiliary";
import { API_URL } from 'constants/APIConfig';

import IconWithTextCard from "components/Metrics/IconWithTextCard";
import { FormOutlined, EyeOutlined } from '@ant-design/icons';

const TaskStats = (props) => {

  console.log("project id", props.projId)
  const userDetails = JSON.parse(sessionStorage.getItem('user_details'));
  const token = sessionStorage.getItem('token');
  
    const [taskStats, setTaskStats] = useState({
      NEW: 0,
      WAITINGFORINPUTS: 0,
      INPROGRESS: 0,
      RESOLVED: 0,
      CLOSED: 0,
      TOTAL: 0,
      BILLABLETASKS: 0
    });
    const getTaskStats = () => {
      axios.get(API_URL+ `/api/task/all/taskStatus/${props.projId}`, 
      {headers: {
        'x-access-token': `${token}`, 
      'Access-Control-Allow-Origin': '*'}
     })
          .then(response => {
            if(response.data.data){
               setTaskStats(response.data.data);
              console.log( "task stats is ",response.data.data)
            }
          })
          .catch(error => {
              console.log(error)
          });
        }   

  const onTaskCardSelected = (v) => {
    if(props.onTaskCardSelected) {
      props.onTaskCardSelected(v)
    }
  }

  useEffect(() => {
    getTaskStats();
  },[props.projId]) 
  return (
    <Auxiliary>
    <Row>
      <Col xl={6} lg={12} md={12} sm={12} xs={12} className="gx-col-full" onClick={()=>{ onTaskCardSelected('Tasks') }} style={{cursor: "pointer"}}>
        <IconWithTextCard icon="orders" iconColor="geekblue" title={taskStats.TOTAL}  subTitle="Tasks" tasksCard = {props.selectedTaskCard == 'Tasks' ? 'tasksCard' : ''}/>
      </Col>
      <Col xl={6} lg={12} md={12} sm={12} xs={12} className="gx-col-full" onClick={()=>{ onTaskCardSelected('In Progress') }} style={{cursor: "pointer"}}>
        <IconWithTextCard icon="queries" iconColor="geekblue" title={taskStats.INPROGRESS} subTitle="In Progress" tasksCard = {props.selectedTaskCard == 'In Progress' ? 'tasksCard' : ''}/>
      </Col>
      <Col xl={6} lg={12} md={12} sm={12} xs={12} className="gx-col-full" onClick={()=>{ onTaskCardSelected('Waiting') }} style={{cursor: "pointer"}}>
        <IconWithTextCard icon="visits" iconColor="geekblue" title={taskStats.WAITINGFORINPUTS}  subTitle="Waiting" tasksCard = {props.selectedTaskCard == 'Waiting' ? 'tasksCard' : ''}/>
      </Col>
      <Col xl={6} lg={12} md={12} sm={12} xs={12} className="gx-col-full" onClick={()=>{ onTaskCardSelected('Closed') }} style={{cursor: "pointer"}}>
        <IconWithTextCard icon="revenue-new" iconColor="primary" title={taskStats.CLOSED} subTitle="Closed" tasksCard = {props.selectedTaskCard == 'Closed' ? 'tasksCard' : ''}/>
      </Col>
      </Row>      
    </Auxiliary>
  );
};
export default TaskStats;
