import React from "react";
import { API_URL } from 'constants/APIConfig';

const ServiceRequest = () => {

    const fetchSRData = async () => {
        let srId = document.getElementById("srId").value
        if(srId) {
            try {
                document.getElementById("resultId").style.display = "none";
                let res = await fetch(`${API_URL}/api/sharepoint/srstatus?serviceRequest=${srId.trim()}`)
                let json = await res.json()
                //console.dir(json)
                if(json.data.length > 0) {
                    document.getElementById("resultId").style.display = "block";
                    let fields = json.data[0].fields;
                    document.getElementById("titleId").innerText = fields.Title
                    document.getElementById("pmId").innerText = fields.ProjectManager0
                    document.getElementById("statusId").innerText = fields.Status
                    document.getElementById("shortDesId").innerText = fields.ShortDescription
                } else {
                    document.getElementById("titleId").innerText = ''
                    document.getElementById("pmId").innerText = ''
                    document.getElementById("statusId").innerText = ''
                    document.getElementById("shortDesId").innerText = ''
                    document.getElementById("resultId").style.display = "none";
                    alert('Invalid Service Request')
                }
            } catch(error) {
                console.error(error)
                alert('Something went wrong.')
            }
        } else {
            alert('Please enter the Service Request.')
        }
    }
    const resetData = () => {
        document.getElementById("srId").innerText = null
        document.getElementById("titleId").innerText = ''
        document.getElementById("pmId").innerText = ''
        document.getElementById("statusId").innerText = ''
        document.getElementById("shortDesId").innerText = ''
        document.getElementById("resultId").style.display = "none";
    }
    return (
        <>
            <div id="serviceReqHeaderId">
                <center className="gx-text-blue gx-pb-3 gx-pt-3" ><h4 className="srHeading">Service Request</h4></center>
            </div>
            <br/>
            <div className="serviceRequest-cont">
                <input type='text' placeholder="Service Request" id="srId"></input>
                <br />
                <button onClick={fetchSRData} className="btnSRsubmit">Search</button>
                <button onClick={resetData} className="btnSRreset">Reset</button>

                <ul id="resultId" style={{'display' : 'none'}}>
                    <li><b>Service Request:</b> <span id="titleId"></span><br/></li>
                    <li><b>Subject:</b> <span id="shortDesId"></span><br/></li>
                    <li><b>Project Manager:</b> <span id="pmId"></span><br/></li>
                    <li><b>Status:</b> <span id="statusId"></span><br/></li>
                </ul> 
            </div>
        </>
    );
};
export default ServiceRequest;