import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import axios from 'axios';
import { Col, Row } from "antd";
import { Table, Select, Form, Tooltip, Button } from "antd";
import Auxiliary from "util/Auxiliary";
import { API_URL } from 'constants/APIConfig';
import { FormOutlined, EyeOutlined } from '@ant-design/icons';

const { Option } = Select;


const ManageProjectHealth = () => {
  const userDetails = JSON.parse(sessionStorage.getItem('user_details'));
  const token = sessionStorage.getItem('token');
  const [issues, setIssues] = useState([]);
  const [projects, setProjects] = useState([]);

  const [form] = Form.useForm();

  let history = useHistory();

  const getProjectList = () => {
    axios.get(API_URL + `/api/project/v2/projectsByManager?userId=${userDetails.id}`, 
    {headers: {
      'x-access-token': `${token}`, 
    'Access-Control-Allow-Origin': '*'}
   })
      .then(response => {
        if (Object.keys(response.data.data).length > 0) {
          setProjects(response.data.data);
          form.setFieldsValue({
            prjname: response.data.data[0].id,
          })
          getProjIssueList(response.data.data[0].id)
        }
      })
      .catch(error => {
        // Error
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log('Error', error.message);
        }
        console.log(error.config);
      });
  }
  const onProjectChange = value => {
    getProjIssueList(value)
  };

  const issuesCols = [
    {
      title: 'Type', dataIndex: 'type', fixed: 'left', width: 150,
      sorter: (a, b) => String(a.type).localeCompare(String(b.type)),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Owner', dataIndex: 'owner', width: 150,
      sorter: (a, b) => String(a.owner).localeCompare(String(b.owner)),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Status', dataIndex: 'status', width: 150,
      sorter: (a, b) => String(a.status).localeCompare(String(b.status)),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Due', dataIndex: 'dueDate', width: 150,
      sorter: (a, b) => String(a.dueDate).localeCompare(String(b.dueDate)),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Description', dataIndex: 'comments', width: 150,
      sorter: (a, b) => String(a.comments).localeCompare(String(b.comments)),
      sortDirections: ['descend', 'ascend'],
    },
    { title: 'Action', fixed: 'right', width: 100, dataIndex: 'id', render: text => <div><span> <a href={'/issues/manage/' + text}><FormOutlined style={{ padding: '10px' }} /></a></span> </div> },
  ];

  let issuesEntries = [];
  const getProjIssueList = (projId) => {
    axios.get(API_URL + `/api/project_issues_risks_actions/getDetails?projectId=${projId}`, 
    {headers: {
      'x-access-token': `${token}`, 
    'Access-Control-Allow-Origin': '*'}
   })
      .then(response => {
        ///
        if (Object.keys(response.data.data).length > 0) {
          response.data.data.map((item, index) => {
            issuesEntries.push({
              id: item.id,
              type: item.type,
              owner: item.assignedUser && item.assignedUser.displayNameTx,
              status: item.status,
              dueDate: item.dueDate,
              comments: item.comments,
            })
          })
          setIssues(issuesEntries);
        }
        console.log("Issue List", response.data.data);
      })
      .catch(error => {
        // Error
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log('Error', error.message);
        }
        console.log(error.config);
      });
  }
  useEffect(() => {
    getProjectList();
  }, [])

  return (
    <Auxiliary> 
      <Form
        form={form}
        initialValues={{ remember: true }}
        name="ohealth"
       // onFinish={onFinish}
        layout="inline"
        align="center"
      >
      <Row gutter={24}>
       <Col xl={16} lg={16} md={16} sm={24} xs={24}>
          <div className="gx-form-row0" >
            <Form.Item
              name="prjname"
              // label="Project Name"
              rules={[
                {
                  required: true,
                  message: 'Enter project name',
                },
              ]}
            >
              <Select
                showSearch
                style={{ width: '100%' }}
                placeholder="Projects"
                optionFilterProp="children"
                onChange={onProjectChange}
                // size="small"
                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              >
                {
                  projects.length ? projects.map((project, index) => (
                    <Option key={index} value={project.id}>{project.name}</Option>
                  )) : null
                }
              </Select>
            </Form.Item>
          </div>
        </Col>

        <Col xl={8} lg={8} md={8} sm={24} xs={24}>
          <Button type="primary" className="add-btn" onClick={() => history.push('/issues/new')}> New Issue </Button>
        </Col>
      </Row>
      </Form> 
      <Row gutter={24}>
        {/*         <Col xl={24} lg={24} md={24} sm={24} xs={24}>
          <Button type="primary" className="add-btn" onClick={() => history.push('./new')}> New WSR </Button>
        </Col> */}
        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
          <Table className="remove-mFixCell2" columns={issuesCols} dataSource={issues} pagination={{ pageSize: 10 }} scroll={{ x: 1300 }} />
        </Col>
      </Row>
    </Auxiliary>
  );
};

export default ManageProjectHealth;
