import React, { useEffect, useState } from "react";
import axios from 'axios';
import { Button, Table, Row, Col, Input, Form, message, Select } from "antd";
import Auxiliary from "util/Auxiliary";
import { API_URL } from 'constants/APIConfig';

const { Option } = Select;

const ParentSkill = () => {
    const [allDepartments, setAllDepartments] = useState([]);
    const [allSkills, setAllSkills] = useState([]);
    const [form] = Form.useForm();
    const token = sessionStorage.getItem('token');
    const [skillDepartment, setSkillDepartment] = useState([]);

    const getAllDepartment = () => {
        axios.get(`${API_URL}/api/sb_department/all?t=${new Date().getTime()}`,
            {
                headers: {
                    'x-access-token': `${token}`,
                    'Access-Control-Allow-Origin': '*'
                }
            })
            .then(response => {
                setAllDepartments(response.data.data);
            })
            .catch(error => {
                console.log(error)
            });
    }

    const onFinish = values => {
        axios.post(API_URL + `/api/skillbase/skills/addNewSkill?t=${new Date().getTime()}`, 
        { nameTx: form.getFieldValue('skillName').trim(), departmentIdNm: form.getFieldValue('departmentID') },
            {
                headers: {
                    'x-access-token': `${token}`,
                    'Access-Control-Allow-Origin': '*'
                }
            })
            .then(response => {
                message.success(`Skill added sucessfully`);
                getSkillWithDepartmentList()
                form.resetFields()
            })
            .catch(error => {
                console.log(error);
                message.error("Error In creating Skill", error)
            });
    }

    const getSkillWithDepartmentList = () => {
        axios.get(API_URL + `/api/skillbase/skills/getAllSkillsWithDepartments?t=${new Date().getTime()}`,
          {
            headers: {
              'x-access-token': `${token}`,
              'Access-Control-Allow-Origin': '*'
            }
          })
          .then(response => {
            if (Object.keys(response.data.data).length > 0) {
              setSkillDepartment(response.data.data);
            }
          })
          .catch(error => {
            console.log(error);
          });
      }

    useEffect(() => {
        getAllDepartment();
        getSkillWithDepartmentList()
    }, [])

    /* End of Get the data form the API*/
    const columns = [
        { title: 'Department', dataIndex: 'departmentNameTx', align: 'center', render: (text, record) => {
            return record.sb_department.nameTx;
        }, },
        { title: 'Skill', dataIndex: 'nameTx', align: 'center' },
    ];
    return (
        <Auxiliary>
            <center><h4>Manage Skills</h4></center>
            <Row gutter={24}>
                <Col lg={24} md={24} sm={24} xs={24} >
                    <div className="add-skills-main content-height-100">
                        <Row className="header-border">
                        </Row>
                        <div className="skill-form">
                            <Form
                                form={form}
                                initialValues={{ remember: true }}
                                name="newSkill"
                                onFinish={onFinish}
                                layout="inline"
                                align="center"
                            >

                                <Row gutter={24}>
                                    <Col lg={9} md={9} sm={9} xs={9} >
                                        <div className="gx-form-row0">
                                            <h6 align="left">Department</h6>
                                            <Form.Item
                                                name="departmentID"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Select the department',
                                                    },
                                                ]}
                                            >
                                                <Select
                                                    style={{ width: '100%' }}
                                                    placeholder="Select the Department"
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                                >
                                                    {
                                                        allDepartments.length ? allDepartments.map((department, index) => (
                                                            <Option key={index} value={department.id}>{department.nameTx}</Option>
                                                        )) : null
                                                    }
                                                </Select>
                                            </Form.Item>
                                        </div>
                                    </Col>
                                    <Col lg={12} md={12} sm={12} xs={24} >
                                        <div className="gx-form-row0">
                                            <h6 align="left">Skill Name</h6>
                                            <Form.Item
                                                name="skillName"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Enter Department Name',
                                                    },
                                                    {
                                                        validator(_, value) {
                                                            let sArr = allSkills.map(s => s.nameTx.toLowerCase()).filter(s => s.trim() === (value + '').trim().toLowerCase())
                                                            if (sArr.length > 0) {
                                                                return Promise.reject('Duplicate Skill Name')
                                                            }
                                                            return Promise.resolve();
                                                        },
                                                    }
                                                ]}
                                                hasFeedback>
                                                <Input placeholder="Skill Name" autoComplete="off" maxLength={250} />
                                            </Form.Item>
                                        </div>
                                    </Col>
                                    <Col lg={3} md={3} sm={3} xs={3} >
                                        <div className="gx-form-row0" style={{ float: "left", marginTop: "20px" }}>
                                            <Form.Item>
                                                <Button type="primary" className="add-btn scale-x" htmlType="submit">Add Skill</Button>
                                            </Form.Item>
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        </div>
                    </div >
                </Col>
            </Row>
            <Table columns={columns} dataSource={skillDepartment} rowKey={'id'} />
        </Auxiliary>
    );
}

export default ParentSkill;