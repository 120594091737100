import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import axios from 'axios';
import { Col, Row } from "antd";
import { Table, Select, Form, Tooltip, Button } from "antd";
import Auxiliary from "util/Auxiliary";
import { API_URL } from 'constants/APIConfig';
import { FormOutlined, EyeOutlined } from '@ant-design/icons';

const { Option } = Select;


const ManageProjectHealth = () => {
  let history = useHistory();
  const userDetails = JSON.parse(sessionStorage.getItem('user_details'));
  const token = sessionStorage.getItem('token');
  
  const [health, setHealth] = useState([]);
  const [projects, setProjects] = useState([]);

  const [form] = Form.useForm();


  const getProjectList = () => {
    axios.get(API_URL + `/api/project/v2/projectsByManager?userId=${userDetails.id}`, 
    {headers: {
      'x-access-token': `${token}`, 
    'Access-Control-Allow-Origin': '*'}
   })
      .then(response => {
        if (Object.keys(response.data.data).length > 0) {
          setProjects(response.data.data);
          form.setFieldsValue({
            prjname: response.data.data[0].id,
          })
          getProjHealthList(response.data.data[0].id)
        }
      })
      .catch(error => {
        // Error
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log('Error', error.message);
        }
        console.log(error.config);
      });
  }
  const onProjectChange = value => {
    //setProjectId(value);
    getProjHealthList(value)
  };

  const healthCols = [
    {
      title: 'Year', dataIndex: 'year', fixed: 'left', width: 100,
      sorter: (a, b) => String(a.year).localeCompare(String(b.year)),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Week', dataIndex: 'weekNumber', width: 100,
      sorter: (a, b) => String(a.weekNumber).localeCompare(String(b.weekNumber)),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Scope',
      dataIndex: 'scope',
      width: 150,
      render(text, record) {

        let color = "";
        if (parseInt(text) == 1) {
          color = "red";
        } else if (parseInt(text) == 2) {
          color = "green";
        } else if (parseInt(text) == 3) {
          color = "orange";
        } else if (parseInt(text) == 4) {
          color = "blue";
        }
        return {
          props: {
            style: {
              color: color,
            }
          },
          children: <div>
            <Tooltip placement="topLeft" title={record.scopeDesc}>
              <i className="icon icon-circle"></i>
            </Tooltip>
          </div>
        };

      },
      sorter: (a, b) => String(a.scope).localeCompare(String(b.scope)),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Resource',
      dataIndex: 'resource',
      width: 150,
      render(text, record) {
        let color = "";
        if (parseInt(text) == 1) {
          color = "red";
        } else if (parseInt(text) == 2) {
          color = "green";
        } else if (parseInt(text) == 3) {
          color = "rgb(248, 149, 66)";
        } else if (parseInt(text) == 4) {
          color = "blue";
        }
        return {
          props: {
            style: {
              color: color,
            }
          },
          children: <div>
            <Tooltip placement="topLeft" title={record.resourceDesc}>
              <i className="icon icon-circle"></i>
            </Tooltip>
          </div>
        };
      },
      sorter: (a, b) => String(a.resource).localeCompare(String(b.resource)),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Schedule',
      dataIndex: 'schedule',
      width: 150,
      render(text, record) {
        let color = "";
        if (parseInt(text) == 1) {
          color = "red";
        } else if (parseInt(text) == 2) {
          color = "green";
        } else if (parseInt(text) == 3) {
          color = "rgb(248, 149, 66)";
        } else if (parseInt(text) == 4) {
          color = "blue";
        }
        return {
          props: {
            style: {
              color: color,
            }
          },
          children: <div>
            <Tooltip placement="topLeft" title={record.scheduleDesc}>
              <i className="icon icon-circle"></i>
            </Tooltip>
          </div>
        };
      },
      sorter: (a, b) => String(a.schedule).localeCompare(String(b.schedule)),
      sortDirections: ['descend', 'ascend'],
    },
    // { title: 'Quality', dataIndex: 'quality' },
    {
      title: 'Quality',
      dataIndex: 'quality',
      width: 150,
      render(text, record) {
        let color = "";
        if (parseInt(text) == 1) {
          color = "red";
        } else if (parseInt(text) == 2) {
          color = "green";
        } else if (parseInt(text) == 3) {
          color = "rgb(248, 149, 66)";
        } else if (parseInt(text) == 4) {
          color = "blue";
        }
        return {
          props: {
            style: {
              color: color,
            }
          },
          children: <div>
            <Tooltip placement="topLeft" title={record.qualityDesc}>
              <i className="icon icon-circle"></i>
            </Tooltip>
          </div>
        };
      },
      sorter: (a, b) => String(a.quality).localeCompare(String(b.quality)),
      sortDirections: ['descend', 'ascend'],
    },
    // { title: 'Chng Mang', dataIndex: 'changeMang', },
    {
      title: 'ChMang',
      dataIndex: 'changeMang',
      width: 150,
      render(text, record) {
        let color = "";
        if (parseInt(text) == 1) {
          color = "red";
        } else if (parseInt(text) == 2) {
          color = "green";
        } else if (parseInt(text) == 3) {
          color = "rgb(248, 149, 66)";
        } else if (parseInt(text) == 4) {
          color = "blue";
        }
        return {
          props: {
            style: {
              color: color,
            }
          },
          children: <div>
            <Tooltip placement="topLeft" title={record.changeMangDesc}>
              <i className="icon icon-circle"></i>
            </Tooltip>
          </div>
        };
      },
      sorter: (a, b) => String(a.changeMang).localeCompare(String(b.changeMang)),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Budget',
      dataIndex: 'budget',
      width: 150,
      render(text, record) {
        let color = "";
        if (parseInt(text) == 1) {
          color = "red";
        } else if (parseInt(text) == 2) {
          color = "green";
        } else if (parseInt(text) == 3) {
          color = "rgb(248, 149, 66)";
        } else if (parseInt(text) == 4) {
          color = "blue";
        }
        return {
          props: {
            style: {
              color: color,
            }
          },
          children: <div>
            <Tooltip placement="topLeft" title={record.budgetDesc}>
              <i className="icon icon-circle"></i>
            </Tooltip>
          </div>
        };
      },
      sorter: (a, b) => String(a.budget).localeCompare(String(b.budget)),
      sortDirections: ['descend', 'ascend'],
    },
/*     {
      title: 'Comments', dataIndex: 'comments', width: 400,
    }, */
    { title: 'Action', fixed: 'right', width: 100, dataIndex: 'id', render: text => <div><span> <a href={'/weeklystatus/manage/' + text}><FormOutlined style={{ padding: '10px' }} /></a></span> </div> },
  ];

  let healthEntries = [];
  const getProjHealthList = (projId) => {
    axios.get(API_URL + `/api/project_health/getProjectHealth?projectId=${projId}`, 
    {headers: {
      'x-access-token': `${token}`, 
    'Access-Control-Allow-Origin': '*'}
   })
      .then(response => {
        console.log("healthEntries", response.data.data);
        if (Object.keys(response.data.data).length > 0) {
          response.data.data.map((item, index) => {
            healthEntries.push({
              key: index+1,
              id: item.id,
              weekNumber: item.weekNumber,
              year: item.year,
              budget: item.budget,
              changeMang: item.changeMang,
              quality: item.quality,
              resource: item.resource,
              schedule: item.schedule,
              scope: item.scope,
              budgetDesc: item.budgetDesc,
              changeMangDesc: item.changeMangDesc,
              qualityDesc: item.qualityDesc,
              resourceDesc: item.resourceDesc,
              scheduleDesc: item.scheduleDesc,
              scopeDesc: item.scopeDesc,
              comments: item.comments,
            })
          })
          setHealth(healthEntries);
        } else {
          setHealth(healthEntries);
        }
      })
      .catch(error => {
        // Error
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log('Error', error.message);
        }
        console.log(error.config);
      });
  }
  useEffect(() => {
    getProjectList();
  }, [])

  return (
    <Auxiliary>
        <Form
        className="mDisplay-block"
        form={form}
        initialValues={{ remember: true }}
        name="ohealth"
        //onFinish={onFinish}
        layout="inline"
        align="center"
      >
      <Row gutter={24}>

        <Col lg={16} md={16} sm={24} xs={24}  >
          <div className="gx-form-row0" >
            <Form.Item
              name="prjname"
              // label="Project Name"
              rules={[
                {
                  required: true,
                  message: 'Enter project name',
                },
              ]}
            >
              <Select
                className="mFull-width"
                showSearch
                style={{ width: '100%' }}
                placeholder="Projects"
                optionFilterProp="children"
                onChange={onProjectChange}
                // size="small"
                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              >
                {
                  projects.length ? projects.map((project, index) => (
                    <Option key={index} value={project.id}>{project.name}</Option>
                  )) : null
                }
              </Select>
            </Form.Item>
          </div>
        </Col>
        <Col xl={8} lg={8} md={24} sm={24} xs={24}>
          <Button type="primary" className="add-btn" onClick={() => history.push('/weeklystatus/new')}> New WSR </Button>
        </Col>
      </Row>
      </Form>
      <Row gutter={24}>
        {/*         <Col xl={24} lg={24} md={24} sm={24} xs={24}>
          <Button type="primary" className="add-btn" onClick={() => history.push('./new')}> New WSR </Button>
        </Col> */}
        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
          <Table columns={healthCols} dataSource={health} size="small" pagination={{ pageSize: 10 }} scroll={{ x: 1300 }}
          expandable={{
            expandedRowRender: record => <p style={{ margin: 0 }}>{record.comments}</p>,
            /* rowExpandable: record => record.comments !== '', */
          }}
          />
        </Col>
      </Row>
    </Auxiliary>
  );
};

export default ManageProjectHealth;
