import React, { useEffect, useState } from "react";
import axios from 'axios';
import { Row, Col, Form, Input, Select, Radio, Upload, message, DatePicker, Button, Tabs,Tooltip } from "antd";
import Auxiliary from "util/Auxiliary";
import { API_URL } from 'constants/APIConfig';
import { useHistory } from "react-router-dom";
import moment from "moment";
import Widget from "components/Widget/index";

const { TextArea } = Input;

const onChange = (date, dateString) => {
  console.log(date, dateString);
}

const EditHealth = (props) => {
  const history = useHistory();
  const userDetails = JSON.parse(sessionStorage.getItem('user_details'));
  const token = sessionStorage.getItem('token');
  
  const [projectHealth, setProjectHealth] = useState([]);
  // UseForm Hook
  const [form] = Form.useForm();

  const getProjHealthById = () => {
    axios.get(API_URL + `/api/project_health/byId/${props.match.params.id}`, 
    {headers: {
      'x-access-token': `${token}`, 
    'Access-Control-Allow-Origin': '*'}
   })
      .then(response => {
        console.log("healthEntries by id", response.data.data);
        if (Object.keys(response.data.data).length > 0) {
          setProjectHealth(response.data.data);
          let item = response.data.data;

          form.setFieldsValue({
            week: moment().day("Sunday").year(item.year).week(item.weekNumber),
            Schedule: item.schedule,
            Budget: item.budget,
            Resource: item.resource,
            Scope: item.scope,
            Quality: item.quality,
            ChangeMang: item.changeMang,
            Schedule1: item.scheduleDesc,
            Budget1: item.budgetDesc,
            Resource1: item.resourceDesc,
            Scope1: item.scopeDesc,
            Quality1: item.qualityDesc,
            ChangeMang1: item.changeMangDesc,
            comments: item.comments,
          });
        }
      })
      .catch(error => {
        // Error
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log('Error', error.message);
        }
        console.log(error.config);
      });
  }

  useEffect(() => {
    if (props.match.params.id != null) {
      getProjHealthById();
    }
  }, [props.match.params.id])

  const onFinish = values => {
    // if (projectId != 0 && weekno != 0 && yearno != 0) {
    const frmValues = form.getFieldsValue();
    const newData = {
      projectId: +projectHealth.projectId,
      year: +projectHealth.year,
      weekNumber: +projectHealth.weekNumber,
      schedule: frmValues.Schedule,
      budget: frmValues.Budget,
      resource: frmValues.Resource,
      scope: frmValues.Scope,
      quality: frmValues.Quality,
      changeMang: frmValues.ChangeMang,
      scheduleDesc: frmValues.Schedule1,
      budgetDesc: frmValues.Budget1,
      resourceDesc: frmValues.Resource1,
      scopeDesc: frmValues.Scope1,
      qualityDesc: frmValues.Quality1,
      changeMangDesc: frmValues.ChangeMang1,
      comments: frmValues.comments,
    };
    axios.put(API_URL + `/api/project_health/update/${props.match.params.id}`, newData, 
    {headers: {
      'x-access-token': `${token}`, 
    'Access-Control-Allow-Origin': '*'}
   })
      .then(response => {
        message.success("updated Successfully");
        history.push("/weeklystatus/manage");
        //props.getProjHealthList();
        //setWeekPick(false);
      })
      .catch(error => {
        // Error
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log('Error', error.message);
        }
        console.log(error.config);
        message.error("Error in updating WSR", error)
      });
    form.resetFields();
    /*   } else {
        if (projectId == 0)
          message.error("Please select the project");
        if (weekno == 0)
          message.error("Please select the week");
        if (yearno == 0)
          message.error("Please select the year");
      } */
  }

  //UI Functions

  return (
    <Auxiliary>
      <Widget>
      <Form
        form={form}
        initialValues={{ remember: true }}
        name="ohealth"
        onFinish={onFinish}
        layout="inline"
        align="center"
      >
        <Row>
          <Col lg={5} md={12} sm={12} xs={24}>
            <Form.Item
              style={{ marginBottom: "0px" }}
              name="week"
              rules={[
                {
                  required: true,
                  message: 'Enter client name',
                },
              ]}
            >
              <DatePicker
                picker="week"
                // onChange={onChangeWeek}
                bordered={true}
                disabled={true}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row className="align_text">
          <Col lg={3} md={12} sm={12} xs={24}>
            <h5 align="left">Schedule</h5>
          </Col>
          <Col lg={5} md={12} sm={12} xs={24}>
            <Form.Item
              style={{ marginBottom: "0px" }}
              name="Schedule"
              rules={[
                {
                  required: true,
                  message: 'Enter client name',
                },
              ]}
            >
              <Radio.Group onChange={onChange}>
                <Tooltip placement="topLeft" title="Red">  <Radio className="red_status1" value={1}></Radio>  </Tooltip>
                <Tooltip placement="topLeft" title="Orange">   <Radio className="orange_status1" value={3}></Radio> </Tooltip>
                <Tooltip placement="topLeft" title="Blue">   <Radio className="skyBlue_status1" value={4}></Radio> </Tooltip>
                <Tooltip placement="topLeft" title="Green">   <Radio className="green_status1" value={2}></Radio> </Tooltip>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col lg={16} md={12} sm={12} xs={24}>
            <Form.Item
              style={{ marginBottom: "0px" }}
              name="Schedule1"
              // label="Schedule1"
              rules={[
                {
                  required: true,
                  message: 'Enter client name',
                },
              ]}
            >
              <Input /* style={{ width: '1000%' }} */ />
            </Form.Item>
          </Col>
        </Row>
        <Row className="align_text">
          <Col lg={3} md={12} sm={12} xs={24}>
            <h5 align="left">Budget</h5>
          </Col>
          <Col lg={5} md={12} sm={12} xs={24}>
            <Form.Item
              style={{ marginBottom: "0px" }}
              name="Budget"
              //label="Budget"
              rules={[
                {
                  required: true,
                  message: 'Enter client name',
                },
              ]}
            >
              <Radio.Group onChange={onChange}>
                <Tooltip placement="topLeft" title="Red">  <Radio className="red_status1" value={1}></Radio>  </Tooltip>
                <Tooltip placement="topLeft" title="Orange">   <Radio className="orange_status1" value={3}></Radio> </Tooltip>
                <Tooltip placement="topLeft" title="Blue">   <Radio className="skyBlue_status1" value={4}></Radio> </Tooltip>
                <Tooltip placement="topLeft" title="Green">   <Radio className="green_status1" value={2}></Radio> </Tooltip>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col lg={16} md={12} sm={12} xs={24}>
            <Form.Item
              style={{ marginBottom: "0px" }}
              name="Budget1"
              // label="Schedule1"
              rules={[
                {
                  required: true,
                  message: 'Enter client name',
                },
              ]}
            >
              <Input /* style={{ width: '1000%' }} */ />
            </Form.Item>
          </Col>
        </Row>
        <Row className="align_text">
          <Col lg={3} md={12} sm={12} xs={24}>
            <h5 align="left">Resource</h5>
          </Col>
          <Col lg={5} md={12} sm={12} xs={24}>
            <Form.Item
              style={{ marginBottom: "0px" }}
              name="Resource"
              //label="Resource"
              rules={[
                {
                  required: true,
                  message: 'Enter client name',
                },
              ]}
            >
              <Radio.Group onChange={onChange}>
                <Tooltip placement="topLeft" title="Red">  <Radio className="red_status1" value={1}></Radio>  </Tooltip>
                <Tooltip placement="topLeft" title="Orange">   <Radio className="orange_status1" value={3}></Radio> </Tooltip>
                <Tooltip placement="topLeft" title="Blue">   <Radio className="skyBlue_status1" value={4}></Radio> </Tooltip>
                <Tooltip placement="topLeft" title="Green">   <Radio className="green_status1" value={2}></Radio> </Tooltip>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col lg={16} md={12} sm={12} xs={24}>
            <Form.Item
              style={{ marginBottom: "0px" }}
              name="Resource1"
              // label="Schedule1"
              rules={[
                {
                  required: true,
                  message: 'Enter client name',
                },
              ]}
            >
              <Input /* style={{ width: '1000%' }} */ />
            </Form.Item>
          </Col>
        </Row>
        <Row className="align_text">
          <Col lg={3} md={12} sm={12} xs={24}>
            <h5 align="left">Scope</h5>
          </Col>
          <Col lg={5} md={12} sm={12} xs={24}>
            <Form.Item
              style={{ marginBottom: "0px" }}
              name="Scope"
              //label="Scope"
              rules={[
                {
                  required: true,
                  message: 'Enter client name',
                },
              ]}
            >
              <Radio.Group onChange={onChange}>
                <Tooltip placement="topLeft" title="Red">  <Radio className="red_status1" value={1}></Radio>  </Tooltip>
                <Tooltip placement="topLeft" title="Orange">   <Radio className="orange_status1" value={3}></Radio> </Tooltip>
                <Tooltip placement="topLeft" title="Blue">   <Radio className="skyBlue_status1" value={4}></Radio> </Tooltip>
                <Tooltip placement="topLeft" title="Green">   <Radio className="green_status1" value={2}></Radio> </Tooltip>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col lg={16} md={12} sm={12} xs={24}>
            <Form.Item
              style={{ marginBottom: "0px" }}
              name="Scope1"
              // label="Schedule1"
              rules={[
                {
                  required: true,
                  message: 'Enter client name',
                },
              ]}
            >
              <Input /* style={{ width: '1000%' }} */ />
            </Form.Item>
          </Col>
        </Row>
        <Row className="align_text">
          <Col lg={3} md={12} sm={12} xs={24}>
            <h5 align="left">Quality</h5>
          </Col>
          <Col lg={5} md={12} sm={12} xs={24}>
            <Form.Item
              style={{ marginBottom: "0px" }}
              name="Quality"
              //label="Quality"
              rules={[
                {
                  required: true,
                  message: 'Enter client name',
                },
              ]}
            >
              <Radio.Group onChange={onChange}>
                <Tooltip placement="topLeft" title="Red">  <Radio className="red_status1" value={1}></Radio>  </Tooltip>
                <Tooltip placement="topLeft" title="Orange">   <Radio className="orange_status1" value={3}></Radio> </Tooltip>
                <Tooltip placement="topLeft" title="Blue">   <Radio className="skyBlue_status1" value={4}></Radio> </Tooltip>
                <Tooltip placement="topLeft" title="Green">   <Radio className="green_status1" value={2}></Radio> </Tooltip>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col lg={16} md={12} sm={12} xs={24}>
            <Form.Item
              style={{ marginBottom: "0px" }}
              name="Quality1"
              // label="Schedule1"
              rules={[
                {
                  required: true,
                  message: 'Enter client name',
                },
              ]}
            >
              <Input /* style={{ width: '1000%' }} */ />
            </Form.Item>
          </Col>
        </Row>
        <Row className="align_text">
          <Col lg={3} md={12} sm={12} xs={24}>
            <h5 align="left">Change Mgnt.</h5>
          </Col>
          <Col lg={5} md={12} sm={12} xs={24}>
            <Form.Item
              style={{ marginBottom: "0px" }}
              name="ChangeMang"
              //label="Change Mang"
              rules={[
                {
                  required: true,
                  message: 'Enter client name',
                },
              ]}
            >
              <Radio.Group onChange={onChange}>
                <Tooltip placement="topLeft" title="Red">  <Radio className="red_status1" value={1}></Radio>  </Tooltip>
                <Tooltip placement="topLeft" title="Orange">   <Radio className="orange_status1" value={3}></Radio> </Tooltip>
                <Tooltip placement="topLeft" title="Blue">   <Radio className="skyBlue_status1" value={4}></Radio> </Tooltip>
                <Tooltip placement="topLeft" title="Green">   <Radio className="green_status1" value={2}></Radio> </Tooltip>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col lg={16} md={12} sm={12} xs={24}>
            <Form.Item
              style={{ marginBottom: "0px" }}
              name="ChangeMang1"
              // label="Schedule1"
              rules={[
                {
                  required: true,
                  message: 'Enter client name',
                },
              ]}
            >
              <Input /* style={{ width: '1000%' }} */ />
            </Form.Item>
          </Col>
        </Row>
        <Row >
          <Col lg={24} md={24} sm={12} xs={24} >
            <div className="gx-form-row0">
              <h6 align="left">Comments</h6>
              <Form.Item
                name="comments"
                // label="Project Name"
                rules={[
                  {
                    required: true,
                    message: 'Enter comments',
                  },
                ]}
              >
                <TextArea rows={4} />
              </Form.Item>
            </div>
          </Col>
        </Row>

        <Row >
          <Col lg={24} md={24} sm={24} xs={24} >
            <div className="gx-form-row0">
              <Form.Item
                name="submit"
              >
                <Button type="primary" className="add-btn" htmlType="submit" >Submit</Button>
                <Button type="primary" className="add-btn gx-mr-2" onClick={() => history.push('/weeklystatus/manage')}>Back</Button>
              </Form.Item>
            </div>
          </Col>
        </Row>
      </Form>
      </Widget>
    </Auxiliary>
  );
};
export default EditHealth;
