import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {  useHistory } from "react-router-dom";
import "./hamburger_menu.css"
import { Menu, message } from 'antd';


function HamburgerMenu(props) {
    const { SubMenu } = Menu;
    const history = useHistory();
    const [loginstate, setLoginstate] = useState("");
    const dispatch = useDispatch();

    const toSubHome = (menu) => {
        if (menu === 'solutions') history.push('/solutions')
        else if(menu === 'Products') (history.push('/products'))
        else if(menu ==='insights') history.push('/blogs')
        else message.warning("COMMING SOON ...")
        // props.onSelect();
    }

    const onMenuSelect = (menu) => {
        if (menu.key === 'home') history.push('/')
        else if (menu.key === 'about') history.push('/about')
        else if (menu.key === 'contactus') history.push('/contact-us')
        else if (menu.key === 'blog') history.push('/blogs')
        else if (menu.key === 'careers') history.push('/careers')
        else if (menu.key === 'login') {
            props.onSelect();
        }
        // else if (menu.key === 'freeTrial') dispatch(setCommingSoonModal(true))
        // else message.warning("COMMING SOON ...")
        props.onSelect();
    }
    const handleCancel = () => {
        setLoginstate("")
        props.onSelect();
    }


    const onDropdownMenuSelect = (menu) => {
        if (menu.key === 'Submit') history.push('/timesheets/submit')
        else if (menu.key === 'Employee') history.push('/dashboard/employee')
        // else if (menu.key === 'solutionsMain') history.push('/solutions')
        // else if (menu.key === 'productsMain') history.push('/solutions')
        else if (menu.key === 'RMApprove') history.push('/timesheets/rmapprove')
        else if (menu.key === 'Dashboard') history.push('/dashboard/manager')
        else if (menu.key === 'Task') history.push('/tasks/manage')
        else if (menu.key === 'TimelogSummary') history.push('/dashboard/manager/reports/timelog/index')
        else if (menu.key === 'WSR') history.push('/weeklystatus/manage')
        else if (menu.key === 'Resources') history.push('/resources/manage')
        else if (menu.key === 'PMApprove') history.push('/timesheets/approve')
        else if (menu.key === 'Projects') history.push('/master/projects/manage')
        else if (menu.key === 'TasksPMO') history.push('/master/tasks/manage?module=PMO')
        else if (menu.key === 'WeeklyTsStatus') history.push('/reports/timesheet/index')
        else if (menu.key === 'Plan') history.push('/plan/manage')
        else if (menu.key === 'Issues') history.push('/issues/manage')
        else if (menu.key === 'Cost') history.push('/cost/manage')
        else if (menu.key === 'Skills') history.push('/skillMgr?module=PMO')
        else if (menu.key === 'OrgStructure') history.push('/orgstructure/manage')
        else if (menu.key === 'ProjectResource') history.push('/master/projectresource')
        else if (menu.key === 'ResetPassword') history.push('/reset/userpasswordbypmo')
        else if (menu.key === 'TravelRequest') history.push('/travel_desk/request')
        else if (menu.key === 'AdminApprove') history.push('/travel_desk/rm-approve')
        else if(menu.key === 'TasksExecutive') history.push('/master/tasks/manage?module=Executive')
        else if(menu.key === 'ProjectsExecutive') history.push('/master/projects/manage')
        else if(menu.key === 'Clients') history.push('/master/clients/manage')
        else if(menu.key === 'SkillsExecutive') history.push('/skillMgr?module=Executive')
        else if(menu.key === 'OrgStructure') history.push('/master/orgstructure/manage')
        else if(menu.key === 'TimesheetStatus') history.push('/timesheet/statuschange')

        //else console.log("COMMING SOON ...")
        props.onSelect();
    }

    const userRoles = JSON.parse(sessionStorage.getItem("user_Roles"));
    const userDetails = JSON.parse(sessionStorage.getItem("user_details"));
    
    return (
        <div className="hamburger-menu-main" >
            <Menu theme="dark" defaultSelectedKeys={['1']} mode="inline"  >
                <SubMenu key="sub1" title="Home" className='main-menu-item'>
                    <Menu.Item onClick={onDropdownMenuSelect} key="Employee" className='sub-menu-item'>
                        <i className="icon icon-user-o" />
                        <span>Employee</span>
                    </Menu.Item >
                </SubMenu>
                <SubMenu key="sub2" title="Log Efforts" className='main-menu-item'>
                    <Menu.Item onClick={onDropdownMenuSelect} key="Submit" className='sub-menu-item'>
                        <i className="icon icon-timepicker" />
                        <span>Submit</span>
                    </Menu.Item >
                </SubMenu>
                {userDetails.isReportingManager ?  <SubMenu key="sub3" title="Resource Manager" className='main-menu-item'>
                    <Menu.Item onClick={onDropdownMenuSelect} key="RMApprove" className='sub-menu-item'>
                        <i className="icon icon-check-circle-o" />
                        <span>RM Approve</span>
                    </Menu.Item >
                </SubMenu> : ("")}
                {userRoles.some((role) => role.id === 3) ?  <SubMenu key="sub4" title="Project Manager" className='main-menu-item'>
                    <Menu.Item onClick={onDropdownMenuSelect} key="Dashboard" className='sub-menu-item'>
                        <i className="icon icon-home" />
                        <span>Dashboard</span>
                    </Menu.Item >
                    <Menu.Item onClick={onDropdownMenuSelect} key="Task" className='sub-menu-item'>
                        <i className="icon icon-tasks" />
                        <span>Task</span>
                    </Menu.Item >
                    <Menu.Item onClick={onDropdownMenuSelect} key="TimelogSummary" className='sub-menu-item'>
                        <i className="icon icon-translation" />
                        <span>Timelog Summary</span>
                    </Menu.Item >
                    <Menu.Item onClick={onDropdownMenuSelect} key="WSR" className='sub-menu-item'>
                        <i className="icon icon-select" />
                        <span>WSR</span>
                    </Menu.Item >
                    <Menu.Item onClick={onDropdownMenuSelect} key="Resources" className='sub-menu-item'>
                        <i className="icon icon-wall" />
                        <span>Resources</span>
                    </Menu.Item >
                    <Menu.Item onClick={onDropdownMenuSelect} key="PMApprove" className='sub-menu-item'>
                        <i className="icon icon-check-circle-o" />
                        <span>PM Approve</span>
                    </Menu.Item >
                </SubMenu> : ("")}
                {userRoles.some((role) => role.id === 2) ? <SubMenu key="sub5" title="PMO" className='main-menu-item'>
                    <Menu.Item onClick={onDropdownMenuSelect} key="Projects" className='sub-menu-item'>
                        <i className="icon icon-home" />
                        <span>Projects</span>
                    </Menu.Item >
                    <Menu.Item onClick={onDropdownMenuSelect} key="TasksPMO" className='sub-menu-item'>
                        <i className="icon icon-tasks" />
                        <span>Tasks</span>
                    </Menu.Item >
                    <Menu.Item onClick={onDropdownMenuSelect} key="WeeklyTsStatus" className='sub-menu-item'>
                        <i className="icon icon-translation" />
                        <span>WeeklyTsStatus</span>
                    </Menu.Item >
                    <Menu.Item onClick={onDropdownMenuSelect} key="TimelogSummary" className='sub-menu-item'>
                        <i className="icon icon-translation" />
                        <span>Timelog Summary</span>
                    </Menu.Item >
                    <Menu.Item onClick={onDropdownMenuSelect} key="Plan" className='sub-menu-item'>
                        <i className="icon icon-translation" />
                        <span>Plan</span>
                    </Menu.Item >
                    <Menu.Item onClick={onDropdownMenuSelect} key="WRS" className='sub-menu-item'>
                        <i className="icon icon-select" />
                        <span>WSR</span>
                    </Menu.Item >
                    <Menu.Item onClick={onDropdownMenuSelect} key="Issues" className='sub-menu-item'>
                        <i className="icon icon-error" />
                        <span>Issues</span>
                    </Menu.Item >
                    <Menu.Item onClick={onDropdownMenuSelect} key="Resources" className='sub-menu-item'>
                        <i className="icon icon-wall" />
                        <span>Resources</span>
                    </Menu.Item >
                    <Menu.Item onClick={onDropdownMenuSelect} key="Cost" className='sub-menu-item'>
                        <i className="icon icon-pricing-table" />
                        <span>Cost</span>
                    </Menu.Item >
                    <Menu.Item onClick={onDropdownMenuSelect} key="Skills" className='sub-menu-item'>
                        <i className="icon icon-graduation" />
                        <span>Skills</span>
                    </Menu.Item >
                    <Menu.Item onClick={onDropdownMenuSelect} key="OrgStructure" className='sub-menu-item'>
                        <i className="icon icon-chart-tree" />
                        <span>Org Structure</span>
                    </Menu.Item >
                    <Menu.Item onClick={onDropdownMenuSelect} key="ProjectResource" className='sub-menu-item'>
                        <i className="icon icon-home" />
                        <span>Project Resource</span>
                    </Menu.Item >
                    <Menu.Item onClick={onDropdownMenuSelect} key="ResetPassword" className='sub-menu-item'>
                        <i className="icon icon-reset-password" />
                        <span>Reset Password</span>
                    </Menu.Item >
                    <Menu.Item onClick={onDropdownMenuSelect} key="TimesheetStatus" className='sub-menu-item'>
                        <i className="icon icon-timepicker" />
                        <span>Timesheet Status</span>
                    </Menu.Item >
                </SubMenu> : ("")}
                {userRoles.some((role) => role.id === 9999) ? (<SubMenu
          key="sub6"
          title="Executive"
          className="main-menu-item"
        >
             <Menu.Item onClick={onDropdownMenuSelect} key="ProjectsExecutive" className='sub-menu-item'>
                <i className="icon icon-diamond" />
                <span>Projects</span>
            </Menu.Item >
            <Menu.Item onClick={onDropdownMenuSelect} key="TasksExecutive" className='sub-menu-item'>
                <i className="icon icon-tasks" />
                <span>Tasks</span>
            </Menu.Item >
            <Menu.Item onClick={onDropdownMenuSelect} key="Clients" className='sub-menu-item'>
                <i className="icon icon-company" />
                <span>Clients</span>
            </Menu.Item >
            <Menu.Item onClick={onDropdownMenuSelect} key="SkillsExecutive" className='sub-menu-item'>
                <i className="icon icon-graduation" />
                <span>Skills</span>
            </Menu.Item >
            <Menu.Item onClick={onDropdownMenuSelect} key="OrgStructure" className='sub-menu-item'>
                <i className="icon icon-family" />
                <span>Org Structure</span>
            </Menu.Item >
          {/* <Menu.Item key="master/projects/manage">
            <Link to="/master/projects/manage" className="scale-x">
              <i className="icon icon-diamond" />
              <span>Projects</span>
            </Link>
          </Menu.Item> */}
          {/* <Menu.Item key="master/tasks/manage">
            <Link to="/master/tasks/manage?module=Executive" className="scale-x">
              <i className="icon icon-tasks" />
              <span>Tasks</span>
            </Link>
          </Menu.Item> */}
          {/* <Menu.Item key="master/clients/manage">
            <Link to="/master/clients/manage" className="scale-x">
              <i className="icon icon-company" />
              <span>Clients</span>
            </Link>
          </Menu.Item> */}
          {/* <Menu.Item key="skillMgr">
            <Link to="/skillMgr?module=Executive" className="scale-x">
              <i className="icon icon-graduation" />
              <span>Skills</span>
            </Link>
          </Menu.Item> */}
          {/* <Menu.Item key="master/orgstructure/manage">
            <Link to="/master/orgstructure/manage" className="scale-x">
              <i className="icon icon-family" />
              <span>Org Structure</span>
            </Link> */}
          {/* </Menu.Item> */}
        </SubMenu>) : ("")}

                <SubMenu key="sub7" title="Travel Desk" className='main-menu-item'>
                    <Menu.Item key="TravelRequest" onClick={onDropdownMenuSelect} className='main-menu-item'>
                        <i className="icon icon-hotel-booking" />
                        <span>Travel Request</span>
                    </Menu.Item>
                   {userRoles.some((role) => role.id === 3) && <Menu.Item key="PMApprove" onClick={onDropdownMenuSelect} className='main-menu-item'>
                        <i className="icon icon-check-circle-o" />
                        <span>PM Approve</span>
                    </Menu.Item> }
                    {userDetails.isReportingManager && <Menu.Item key="RMApprove" onClick={onDropdownMenuSelect} className='main-menu-item'>
                        <i className="icon icon-check-circle-o" />
                        <span>RM Approve</span>
                    </Menu.Item>}
                    {userRoles.some((role) => role.id === 8) && <Menu.Item key="AdminApprove" onClick={onDropdownMenuSelect} className='main-menu-item'>
                        <i className="icon icon-check-circle-o" />
                        <span>Admin Approve</span>
                    </Menu.Item> }
                </SubMenu>
            </Menu>
        </div>
    );
}
export default HamburgerMenu