import React, { useEffect, useState } from "react";
import Widget from "../../../../components/Widget/index";
import { Col, Row, Form, Table, Breadcrumb, Cascader, Button, message, Switch, Avatar } from "antd";
import Auxiliary from "util/Auxiliary";
import axios from 'axios';
import moment from 'moment';
import { API_URL } from 'constants/APIConfig';

const RMDashboardTasks = (props) => {
    const [form] = Form.useForm()
    const userDetails = JSON.parse(sessionStorage.getItem('user_details'))
    const token = sessionStorage.getItem('token')
    const [allReportees, setAllReportees] = useState([])

    const [tasksCount, setTasksCount] = useState({})

    const [previousUserId, setPreviousUserId] = useState([])
    const [allTasksData, setAllTasksData] = useState([])
    const [filteredTasksData, setFilteredTasksData] = useState([])

    const [loader, setLoader] = useState(false)
    const [selectedCard, setSelectedCard] = useState('New')

    const getAllReportees = (val) => {
        axios.get(API_URL + `/api/rm_dashboard/getAllReportees/${userDetails.id}?inActive=${val}&t=${new Date().getTime()}`,
            {
                headers: {
                    'x-access-token': `${token}`,
                    'Access-Control-Allow-Origin': '*'
                }
            })
            .then(response => {
                let gData = {}
                if (Object.keys(response.data.data).length > 0) {
                    let gNo = 0
                    response.data.data.forEach(d => {
                        d.key = d.value
                        if (gData[d.levelNo]) {
                            gData[d.levelNo].push(d)
                        } else {
                            gData[d.levelNo] = [d]
                        }
                        if (d.levelNo > gNo) {
                            gNo = d.levelNo
                        }
                        if (d.activate_yn_nm === 0) {
                            d.label = (<span style={{ color: "red" }} title={d.label + ' - Resigned'}>
                                <Avatar className="gx-size-20 gx-align-self-end" src={require("assets/images/resignation.png")} alt="Resigned" />
                                &nbsp;{d.label}</span>)
                        }
                    })

                    for (let i = gNo; i > 0; i--) {
                        gData[i].forEach(c => {
                            gData[i - 1].forEach(p => {
                                if (p.value == c.reporting_manager_nm) {
                                    if (p.children) {
                                        p.children.push(c)
                                    } else {
                                        p.children = [c]
                                    }
                                }
                            })
                        })
                    }
                }
                setAllReportees([gData[0][0]])
            })
            .catch(error => {
                console.log(error);
            });
    }

    const getAllUserTasks = (id) => {
        setLoader(true)
        axios.get(API_URL + `/api/rm_dashboard/getAllUserTasks/${id}?t=${new Date().getTime()}`,
            {
                headers: {
                    'x-access-token': `${token}`,
                    'Access-Control-Allow-Origin': '*'
                }
            })
            .then(response => {
                let count = {
                    'new': 0,
                    'inProgress': 0,
                    'waiting': 0,
                    'resolved': 0,
                    'closed': 0
                }
                response.data.data.forEach(t => {
                    t.key = t.taskId
                    if (t.taskStatus === 'New') {
                        count.new++
                    } else if (t.taskStatus === 'In Progress') {
                        count.inProgress++
                    } else if (t.taskStatus === 'Waiting For Inputs') {
                        count.waiting++
                    } else if (t.taskStatus === 'Resolved') {
                        count.resolved++
                    } else {
                        count.closed++
                    }
                    t.startDate = t.startDate ? moment(t.startDate).format("DD-MM-YYYY") : ''
                    t.dueDate = t.dueDate ? moment(t.dueDate).format("DD-MM-YYYY") : ''
                })
                setTasksCount(count)
                setAllTasksData(response.data.data)
                let newTasksList = response.data.data.filter(t => t.taskStatus === selectedCard)
                setFilteredTasksData(newTasksList);
                setLoader(false)
            })
            .catch(error => {
                setAllTasksData([])
                setLoader(false)
                message.error('Error occured')
                console.log(error)
            });
    }

    const downloadAllData = () => {
        let csvHeader1 = "Task ID,Task Name,Status,Project,Start Date,Due Date\n";
        let csvData1 = ""
        allTasksData.forEach(emp => {
            csvData1 += (emp.taskId + "," + emp.taskName + "," +
                emp.taskStatus + "," + emp.projectName + "," + emp.startDate + "," + emp.dueDate + "\n");
        })

        let anchor1 = document.createElement('a')
        anchor1.href = 'data:text/csv;charset=utf-8,' + encodeURI(csvHeader1 + csvData1)
        anchor1.download = "Employee All Tasks"
        anchor1.click()
    }

    const fetchReporteeUtilizationData = (card) => {
        let reportee = form.getFieldValue('resourceId') || [];
        if (reportee.length) {
            getAllUserTasks(reportee[reportee.length - 1])
        }
    }

    const taskColumns = [
        {
            title: 'Task ID', dataIndex: 'taskId', width: 100
        },
        {
            title: 'Task Name', dataIndex: 'taskName', width: 150
        },
        {
            title: 'Status', dataIndex: 'taskStatus', width: 150
        },
        {
            title: 'Project', dataIndex: 'projectName', width: 150
        },
        {
            title: 'Start Date', dataIndex: 'startDate', width: 100
        },
        {
            title: 'Due Date', dataIndex: 'dueDate', width: 100
        },

    ]

    const cardSelect = (v) => {
        if(selectedCard !== v && allTasksData.length) {
            setSelectedCard(v)
            let newTasksList = allTasksData.filter(t => t.taskStatus === v)
            setFilteredTasksData(newTasksList);
        }
    }

    useEffect(() => {
        getAllReportees(true)
    }, [])

    const displayRender = (labels, selectedOptions) =>
        labels.map((label, i) => {
            const option = selectedOptions[i];
            if (i === labels.length - 1) {
                return (
                    <span key={option.value}>
                        <b>{label}</b>
                    </span>
                );
            }
            return <span key={option.value}>{label} / </span>;
        });

    return (
        <Auxiliary>
            <Breadcrumb>
                <Breadcrumb.Item>Resource Manager</Breadcrumb.Item>
                <Breadcrumb.Item className={'text-helight'}><i className="icon icon-check-circle-o" /> Dashboard - Tasks</Breadcrumb.Item>
            </Breadcrumb>

            <Row>
                <Col lg={4} md={4} sm={4} xs={4} className="scale-x">
                    <div style={{"cursor": 'pointer'}} onClick={() => { cardSelect('New') }}>
                    <Widget styleName={selectedCard === 'New' ? 'rmTaskCard' : ''}>
                        <div className="gx-media gx-align-items-center gx-flex-nowrap" >
                            <div className="gx-mr-lg-4 gx-mr-3">
                                <i className={`icon icon-revenue-new gx-fs-xlxl gx-text-geekblue gx-d-flex`}
                                    style={{ fontSize: 25 }} />
                            </div>
                            <div className="gx-media-body">
                                <h1 className="gx-fs-xxxl gx-font-weight-medium gx-mb-1">{tasksCount.new}</h1>
                                <p className="gx-text-grey gx-mb-0">New</p>
                            </div>
                        </div>
                    </Widget>
                    </div>
                </Col>
                <Col lg={4} md={4} sm={4} xs={4} className="scale-x">
                <div style={{"cursor": 'pointer'}} onClick={() => { cardSelect('In Progress') }} disabled={true}>
                    <Widget styleName={selectedCard === 'In Progress' ? 'rmTaskCard' : ''} >
                        <div className="gx-media gx-align-items-center gx-flex-nowrap" onClick={() => { cardSelect('In Progress') }}>
                            <div className="gx-mr-lg-4 gx-mr-3">
                                <i className={`icon icon-queries gx-fs-xlxl gx-text-geekblue gx-d-flex`}
                                    style={{ fontSize: 25 }} />
                            </div>
                            <div className="gx-media-body">
                                <h1 className="gx-fs-xxxl gx-font-weight-medium gx-mb-1">{tasksCount.inProgress}</h1>
                                <p className="gx-text-grey gx-mb-0">In Progress</p>
                            </div>
                        </div>
                    </Widget>
                    </div>
                </Col>
                <Col lg={4} md={4} sm={4} xs={4} className="scale-x">
                <div style={{"cursor": 'pointer'}} onClick={() => { cardSelect('Waiting For Inputs') }}>
                    <Widget styleName={selectedCard === 'Waiting For Inputs' ? 'rmTaskCard' : ''} >
                        <div className="gx-media gx-align-items-center gx-flex-nowrap" onClick={() => { cardSelect('Waiting For Inputs') }}>
                            <div className="gx-mr-lg-4 gx-mr-3">
                                <i className={`icon icon-visits gx-fs-xlxl gx-text-geekblue gx-d-flex`}
                                    style={{ fontSize: 25 }} />
                            </div>
                            <div className="gx-media-body">
                                <h1 className="gx-fs-xxxl gx-font-weight-medium gx-mb-1">{tasksCount.waiting}</h1>
                                <p className="gx-text-grey gx-mb-0">Waiting</p>
                            </div>
                        </div>
                    </Widget>
                    </div>
                </Col>
                <Col lg={4} md={4} sm={4} xs={4} className="scale-x">
                <div style={{"cursor": 'pointer'}} onClick={() => { cardSelect('Resolved') }}>
                    <Widget styleName={selectedCard === 'Resolved' ? 'rmTaskCard' : ''}>
                        <div className="gx-media gx-align-items-center gx-flex-nowrap" onClick={() => { cardSelect('Resolved') }}>
                            <div className="gx-mr-lg-4 gx-mr-3">
                                <i className={`icon icon-check-circle-o gx-fs-xlxl gx-text-geekblue gx-d-flex`}
                                    style={{ fontSize: 25 }} />
                            </div>
                            <div className="gx-media-body">
                                <h1 className="gx-fs-xxxl gx-font-weight-medium gx-mb-1">{tasksCount.resolved}</h1>
                                <p className="gx-text-grey gx-mb-0">Resolved</p>
                            </div>
                        </div>
                    </Widget>
                    </div>
                </Col>
                <Col lg={4} md={4} sm={4} xs={4} className="scale-x">
                <div style={{"cursor": 'pointer'}} onClick={() => { cardSelect('Closed') }}>
                    <Widget styleName={selectedCard === 'Closed' ? 'rmTaskCard' : ''}>
                        <div className="gx-media gx-align-items-center gx-flex-nowrap" onClick={() => { cardSelect('Closed') }}>
                            <div className="gx-mr-lg-4 gx-mr-3">
                                <i className={`icon icon-close-circle gx-fs-xlxl gx-text-geekblue gx-d-flex`}
                                    style={{ fontSize: 25 }} />
                            </div>
                            <div className="gx-media-body">
                                <h1 className="gx-fs-xxxl gx-font-weight-medium gx-mb-1">{tasksCount.closed}</h1>
                                <p className="gx-text-grey gx-mb-0">Closed</p>
                            </div>
                        </div>
                    </Widget>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col lg={4} md={4} sm={4} xs={4} >
                    <Button onClick={downloadAllData}
                        disabled={filteredTasksData.length === 0}
                        style={{ float: 'right' }}>Download As CSV</Button>
                </Col>
                <Col lg={4} md={4} sm={4} xs={4} ></Col>
                <Col lg={4} md={4} sm={4} xs={4} ></Col>
                <Col lg={4} md={4} sm={4} xs={4} ></Col>
                <Col lg={8} md={8} sm={8} xs={8} >
                    <Form
                        form={form}
                        layout="inline"
                        align="center"
                    >
                        <Row>
                            <Col lg={24} md={24} sm={24} xs={24}>
                                <Form.Item name="active" label="Display InActive Resources: " valuePropName="checked" initialValue={false}>
                                    <Switch onChange={(v) => { getAllReportees(!v) }} className="switchSpace" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={24} md={24} sm={24} xs={24}>
                                <Form.Item
                                    name="resourceId">
                                    <Cascader
                                        options={allReportees}
                                        //expandTrigger="hover"
                                        onPopupVisibleChange={v => {
                                            if (v === false) {
                                                let reportee = form.getFieldValue('resourceId') || [0];
                                                if (reportee.toString() !== previousUserId.toString()) {
                                                    setPreviousUserId(reportee)
                                                    fetchReporteeUtilizationData()
                                                }
                                            }
                                        }}
                                        displayRender={displayRender}
                                        placeholder="Select The Resource"
                                        allowClear={false}
                                        changeOnSelect={true}
                                    />
                                </Form.Item>
                                <b>Note:</b> To select Parent Resource, Double click on it.
                                <br /><br />
                            </Col>
                        </Row>

                    </Form>
                </Col>
            </Row>

            <Row gutter={24}>
                <Col lg={24} md={24} sm={24} xs={24}  >
                    <Table dataSource={filteredTasksData} scroll={{ x: 1200, y: 350, }} columns={taskColumns} bordered={true} loading={loader}>
                    </Table>
                </Col>
            </Row>


        </Auxiliary>
    );
};
export default RMDashboardTasks;