import React from "react";
import axios from 'axios';
import Highlighter from "react-highlight-words";
import { Button, Input, Avatar, Tooltip, Breadcrumb, Row, Col } from "antd";
import Auxiliary from "util/Auxiliary";
import { API_URL } from 'constants/APIConfig';
import { FormOutlined, SearchOutlined, FileAddTwoTone } from '@ant-design/icons';
import get from "lodash.get";
import isequal from "lodash.isequal";
import { Table } from "ant-table-extensions";
import { Link } from "react-router-dom";

class ManageResource extends React.Component {
    state = {
        searchText: "",
        searchedColumn: "",
        resources: [],
        loading: false,
        userDetails: JSON.parse(sessionStorage.getItem('user_details')),
        token: sessionStorage.getItem('token'),
    };

    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters
        }) => (
            <div className="custom-filter-dropdown">
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e =>
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                    }
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}

                    style={{ width: 188, marginBottom: 8, display: "block" }}
                />
                <Button
                    type="primary"
                    onClick={() => this.handleSearch(selectedKeys, confirm)}
                    icon="search"
                    size="small"
                    style={{ width: 70, marginRight: 8, float: 'left' }}
                >
                    {/*   Search */}
                </Button>
                <Button
                    onClick={() => this.handleReset(clearFilters)}
                    size="small"
                    style={{ width: 70, float: 'right' }}
                >
                    Reset
            </Button>
            </div>
        ),
        filterIcon: filtered => (
            <SearchOutlined />
        ),
        onFilter: (value, record) => {
            return get(record, dataIndex)
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase());
        },

        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select());
            }
        },
        render: text => {
            return isequal(this.state.searchedColumn, dataIndex) ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
                    searchWords={[this.state.searchText]}
                    autoEscape
                    textToHighlight={text.toString()}
                />
            ) : (
                text
            );
        }
    });
    getResources = () => {
        axios.get(API_URL + `/api/users/getall`, 
        {headers: {
          'x-access-token': `${this.state.token}`, 
        'Access-Control-Allow-Origin': '*'}
       })
            .then(response => {
                let data =[];
                response.data.data.map((item, index) => {
                  data.push({
                    id:item.id,
                    employeeId: item.employeeId,
                    displayNameTx: item.displayNameTx,
                    joinedOn: item.joinedOn,
                    designation: item.designation,
                    emailTx: item.emailTx,
                    mobileTx: item.mobileTx,
                    primarySkill: item.primarySkill,
                    reportingManager: item.reportingManager && item.reportingManager.displayNameTx,
                    location: item.location,
                    //department: item.department,
                    //subDepartment: item.subDepartment,
                    //division: item.division,
                    competency: item.competency,
                    isCompetencyMgr: item.isCompetencyMgr,
                    activateYnNm: item.relievedOn,
                    key: item.id
                    })
                })
                this.setState({ resources: data });
            })
            .catch(error => {
                console.log(error);
            });
    }
    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex
        });
    };
    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: "" });
    };
    componentDidMount() {
        this.getResources();
    }
    render() {
        const columns = [
            {
                title: 'Employee', dataIndex: 'employeeId', width: 100, align: 'center',fixed: 'left',
                render: (text, record) => {
                  return (
                    <div className="gx-d-flex">
                      {(() => {
                        try {
                          return <Tooltip placement="topLeft" title={record.displayNameTx?record.displayNameTx:""}> <Avatar size={{ xs: 24, sm: 32, md: 40, lg: 64, xl: 80, xxl: 100 }} src={require(`../../../../src/assets/images/avatar/${text.toLowerCase()}.png`)} /></Tooltip>
                        } catch (err) {
                            try {
                                return <Tooltip placement="topLeft" title={record.displayNameTx?record.displayNameTx:""}> <Avatar size={{ xs: 24, sm: 32, md: 40, lg: 64, xl: 80, xxl: 100 }} src={require(`../../../../src/assets/images/avatar/profile.png`)} /></Tooltip>
                              } catch (err) {
                                return <Tooltip placement="topLeft" title={record.displayNameTx?record.displayNameTx:""}><span className="ant-avatar gx-size-40 gx-border gx-border-grey gx-bg-transparent gx-text-grey gx-align-items-center gx-justify-content-center"><i
                                  className="icon icon-add" /></span></Tooltip>
                              }
                        }
                      })()}
                      <div className="gx-ml-1">
                        <div>{record.empname}</div>
                        <small> {record.empid} </small>
                      </div>
                    </div>
                  );
                },
              },
            {
                title: 'Emp ID', dataIndex: 'employeeId', key: 'employeeId', fixed: 'left', width: 150,
                sorter: (a, b) => String(a.employeeId).localeCompare(String(b.employeeId)),
                sortDirections: ['descend', 'ascend'],
                ...this.getColumnSearchProps("employeeId")
            },
            {
                title: 'Name', dataIndex: 'displayNameTx', key: 'displayNameTx', width: 250,
                sorter: (a, b) => String(a.displayNameTx).localeCompare(String(b.displayNameTx)),
                sortDirections: ['descend', 'ascend'],
                ...this.getColumnSearchProps("displayNameTx")
            },
            {
                title: 'DOJ', dataIndex: 'joinedOn', key: 'joinedOn', width: 150,
                sorter: (a, b) => String(a.joinedOn).localeCompare(String(b.joinedOn)),
                sortDirections: ['descend', 'ascend'],
                ...this.getColumnSearchProps("joinedOn")
            },
            {
                title: 'Designation', dataIndex: 'designation', key: 'designation', width: 250,
                sorter: (a, b) => String(a.designation).localeCompare(String(b.designation)),
                sortDirections: ['descend', 'ascend'],
                ...this.getColumnSearchProps("designation")
            },

            {
                title: 'Email', dataIndex: 'emailTx', key: 'emailTx', width: 250,
                sorter: (a, b) => String(a.emailTx).localeCompare(String(b.emailTx)),
                sortDirections: ['descend', 'ascend'],
                ...this.getColumnSearchProps("emailTx")
            },

            {
                title: 'Mobile', dataIndex: 'mobileTx', key: 'mobileTx', width: 200,
                sorter: (a, b) => String(a.mobileTx).localeCompare(String(b.mobileTx)),
                sortDirections: ['descend', 'ascend'],
            },
            {
                title: 'Primary Skill', dataIndex: 'primarySkill', key: 'primarySkill', width: 250,
                sorter: (a, b) => String(a.primarySkill).localeCompare(String(b.primarySkill)),
                sortDirections: ['descend', 'ascend'],
            },
            {
                title: 'Reporting To', dataIndex: 'reportingManager', key: 'reportingManager', width: 250,
                sorter: (a, b) => String(a.reportingManager).localeCompare(String(b.reportingManager)),
                sortDirections: ['descend', 'ascend'],
                ...this.getColumnSearchProps("reportingManager")
            },
            {
                title: 'Location', dataIndex: 'location', key: 'location', width: 250,
                sorter: (a, b) => String(a.location).localeCompare(String(b.location)),
                sortDirections: ['descend', 'ascend'],
            },
            {
                title: 'Department', dataIndex: 'department', key: 'department', width: 200,
                sorter: (a, b) => String(a.department).localeCompare(String(b.department)),
                sortDirections: ['descend', 'ascend'],
            },
            {
                title: 'Sub Department', dataIndex: 'subDepartment', key: 'subDepartment', width: 200,
                sorter: (a, b) => String(a.subDepartment).localeCompare(String(b.subDepartment)),
                sortDirections: ['descend', 'ascend'],
            },
            {
                title: 'Division', dataIndex: 'division', key: 'division', width: 200,
                sorter: (a, b) => String(a.division).localeCompare(String(b.division)),
                sortDirections: ['descend', 'ascend'],
            },
            {
                title: 'Competency', dataIndex: 'competency', key: 'competency', width: 200,
                sorter: (a, b) => String(a.competency).localeCompare(String(b.competency)),
                sortDirections: ['descend', 'ascend'],
            },
            {
                title: 'Competency Mgr', dataIndex: 'isCompetencyMgr', key: 'isCompetencyMgr', width: 200,
                sorter: (a, b) => String(a.isCompetencyMgr).localeCompare(String(b.isCompetencyMgr)),
                sortDirections: ['descend', 'ascend'],
            },
            {
                title: 'Active', dataIndex: 'activateYnNm', key: 'activateYnNm', width: 150,
                sorter: (a, b) => String(a.activateYnNm).localeCompare(String(b.activateYnNm)),
                sortDirections: ['descend', 'ascend'],
            },
            { title: 'Action', fixed: 'right', width: 100, dataIndex: 'id', render: text => <div><span> <a href={'./manage/' + text}><FormOutlined style={{ padding: '10px' }} /></a></span> </div> },
        ];
        return (
            <Auxiliary>
                <Row className="m_0 gx-pt-2">
                    <Col xl={4} lg={4} md={4} sm={24} xs={24} className="flex_style gx-pl-0 ant-row-start">
                        <Breadcrumb>
                            <Breadcrumb.Item>PMO</Breadcrumb.Item>
                            <Breadcrumb.Item className="text-helight"><i className="icon icon-chart-tree" /> Org Structure</Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                    <Col xl={16} lg={16} md={16} sm={24} xs={24} className="flex_style gx-pr-0">

                    </Col>
                    <Col xl={4} lg={4} md={4} sm={24} xs={24} className="flex_style gx-pr-0">

                    </Col>
                </Row>
                
                <div className="newprojectBtn">
                    <span> <Link className="gx-login-form-forgot gx-float-right gx-pr-1" to="./new"><FileAddTwoTone style={{ padding: '10px' }} /> New Resource</Link></span> 
                </div>
                <Table className="PmoOrgStructure-table" columns={columns} dataSource={this.state.resources} size="small" pagination={{ pageSize: 10 }} scroll={{ x: 1300 }} 
                exportable
                exportableProps={{ showColumnPicker: true }}
                />
            </Auxiliary>
        );
    }
}
export default ManageResource;