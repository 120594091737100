import React, { useEffect, useState } from "react";
import { Calendar, momentLocalizer } from 'react-big-calendar'
import { Card, Radio, Row, Col, DatePicker, Button, Cascader, Table } from "antd";
import moment from 'moment'
import events from "../events";
import Auxiliary from "util/Auxiliary";
import axios from 'axios';
import { API_URL } from 'constants/APIConfig';

const localizer = momentLocalizer(moment);
const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;
const { RangePicker } = DatePicker;

const options = [{
  value: 'zhejiang',
  label: 'Zhejiang',
  children: [{
    value: 'hangzhou',
    label: 'Hangzhou',
    children: [{
      value: 'xihu',
      label: 'West Lake',
    }, {
      value: 'xiasha',
      label: 'Xia Sha',
      disabled: true,
    }],
  }],
}, {
  value: 'jiangsu',
  label: 'Jiangsu',
  children: [{
    value: 'nanjing',
    label: 'Nanjing',
    children: [{
      value: 'zhonghuamen',
      label: 'Zhong Hua men',
    }],
  }],
}];

function onChange2(value, selectedOptions) {
  console.log(value, selectedOptions);
}
function filter(inputValue, path) {
  return (path.some(option => (option.label).toLowerCase().indexOf(inputValue.toLowerCase()) > -1));
}
function onChange(e) {
  console.log(`radio checked:${e.target.value}`);
}

const ResUtil = () => {

  const userDetails = JSON.parse(sessionStorage.getItem('user_details'));
  const token = sessionStorage.getItem('token');

  const [resAlloc, setResAlloc] = useState([]);
  const getRescUtilization = () => {
    axios.get(API_URL + `/api/project_resources/getResourceAvailability?reportingManagerId=${"" + userDetails.id}&year=2020&month=09`, 
    {headers: {
      'x-access-token': `${token}`, 
    'Access-Control-Allow-Origin': '*'}
   })

      .then(response => {
        console.log('getRescUtilization', response.data.data)
        setResAlloc(response.data.data);
      })
      .catch(error => {
        console.log(error)
      });
  }

  useEffect(() => {
    getRescUtilization()
  }, [])

  let cols = [{
    title: `Resource Name`,
    dataIndex: `key0`,
    width: 450,
  }];
  const getDaysArray = (year, month) => {
    const monthIndex = month - 1
    const names = Object.freeze(
      ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']);
    const date = new Date(year, monthIndex, 1);
    let i = 1;
    while (date.getMonth() == monthIndex) {
      cols = [...cols, ...[{
        title: `${date.getDate()} ${names[date.getDay()]}`,
        dataIndex: `key${i}`,
        width: 40,
        render(text, record) {
          return {
            props: {
              style: { background: parseInt(text) < 5 ? "red" : "green", color: "white" }
            },
            children: <div>{text}</div>
          };
        }
      }]]
      i++;
      date.setDate(date.getDate() + 1);
    }
  }
  let days = getDaysArray(2020, 9);


  return (
    <Auxiliary>
      <Row gutter={24}>
        <Col xl={12} lg={6} md={6} sm={12} xs={12}>
          {/*  <h2 className="gx-mb-3 gx-mb-sm-4 headings_title">Resource Utilization</h2> */}
        </Col>
        <Col xl={12} lg={6} md={6} sm={12} xs={12} >
          <div className="float-right" >
            <RadioGroup onChange={onChange} defaultValue="a">
              <RadioButton value="a">Weekly</RadioButton>
              <RadioButton value="b">Monthly</RadioButton>
              <RadioButton value="c">Quaterly</RadioButton>
            </RadioGroup>
          </div>
        </Col>
      </Row>

      <Row gutter={24}>
        <Col lg={24} md={24} sm={24} xs={24} >
          <Table className="gx-table-responsive rutil" columns={cols} dataSource={resAlloc} pagination={{ pageSize: 5 }} size="small" />
        </Col>
      </Row>

    </Auxiliary>
  )
};
export default ResUtil;