import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import axios from 'axios';
import { Col, Row } from "antd";
import { Avatar, Descriptions, Collapse, Tooltip} from "antd";
import Auxiliary from "util/Auxiliary";
import { API_URL } from 'constants/APIConfig';
import Issues from "../../reports/issue/index";
import Cost from "../../reports/cost/index";
import Resources from "../../reports/resource/index";
import Plan from "../../reports/plan/index";
import Tasks from "../../reports/task/index";
import WSR from "../../reports/wsr/by-id/index";

const Panel = Collapse.Panel;

const ProjectsReport = (props) => {
  const [item, setItems] = useState([]);
  let i = 0;
  
  let history = useHistory();  
  const userDetails = JSON.parse(sessionStorage.getItem('user_details'));
  const token = sessionStorage.getItem('token');

  const getProjectbyId = () => {
    axios.get(API_URL + '/api/project/v2/get/' + props.Project, 
    {headers: {
      'x-access-token': `${token}`, 
    'Access-Control-Allow-Origin': '*'}
   })
      .then(response => {
        setItems(response.data.data);
      })
      .catch(error => {
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log('Error', error.message);
        }
        console.log(error.config);
      });
  }

  useEffect(() => {
    getProjectbyId();
  }, [])

  return (
    <Auxiliary>
      <Collapse defaultActiveKey={['1']} expandIconPosition="right">
        <Panel header=""
          extra={
            <ul className="gx-list-inline gx-ml-auto gx-mb-0 gx-text-grey reportsPage">
              <li className="projectBlock">
                <Row gutter={24} className="alignItemCenter">
                  <Col xl={12} lg={12} md={12} sm={12} xs={12} className="subprojectDetails gx-text-left"><p>{item.name}</p>{item.shortName}</Col>
                  <Col xl={3} lg={3} md={3} sm={3} xs={3} className="subprojectDetails"><p>{item.startDate}</p>Start Date</Col>
                  <Col xl={3} lg={3} md={3} sm={3} xs={3} className="subprojectDetails"><p>{item.endDate}</p>End Date</Col>
                  <Col xl={3} lg={3} md={3} sm={3} xs={3} className="subprojectDetails"><p>{item.status && item.status.statusTx}</p>Status</Col>
                  <Col xl={3} lg={3} md={3} sm={3} xs={3} className="subprojectDetails"><p>{item.progress}</p>Progress</Col>
                </Row>
              </li>
            </ul>
          }
          key="1"
        >
          <Descriptions size="small">
            <Descriptions.Item label="Scope">{item.scope}</Descriptions.Item>
          </Descriptions>
          <hr></hr>
          <Descriptions size="small">
            <Descriptions.Item label="Client">{item.client && item.client.organization}</Descriptions.Item>
            <Descriptions.Item label="Estd Hours">{item.estimatedHours}</Descriptions.Item>
            <Descriptions.Item label="Type">{item.type && item.type.typeTx}</Descriptions.Item>
            <Descriptions.Item label="Priority">{item.project_priority && item.project_priority.priority}</Descriptions.Item>
            <Descriptions.Item label="Billable">{item.name}</Descriptions.Item>
          </Descriptions>
          <hr></hr>
          <Descriptions size="small">
            <Descriptions.Item label="Project Manager">{item.ProjectManager && item.ProjectManager.displayNameTx}</Descriptions.Item>
            <Descriptions.Item label="Account Manager">{item.AccountManager && item.AccountManager.displayNameTx}</Descriptions.Item>
            <Descriptions.Item label="Project Initiator">{item.Initiator && item.Initiator.displayNameTx}</Descriptions.Item>
            <Descriptions.Item label="Project Coordinator">{item.ProjectCoordinator && item.ProjectCoordinator.displayNameTx}</Descriptions.Item>
          </Descriptions>
          <hr></hr>
          <Descriptions size="small">
            <Descriptions.Item label="PO Number">{item.poNumber}</Descriptions.Item>
            <Descriptions.Item label="PO Date">{item.poDate}</Descriptions.Item>
            <Descriptions.Item label="PO Value">{item.poDate}</Descriptions.Item>
            <Descriptions.Item label="Billing Type">{item.ProjectCoordinator && item.ProjectCoordinator.displayNameTx}</Descriptions.Item>
          </Descriptions>
          <hr></hr>
          <Descriptions size="small">
            <Descriptions.Item label="Technology">{item.technology && item.technology.nameTx}</Descriptions.Item>
            <Descriptions.Item label="Industry">{item.industryType}</Descriptions.Item>
            <Descriptions.Item label="Sector">{item.sector}</Descriptions.Item>
          </Descriptions>
          <hr></hr>
          <Descriptions size="small" className="gx-list-inline gx-mb-3 gx-mb-lg-4 ">
            <Descriptions.Item label="Resources">
              {
                item.users ? item.users.map((user, index) => (
                  <li>
                    {(() => {
                      try {
                        return <Tooltip placement="topLeft" title={user.displayNameTx}><Avatar src={require(`../../../../assets/images/avatar/${user.employeeId.toLowerCase()}.png`)} /></Tooltip>
                      } catch (err) {
                        return <Tooltip placement="topLeft" title={user.displayNameTx}><span className="ant-avatar gx-size-40 gx-border gx-border-grey gx-bg-transparent gx-text-grey gx-d-flex gx-align-items-center gx-justify-content-center"><i
                          className="icon icon-add" /></span></Tooltip>
                      }
                    })()}
                  </li>)) : null
              }
            </Descriptions.Item>
          </Descriptions>
          <hr></hr>
          <Descriptions size="small">
            <Descriptions.Item label="Note">{item.description}</Descriptions.Item>
          </Descriptions>
        </Panel>
        <Panel header="Project Plan" key="2" >
          <Plan id={props.Project} />
        </Panel>
        <Panel header="Project WSR" key="3" >
          <WSR id={props.Project} />
        </Panel>
        <Panel header="Project Resources" key="4" >
          <Resources id={props.Project} />
        </Panel>
        <Panel header="Project Tasks" key="5" >
          <Tasks id={props.Project} />
        </Panel>
        <Panel header="Project Cost" key="6">
          <Cost id={props.Project} />
        </Panel>
        <Panel header="Project Issues" key="7">
          <Issues id={props.Project} />
        </Panel>
      </Collapse>
    </Auxiliary>
  );
};
export default ProjectsReport;
