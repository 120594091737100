import React, { useState } from "react";
import { Button, Row, Input, Col, message } from "antd";
import axios from 'axios';
import { API_URL } from 'constants/APIConfig';
import 'moment/min/locales';
import Auxiliary from "util/Auxiliary";

const { TextArea } = Input;

const TSPMApproval = (props) => {
  const userDetails = JSON.parse(sessionStorage.getItem('user_details'));
  const token = sessionStorage.getItem('token');
  
  const [comments, setComments] = useState("");
  const [approvedHrs, setApprovedHrs] = useState(0);
  const [defaultApprovedHrs, setDefaultApprovedHrs] = useState(0);
  const [textAreaCount, setTextAreaCount] = React.useState(0);

  function onChangeApproveHrs(value) {
    setApprovedHrs(value);
  }

  const approveTimesheet = () => {   
    axios.post(API_URL +'/api/timesheets/submitProjectMgrApprove',{
      "approverId": +userDetails.id,
      "userId": +props.rcrd.userid,
      "projectId": +props.rcrd.projectid,
      "weekNumber": props.rcrd.weekno,
      "year": props.rcrd.yearno,
      "taskId": +props.rcrd.taskid,
      "status": "Approved",
      "approvedHrs": approvedHrs,
      "comments": comments,
     // "defaultProjectId": null,
     // "defaultTaskId": null
    }, 
    {headers: {
      'x-access-token': `${token}`, 
    'Access-Control-Allow-Origin': '*'}
   })
      .then(response => {
        //console.log("Approvl Res",response);
        message.success("Timesheet Approved Successfully");
       // history.push("/timesheets/approve");
        props.onClose();
      })
      .catch(error => {
        // Error
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log('Error', error.message);
        }
        console.log(error.config);
        message.error("Error In submitting timesheet", error)

      }); 
  }

  const handleComments = (e) => {
    setComments(e.target.value);
    setTextAreaCount(e.target.value.length);
  }

   React.useEffect(() => {
    setDefaultApprovedHrs(+props.rcrd.totalTaskHrs);
  }, []) 
  return (
    <Auxiliary>
      <Row className="m_0 buttonGroup">
        <Col xl={8} lg={8} md={8} sm={8} xs={8} className="gx-p-0">
          <p className="gx-mb-1 gx-mt-1">Comments:</p>
        </Col>
        <Col xl={16} lg={16} md={16} sm={16} xs={16} className="gx-p-0">
          <TextArea placeholder="Please enter the comments" onChange={e => handleComments(e)} rows={4} value={comments} maxLength={255}/>
          <p style={{textAlign: 'right'}}> {`${textAreaCount} / 255`} </p>
        </Col>
      </Row>

      <Row className="m_0 buttonGroup">
        <Col xl={24} lg={24} md={24} sm={24} xs={24} className="gx-p-0">
          <Button type="primary" className="save_btn" onClick={approveTimesheet} htmlType="submit">Approve</Button>
         <Button type="primary" className="add-btn gx-mr-2" onClick={props.onClose}>Cancel</Button>  
        </Col>
      </Row>

    </Auxiliary>
  );
};
export default TSPMApproval;