import React, { useState } from "react";
import { Col, Row, Tabs, Breadcrumb } from "antd";
import Skills from './skill/manage/index';
import ManageLocation from './skill/location/index';
import ManageDepartment from './skill/department/index';
import ParentSkill from './skill/parentSkills/index';
import ManageSkillBase from './skill/skillBase/index';
import ManageEmployeeSkills from './skill/employee/index';

const TabPane = Tabs.TabPane;

const SkillMgrDashboard = (props) => {
  const query = new URLSearchParams(props.location.search);
  const [tabValue, setTabValue] = useState(1)

  const onTabCHange = (key)=>{
    setTabValue(key);
  }

  return (
    <div>
      <Row className="m_0 gx-pt-2">
        <Col xl={24} lg={24} md={24} sm={24} xs={24} className="gx-p-0">
          <Breadcrumb>
            <Breadcrumb.Item>{query.get('module')}</Breadcrumb.Item>
            <Breadcrumb.Item className="text-helight"><i className="icon icon-graduation" /> Skills</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>
      
      <br />
      <Row gutter={24} className="m_0" style={{border: "1px solid #28aaeb"}}>
        <Col xl={24} lg={24} md={24} sm={24} xs={24} className="">

          <Tabs tabPosition='left' className="skillTabs" id="skillTabsId" onChange={(e)=>onTabCHange(e)} destroyInactiveTabPane={true}>
            <TabPane tab="Location" key="1"><ManageLocation /></TabPane>
            <TabPane tab="Department" key="2"><ManageDepartment /></TabPane>
            <TabPane tab="Skills" key="3"><ParentSkill /></TabPane>
            <TabPane tab="Sub Skills" key="4"><Skills /></TabPane>
            <TabPane tab="Skill Base" key="5"><ManageSkillBase /></TabPane>
            <TabPane tab="Employee Skills" key="6"><ManageEmployeeSkills /></TabPane>
          </Tabs>
        </Col>
        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
          <div className="PmoSkillsTable-sm">
            {
              tabValue == 1 ?  <ManageLocation /> :  tabValue == 2 ? <ManageDepartment /> :  tabValue == 3 ? <Skills /> : <ManageSkillBase />
            }
          </div>    
        </Col>
      </Row>
    </div>
  );
};
export default SkillMgrDashboard;