import React, { useEffect, useState } from "react";
import { Button, Row, Col, Form, Input, Select, DatePicker, message, InputNumber } from "antd";
import axios from 'axios';
import moment from "moment";
import { API_URL } from 'constants/APIConfig';
import Auxiliary from "util/Auxiliary";
import { useHistory } from "react-router-dom";
import Widget from "components/Widget/index";

const { Option } = Select;
const { RangePicker } = DatePicker;
const { TextArea } = Input;


function handleChange(value) {
  console.log(`selected ${value}`);
}

function handleBlur() {
  console.log('blur');
}

function handleFocus() {
  console.log('focus');
}

const ProjPlan = (props) => {
  let history = useHistory();
  const userDetails = JSON.parse(sessionStorage.getItem('user_details'));
  const token = sessionStorage.getItem('token');
  
  const [form] = Form.useForm();
  const [projectPlan, setProjectPlan] = useState([]);
  const [count, setCount] = useState(100);
  const [currkey, setCurrkey] = useState(0);
  const [edit, setEdit] = useState(false);


console.log(props);
  const getProjPlanById = () => {
    axios.get(API_URL + `/api/project_milestones/byId/${props.match.params.id}`, 
    {headers: {
      'x-access-token': `${token}`, 
    'Access-Control-Allow-Origin': '*'}
   })
      .then(response => {
        console.log("plan by id", response.data.data);
        if (Object.keys(response.data.data).length > 0) {
          setProjectPlan(response.data.data);
          let item =  response.data.data;
          form.setFieldsValue({
            duration: [moment(item.startDateDt), moment(item.endDateDt)],
            assignedto: item.assignedTo,
            status: item.deliveryMilestoneStatus,
            days: +item.duration,
            taskname: item.titleTx,
            comments: item.comments,
          }); 
        } 
      })
      .catch(error => {
        // Error
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log('Error', error.message);
        }
        console.log(error.config);
      });
  }  

  useEffect(() => {
    if(props.match.params.id!=null)
      getProjPlanById()

  }, [])


  const handleAdd = () => {
    if (!edit) {
      const dates = form.getFieldValue('duration');
      const newData = {
        projectIdNm: +projectPlan.projectIdNm,
        duration: form.getFieldValue('days'),
        assignedTo: form.getFieldValue('assignedto'),
        titleTx: form.getFieldValue('taskname'),
        milestoneType: 'DELIVERY',
        deliveryMilestoneStatus: form.getFieldValue('status'),
        startDateDt: dates[0].format("YYYY-MM-DD"),
        endDateDt: dates[1].format("YYYY-MM-DD"),
        comments: form.getFieldValue('comments'),
      };
      axios.put(API_URL + `/api/project_milestones/update/${props.match.params.id}`, newData, 
      {headers: {
        'x-access-token': `${token}`, 
      'Access-Control-Allow-Origin': '*'}
     })
        .then(response => {
          message.success("Schedule Created Successfully");
          history.push("/plan/manage");
        })
        .catch(error => {
          // Error
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log('Error', error.message);
          }
          console.log(error.config);
          message.error("Error In creating Schedule", error)
        });
    }
    form.resetFields();
  };

  const [users, setUsers] = useState([]);

  const getUserList = () => {
    axios.get(API_URL + '/api/users/getall', 
    {headers: {
      'x-access-token': `${token}`, 
    'Access-Control-Allow-Origin': '*'}
   })
      .then(response => {
        console.log('User List', response.data.data)
        setUsers(response.data.data);
      })
      .catch(error => {
        console.log(error)
      });
  }

  useEffect(() => {
    getUserList();
    //getProjectPlan();
  }, [])

  return (
    <Auxiliary>
      <Widget>
      <Form
        form={form}
        initialValues={{ remember: true }}
        name="prjplan"
        onFinish={handleAdd}
        //onFinishFailed={this.onFinishFailed}
        layout="inline"
        align="center"
      >
        <Row gutter={24}>
          <Col lg={8} md={8} sm={12} xs={24} >
            <div className="gx-form-row0">
              <h6 align="left">Name</h6>
              <Form.Item
                name="taskname"
                // label="Project Name"
                rules={[
                  {
                    required: true,
                    message: 'Enter name',
                  },
                ]}
              >
                <Input placeholder="Name" />

              </Form.Item>
            </div>
          </Col>
          <Col lg={8} md={8} sm={12} xs={24} >
            <div className="gx-form-row0">
              <h6 align="left">Planned Dates</h6>
              <Form.Item
                name="duration"
                //label="Duration"
                rules={[
                  {
                    required: true,
                    message: 'Enter Duration',
                  },
                ]}
              >
                <RangePicker className="gx-mb-3 gx-w-100" />
              </Form.Item>
            </div>
          </Col>
          <Col lg={8} md={8} sm={12} xs={24} >
            <div className="gx-form-row0">
              <h6 align="left">Duration (Hours)</h6>
              <Form.Item
                name="days"
                //label="Billable"
                rules={[
                  {
                    required: true,
                    message: 'Duration',
                  },
                ]}
              >
                <InputNumber placeholder="Duration" />
              </Form.Item>
            </div>
          </Col>
          <Col lg={8} md={8} sm={12} xs={24} >
            <div className="gx-form-row0">
              <h6 align="left">Assigned To</h6>
              <Form.Item
                name="assignedto"
                //label="Billable"
                rules={[
                  {
                    required: true,
                    message: 'assignedto',
                  },
                ]}
              >
                <Select
                  showSearch
                  style={{ width: '100%' }}
                  placeholder="Users"
                  optionFilterProp="children"
                  onChange={handleChange}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                >
                  {users.length ? users.map((user, index) => (
                    <Option key={index} value={user.id}>{user.displayNameTx}</Option>
                  )) : null}
                </Select>
              </Form.Item>
            </div>
          </Col>
          <Col lg={8} md={8} sm={12} xs={24} >
            <h6 align="left">Status</h6>
            <div className="gx-form-row0">
              <Form.Item
                name="status"
                //label="Billable"
                rules={[
                  {
                    required: true,
                    message: 'Status',
                  },
                ]}
              >
                <Select
                  showSearch
                  style={{ width: '100%' }}
                  placeholder="Select a Value"
                  optionFilterProp="children"
                  onChange={handleChange}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                >
                  <Option value="New">New</Option>
                  <Option value="Progress">In Progress</Option>
                  <Option value="Hold">On Hold</Option>
                  <Option value="Completed">Completed</Option>
                </Select>
              </Form.Item>
            </div>
          </Col>
          <Col lg={24} md={24} sm={24} xs={24} >
            <div className="gx-form-row0">
              <h6 align="left">Comments</h6>
              <Form.Item
                name="comments"
                // label="Project Name"
                rules={[
                  {
                    required: true,
                    message: 'Enter Comments',
                  },
                ]}
              >
                <TextArea rows={4} />
              </Form.Item>
            </div>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col lg={24} md={24} sm={24} xs={24} >
            <Button type="primary" className="add-btn" htmlType="submit">Submit </Button>
            <Button type="primary" className="add-btn gx-mr-2" onClick={() => history.push('/plan/manage')}>Back</Button>
          </Col>
        </Row>
      </Form>
      </Widget>
    </Auxiliary>
  );
};
export default ProjPlan;

