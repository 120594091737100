import React, { useEffect, useState } from "react";
import axios from 'axios';
import { Col, Row, Breadcrumb, Switch, Spin } from "antd";
import { Table, Select, Form } from "antd";
import Auxiliary from "util/Auxiliary";
import { API_URL } from 'constants/APIConfig';
import moment from "moment";

const { Option } = Select;

const ManageProjectResource = () => {

  const userDetails = JSON.parse(sessionStorage.getItem('user_details'));
  const token = sessionStorage.getItem('token');

  const [resAllocation, setResAllocation] = useState([]);
  const [projects, setProjects] = useState([]);
  const [allProjects, setAllProjects] = useState([]);
  const [projectManagers, setProjectManagers] = useState([]);
  const [isLoadingData, setIsLoadingData] = useState(false);
  
  const [form] = Form.useForm();

  const getProjectList = () => {
    axios.get(API_URL + `/api/project/v2/projectsByManager?userId=${userDetails.id}`,
      {
        headers: {
          'x-access-token': `${token}`,
          'Access-Control-Allow-Origin': '*'
        }
      })
      .then(response => {
        if (Object.keys(response.data.data).length > 0) {
          let pmIdList = new Set()
          let pmList = [{ id: 0, displayNameTx: '     All     ' }]
          setProjects(response.data.data);
          response.data.data.forEach(d => {
            if (d.ProjectManager && d.ProjectManager.id && !pmIdList.has(d.ProjectManager.id)) {
              pmIdList.add(d.ProjectManager.id)
              pmList.push(d.ProjectManager)
            }
          })
          setProjectManagers(pmList)
          setAllProjects(response.data.data)
        }
      })
      .catch(error => {
        console.log(error);
      });
  }
  const onProjectChange = value => {
    getProjResourcesList(value)
  };

  const setProjectData = () => {
    let pm = form.getFieldValue('prjManager')
    let status = form.getFieldValue('active')
    form.setFieldsValue({
      prjname: undefined,
    })
    if (pm === 0) {
      let prjArr = []
      allProjects.forEach(p=>{
        if(status) {
          if(p.statusId !== 3) {
            prjArr.push(p)
          }
        } else {
          if(p.statusId === 3) {
            prjArr.push(p)
          }
        }
      })
      setProjects(prjArr)
    } else {
      let projectsArr = []
      allProjects.forEach(p => {
        if (p.projectManager === pm) {
          if(status) {
            if(p.statusId !== 3) {
              projectsArr.push(p)
            }
          } else {
            if(p.statusId === 3) {
              projectsArr.push(p)
            }
          }
        }
      })
      setProjects(projectsArr)
      setResAllocation([])
    }
  }

  const resourceCols = [
    {
      title: 'Resource', dataIndex: 'resource', fixed: 'left', width: 150,
      sorter: (a, b) => String(a.resource).localeCompare(String(b.resource)),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Name', dataIndex: 'name', width: 150,
      sorter: (a, b) => String(a.name).localeCompare(String(b.name)),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Start Date', dataIndex: 'sDate', width: 100,
      sorter: (a, b) => String(a.sDate).localeCompare(String(b.sDate)),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'End Date', dataIndex: 'eDate', width: 100,
      sorter: (a, b) => String(a.eDate).localeCompare(String(b.eDate)),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Allocation', dataIndex: 'allocation', width: 100,
      sorter: (a, b) => String(a.allocation).localeCompare(String(b.allocation)),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Comments', dataIndex: 'comments', width: 150,
      sorter: (a, b) => String(a.comments).localeCompare(String(b.comments)),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Status', dataIndex: 'status', width: 150,
      sorter: (a, b) => String(a.status).localeCompare(String(b.status)),
      sortDirections: ['descend', 'ascend'],
    },
  ];

  let data = [];
  const getProjResourcesList = (projId) => {
    setIsLoadingData(true)
    axios.get(API_URL + `/api/project_resources/getProjectResources?userId=${userDetails.id}&projectId=${projId}`,
      {
        headers: {
          'x-access-token': `${token}`,
          'Access-Control-Allow-Origin': '*'
        }
      })
      .then(response => {
        if (Object.keys(response.data.data).length > 0) {
          response.data.data.map((item, index) => {
            data.push({
              id: item.id,
              resource: item.assignedDepartment.nameTx + "-" + item.assignedSkill.nameTx,
              name: item.assignedUser && item.assignedUser.displayNameTx,
              sDate: item.startDate ? moment(item.startDate).format("YYYY-MM-DD") : null,
              eDate: item.endDate ? moment(item.endDate).format("YYYY-MM-DD") : null,
              allocation: item.percentageOfAllocation ? item.percentageOfAllocation : null,
              status: item.status ? item.status : null,
              comments: item.comments ? item.comments : null,
              uid: item.userIdNm ? item.userIdNm : null,
              key: item.id
            })
          })
          setResAllocation(data);
        } else {
          setResAllocation([]);
        }
        setIsLoadingData(false)
      })
      .catch(error => {
        console.log(error);
        setIsLoadingData(false)
      });
  }
  useEffect(() => {
    getProjectList();
  }, [])

  return (
    <Auxiliary>
      <Col xl={24} lg={24} md={24} sm={24} xs={24} className="flex_style gx-pl-0 ant-row-start">
        <Breadcrumb>
          <Breadcrumb.Item>{userDetails.id === 9999 ? 'PMO' : 'Project Manager'}</Breadcrumb.Item>
          <Breadcrumb.Item className={'text-helight'}><i className="icon icon-wall" /> Resources</Breadcrumb.Item>
        </Breadcrumb>
      </Col>
      <Form
        style={{ display: "block" }}
        form={form}
        initialValues={{ remember: true }}
        name="ohealth"
        layout="inline"
        align="center"
      >
        <Row className="m_0" gutter={24}>
          <Col xl={6} lg={6} md={6} sm={24} xs={24} className="flex_style gx-pr-0">

          </Col>
          <Col xl={6} lg={6} md={6} sm={24} xs={24} className="flex_style gx-pr-0">
            <Form.Item
              name="prjManager" initialValue={0} hidden={userDetails.id !== 9999}>
              <Select
                showSearch
                style={{ width: '100%' }}
                placeholder="Project Manager"
                optionFilterProp="children"
                onChange={setProjectData}
                
                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              >
                {
                  projectManagers.length ? projectManagers.map((pm, index) => (
                    <Option key={index} value={pm.id}>{pm.displayNameTx}</Option>
                  )) : null
                }
              </Select>
            </Form.Item>
          </Col>
          <Col xl={4} lg={4} md={4} sm={24} xs={24} className="flex_style gx-pr-0">
            <Form.Item name="active" valuePropName="checked" initialValue={true}>
              <Switch defaultChecked={true} checkedChildren="Active" unCheckedChildren="Completed" checked={true} onChange={setProjectData} className="switchSpace" />
            </Form.Item>
          </Col>
          <Col xl={8} lg={8} md={8} sm={24} xs={24}  >
            <div className="gx-form-row0" >
              <Form.Item
                name="prjname"
                rules={[
                  {
                    required: true,
                    message: 'Enter project name',
                  },
                ]}
              >
                <Select
                  showSearch
                  style={{ width: '100%' }}
                  placeholder="Projects"
                  optionFilterProp="children"
                  onChange={onProjectChange}
                  filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                >
                  {
                    projects.length ? projects.map((project, index) => (
                      <Option key={index} value={project.id}>{project.name}</Option>
                    )) : null
                  }
                </Select>
              </Form.Item>
            </div>
          </Col>
        </Row>
      </Form>
      <Row className="m_0" gutter={24}>
        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
          <Table className="remove-mFixCell4" columns={resourceCols} dataSource={resAllocation} size="small" pagination={{ pageSize: 10 }} scroll={{ x: 1300 }} 
          loading={{ indicator: <div><Spin /></div>, spinning: isLoadingData}} />
        </Col>
      </Row>
    </Auxiliary>
  );
};
export default ManageProjectResource;