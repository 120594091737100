import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import axios from 'axios';
//import ClientStats from "../utils/ClientStats";
import { Table, Row, Col } from "antd";
import Auxiliary from "util/Auxiliary";
import { API_URL } from 'constants/APIConfig';

const ClientsReport = () => {
  const userDetails = JSON.parse(sessionStorage.getItem('user_details'));
  const token = sessionStorage.getItem('token');
  
  const [clients, setClients] = useState([]);

  const columns = [
    {title: 'Name',dataIndex: 'organization', key: 'name',fixed: 'left',
    sorter: (a, b) => String(a.organization).localeCompare(String(b.organization)),
    sortDirections: ['descend', 'ascend'],
    }, 
    {title: 'Contact 1',dataIndex: 'firstName', key: 'name1',
    sorter: (a, b) => String(a.firstName).localeCompare(String(b.firstName)),
    sortDirections: ['descend', 'ascend'],
    }, 
    {title: 'Email Id',  dataIndex: 'email',  key: 'email1',
    sorter: (a, b) => String(a.email).localeCompare(String(b.email)),
    sortDirections: ['descend', 'ascend'],
    }, 
    {title: 'Phone', dataIndex: 'phone',key: 'phone1',
    sorter: (a, b) => String(a.phone).localeCompare(String(b.phone)),
    sortDirections: ['descend', 'ascend'],
    }, 
    {title: 'Contact 2',dataIndex: 'secondaryContactFirstName',key: 'name2',
    sorter: (a, b) => String(a.secondaryContactFirstName).localeCompare(String(b.secondaryContactFirstName)),
    sortDirections: ['descend', 'ascend'],
    }, 
    {title: 'Email Id',dataIndex: 'secondaryContactEmail', key: 'email2',
    sorter: (a, b) => String(a.secondaryContactEmail).localeCompare(String(b.secondaryContactEmail)),
    sortDirections: ['descend', 'ascend'],
    }, 
    {title: 'Phone',dataIndex: 'secondaryContactPhone',key: 'name',
    sorter: (a, b) => String(a.secondaryContactPhone).localeCompare(String(b.secondaryContactPhone)),
    sortDirections: ['descend', 'ascend'],
    }, 
    {title: 'Region',dataIndex: 'region',key: 'region',
    sorter: (a, b) => String(a.region).localeCompare(String(b.region)),
    sortDirections: ['descend', 'ascend'],
    }, 
    {title: 'Website',dataIndex: 'website',  fixed: 'right', key: 'web',
    sorter: (a, b) => String(a.website).localeCompare(String(b.website)),
    sortDirections: ['descend', 'ascend'],
    }, 
  ];
  
  
  const getClientLists = () => {
    axios.get(API_URL + '/api/invoice_customers/all?limit=500&offset=0', 
    {headers: {
      'x-access-token': `${token}`, 
    'Access-Control-Allow-Origin': '*'}
   })
      .then(response => {
        if (response.data.data.length) {
          setClients(response.data.data);
          console.log(response.data.data)
        }
      })
  }
  useEffect(() => {
    getClientLists()

  }, [])

  return (
    <Auxiliary>
{/*       <ClientStats /> */}
      <Row gutter={24}>
        <Col lg={24} md={24} sm={24} xs={24} >
          <Table columns={columns} dataSource={clients} size="small" scroll={{ x: 1300 }} />
        </Col>
      </Row>
    </Auxiliary>
  );
};
export default ClientsReport;
