import React, { useEffect, useState } from "react";
import axios from 'axios';
import { Table, Row, Col, Select, Form, Tag, Button } from 'antd';
import Auxiliary from "util/Auxiliary";
import { API_URL } from 'constants/APIConfig';

const { Option } = Select;

const ManageEmployeeSkills = () => {
  const [tableForm] = Form.useForm();
  const token = sessionStorage.getItem('token');
  const [empList, setEmpList] = useState([])
  const [empSkills, setEmpSkills] = useState([])

  const [allUsersAllSkillList, setAllUsersAllSkillList] = useState([])

  const getAllUsersAllSkills = () => {
    axios.get(API_URL + `/api/user_skills/fetchAllUsersAllSkills?t=${new Date().getTime()}`,
      {
        headers: {
          'x-access-token': `${token}`,
          'Access-Control-Allow-Origin': '*'
        }
      })
      .then(response => {
        if (response.data && response.data.data) {
          response.data.data.forEach((d, i) => { d['key'] = i })
          setAllUsersAllSkillList(response.data.data)

          let empListArr = []
          let empListSet = new Set()

          response.data.data.forEach(s => {
            if(!empListSet.has(s.assignedUser.employeeId)) {
                empListSet.add(s.assignedUser.employeeId)
                empListArr.push({empId: s.assignedUser.employeeId, name: s.assignedUser.displayNameTx})
            }
          })

          setEmpList(empListArr)
        }
      })
      .catch(error => {
      });
  }

  const onChangeEmployee = (id) => {
    let empList = []
    allUsersAllSkillList.filter(e => e.assignedUser.employeeId === id).forEach((emp, i, arr) => {
        empList.push({
            empId: emp.assignedUser.employeeId,
            name: emp.assignedUser.displayNameTx,
            subSkill: emp.assignedSkill.nameTx,
            relevantExp:  <Tag>{emp.relevantExp}</Tag> ,
            key:i
            })
    })
    setEmpSkills(empList)
  }

  const downloadAllData = () => {
    let csvHeader = "Emp Id,Name,Department,Skill,SubSkill,Self Assessment,Relevant Experience,Primary Skill,Total Experience,Mobile No, Certified\n";
    let csvData = "";
    allUsersAllSkillList.forEach(emp => {
        csvData += emp.assignedUser.employeeId + "," + emp.assignedUser.displayNameTx + "," 
                + emp.assignedSkill.sb_department.nameTx + "," + emp.assignedSkill.sb_skill.nameTx + ","
                + emp.assignedSkill.nameTx + "," + emp.selfAssessment + "," + emp.relevantExp + "," + (emp.isPrimary ? 'Yes' : 'No') + ","
                + emp.assignedUser.totalExperience + "," + emp.assignedUser.mobileTx + "," + (emp.isCertified ? 'Yes' : 'No')
                + "\n";
    })
    
    let anchor = document.createElement('a');
    anchor.href = window.URL.createObjectURL(new Blob([csvHeader+csvData]))
    anchor.download = "All Employee Skills.csv"
    anchor.click();
  }

  useEffect(() => {
    getAllUsersAllSkills()
  }, [])

  const columns = [
    { title: 'Emp Id', dataIndex: 'empId', fixed: 'left' },
    { title: 'Name', dataIndex: 'name' },
    { title: 'Sub Skill', dataIndex: 'subSkill' },
    { title: 'Relevant Experience (Yrs)', dataIndex: 'relevantExp' }
  ]
  return (
    <Auxiliary>
      <center><h4>Manage Employee Skill</h4></center>
      <Row gutter={24}>
        <Form
          form={tableForm}
          name="tableForm"
          layout="inline"
          align="center"
        >
          <Col lg={6} md={6} sm={6} xs={6}>
            <Form.Item
              name="employeeId" rules={[
                {
                  required: true,
                  message: 'Select the Employee',
                },
              ]}>
              <Select
                showSearch
                style={{ width: '100%' }}
                placeholder="Select the Employee"
                filterOption={(input, option) => option.name.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                onChange={onChangeEmployee}
              >
                {
                  empList.length > 0 && empList.map((emp, index) => (
                    <Option key={index} value={emp.empId} name={emp.name}>{emp.name}</Option>
                  ))
                }
              </Select>
            </Form.Item>
          </Col>
          <Col lg={6} md={6} sm={6} xs={6}></Col>
          <Col lg={6} md={6} sm={6} xs={6}></Col>
          <Col lg={6} md={6} sm={6} xs={6}>
            <Button onClick={downloadAllData}>Download All</Button>
          </Col>
        </Form>
      </Row>
      <br />
      <Table className="gx-table-responsive" columns={columns} dataSource={empSkills} />

    </Auxiliary>
  );
};

export default ManageEmployeeSkills;