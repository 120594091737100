import React, { useEffect, useState } from "react";
import { Button, Row, Col, Form, Input, Select, InputNumber, Table, message, TreeSelect, DatePicker, Popconfirm, Modal } from "antd";
import { API_URL } from 'constants/APIConfig';
import axios from 'axios';
import { PlusCircleOutlined } from '@ant-design/icons';
import moment from "moment";
import Auxiliary from "util/Auxiliary";

const { Option } = Select;
const InputGroup = Input.Group;
const { TextArea } = Input;
const { RangePicker } = DatePicker;

function onProjectChange(value) {
    console.log(`selected ${value}`);
}
// function handleBlur() {
//     console.log('blur');
// }
// function handleFocus() {
//     console.log('focus');
// }

const NewTeam = (props) => {
    
    const userDetails = JSON.parse(sessionStorage.getItem('user_details'));
    const token = sessionStorage.getItem('token');

    const [form] = Form.useForm();
    const [treeData, setTreeData] = useState([]);
    const [projects, setProjects] = useState([]);
    const [selectedTask, setselectedTask] = useState();
    const [projectId, setProjectId] = useState();

    const [editId, setEditId] = useState("");

    const onFinish = values => {
        let newTeamdata = [];
        newTeamdata.push({
            // parentId: +form.getFieldValue('parentAdmin'),
            parentId: "",
                name: form.getFieldValue('teamName'),
            })
        axios.post(API_URL + '/api/skillbase/teams/addNewTeam', newTeamdata, 
        {headers: {
          'x-access-token': `${token}`, 
        'Access-Control-Allow-Origin': '*'}
       })
            .then(response => {
                message.success(`team added sucessfully`);
                form.setFieldValue({
                    parentAdmin:"",
                    teamName:"",
                    delegates:"",
                    userOption:"",
                    skillCategory:""
                })
            })
            .catch(error => {
                // Error
                if (error.response) {
                    console.log(error.response.data);
                } else if (error.request) {
                    console.log(error.request);
                } else {
                    console.log('Error', error.message);
                }
                console.log(error.config);
                message.error("Error In creating Skill", error)
            });

    }

    return (
    <Auxiliary> 
      <Form
            form={form}
            initialValues={{ remember: true }}
            name="timelog"
           // onFinish={handleAdd}
            //onFinishFailed={this.onFinishFailed}
            layout="inline"
            align="center"
        >
            <Row gutter={24}>
                <Col lg={8} md={8} sm={12} xs={24} >
                    <div className="gx-form-row0">
                        <h6 align="left">Parent Admin</h6>
                        <Form.Item
                            name="projects"
                            //label="Project Status"
                            rules={[
                                {
                                    required: true,
                                    message: 'Enter project Admin',
                                },
                            ]}
                        >
                            <Select
                                showSearch
                                style={{ width: '100%' }}
                                placeholder="Select a Value"
                                optionFilterProp="children"
                                onChange={onProjectChange}
                                //onFocus={handleFocus}
                                //onBlur={handleBlur}

                                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            >
                               <Option value="Web">Hyderabad</Option>
                                    <Option value="Android">Vijayawada</Option>
                                    <Option value="IOS">Bhuvaneswar</Option>
                                    <Option value="usa">USA</Option>
                            </Select>
                        </Form.Item>
                    </div>
                </Col>
                <Col lg={8} md={8} sm={12} xs={24} >
                    <div className="gx-form-row0">
                        <h6 align="left">Team Name</h6>
                        <Form.Item
                            //name="title"
                            // label="Project Name"
                            rules={[
                                {
                                    required: true,
                                    message: 'Enter Team Name',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </div>
                </Col>
{/*             </Row>
            <Row gutter={24}> */}
                <Col lg={8} md={8} sm={12} xs={24} >
                    <div className="gx-form-row0">
                        <h6 align="left">Delegates</h6>
                        <Form.Item
                            name="projects"
                            //label="Project Status"
                            rules={[
                                {
                                    required: true,
                                    message: 'Enter Delegates in the list',
                                },
                            ]}
                        >
                            <Select
                                showSearch
                                style={{ width: '100%' }}
                                placeholder="Select a Value"
                                optionFilterProp="children"
                                onChange={onProjectChange}
                                //onFocus={handleFocus}
                                //onBlur={handleBlur}

                                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            >
                               <Option value="Web">Hyderabad</Option>
                                    <Option value="Android">Vijayawada</Option>
                                    <Option value="IOS">Bhuvaneswar</Option>
                                    <Option value="usa">USA</Option>
                            </Select>
                        </Form.Item>
                    </div>
                </Col>
                <Col lg={8} md={8} sm={12} xs={24} >
                    <div className="gx-form-row0">
                        <h6 align="left">User Selectable</h6>
                        <Form.Item
                            name="projects"
                            //label="Project Status"
                            rules={[
                                {
                                    required: true,
                                    message: 'Enter project Admin',
                                },
                            ]}
                        >
                            <Select
                                showSearch
                                style={{ width: '100%' }}
                                placeholder="Select a Value"
                                optionFilterProp="children"
                                onChange={onProjectChange}
                                //onFocus={handleFocus}
                                //onBlur={handleBlur}

                                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            >
                               <Option value="Web">Hyderabad</Option>
                                    <Option value="Android">Vijayawada</Option>
                                    <Option value="IOS">Bhuvaneswar</Option>
                                    <Option value="usa">USA</Option>
                            </Select>
                        </Form.Item>
                    </div>
                </Col>
{/*             </Row>
            <Row gutter={24}> */}
                <Col lg={8} md={8} sm={12} xs={24} >
                    <div className="gx-form-row0">
                        <h6 align="left">Assign Skill Categories</h6>
                        <Form.Item
                            name="projects"
                            //label="Project Status"
                            rules={[
                                {
                                    required: true,
                                    message: 'Enter project Admin',
                                },
                            ]}
                        >
                            <Select
                                showSearch
                                style={{ width: '100%' }}
                                placeholder="Select a Value"
                                optionFilterProp="children"
                                onChange={onProjectChange}
                                //onFocus={handleFocus}
                                //onBlur={handleBlur}

                                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            >
                               <Option value="Web">Hyderabad</Option>
                                    <Option value="Android">Vijayawada</Option>
                                    <Option value="IOS">Bhuvaneswar</Option>
                                    <Option value="usa">USA</Option>
                            </Select>
                        </Form.Item>
                    </div>
                </Col>
            </Row>
            <Row>
                    <Col lg={24} md={24} sm={24} xs={24} >
                        <div className="gx-form-row0">
                            <Form.Item
                                name="submit"
                            >
                             {editId ? <Button type="primary" className="add-btn" onClick={onFinish}>Update Team</Button> : <Button type="primary" className="add-btn" onClick={onFinish}>Add Team</Button>}   
                            </Form.Item>
                        </div>
                    </Col>
                </Row>
            </Form>
    </Auxiliary>
    );
};
export default NewTeam;

