import React, { useEffect, useState } from "react";
import axios from 'axios';
import { Table, Col, Row, Tooltip, Avatar, List } from "antd";
import Auxiliary from "util/Auxiliary";
import { API_URL } from 'constants/APIConfig';
import IconWithTextCard from "components/Metrics/IconWithTextCard";
import { EyeOutlined } from '@ant-design/icons';
import Widget from "components/Widget";

const Reportees = () => {
  const [reportees, setReportees] = useState([]);
  const userDetails = JSON.parse(sessionStorage.getItem('user_details'));
  const token = sessionStorage.getItem('token');

  const getReportees = () => {
    axios.get(API_URL + `/api/users/getAllUsersGroupByRM`, 
    {headers: {
      'x-access-token': `${token}`, 
    'Access-Control-Allow-Origin': '*'}
   })
      .then(response => {
        if (Object.keys(response.data.data).length > 0) {
          setReportees(response.data.data);
          console.log(response.data.data);
        }
      })
      .catch(error => {
        // Error
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log('Error', error.message);
        }
        console.log(error.config);
      });
  }

  useEffect(() => {
    getReportees();
  }, [])

  let projectCols = [
    {
      title: 'Reporting Manager', dataIndex: 'name', fixed: 'left', width: 300,
      sorter: (a, b) => String(a.titleTx).localeCompare(String(b.titleTx)),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Resources', dataIndex: 'projectmgr', width: 200,
      sorter: (a, b) => String(a.projectmgr).localeCompare(String(b.projectmgr)),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Review', fixed: 'right', width: 100, dataIndex: 'id', render: text => <div><span> <a href={'/projects/reports/by-id/' + text}><EyeOutlined style={{ padding: '10px' }} /></a></span> </div>
    },
  ];
  return (
    <Auxiliary>
      <Widget title="Reporting Manager's Team">
        <List className="gx-bg-white"
          size="small"
          pagination={{
            onChange: page => {
              console.log(page);
            },
            pageSize: 5,
          }}
          dataSource={reportees}
          renderItem={item => <List.Item>
            {/* {reportees ? reportees.map((item, index) => ( */}
            <Row gutter={24} className="projectDetails">
              <Col xl={8} lg={8} md={8} sm={8} xs={8} >{item.manager.displayNameTx}</Col>
              <Col xl={16} lg={16} md={16} sm={16} xs={16} className="notifyIcons">
                <ul className="gx-list-inline">
                  {
                    item.reportees ? item.reportees.map((reportee, index) => (
                      <li className="gx-pb-2">
                        {(() => {
                          try {
                            return <Tooltip placement="topLeft" title={reportee.displayNameTx?reportee.displayNameTx:""}><Avatar size={{ xs: 24, sm: 32, md: 40, lg: 64, xl: 80, xxl: 100 }} src={require(`../../../../assets/images/avatar/${reportee.employeeId.toLowerCase()}.png`)} /></Tooltip>
                          } catch (err) {
                            try {
                              return <Tooltip placement="topLeft" title={reportee.displayNameTx?reportee.displayNameTx:""}><Avatar size={{ xs: 24, sm: 32, md: 40, lg: 64, xl: 80, xxl: 100 }} src={require(`../../../../assets/images/avatar/profile.png`)} /></Tooltip>
                            } catch (err) {
                              return <Tooltip placement="topLeft" title={reportee.displayNameTx?reportee.displayNameTx:""}><span className="ant-avatar gx-size-40 gx-border gx-border-grey gx-bg-transparent gx-text-grey gx-d-flex gx-align-items-center gx-justify-content-center"><i
                              className="icon icon-add" /></span></Tooltip>
                            }
                          }
                        })()}
                      </li>
                    )) : null
                  }
                </ul>

              </Col>
            </Row>
            {/*           // )) : null} */}
          </List.Item>}
        />
      </Widget>
    </Auxiliary>
  );
}
export default Reportees;
