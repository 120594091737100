import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import API_URL from "../../constants/APIConfig";
import axios from 'axios';

import {
  SIGNIN_USER,
  SIGNOUT_USER,
} from "constants/ActionTypes";
import { showAuthMessage, userSignInSuccess, userSignOutSuccess, userSignUpSuccess } from "../../appRedux/actions/Auth";

const signInUserWithEmailPasswordRequest = async (username, password) => {
  var url = `${API_URL}/api/users/login`;
  const body = {
    username: username,
    passwordTx: password
  }
  try {
    const data = await axios.post(url, body);
    if (data.data.data.token) {
      sessionStorage.setItem("user_id", data.data.data.token);
      return data.data.data
    }
    else {
      let message = "Login Failed"
      return { message }
    };
  } catch (error) {
      let msg = (error.response && error.response.data && error.response.data.error) || "Please contact PMO team to resolve login issue";
      return {message: msg}
  }
}


function* signInUserWithEmailPassword({ payload }) {
  const { username, password, remember } = payload;
  try {
    const signInUser = yield call(signInUserWithEmailPasswordRequest, username, password);
    if (signInUser.message) {
      yield put(showAuthMessage(signInUser.message));
    } else if (signInUser.token){
      sessionStorage.setItem('token', signInUser.token);
      sessionStorage.setItem('refreshToken', signInUser.refreshToken);
      sessionStorage.setItem('user_id', signInUser.token);
      sessionStorage.setItem('user_Roles', JSON.stringify(signInUser.userdetails.roles));
      sessionStorage.setItem("user_details", JSON.stringify(signInUser.userdetails));
      sessionStorage.setItem("skillLastUpdated", JSON.stringify(signInUser.skillLastUpdated));
      if(remember) {
        localStorage.setItem('employeeId', signInUser.userdetails.employeeId)
        localStorage.setItem('employeeName', signInUser.userdetails.displayNameTx)
      } else if(localStorage.getItem('employeeId') != signInUser.userdetails.employeeId){
        localStorage.removeItem('employeeId')
        localStorage.removeItem('employeeName')
      }
      yield put(userSignInSuccess(signInUser.token));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* signOut() {
  try {
    const signOutUser = undefined;
    if (signOutUser === undefined) {
      sessionStorage.removeItem('user_id');
      sessionStorage.removeItem('user_Roles');
      sessionStorage.removeItem("user_details");
      sessionStorage.removeItem("token");
      yield put(userSignOutSuccess(signOutUser));
    } else {
      yield put(showAuthMessage(signOutUser.message));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

export function* signInUser() {
  yield takeEvery(SIGNIN_USER, signInUserWithEmailPassword);
}

export function* signOutUser() {
  yield takeEvery(SIGNOUT_USER, signOut);
}

export default function* rootSaga() {
  yield all([fork(signInUser),
  fork(signOutUser)]);
}