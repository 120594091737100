import React, { useEffect, useState } from "react";
import {
  Button,
  Row,
  Col,
  Form,
  Table,
  Input,
  Select,
  InputNumber,
  Popconfirm,
  DatePicker,
  message,
  Card,
} from "antd";
import { API_URL } from "constants/APIConfig";
import axios from "axios";
import { FormOutlined } from "@ant-design/icons";
import moment from "moment";
import { useHistory } from "react-router-dom";
const { Option } = Select;
const InputGroup = Input.Group;
const { TextArea } = Input;


const { RangePicker } = DatePicker;

const token = sessionStorage.getItem("token");
function handleChange(value) {
  console.log(`selected ${value}`);
}

function handleBlur() {
  console.log("blur");
}

function handleFocus() {
  console.log("focus");
}

const handleResourceType = (e) => {};

const handleModules = (e) => {};
let engageType = "";
const handleEnagementType = (e) => {};

const handleExperience = (e) => {};

let userID = sessionStorage.getItem("user_details")?JSON.parse(sessionStorage.getItem("user_details")).id:null;

const NewProjPlan = (props) => {
  let history = useHistory();
  const [projEfforts, setProjEfforts] = useState([]);
  const [modules, setModules] = useState([]);
  const [projects, setProjects] = useState([]);
  const [edit, setEdit] = useState(false);
  const [form] = Form.useForm();
  const [count, setCount] = useState(0);
  const [rcrdId, setRcrdId] = useState(0);
  const [projId, setProjId] = useState(0);

  const handleDelete = (id) => {
    const newData = projEfforts.filter((item) => item.key !== id);
    setProjEfforts(newData);
  };

  const ProjPlanTbl = [
    {
      title: "Action",
      dataIndex: "action",
      width: 150,
      fixed: "left",
      sorter: (a, b) =>
        String(a.actualSpend).localeCompare(String(b.actualSpend)),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Milestone",
      dataIndex: "milestone",
      width: 150,
      fixed: "left",
      sorter: (a, b) =>
        String(a.actualSpend).localeCompare(String(b.actualSpend)),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Module",
      dataIndex: "module",
      width: 100,
      sorter: (a, b) =>
        String(a.plannedSpend).localeCompare(String(b.plannedSpend)),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Onsite",
      dataIndex: "onsite",
      width: 150,
      sorter: (a, b) =>
        String(a.actualSpend).localeCompare(String(b.actualSpend)),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Offshore",
      dataIndex: "offshore",
      width: 150,
      sorter: (a, b) => String(a.spendType).localeCompare(String(b.spendType)),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "status",
      dataIndex: "status",
      width: 120,
      sorter: (a, b) =>
        String(a.plannedSpend).localeCompare(String(b.plannedSpend)),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Duration",
      dataIndex: "duration",
      width: 100,
      sorter: (a, b) => String(a.variance).localeCompare(String(b.variance)),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Start Date",
      dataIndex: "startDate",
      width: 120,
      sorter: (a, b) => String(a.variance).localeCompare(String(b.variance)),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "End Date",
      dataIndex: "endDate",
      width: 120,
      sorter: (a, b) => String(a.variance).localeCompare(String(b.variance)),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Notes",
      dataIndex: "notes",
      width: 100,
    },
    {
      title: "Action",
      width: 100,
      fixed: "right",
      dataIndex: "key",
      render: (_, record) =>
        projEfforts.length >= 1 ? (
          <span>
            <Popconfirm
              title="Sure to Edit?"
              onConfirm={() => handleEdit(record)}
            >
              <a style={{ marginRight: 8 }}>Edit</a>
            </Popconfirm>
            <Popconfirm
              title="Sure to Delete?"
              onConfirm={() => handleDelete(record.key)}
            >
              <a>Delete</a>
            </Popconfirm>
          </span>
        ) : null,
    },
  ];

  const handleEdit = (record) => {
    form.setFieldsValue({
      effortDuration: [
        record.startDate ? moment(record.startDate) : null,
        record.endDate ? moment(record.endDate) : null,
      ],
      action: record.action ? record.action : null,
      milestone: record.milestone ? record.milestone : null,
      moduleType: record.module ? record.module : null,
      onsite: record.onsite ? record.onsite : null,
      offshore: record.offshore ? record.offshore : null,
      status: record.status ? record.status : null,
      duration: record.duration ? record.duration : null,
      notes: record.notes ? record.notes : null,
    });
    setEdit(true);
    setRcrdId(record.key);
  };

  const getModulesList = () => {
    axios
      .get(API_URL + "/api/masters/modules/getall", {
        headers: {
          "x-access-token": `${token}`,
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((response) => {
        console.log("Modules List", response.data.data);
        setModules(response.data.data);
      })
      .catch((error) => {
        // Error
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log("Error", error.message);
        }
        console.log(error.config);
      });
  };

  const handleChange = (value) => {
    setProjId(value);
  };

  const handleAddEfforts2 = () => {
    const dates = form.getFieldValue("effortDuration");
    let projectID = projId;
    let action= form.getFieldValue("action");
    let milestone = form.getFieldValue("milestone");
    let moduleType = form.getFieldValue("moduleType");
    let onsite= form.getFieldValue("onsite");
    let offshore = form.getFieldValue("offshore");
    let status = form.getFieldValue("status");
    let duration = form.getFieldValue("duration");
    let startDt = dates[0].format("YYYY-MM-DD");
    let endDt = dates[1].format("YYYY-MM-DD");
    let notes = form.getFieldValue("notes");
    if (projId != 0) {
      if (!edit) {
        setProjEfforts([
          ...projEfforts,
          {
            key: count + 1,
            projectId: projectID,
            action: action,
            milestone: milestone,
            module: moduleType,
            onsite: onsite,
            offshore: offshore,
            status: status,
            duration: duration,
            startDate: startDt,
            endDate: endDt,
            notes: notes,
          },
        ]);
        setCount(count + 1);
        form.resetFields();
      } else {
        const newArray = projEfforts.map((item, i) => {
          if (rcrdId === item.key) {
            return {
              ...item,
              key: item.key,
              projectId: projectID,
              action: action,
              milestone: milestone,
              module: moduleType,
              onsite: onsite,
              offshore: offshore,
              status: status,
              duration: duration,
              startDate: startDt,
              endDate: endDt,
              notes: notes,
            };
          } else {
            return item;
          }
        });

        setProjEfforts(newArray);
        form.resetFields();
      }
    }
  };
  const getProjectList = () => {
    axios
      .get(
        API_URL +
          `/api/project/v2/allProjectNames?userId=${userID}&limit=500&offset=0`,
        {
          headers: {
            "x-access-token": `${token}`,
            "Access-Control-Allow-Origin": "*",
          },
        }
      )
      .then((response) => {
        if (Object.keys(response.data.data).length > 0) {
          setProjects(response.data.data);
          /*           this.formRef.current.setFieldsValue({
            prjname: response.data.data[0].id,
          }); */
        }
      })
      .catch((error) => {
        // Error
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log("Error", error.message);
        }
        console.log(error.config);
      });
  };
  const onFinish = (values) => {
    axios
      .post(
        API_URL + "/api/project_initiation/projectplan/create",
        projEfforts,
        {
          headers: {
            "x-access-token": `${token}`,
            "Access-Control-Allow-Origin": "*",
          },
        }
      )
      .then((response) => {
        message.success("Project Plan are added Successfully");
         history.push("/master/projects/projplan/manage");
      })
      .catch((error) => {
        // Error
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log("Error", error.message);
        }
        console.log(error.config);
        message.error("Error In creating Schedule", error);
      });
  };
  useEffect(() => {
    getModulesList();
    getProjectList();
  }, []);
  return (
    <Card
      title="Delivery Plan"
      size="small"
      bordered={false}
      style={{ width: 1100 }}
    >
      <Row>
        <Col lg={16} md={16} sm={16} xs={16}>
          <Select
            showSearch
            style={{ width: "50%" }}
            placeholder="Select a Project"
            optionFilterProp="children"
            onChange={handleChange}
            //onFocus={handleFocus}
            // onBlur={handleBlur}
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
          >
            {projects.length
              ? projects.map((project, index) => (
                  <Option key={index} value={project.id}>
                    {project.name}
                  </Option>
                ))
              : null}
          </Select>
        </Col>
      </Row>
      <Form
        form={form}
        initialValues={{ remember: true }}
        name="proj_efforts"
        onFinish={onFinish}
        layout="inline"
        align="center"
      >
        <Row>
          <Col lg={8} md={8} sm={8} xs={8}>
          <div className="gx-form-row0">
              <h6 align="left">Action Item</h6>
              <Form.Item
                name="action"
                rules={[
                  {
                    required: true,
                    message: "Please maintain action item",
                  },
                ]}
              >
                <Input placeholder="Action Item" />
              </Form.Item>
            </div>
          </Col>
          <Col lg={4} md={4} sm={12} xs={24}>
            <div className="gx-form-row0">
              <h6 align="left">Milestone</h6>
              <Form.Item
                name="milestone"
                // label="Project Name"
                rules={[
                  {
                    required: true,
                    message: "Enter name",
                  },
                ]}
              >
                <Select
                  showSearch
                  style={{ width: "100%" }}
                  placeholder="Select a Value"
                  optionFilterProp="children"
                  onChange={handleEnagementType}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  <Option value={"ResourceDeployment"}>ResourceDeployment</Option>
                  <Option value={"BBP"}>BBP</Option>
                  <Option value={"Realization"}>Realization</Option>
                  <Option value={"UAT"}>UAT</Option>
                  <Option value={"Go-Live"}>Go-Live</Option>
                  <Option value={"Hypercare"}>Hypercare</Option>
                  <Option value={"AMS"}>AMS</Option>
                </Select>
              </Form.Item>
            </div>
          </Col>
          <Col lg={8} md={8} sm={8} xs={8}>
            <div className="gx-form-row0">
              <h6 align="left">Module</h6>
              <Form.Item
                name="moduleType"
                //label="Billable"
                rules={[
                  {
                    required: true,
                    message: "Enter project billable",
                  },
                ]}
              >
                <Select
                  showSearch
                  style={{ width: "100%" }}
                  placeholder="Select a Value"
                  optionFilterProp="children"
                  onChange={handleModules}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {modules.length
                    ? modules.map((module, index) => (
                        <Option key={index} value={module.name}>
                          {module.name}
                        </Option>
                      ))
                    : null}
                </Select>
              </Form.Item>
            </div>
          </Col>
          <Col lg={4} md={4} sm={12} xs={24}>
            <div className="gx-form-row0">
              <h6 align="left">Onsite</h6>
              <Form.Item
                name="onsite"
                rules={[
                  {
                    required: true,
                    message: "Enter Resource Type",
                  },
                ]}
              >
                <Select
                  showSearch
                  style={{ width: "100%" }}
                  placeholder="Select a Value"
                  optionFilterProp="children"
                  onChange={handleResourceType}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  <Option value={"Junior"}>Junior</Option>
                  <Option value={"Senior"}>Senior</Option>
                </Select>
              </Form.Item>
            </div>
          </Col>
          <Col lg={4} md={4} sm={12} xs={24}>
            <div className="gx-form-row0">
              <h6 align="left">Offshore</h6>
              <Form.Item
                name="offshore"
                rules={[
                  {
                    required: true,
                    message: "Enter Resource Type",
                  },
                ]}
              >
                <Select
                  showSearch
                  style={{ width: "100%" }}
                  placeholder="Select a Value"
                  optionFilterProp="children"
                  onChange={handleResourceType}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  <Option value={"Junior"}>Junior</Option>
                  <Option value={"Senior"}>Senior</Option>
                </Select>
              </Form.Item>
            </div>
          </Col>
          <Col lg={4} md={4} sm={12} xs={24}>
            <div className="gx-form-row0">
              <h6 align="left">Status</h6>
              <Form.Item
                name="status"
                rules={[
                  {
                    required: true,
                    message: "Enter Resource Type",
                  },
                ]}
              >
                <Select
                  showSearch
                  style={{ width: "100%" }}
                  placeholder="Select a Value"
                  optionFilterProp="children"
                  onChange={handleResourceType}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  <Option value={"Junior"}>Junior</Option>
                  <Option value={"Senior"}>Senior</Option>
                </Select>
              </Form.Item>
            </div>
          </Col>
          <Col lg={8} md={8} sm={8} xs={8}>
          <div className="gx-form-row0">
              <h6 align="left">Est. Duration</h6>
              <Form.Item
                name="duration"
                rules={[
                  {
                    required: true,
                    message: "Please maintain action item",
                  },
                ]}
              >
                <Input placeholder="Weeks" />
              </Form.Item>
            </div>
          </Col>
           <Col lg={8} md={8} sm={12} xs={24}>
            <div className="gx-form-row0">
              <h6 align="left">Duration</h6>
              <Form.Item
                name="effortDuration"
                rules={[
                  {
                    required: true,
                    message: "Please maintain total hours",
                  },
                ]}
              >
                <RangePicker className="gx-mb-3 gx-w-100" />
              </Form.Item>
            </div>
          </Col>
          <Col lg={24} md={24} sm={12} xs={24}>
            <div className="gx-form-row0">
              <h6 align="left">Notes</h6>
              <Form.Item
                name="notes"
                rules={[
                  {
                    required: true,
                    message: "Enter Remarks",
                  },
                ]}
              >
                <TextArea rows={4} />
              </Form.Item>
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg={24} md={24} sm={24} xs={24}>
             <Button
              type="primary"
              className="add-btn"
              onClick={handleAddEfforts2}
            >
              Add / Edit
            </Button>
          </Col>
        </Row>

        <Row>
          <Col xl={24} lg={24} md={24} sm={24} xs={24}>
            <Table
              columns={ProjPlanTbl}
              dataSource={projEfforts}
              pagination={{ pageSize: 10 }}
              scroll={{ x: 1000 }}
              size="small"
            />
          </Col>
        </Row>
        <Row>
          <Col lg={24} md={24} sm={24} xs={24}>
            <Button type="primary" className="add-btn" onClick={onFinish}>
              Submit
            </Button>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};
export default NewProjPlan;
