import React from "react";
import {Bar, BarChart, ResponsiveContainer, Tooltip, XAxis} from "recharts";
import Widget from "components/Widget/index";
import {Badge} from "antd";

const data = [
  {name: 'JAN', closed: 200, open: 600,},
  {name: 'FEB', closed: 500, open: 900,},
  {name: 'MAR', closed: 700, open: 1200,},
  {name: 'APR', closed: 800, open: 1300,},
  {name: 'MAY', closed: 700, open: 1200,},
  {name: 'JUN', closed: 500, open: 900,},
  {name: 'JUL', closed: 600, open: 200,},
  {name: 'AUG', closed: 200, open: 800,},
  {name: 'SEP', closed: 400, open: 400,},
  {name: 'OCT', closed: 400, open: 500,},
  {name: 'NOV', closed: 400, open: 1200,},
  {name: 'DEC', closed: 200, open: 800,},
];

const tickets = () => {

  return (
    <Widget>
      <div className="gx-dealclose-header">
        <div>
          <h2 className="h4 gx-mb-2">92 Tickets Closed</h2>
          <p className="gx-text-grey">This year</p>
        </div>
        <div className="gx-dealclose-header-right">
          <p className="gx-mb-2"><Badge className="gx-mb-0" status="warning"/>Open</p>
          <p className="gx-ml-2 gx-mb-2"><Badge className="gx-mb-0" status="processing"/>Closed</p>
        </div>
      </div>
      <ResponsiveContainer width="100%" height={90}>
        <BarChart data={data}
                  margin={{top: 0, right: 0, left: 0, bottom: 0}}>
          <Tooltip/>
          <XAxis dataKey="name"/>
          <Bar dataKey="closed" stackId="a" fill="#038FDE" barSize={8}/>
          <Bar dataKey="open" stackId="a" fill="#FE9E15" barSize={8}/>
        </BarChart>
      </ResponsiveContainer>
    </Widget>
  );
};

export default tickets;
