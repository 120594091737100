import React, { useEffect, useState } from "react";
import axios from 'axios';
import { Col, Row } from "antd";
import Auxiliary from "util/Auxiliary";
import { API_URL } from 'constants/APIConfig';
import IconWithTextCard from "../../../components/Metrics/IconWithTextCard";

const TaskStats = (props) => {

  const token = sessionStorage.getItem('token');
  const [taskStats, setTaskStats] = useState({
    NEW: 0,
    'WAITING FOR INPUTS': 0,
    'IN PROGRESS': 0,
    RESOLVED: 0,
    CLOSED: 0,
    TOTAL: 0,
    BILLABLETASKS: 0
  });
  const getTaskStats = () => {
    axios.get(API_URL + `/api/task/all/taskStatus/${props.projId}`,
      {
        headers: {
          'x-access-token': `${token}`,
          'Access-Control-Allow-Origin': '*'
        }
      })
      .then(response => {
        if (response.data.data) {
          setTaskStats(response.data.data);
        }
      })
      .catch(error => {
        console.log(error)
      });
  }
  const onTaskCardSelected = (v) => {
    if (props.onTaskCardSelected) {
      props.onTaskCardSelected(v)
    }
  }

  useEffect(() => {
    getTaskStats();
  }, [props.projId])
  return (
    <Auxiliary>
      <Row>
        <Col xl={4} lg={4} md={12} sm={12} xs={12} className="gx-col-full scale-x" onClick={() => { onTaskCardSelected('Tasks') }} style={{ cursor: "pointer" }}>
          <IconWithTextCard icon="orders" iconColor="geekblue" title={taskStats.TOTAL} subTitle="Tasks" tasksCard={props.selectedTaskCard === 'Tasks' ? 'tasksCard' : ''} />
        </Col>
        <Col xl={4} lg={4} md={12} sm={12} xs={12} className="gx-col-full scale-x" onClick={() => { onTaskCardSelected('New') }} style={{ cursor: "pointer" }}>
          <IconWithTextCard icon="revenue-new" iconColor="geekblue" title={taskStats.NEW} subTitle="New" tasksCard={props.selectedTaskCard === 'New' ? 'tasksCard' : ''} />
        </Col>
        <Col xl={4} lg={4} md={12} sm={12} xs={12} className="gx-col-full scale-x" onClick={() => { onTaskCardSelected('In Progress') }} style={{ cursor: "pointer" }}>
          <IconWithTextCard icon="queries" iconColor="geekblue" title={taskStats['IN PROGRESS']} subTitle="InProgress" tasksCard={props.selectedTaskCard === 'In Progress' ? 'tasksCard' : ''} />
        </Col>
        <Col xl={4} lg={4} md={12} sm={12} xs={12} className="gx-col-full scale-x" onClick={() => { onTaskCardSelected('Waiting For Inputs') }} style={{ cursor: "pointer" }}>
          <IconWithTextCard icon="visits" iconColor="geekblue" title={taskStats['WAITING FOR INPUTS']} subTitle="Waiting" tasksCard={props.selectedTaskCard === 'Waiting For Inputs' ? 'tasksCard' : ''} />
        </Col>
        <Col xl={4} lg={4} md={12} sm={12} xs={12} className="gx-col-full scale-x" onClick={() => { onTaskCardSelected('Resolved') }} style={{ cursor: "pointer" }}>
          <IconWithTextCard icon="check-circle-o" iconColor="primary" title={taskStats.RESOLVED} subTitle="Resolved" tasksCard={props.selectedTaskCard === 'Resolved' ? 'tasksCard' : ''} />
        </Col>
        <Col xl={4} lg={4} md={12} sm={12} xs={12} className="gx-col-full scale-x" onClick={() => { onTaskCardSelected('Closed') }} style={{ cursor: "pointer" }}>
          <IconWithTextCard icon="close-circle" iconColor="primary" title={taskStats.CLOSED} subTitle="Closed" tasksCard={props.selectedTaskCard === 'Closed' ? 'tasksCard' : ''} />
        </Col>
      </Row>
    </Auxiliary>
  );
};
export default TaskStats;