import React, { useEffect, useState } from "react";
import { Row, Col, message, Table, Radio, Button, Modal, DatePicker, Tag, Breadcrumb, Input } from "antd";
import axios from 'axios';
import { API_URL } from 'constants/APIConfig';
import moment from 'moment';
import 'moment/min/locales';
import Auxiliary from "util/Auxiliary";
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import Approve from "./approve";
import Reject from "./reject";

const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;
const { RangePicker } = DatePicker;
const { TextArea } = Input;

const TSPMApproval = () => {
  const userDetails = JSON.parse(sessionStorage.getItem('user_details'));
  const token = sessionStorage.getItem('token');
  const [rangeDateChanged, setRangeDateChanged] = useState(true);
  const [eWeekNumber, setEWeekNumber] = useState(moment().week());
  const [eYear, setEYear] = useState(moment().weekYear());
  const [sWeekNumber, setSWeekNumber] = useState(moment().subtract(1, 'months').week());
  const [sYear, setSYear] = useState(moment().subtract(1, 'months').weekYear());

  const [timesheetStatus, setTimesheetStatus] = useState('Pending');
  const [loading, setLoading] = useState(false);
  const [tSheet, setTSheet] = useState([]);

  const timeFormatting = (time) => {
    return moment(time, 'HH:mm:ss').format('HH:mm')
  }

  const minsToStrApproveTime = (val) => {
    let [h, m] = val.split(":");
    // Number(m) > 30 ? "0" + Number(h) + 1 : h;
    return Number(Number(m) > 30 ? "0" + Number(h) + 1 : h);
  }

  const minsToStrTime = (val) => {
    let m = val % 60;
    let h = (val - m) / 60;

    h = h < 10 ? "0" + h : h;
    m = m < 10 ? "0" + m : m;

    return `${h}:${m}`;
  }

  const strTimeToMins = (strTime) => {
    //console.log(strTime);

    let [h, m] = strTime.split(":");
    return Number(h) * 60 + Number(m);
  }

  const onChangeWeek = (dateArr, dateStringArr) => {
    if (dateArr != null && dateStringArr != null) {
      let sDt = dateStringArr[0].split('-');
      let eDt = dateStringArr[1].split('-');
      let sYear = Number(sDt[0])
      setSYear(sYear)
      let eYear = Number(eDt[0])
      setEYear(eYear)
      let sWeek = Number(sDt[1].match(/\d+/)[0])
      setSWeekNumber(sWeek)
      let eWeek = Number(eDt[1].match(/\d+/)[0])
      setEWeekNumber(eWeek)
    }
  }

  function onChange(e) {
    setTimesheetStatus(e.target.value)
    getTsApprovals(e.target.value, sYear, sWeekNumber, eYear, eWeekNumber);
  }

  const handleClose = () => {
    setTSheet(null);
    setApprovePop(false);
    getTsApprovals(timesheetStatus, sYear, sWeekNumber, eYear, eWeekNumber);
  }

  const handleClose1 = () => {
    setTSheet(null);
    setRejectPop(false);
    getTsApprovals(timesheetStatus, sYear, sWeekNumber, eYear, eWeekNumber);
  }


  const handleApprove = (record) => {
    setTSheet(record);
    setApprovePop(true);

  }

  const handleReject = (record) => {
    setTSheet(record);
    setRejectPop(true);
  }

  const [timeSheetList, setTimeSheetList] = useState([]);

  const [approvePop, setApprovePop] = useState(false);
  const [rejectPop, setRejectPop] = useState(false);

  const timeSheetListCols = [
    {
      title: 'Year', dataIndex: 'yearno', align: 'center', width: 80,
      sorter: (a, b) => String(a.yearno).localeCompare(String(b.yearno)),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Week', dataIndex: 'weekno', align: 'center', width: 80,
      sorter: (a, b) => String(a.weekno).localeCompare(String(b.weekno)),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Employee Name', dataIndex: 'empname', ellipsis: true, align: 'left', width: 200,
      render: (text, record) => {
        return (
          <div className="gx-d-flex">
            <div className="gx-ml-1">
              <div>{record.empname}</div>
              <small> {record.empid} </small>
            </div>
          </div>
        );
      },
    },
    {
      title: 'Efforts', dataIndex: 'yearweek', ellipsis: true, align: 'center', width: 700,
      render: (text, record) => {
        return (
          <>
            <div className="gx-d-flex gx-justify-content-between timeHoursBlock">
              <span> {record.projectname} / {record.taskname} </span>
              <span className="gx-font-weight-semi-bold"> {
                minsToStrTime(strTimeToMins(record.day1) + strTimeToMins(record.day2) + strTimeToMins(record.day3) + strTimeToMins(record.day4) + strTimeToMins(record.day5) + strTimeToMins(record.day6) + strTimeToMins(record.day7))
              } </span>
            </div>
            <div className="gx-d-flex gx-justify-content-around timeSheetBH timeSheetHoursBlock">
              <div className="timeSheetHours">
                <small className="gx-d-flex gx-justify-content-center">S</small>
                {record.day1Leave == true ?
                  <span className="leave-color" title="Leave">{timeFormatting(record.day1)}</span>
                  :
                  <span >{timeFormatting(record.day1)}</span>
                }
              </div>
              <div className="timeSheetHours">
                <small className="gx-d-flex gx-justify-content-center">M</small>
                {record.day2Leave == true ?
                  <span className="leave-color" title="Leave">{timeFormatting(record.day2)}</span>
                  :
                  <span >{timeFormatting(record.day2)}</span>
                }
              </div>
              <div className="timeSheetHours">
                <small className="gx-d-flex gx-justify-content-center">T</small>
                {record.day3Leave == true ?
                  <span className="leave-color" title="Leave">{timeFormatting(record.day3)}</span>
                  :
                  <span >{timeFormatting(record.day3)}</span>
                }
              </div>
              <div className="timeSheetHours">
                <small className="gx-d-flex gx-justify-content-center">W</small>
                {record.day4Leave == true ?
                  <span className="leave-color" title="Leave">{timeFormatting(record.day4)}</span>
                  :
                  <span >{timeFormatting(record.day4)}</span>
                }
              </div>
              <div className="timeSheetHours">
                <small className="gx-d-flex gx-justify-content-center">T</small>
                {record.day5Leave == true ?
                  <span className="leave-color" title="Leave">{timeFormatting(record.day5)}</span>
                  :
                  <span >{timeFormatting(record.day5)}</span>
                }
              </div>
              <div className="timeSheetHours">
                <small className="gx-d-flex gx-justify-content-center">F</small>
                {record.day6Leave == true ?
                  <span className="leave-color" title="Leave">{timeFormatting(record.day6)}</span>
                  :
                  <span >{timeFormatting(record.day6)}</span>
                }
              </div>
              <div className="timeSheetHours">
                <small className="gx-d-flex gx-justify-content-center">S</small>
                {record.day7Leave == true ?
                  <span className="leave-color" title="Leave">{timeFormatting(record.day7)}</span>
                  :
                  <span >{timeFormatting(record.day7)}</span>
                }
              </div>
            </div>
          </>
        );
      },
    },
    {
      title: 'Action', fixed: 'right', dataIndex: 'action', width: 100, align: 'left',
      render: (text, record) =>
        <div className="gx-d-flex">
          {(() => {
            if (record.pmstatus === "Pending") {
              return (<>
                <Button className="approved_btn" shape="circle" htmlType="submit" icon={<CheckOutlined />} onClick={() => handleApprove(record)}> </Button>
                <Button className="reject_btn" shape="circle" htmlType="submit" icon={<CloseOutlined />} onClick={() => handleReject(record)}> </Button>
              </>);
            } else {
              if (record.pmstatus === "Approved")
                return <Tag color="blue" >{record.pmstatus ? record.pmstatus : ""}</Tag>
              if (record.pmstatus === "Rejected")
                return <Tag color="red" >{record.pmstatus ? record.pmstatus : ""}</Tag>
            }
          })()}

        </div>
    },
  ];

  let tSheetList = [];
  const getTsApprovals = async (status, sYear, sWeekNumber, eYear, eWeekNumber) => {
    setLoading(true);
    setTimeSheetList([]);
    setRangeDateChanged(false)
    const url = API_URL + `/api/timesheets/getProjectMgrApprovalList?userId=${userDetails.id}&timesheetStatus=${status}&sYear=${sYear}&sWeekNumber=${sWeekNumber}&eYear=${eYear}&eWeekNumber=${eWeekNumber}`;
    try {
      const res = await axios.get(url,
        {
          headers: {
            'x-access-token': `${token}`,
            'Access-Control-Allow-Origin': '*'
          }
        });
      if (res.status === 200) {
        setLoading(false);
        if (Object.keys(res.data.data).length > 0) {
          res.data.data.map((item, index) => {
            tSheetList.push({
              key: index,
              userid: item.userId,
              empid: item.user && item.user.employeeId,
              empname: item.user && item.user.displayNameTx,
              empcomments: item.timesheetComment,
              pmcomments: item.projectManagerComment,
              pmstatus: item.projectManagerAction,
              rmcomments: item.reportingManagerComment,
              rmstatus: item.reportingManagerAction,
              projectid: item.project && item.project.id,
              projectname: item.project && item.project.shortName,
              pmid: item.projectManagerId,
              taskid: item.task && item.task.id,
              taskname: item.task && item.task.taskNameTx,
              yearno: item.year,
              weekno: item.weekNumber,
              day1: item.timelog[0].hours,
              day2: item.timelog[1].hours,
              day3: item.timelog[2].hours,
              day4: item.timelog[3].hours,
              day5: item.timelog[4].hours,
              day6: item.timelog[5].hours,
              day7: item.timelog[6].hours,
              day1Leave: item.timelog[0].isTodayLeave,
              day2Leave: item.timelog[1].isTodayLeave,
              day3Leave: item.timelog[2].isTodayLeave,
              day4Leave: item.timelog[3].isTodayLeave,
              day5Leave: item.timelog[4].isTodayLeave,
              day6Leave: item.timelog[5].isTodayLeave,
              day7Leave: item.timelog[6].isTodayLeave,
              totalTaskHrs: minsToStrApproveTime(minsToStrTime(strTimeToMins(item.timelog[0].hours) + strTimeToMins(item.timelog[1].hours) + strTimeToMins(item.timelog[2].hours) + strTimeToMins(item.timelog[3].hours) + strTimeToMins(item.timelog[4].hours) + strTimeToMins(item.timelog[5].hours) + strTimeToMins(item.timelog[6].hours))),
            });
          })
          tSheetList.sort((a, b) => (a.weekno < b.weekno) ? 1 : -1)
          setTimeSheetList(tSheetList);
        }
      } else {
        setLoading(false);
        return {}
      };
    } catch (ex) {
      setLoading(false)
      message.error(`${ex.message}:get timesheet`);
      return {}
    }
  }
  useEffect(() => {
    if(rangeDateChanged) {
      getTsApprovals(timesheetStatus, sYear, sWeekNumber, eYear, eWeekNumber);
    }
  }, [rangeDateChanged])
  return (
    <Auxiliary>
      <Row className="m_0 gx-pt-2">
        <Col xl={8} lg={8} md={8} sm={24} xs={24} className="flex_style gx-pl-0 ant-row-start">
          <Breadcrumb>
              <Breadcrumb.Item>Project Manager</Breadcrumb.Item>
              <Breadcrumb.Item className="text-helight"><i className="icon icon-check-circle-o" /> PM Approve</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
        <Col xl={8} lg={8} md={8} sm={24} xs={24} className="gx-text-center gx-pt-1">
          <h5>
            {
              moment().weekYear(sYear).week(sWeekNumber).startOf('week').format('MMM D, YYYY') + " - " + moment().weekYear(eYear).week(eWeekNumber).endOf('week').format('MMM D, YYYY')
            }
          </h5>
        </Col>
        <Col xl={8} lg={8} md={8} sm={24} xs={24} className="gx-p-0">
        <div className="float-right" >
            <RangePicker className="gx-mb-3 gx-w-100" picker="week"
              defaultValue={[moment().weekYear(sYear).week(sWeekNumber), moment()]}
              allowClear={ false }
              disabledDate={current => {
                return current > moment().add(0, 'weeks')
              }}
              onChange={onChangeWeek}
              onOpenChange={(open) => {
                if(open === false) {
                  setRangeDateChanged(true)
                }
              }}
              />
          </div>
        </Col>
      </Row>
      
      {/* <Row>
        <Col xl={8} lg={8} md={8} sm={8} xs={8} className="gx-text-center">
        </Col>
        <Col xl={8} lg={8} md={8} sm={8} xs={8} className="gx-text-center">
          <h4>
            {
              moment().weekYear(sYear).week(sWeekNumber).startOf('week').format('MMM D, YYYY') + " - " + moment().weekYear(eYear).week(eWeekNumber).endOf('week').format('MMM D, YYYY')
            }
          </h4>
        </Col>
        <Col xl={8} lg={8} md={8} sm={8} xs={8} className="gx-p-0">
          <div className="float-right" >
            <RangePicker className="gx-mb-3 gx-w-100" picker="week"
              defaultValue={[moment().weekYear(sYear).week(sWeekNumber), moment()]}
              allowClear={ false }
              disabledDate={current => {
                return current > moment().add(0, 'weeks')
              }}
              onChange={onChangeWeek}
              onOpenChange={(open) => {
                if(open === false) {
                  setRangeDateChanged(true)
                }
              }}
              />
          </div>
        </Col>
      </Row> */}
      <Row className="m_0">
        <Col xl={24} lg={24} md={24} sm={24} xs={24} className="gx-p-0">
          <div className="float-right" >
            <RadioGroup onChange={onChange} buttonStyle="solid" defaultValue={timesheetStatus} disabled={loading}>
              <RadioButton value="Pending">Pending</RadioButton>
              <RadioButton value="Approved">Approved</RadioButton>
              <RadioButton value="Rejected">Rejected</RadioButton>
            </RadioGroup>
          </div>
        </Col>
      </Row>
      <Table loading={loading} className="gx-table-responsive" columns={timeSheetListCols} dataSource={timeSheetList} bordered={false} scroll={{ x: 1200 }}
        expandable={{
          expandedRowRender: record => <>
              <Row xl={24} lg={24} md={24} sm={24} xs={24}>
                <Col xl={8} lg={8} md={8} sm={8} xs={8}><center><b>Employee</b></center> 
                <TextArea readOnly={true} rows={4} value={record.empcomments} className='backgroundWhite' />
                </Col>
                <Col xl={8} lg={8} md={8} sm={8} xs={8}><center><b>Project Manager</b></center>
                <TextArea readOnly={true} rows={4} value={record.pmcomments} className='backgroundWhite' />
                </Col>
                <Col xl={8} lg={8} md={8} sm={8} xs={8}><center><b>Resource Manager</b></center>
                <TextArea readOnly={true} rows={4} value={record.rmcomments} className='backgroundWhite' />
                </Col>
              </Row>
          </>,
        }}
      />

      <Modal
        title="Approve Comments"
        centered
        visible={approvePop}
        onOk={() => setApprovePop(false)}
        onCancel={() => setApprovePop(false)}
        width={600}
        destroyOnClose={true}
        footer={null}
      >
        <Approve rcrd={tSheet} onClose={handleClose} />
      </Modal>

      <Modal
        title="Reject Comments"
        centered
        visible={rejectPop}
        onOk={() => setRejectPop(false)}
        onCancel={() => setRejectPop(false)}
        width={600}
        destroyOnClose={true}
        footer={null}
      >
        <Reject rcrd={tSheet} onClose={handleClose1} />
      </Modal>

    </Auxiliary>
  );
};
export default TSPMApproval;
