import React from "react";
import { Menu } from "antd";
import { Link } from "react-router-dom";

import CustomScrollbars from "util/CustomScrollbars";
import SidebarLogo from "./SidebarLogo";
import UserProfile from "./UserProfile";
import AppsNavigation from "./AppsNavigation";
import {
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  THEME_TYPE_LITE,
} from "../../constants/ThemeSetting";
import IntlMessages from "../../util/IntlMessages";
import { useSelector } from "react-redux";

const SubMenu = Menu.SubMenu;
const MenuItemGroup = Menu.ItemGroup;

const SidebarContent = () => {
  let { pathname } = useSelector(({ common }) => common);
  let { navStyle, themeType } = useSelector(({ settings }) => settings);

  const getNoHeaderClass = (navStyle) => {
    if (
      navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR ||
      navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR
    ) {
      return "gx-no-header-notifications";
    }
    return "";
  };
  const getNavStyleSubMenuClass = (navStyle) => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR) {
      return "gx-no-header-submenu-popup";
    }
    return "";
  };
  const selectedKeys = pathname.substr(1);
  const defaultOpenKeys = selectedKeys.split("/")[1];
  const userRoles = JSON.parse(sessionStorage.getItem("user_Roles"));
  const userDetails = JSON.parse(sessionStorage.getItem("user_details"));

  return (
    <>
      <SidebarLogo />
      <div className="gx-sidebar-content">
        <div
          className={`gx-sidebar-notifications ${getNoHeaderClass(navStyle)}`}
        >
          <UserProfile />
          <AppsNavigation />
        </div>
        <CustomScrollbars className="gx-layout-sider-scrollbar">
          <Menu
            defaultOpenKeys={[defaultOpenKeys]}
            selectedKeys={[selectedKeys]}
            theme={themeType === THEME_TYPE_LITE ? "lite" : "dark"}
            mode="inline"
          >
            <MenuItemGroup
              key="homedashboard"
              className="gx-menu-group"
              title="Dashboards"
            ></MenuItemGroup>
            <Menu.Item key="dashboard/home">
              <Link to="/dashboard/home">
                <i className="icon icon-home" />
                <span>Home</span>
              </Link>
            </Menu.Item>

            {userRoles.some((role) => role.id === 1) ? (
              <MenuItemGroup
                key="execdashboard"
                className="gx-menu-group"
                title=""
              >
                <SubMenu
                  key="Executive"
                  className="subMenuStyle"
                  title={
                    <span>
                      <i className="icon icon-custom-view" />
                      <span>Executive</span>
                    </span>
                  }
                >
                  <Menu.Item key="dashboard/executive">
                    <Link to="/dashboard/executive">
                      <i className="icon icon-dasbhoard" />
                      <span>Dashboard</span>
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="reports/project">
                    <Link to="/reports/project">
                      <i className="icon icon-diamond" />
                      <span>Projects</span>
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="/master/executive/tasks/manage">
                    <Link to="/master/executive/tasks/manage">
                      <i className="icon icon-tasks" />
                      <span>Task</span>
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="reports/timelog">
                    <Link to="/dashboard/pmo/reports/timelog/index">
                      <i className="icon icon-translation" />
                      <span>Timelog Summary</span>
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="reports/plan">
                    <Link to="/reports/plan">
                      <i className="icon icon-translation" />
                      <span>Plan</span>
                    </Link>
                  </Menu.Item>

                  <Menu.Item key="reports/wsr">
                    <Link to="/reports/wsr">
                      <i className="icon icon-select" />
                      <span>WSR</span>
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="reports/issue">
                    <Link to="/reports/issue">
                      <i className="icon icon-error" />
                      <span>Issues</span>
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="reports/resource">
                    <Link to="/reports/resource">
                      <i className="icon icon-wall" />
                      <span>Resources</span>
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="reports/cost">
                    <Link to="/reports/cost">
                      <i className="icon icon-pricing-table" />
                      <span>Cost</span>
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="reports/orgstructure">
                    <Link to="/reports/orgstructure">
                      <i className="icon icon-chart-tree" />
                      <span>Org Structure</span>
                    </Link>
                  </Menu.Item>
                </SubMenu>
              </MenuItemGroup>
            ) : (
              ""
            )}
            {userRoles.some((role) => role.id === 2) ? (
              <MenuItemGroup
                key="pmodashboard"
                className="gx-menu-group"
                title=""
              >
                <SubMenu
                  key="PMO"
                  className="subMenuStyle"
                  title={
                    <span>
                      <i className="icon icon-team" />
                      <span>PMO</span>
                    </span>
                  }
                >
                  <Menu.Item key="/dashboard/pmo">
                    <Link to="/dashboard/pmo">
                      <i className="icon icon-dasbhoard" />
                      <span>Dashboard</span>
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="master/projects/manage">
                    <Link to="/master/projects/manage">
                      <i className="icon icon-diamond" />
                      <span>Projects</span>
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="pmo/tasks/manage">
                    <Link to="/master/tasks/manage">
                      <i className="icon icon-tasks" />
                      <span>Tasks</span>
                    </Link>
                  </Menu.Item>

                  <Menu.Item key="reports/timesheet/index">
                    <Link to="/reports/timesheet/index">
                      <i className="icon icon-translation" />
                      <span>Weekly TS Status</span>
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="reports/timesheet/timelog">
                    <Link to="/reports/timesheet/timelog">
                      <i className="icon icon-translation" />
                      <span>Timelog Summary</span>
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="/plan/manage">
                    <Link to="/plan/manage">
                      <i className="icon icon-translation" />
                      <span>Plan</span>
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="/weeklystatus/manage">
                    <Link to="/weeklystatus/manage">
                      <i className="icon icon-select" />
                      <span>WSR</span>
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="/issues/manage">
                    <Link to="/issues/manage">
                      <i className="icon icon-error" />
                      <span>Issues</span>
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="/resources/manage">
                    <Link to="/resources/manage">
                      <i className="icon icon-wall" />
                      <span>Resources</span>
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="/cost/manage">
                    <Link to="/cost/manage">
                      <i className="icon icon-pricing-table" />
                      <span>Cost</span>
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="/orgstructure/manage">
                    <Link to="/orgstructure/manage">
                      <i className="icon icon-chart-tree" />
                      <span>Org Structure</span>
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="/master/projectresouce">
                    <Link to="/master/projectresouce">
                      <i className="icon icon-wall" />
                      <span>Project Resource</span>
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="/reset/userpasswordbypmo">
                    <Link to="/reset/userpasswordbypmo">
                      <i className="icon icon-translation" />
                      <span>Reset Password</span>
                    </Link>
                  </Menu.Item>
                </SubMenu>
              </MenuItemGroup>
            ) : (
              ""
            )}
            {userRoles.some((role) => role.id === 3) ? (
              <MenuItemGroup
                key="mgrdashboard"
                className="gx-menu-group"
                title=""
              >
                <SubMenu
                  key="Manager"
                  className="subMenuStyle"
                  title={
                    <span>
                      <i className="icon icon-crm" />
                      <span>Manager</span>
                    </span>
                  }
                >
                  <Menu.Item key="/dashboard/manager">
                    <Link to="/dashboard/manager">
                      <i className="icon icon-dasbhoard" />
                      <span>Dashboard</span>
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="/tasks/manage">
                    <Link to="/tasks/manage">
                      <i className="icon icon-tasks" />
                      <span>Task</span>
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="reports/mgrtimelog">
                    <Link to="/dashboard/manager/reports/timelog/index">
                      <i className="icon icon-translation" />
                      <span>Timelog Summary</span>
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="/plan/manage">
                    <Link to="/plan/manage">
                      <i className="icon icon-translation" />
                      <span>Plan</span>
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="/weeklystatus/manage">
                    <Link to="/weeklystatus/manage">
                      <i className="icon icon-select" />
                      <span>WSR</span>
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="/issues/manage">
                    <Link to="/issues/manage">
                      <i className="icon icon-error" />
                      <span>Issues</span>
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="/resources/manage">
                    <Link to="/resources/manage">
                      <i className="icon icon-wall" />
                      <span>Resources</span>
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="/cost/manage">
                    <Link to="/cost/manage">
                      <i className="icon icon-pricing-table" />
                      <span>Cost</span>
                    </Link>
                  </Menu.Item>
                  {/*                     <Menu.Item key="/timesheets/submit">
                      <Link to="/timesheets/submit">
                        <i className="icon icon-timepicker" />
                        <span>Time - Submit</span>
                      </Link>
                    </Menu.Item> */}
                  <Menu.Item key="/timesheets/approve">
                    <Link to="/timesheets/approve">
                      <i className="icon icon-check-circle-o" />
                      <span>TimeLog </span>
                    </Link>
                  </Menu.Item>
                  {/* <Menu.Item key="/orgstructure/manage">
                      <Link to="/orgstructure/manage">
                        <i className="icon icon-chart-tree" />
                        <span>Org Structure</span>
                      </Link>
                    </Menu.Item> */}
                </SubMenu>
              </MenuItemGroup>
            ) : (
              ""
            )}

            {userRoles.some((role) => role.id === 4) ? (
              <MenuItemGroup
                key="empdashboard"
                className="gx-menu-group"
                title=""
              >
                {/*                   <SubMenu key="employee" className="subMenuStyle"
                    title={<span><i className="icon icon-user-o" /><span>Employee</span></span>}> */}
                <Menu.Item key="/dashboard/employee1">
                  <Link to="/dashboard/employee">
                    <i className="icon icon-dasbhoard" />
                    <span>Dashboard</span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="/timesheets/submit1">
                  <Link to="/timesheets/submit">
                    <i className="icon icon-timepicker" />
                    <span>Time - Submit</span>
                  </Link>
                </Menu.Item>
                {/*                   </SubMenu> */}
              </MenuItemGroup>
            ) : (
              ""
            )}

            {userRoles.some((role) => role.id === 9999) ? (
              <MenuItemGroup
                key="master"
                className="gx-menu-group"
                title="Master Data"
              >
                <SubMenu
                  key="masterdata"
                  className="subMenuStyle"
                  title={
                    <span>
                      <i className="icon icon-inbuilt-apps" />
                      <span>Master</span>
                    </span>
                  }
                >
                  <Menu.Item key="master/projects/manage">
                    <Link to="/master/projects/manage">
                      <i className="icon icon-diamond" />
                      <span>Projects</span>
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="master/tasks/manage">
                    <Link to="/master/tasks/manage">
                      <i className="icon icon-tasks" />
                      <span>Tasks</span>
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="master/clients/manage">
                    <Link to="/master/clients/manage">
                      <i className="icon icon-company" />
                      <span>Clients</span>
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="skillMgr">
                    <Link to="/skillMgr">
                      <i className="icon icon-graduation" />
                      <span>Skills</span>
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="master/OrgStructure">
                    <Link to="/master/orgstructure/manage">
                      <i className="icon icon-family" />
                      <span>Org Structure</span>
                    </Link>
                  </Menu.Item>
                </SubMenu>
              </MenuItemGroup>
            ) : (
              ""
            )}

            {userRoles.some((role) => role.id === 6) ? (
              <MenuItemGroup
                key="master"
                className="gx-menu-group"
                title="Master Data"
              >
                <Menu.Item key="clients/manage">
                  <Link to="/clients/manage">
                    <i className="icon icon-company" />
                    <span>Clients</span>
                  </Link>
                </Menu.Item>
              </MenuItemGroup>
            ) : (
              ""
            )}

            {userRoles.some((role) => role.id === 7) ? (
              <MenuItemGroup
                key="master"
                className="gx-menu-group"
                title="Master Data"
              >
                <Menu.Item key="OrgStructure">
                  <Link to="/orgstructure/manage">
                    <i className="icon icon-family" />
                    <span>Org Structure</span>
                  </Link>
                </Menu.Item>
              </MenuItemGroup>
            ) : (
              ""
            )}

            {userDetails.isReportingManager ? (
              <MenuItemGroup
                key="repmgrdashboard"
                className="gx-menu-group"
                title=""
              >
                {/*                   <SubMenu key="reportingmanager" className="subMenuStyle"
                    title={<span><i className="icon icon-timepicker" /><span>Reporting Manager</span></span>}> */}
                <Menu.Item key="/timesheets/rmapprove">
                  <Link to="/timesheets/rmapprove">
                    <i className="icon icon-check-circle-o" />
                    <span>Time - Approve</span>
                  </Link>
                </Menu.Item>
                {/*                   </SubMenu> */}
              </MenuItemGroup>
            ) : (
              ""
            )}

            {/*             <MenuItemGroup key="wsr" className="gx-menu-group"
              title="Project Status">
              <Menu.Item key="submitwsr">
                <Link to="/weeklystatus/submit"><i
                  className="icon icon-timepicker" /><span>Submit</span></Link>
              </Menu.Item>
              <Menu.Item key="reviewwsr">
                <Link to="/weeklystatus/review"><i
                  className="icon icon-check-square-o" /><span>Review</span></Link>
              </Menu.Item>
            </MenuItemGroup> */}

            {/*             <MenuItemGroup key="timesheets" className="gx-menu-group"
              title="TimeSheets">
              <Menu.Item key="submitts">
                <Link to="/timesheets/submit"><i
                  className="icon icon-datepicker" /><span>Submit</span></Link>
              </Menu.Item>
              <Menu.Item key="reviewts">
                <Link to="/timesheets/review"><i
                  className="icon icon-check-square-o" /><span>Approve</span></Link>
              </Menu.Item>
            </MenuItemGroup> */}

            {/*             <MenuItemGroup key="in-built-apps" className="gx-menu-group"
              title={<IntlMessages id="sidebar.inBuiltApp" />}>
              <Menu.Item key="in-built-apps/todo">
                <Link to="/in-built-apps/todo"><i
                  className="icon icon-check-square-o" /><span><IntlMessages
                    id="sidebar.todoApp" /></span></Link>
              </Menu.Item>

              <Menu.Item key="in-built-apps/contacts">
                <Link to="/in-built-apps/contacts"><i className="icon icon-contacts" /><span><IntlMessages
                  id="sidebar.contactsApp" /></span></Link>
              </Menu.Item>

              <Menu.Item key="main/notes">
                <Link to="/in-built-apps/notes"><i className="icon icon-copy" />
                  <span><IntlMessages id="sidebar.notes" /></span></Link>
              </Menu.Item>
            </MenuItemGroup> */}
            {/* 
            <MenuItemGroup key="social-apps" className="gx-menu-group" title={<IntlMessages id="sidebar.social" />}>
              <Menu.Item key="social-apps/profile">
                <Link to="/social-apps/profile">
                  <i className="icon icon-profile2" />
                  <span><IntlMessages id="sidebar.extensions.profile" /></span>
                </Link>
              </Menu.Item>
            </MenuItemGroup> */}

            {/*             <MenuItemGroup key="custom-views" className="gx-menu-group"
              title="User Management">
              <SubMenu key="user-auth" popupClassName={getNavStyleSubMenuClass(navStyle)}
                title={<span><i className="icon icon-auth-screen" /><span><IntlMessages
                  id="app.userAuth" /></span></span>}>
                <Menu.Item key="custom-views/user-auth/sign-in">
                  <Link to="/custom-views/user-auth/sign-in">
                    <span><IntlMessages
                      id="app.userAuth.signIn" /></span></Link>
                </Menu.Item>
                <Menu.Item key="custom-views/user-auth/forgot-password">
                  <Link to="/custom-views/user-auth/forgot-password">
                    <span><IntlMessages
                      id="app.userAuth.forgotPassword" /></span></Link>
                </Menu.Item>
                <Menu.Item key="custom-views/user-auth/sign-up">
                  <Link to="/custom-views/user-auth/sign-up">
                    <span><IntlMessages
                      id="app.userAuth.signUp" /></span></Link>
                </Menu.Item>
                <Menu.Item key="custom-views/user-auth/lock-screen">
                  <Link to="/custom-views/user-auth/lock-screen">
                    <span><IntlMessages
                      id="app.userAuth.lockScreen" /></span></Link>
                </Menu.Item>
                <Menu.Item key="custom-views/user-auth/reset-password">
                  <Link to="/custom-views/user-auth/reset-password">
                    <span><IntlMessages
                      id="app.userAuth.resetPassword" /></span></Link>
                </Menu.Item>
              </SubMenu>

            </MenuItemGroup> */}
          </Menu>
        </CustomScrollbars>
      </div>
    </>
  );
};

SidebarContent.propTypes = {};
export default SidebarContent;
