import React, { useEffect, useState } from "react";
import axios from 'axios';
import { Table, Col, Row, Tooltip, Avatar, List, Tag } from "antd";
import Auxiliary from "util/Auxiliary";
import { API_URL } from 'constants/APIConfig';
import IconWithTextCard from "components/Metrics/IconWithTextCard";
import { EyeOutlined } from '@ant-design/icons';
import Widget from "components/Widget";

const Issues = () => {
  const userDetails = JSON.parse(sessionStorage.getItem('user_details'));
  const token = sessionStorage.getItem('token');
  const [issues, setIssues] = useState([]);

  const getTags = (type) => {
    if (type === "InProgress") {
      return <Tag color="#f50">{type}</Tag>
      {/*           <li className={`gx-text-${tag.color}`}>
            <i className="icon icon-circle gx-fs-xxs"/>
          </li> */}

    }
  };

  const getIssues = () => {
    axios.get(API_URL + `/api/project_issues_risks_actions/getFullDetails`, 
    {headers: {
      'x-access-token': `${token}`, 
    'Access-Control-Allow-Origin': '*'}
   })
      .then(response => {
        if (Object.keys(response.data.data).length > 0) {
          setIssues(response.data.data);
          console.log("issues List",response.data.data);
        }
      })
      .catch(error => {
        // Error
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log('Error', error.message);
        }
        console.log(error.config);
      });
  }

  useEffect(() => {
    getIssues();
  }, [])

  return (
    <Auxiliary>
      <Widget title="Actions/Risks/Issues">
        <List className="gx-bg-white"
          size="small"
          pagination={{
            onChange: page => {
              console.log(page);
            },
            pageSize: 5,
          }}
          dataSource={issues}
          renderItem={issue => <List.Item className="gx-pl-0 gx-pr-0">
            <Row gutter={24} className="ant-space-align-baseline orgBlock1">
              <Col lg={8} md={8} sm={8} xs={8} className="">
                  {(() => {
                    if (issue.type == "Issue") 
                      return <Tag color="#f50">{issue.type}</Tag>
                    if (issue.type == "Decision") 
                      return <Tag color="#108ee9">{issue.type}</Tag>
                    if (issue.type == "Risk") 
                      return <Tag color="#2db7f5">{issue.type}</Tag>
                    
                  })()}
                </Col>
                <Col lg={16} md={16} sm={16} xs={16} className="gx-pl-0 gx-pr-0">
                <Row gutter={24} className="gx-ml-0 gx-mr-0 orgBlock1 ant-space-align-baseline">
                  <Col lg={14} md={14} sm={14} xs={14} className="gx-fs-sm">{issue.dueDate}</Col>

                  
                  <Col lg={6} md={6} sm={6} xs={6} className="gx-fs-sm">
                  {(() => {
                        if (issue.status == "Low")
                          return <span style={{ color: "blue" }}>{issue.status}</span>
                        if (issue.status == "Medium")
                          return <span style={{ color: "purple" }}>{issue.status}</span>
                          if (issue.status == "High")
                          return <span style={{ color: "orange" }}>{issue.status}</span>
                        if (issue.status == "Critical")
                          return <span style={{ color: "red" }}>{issue.status}</span>

                      })()}
                  </Col>
                  {/* <Col lg={4} md={4} sm={4} xs={4} className="gx-fs-sm"><a href={`/reports/project/by-id/${issue.id}`}><EyeOutlined style={{ padding: '10px' }} /></a></Col> */}
                  <Col lg={4} md={4} sm={4} xs={4} className="gx-fs-sm"><a href={`/reports/issue`}><EyeOutlined style={{ padding: '10px' }} /></a></Col>
                </Row>
               
                </Col>
                <Row gutter={24} className="gx-ml-0 gx-mr-0 orgBlock1">
                  <Col lg={24} md={24} sm={24} xs={24} className="gx-fs-sm">{issue.comments}</Col>
                  <Col lg={24} md={24} sm={24} xs={24} className="gx-fs-sm">- {issue.project && issue.project.name}</Col>
                </Row>
             </Row>
             
          </List.Item>}
        />
      </Widget>
    </Auxiliary>
  );
}
export default Issues;
