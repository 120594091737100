import React, { useEffect, useState } from "react";
import { Table, Tag, Row, Col, DatePicker, Breadcrumb } from "antd";
import axios from 'axios';
import { API_URL } from 'constants/APIConfig';
import moment from 'moment';
import 'moment/min/locales';
import Auxiliary from "../../../util/Auxiliary";
import { Link } from "react-router-dom";
import { FormOutlined, FileAddTwoTone } from '@ant-design/icons';

const TimeLogs = (props) => {

  const userDetails = JSON.parse(sessionStorage.getItem('user_details'));
  const token = sessionStorage.getItem('token');
  const [loading, setLoading] = useState(false);

  const onChangeYear = (date, dateString) => {
    if (date != null && dateString != null) {
      getTSList(dateString);
    }
  }

  const minsToStrTime = (val) => {
    let m = val % 60;
    let h = (val - m) / 60;
    h = h < 10 ? "0" + h : h;
    m = m < 10 ? "0" + m : m;
    return `${h}:${m}`;
  }

  const strTimeToMins = (strTime) => {
    let [h, m] = strTime.split(":");
    return Number(h) * 60 + Number(m);
  }

  const [timeSheetList, setTimeSheetList] = useState([]);
  const timeSheetListCols = [
    {
      title: 'Year-Week', fixed: 'left', dataIndex: 'yearweek', width: 100, align: 'center',
      render: (text, record) => {
        return (
          <div>
            {text}
            <div className="timeSheetBH">
              {record.period}
            </div>
          </div>

        );
      },
    },
    {
      title: 'Submitted On', dataIndex: 'submiton', width: 130, align: 'center',
      render: (text, record) => {
        return (
          <div>
            <span> {record.submittedDate ? moment(record.submittedDate).format('lll') : null} </span>
          </div>
        );
      },
    },
    {
      title: 'Approved On', dataIndex: 'submiton', width: 130, align: 'center',
      render: (text, record) => {
        return (
          <div>
            <span> {record.rmApprovedDate ? moment(record.rmApprovedDate).format('lll') : null} </span>
          </div>
        );
      },
    },
    {
      title: 'Logged Hours', dataIndex: 'thours', width: 100, align: 'center',
      render: (text, record) => {
        return (
          <div>
            <span> {record.loggedHours}</span>
          </div>
        );
      },
    },
    {
      title: 'Approved Hours', dataIndex: 'thours', width: 100, align: 'center',
      render: (text, record) => {
        return (
          <div>
            <span> {record.approvedHours} </span>
          </div>
        );
      },
    },
    {
      title: 'Reporting Manager', dataIndex: 'rmanager', width: 250, ellipsis: true, align: 'center',
      render: (text, record) => {
        return (
          <div>
            <span> {text} </span>
          </div>
        );
      },
    },

    {
      title: 'Status', fixed: 'right', dataIndex: 'status', width: 80, align: 'center',
      render: (text, record) => {
        return (
          <div className="gx-d-flex">
            {(() => {
              if (record.status === 0)
                return <Tag color="yellow" > {"Drafted"} </Tag>
              if (record.status === 1)
                return <Tag color="blue" >{"Submitted"}</Tag>
              if (record.status === 2)
                return <Tag color="green" >{"Approved"}</Tag>
              if (record.status === 3)
                return <Tag color="red" >{"Rejected"}</Tag>
            })()}
          </div>
        );
      },
    },
    {
      title: 'Action', fixed: 'right', width: 60, dataIndex: 'action',
      render: (text, record) =>
        <div className="gx-d-flex">
          <span> <Link to={`/timesheets/submit/by-id/${record.year}/${record.weekNumber}`}><FormOutlined style={{ padding: '10px' }} /></Link></span>
        </div>
    },
  ];

  const getLoggedHours = (item) => {
    let totalHours = 0;
    item.projects.map((projectItem, index) => (
      projectItem.tasks.map((taskItem, index) => (
        totalHours = totalHours + strTimeToMins(taskItem.timelog[0].hours) + strTimeToMins(taskItem.timelog[1].hours) + strTimeToMins(taskItem.timelog[2].hours) + strTimeToMins(taskItem.timelog[3].hours) + strTimeToMins(taskItem.timelog[4].hours) + strTimeToMins(taskItem.timelog[5].hours) + strTimeToMins(taskItem.timelog[6].hours)
      ))
    ))
    return minsToStrTime(totalHours);
  }

  const getApprovedLoggedHours = (item) => {
    let totalHours = 0;
    item.projects.map((projectItem, index) => (
      projectItem.tasks.map((taskItem, index) => {
        if(taskItem.projectManagerAction === 'Approved') {
          totalHours = totalHours + strTimeToMins(taskItem.timelog[0].hours) + strTimeToMins(taskItem.timelog[1].hours) + strTimeToMins(taskItem.timelog[2].hours) + strTimeToMins(taskItem.timelog[3].hours) + strTimeToMins(taskItem.timelog[4].hours) + strTimeToMins(taskItem.timelog[5].hours) + strTimeToMins(taskItem.timelog[6].hours)
        }
        return ''
    })
    ))
    return minsToStrTime(totalHours);
  }

  let tSheetList = [];
  const getTSList = (year) => {
    setLoading(true)
    axios.get(API_URL + `/api/timesheets/getAllUserTimesheets?userId=${userDetails.id}&year=${year}`,
      {
        headers: {
          'x-access-token': `${token}`,
          'Access-Control-Allow-Origin': '*'
        }
      })
      .then(response => {
        setLoading(false)
        if (Object.keys(response.data.data).length > 0) {
          response.data.data.map((item, index) => {
            let status = item.timesheetStatus;
            if(status === 1) {
              let projectsArr = item.projects;
              for(let i=0; i<projectsArr.length;i++) {
                let tasksArr = projectsArr[i].tasks;
                for(let j=0; j<tasksArr.length;j++) {
                  let t = tasksArr[j];
                  if(t.projectManagerAction === 'Rejected') {
                    status = 3
                    break;
                  }
                }
                if(status === 3) {
                  break;
                }
              }
            }

            tSheetList.push({
              empname: item.user && item.user.displayNameTx,
              empid: item.user && item.user.employeeId,
              yearweek: item.year + " - " + item.weekNumber,
              year: item.year,
              weekNumber: item.weekNumber,
              rmanager: item.reportingManager && item.reportingManager.displayNameTx,
              rmcomments: item.reportingManagerComment,
              status: status,
              submittedDate: item.submittedDate,
              rmApprovedDate: item.rmApprovedDate,
              loggedHours: getLoggedHours(item),
              approvedHours: getApprovedLoggedHours(item),
              key: index
            })
            return ''
          })
          tSheetList.sort((a, b) => ((parseInt(a.year + '' + a.weekNumber) < parseInt(b.year + '' + b.weekNumber)) ? 1 : -1))
          setTimeSheetList(tSheetList);
        } else {
          setTimeSheetList([]);
        }
      })
      .catch(error => {
        setLoading(false);
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log('Error', error.message);
        }
        console.log(error.config);
        setLoading(false);
      });
  }
  useEffect(() => {
    getTSList(moment().year());
  }, [token])
  return (
    <Auxiliary>
      
      <Row className="dateRangeBlock m_0">
        <Col xl={4} lg={4} md={4} sm={24} xs={24} className="flex_style gx-pl-0 ant-row-start">
          <Breadcrumb>
            <Breadcrumb.Item>Log Efforts</Breadcrumb.Item>
            <Breadcrumb.Item className={'text-helight'}><i className="icon icon-check-circle-o" /> Submit</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
        <Col xl={16} lg={16} md={16} sm={24} xs={24} className="flex_style gx-pr-0">
          <div className="newprojectBtn"><span> <Link to={'/timesheets/submit/new'}><FileAddTwoTone style={{ padding: '10px' }} /> New Timesheet</Link></span> </div>
        </Col>
        <Col xl={4} lg={4} md={4} sm={24} xs={24} className="flex_style gx-pr-0">
          <DatePicker picker="year" onChange={onChangeYear} defaultValue={moment()} bordered={true} allowClear={ false }/> 
        </Col>
      </Row>
      <Table loading={loading} className="gx-table-responsive submitTable" columns={timeSheetListCols} dataSource={timeSheetList} bordered={false} 
      />
    </Auxiliary>
  );
};
export default TimeLogs;