import React, { useEffect, useState } from "react";
import axios from 'axios';
import { Table, Col, Row, Tooltip, Avatar, List } from "antd";
import Auxiliary from "util/Auxiliary";
import { API_URL } from 'constants/APIConfig';
import IconWithTextCard from "components/Metrics/IconWithTextCard";
import { EyeOutlined } from '@ant-design/icons';
import Widget from "components/Widget";

const RecentWins = () => {
  const userDetails = JSON.parse(sessionStorage.getItem('user_details'));
  const token = sessionStorage.getItem('token');
  const [recentWins, setRecentWins] = useState([]);


  const getRecentWins = () => {
    axios.get(API_URL + `/api/project/v2/getRecentProjects?userId=1&limit=5&offset=5`, 
    {headers: {
      'x-access-token': `${token}`, 
    'Access-Control-Allow-Origin': '*'}
   })
      .then(response => {
        if (Object.keys(response.data.data).length > 0) {
          setRecentWins(response.data.data);
          console.log(response.data.data);
        }
      })
      .catch(error => {
        // Error
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log('Error', error.message);
        }
        console.log(error.config);
      });
  }

  useEffect(() => {
    getRecentWins();
  }, [])

  return (
    <Auxiliary>
      <Widget title="Project's Won">
        <List className="gx-bg-white"
          size="small"
          pagination={{
            onChange: page => {
              console.log(page);
            },
            pageSize: 5,
          }}
          dataSource={recentWins}
          renderItem={recentWin => <List.Item className="gx-pl-0 gx-pr-0">
            <Row gutter={24} className="ant-space-align-baseline orgBlock1 gx-ml-0 gx-mr-0">
              <Col lg={14} md={14} sm={14} xs={14} className="gx-pl-0 gx-pr-0">{recentWin.name?recentWin.name:""}</Col>
              <Col lg={6} md={6} sm={6} xs={6} className="gx-fs-sm">{recentWin.technology? recentWin.technology && recentWin.technology.nameTx:""}</Col>
              {/* <Col lg={4} md={4} sm={4} xs={4} className="gx-fs-sm"><a href={`/projects/reports/by-id/${recentWin.id}`}><EyeOutlined style={{ padding: '10px 10px 0px 10px' }} /></a></Col> */}
              <Col lg={24} md={24} sm={24} xs={24} className="gx-fs-sm gx-pl-0 gx-pr-0 text_blue">{recentWin.client? recentWin.client && recentWin.client.organization:""}</Col>
             </Row>

            
          </List.Item>}
        />
      </Widget>
    </Auxiliary>
  );
}
export default RecentWins;
