import React, { useEffect, useState } from "react";
import { Button, Row, Col, Form, Input, Select, Radio, InputNumber, Table, DatePicker, Popconfirm, message } from "antd";
import { API_URL } from 'constants/APIConfig';
import Auxiliary from "util/Auxiliary";
import { FormOutlined } from '@ant-design/icons';
import axios from 'axios';
import moment from "moment";
import { useHistory } from "react-router-dom";
import Widget from "components/Widget/index";

const FormItem = Form.Item;
const { Option } = Select;
const RadioGroup = Radio.Group;
const InputGroup = Input.Group;
const { RangePicker } = DatePicker;
const { TextArea } = Input;

function handleChange(value) {
  console.log(`selected ${value}`);
}

function handleBlur() {
  console.log('blur');
}

function handleFocus() {
  console.log('focus');
}

const ResAlloc = (props) => {

  let history = useHistory();
  const userDetails = JSON.parse(sessionStorage.getItem('user_details'));
  const token = sessionStorage.getItem('token');

  const [resAlloc, setResAlloc] = useState([]);
  const [users, setUsers] = useState([]);
  const [currkey, setCurrkey] = useState(0);
  const [edit, setEdit] = useState(false);

  const [projects, setProjects] = useState([]);
  const [projectId, setProjectId] = useState(0);

  const [form] = Form.useForm();

  const getUserList = () => {
    axios.get(API_URL + '/api/users/getall', 
    {headers: {
      'x-access-token': `${token}`, 
    'Access-Control-Allow-Origin': '*'}
   })
      .then(response => {
        setUsers(response.data.data);
      })
      .catch(error => {
        console.log(error)
      });
  }

  const onProjectChange = value => {
    setProjectId(value);
  };

  const getProjectList = () => {
    axios.get(API_URL + `/api/project/v2/projectsByManager?userId=${userDetails.id}`, 
    {headers: {
      'x-access-token': `${token}`, 
    'Access-Control-Allow-Origin': '*'}
   })
      .then(response => {
        if (Object.keys(response.data.data).length > 0) {
          setProjects(response.data.data);
        }
      })
      .catch(error => {
        console.log(error);
      });
  }
  useEffect(() => {
    getUserList();
    getProjectList();
  }, [])

  const onFinish = () => {
    if (projectId != 0) {
    let fieldsData = form.getFieldsValue();
    let dates = fieldsData.duration;

    let resAllocations = [];
    resAllocations.push({
      projectIdNm: projectId,
      userIdNm: form.getFieldValue('resname'),
      resourceType: fieldsData.resource.type,
      resourceTypeTitle: fieldsData.resource.title,
      percentageOfAllocation: +form.getFieldValue('alloc'),
      status: form.getFieldValue('status'),
      startDateDt: dates[0].format("YYYY-MM-DD"),
      endDateDt: dates[1].format("YYYY-MM-DD"),
      comments: form.getFieldValue('comments'),
    })

    axios.post(API_URL + '/api/project_resources/addNewResources', resAllocations, 
    {headers: {
      'x-access-token': `${token}`, 
    'Access-Control-Allow-Origin': '*'}
   })
      .then(response => {
        message.success("New resource assigned");
        history.push("/resources/manage");
      })
      .catch(error => {
        console.log(error);
        message.error("Error in assigning resource", error)
      });
    } else if(projectId == 0){
        message.error("Please select the project");
    }
  };

  return (
    <Auxiliary>
      <Form
        form={form}
        initialValues={{ remember: true }}
        name="resAlloc"
        onFinish={onFinish}
        layout="inline"
        align="center"
      >
      <Row gutter={24}>
        <Col lg={16} md={12} sm={24} xs={24}  >
        </Col>
        <Col lg={8} md={12} sm={24} xs={24}  >
          <div className="gx-form-row0" >
            <Form.Item
              name="prjname"
              // label="Project Name"
              rules={[
                {
                  required: true,
                  message: 'Enter project name',
                },
              ]}
            >
              <Select
                showSearch
                style={{ width: '100%' }}
                placeholder="Projects"
                optionFilterProp="children"
                onChange={onProjectChange}
                // size="small"
                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              >
                {
                  projects.length ? projects.map((project, index) => (
                    <Option key={index} value={project.id}>{project.name}</Option>
                  )) : null
                }
              </Select>
            </Form.Item>
          </div>
        </Col>
      </Row>

        <Row gutter={24}>
          <Col lg={24} md={24} sm={12} xs={24} >
            <Widget>
              <Row gutter={24}>
                <Col lg={8} md={8} sm={12} xs={24} >
                  <div className="gx-form-row0">
                    <h6 align="left">Resource</h6>
                    <Form.Item
                      //name="resource"
                      // label="Project Name"
                      rules={[
                        {
                          required: true,
                          message: 'Enter resource name',
                        },
                      ]}
                    >
                      <InputGroup compact className="gx-mb-3">
                        <Form.Item
                          name={['resource', 'type']}
                          noStyle
                          rules={[{ required: true, message: 'Enter resource type' }]}
                        >
                          <Select style={{ width: '40%' }}>
                            <Option value="Onsite">Onsite</Option>
                            <Option value="Offshore">Offshore</Option>
                          </Select>
                        </Form.Item>
                        <Form.Item
                          name={['resource', 'title']}
                          noStyle
                          rules={[{ required: true, message: 'Enter resource title' }]}              >
                          <Input style={{ width: '50%' }} />
                        </Form.Item>
                      </InputGroup>
                    </Form.Item>
                  </div>
                </Col>
                <Col lg={8} md={8} sm={12} xs={24} >
                  <div className="gx-form-row0">
                    <h6 align="left">Name</h6>
                    <Form.Item
                      name="resname"
                      // label="Project Name"
                      rules={[
                        {
                          required: true,
                          message: 'Enter resource name',
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        style={{ width: '100%' }}
                        placeholder="Select a Value"
                        optionFilterProp="children"
                        onChange={handleChange}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                      >
                        {users.length ? users.map((user, index) => (
                          <Option key={index} value={user.id}>{user.displayNameTx}</Option>
                        )) : null}
                      </Select>
                    </Form.Item>
                  </div>
                </Col>
                <Col lg={8} md={8} sm={12} xs={24} >
                  <div className="gx-form-row0">
                    <h6 align="left">Duration</h6>
                    <Form.Item
                      name="duration"
                      //label="Duration"
                      rules={[
                        {
                          required: true,
                          message: 'Enter Duration',
                        },
                      ]}
                    >

                      <RangePicker className="gx-mb-3 gx-w-100" />
                    </Form.Item>
                  </div>
                </Col>

                <Col lg={8} md={8} sm={12} xs={24} >
                  <div className="gx-form-row0">
                    <h6 align="left">Perctange of allocation</h6>
                    <Form.Item
                      name="alloc"
                      // label="Project Name"
                      rules={[
                        {
                          required: true,
                          message: 'Enter % of allocation',
                        },
                      ]}
                    >
                      <InputNumber placeholder="Perctange of allocation" />
                    </Form.Item>
                  </div>
                </Col>

                <Col lg={8} md={8} sm={12} xs={24} >
                  <h6 align="left">Status</h6>
                  <div className="gx-form-row0">
                    <Form.Item
                      name="status"
                      //label="Billable"
                      rules={[
                        {
                          required: true,
                          message: 'Status',
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        style={{ width: '100%' }}
                        placeholder="Select a Value"
                        optionFilterProp="children"
                        onChange={handleChange}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                      >
                        <Option value="Planned">Planned</Option>
                        <Option value="Assigned">Assigned</Option>
                        <Option value="Hold">Hold</Option>
                        <Option value="Unassign">Unassign</Option>
                        {/* <Option value="Yet to assign">Yet to assign</Option> */}

                      </Select>
                    </Form.Item>
                  </div>
                </Col>
                <Col lg={24} md={24} sm={12} xs={24} >
                  <div className="gx-form-row0">
                    <h6 align="left">Comments</h6>
                    <Form.Item
                      name="comments"
                      // label="Project Name"
                      rules={[
                        {
                          required: true,
                          message: 'Enter comments',
                        },
                      ]}
                    >
                      <TextArea rows={4} />
                    </Form.Item>
                  </div>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col lg={24} md={24} sm={24} xs={24} >
                  <Button type="primary" className="add-btn" htmlType="submit" >Submit</Button>
                  <Button type="primary" className="add-btn gx-mr-2" onClick={() => history.push('/resources/manage')}>Back</Button>
                </Col>
              </Row>
            </Widget>
          </Col>
        </Row>
      </Form>
    </Auxiliary>
  );
};
export default ResAlloc;
