import React, { useEffect, useState} from "react";
import axios from 'axios';
import { Row, Col, Form, Input, Select, Radio, Upload, message, DatePicker, Button, Tabs, Tooltip } from "antd";
import Auxiliary from "util/Auxiliary";
import { API_URL } from 'constants/APIConfig';
import { useHistory } from "react-router-dom";
import Widget from "components/Widget/index";


const { Option } = Select;
const { TextArea } = Input;

const onChange = (date, dateString) => {
  console.log(date, dateString);
}

const NewHealth = (props) => {

  let history = useHistory();
  const userDetails = JSON.parse(sessionStorage.getItem('user_details'));
  const token = sessionStorage.getItem('token');

  const [projects, setProjects] = useState([]);
  const [projectId, setProjectId] = useState(0);
  const [weekno, setWeekno] = useState(0);
  const [yearno, setYearno] = useState(0);
  const [form] = Form.useForm();


  const getProjectList = () => {
    axios.get(API_URL + `/api/project/v2/projectsByManager?userId=${userDetails.id}`, 
    {headers: {
      'x-access-token': `${token}`, 
    'Access-Control-Allow-Origin': '*'}
   })
      .then(response => {
        if (Object.keys(response.data.data).length > 0) {
          setProjects(response.data.data);
        }
      })
      .catch(error => {
        // Error
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log('Error', error.message);
        }
        console.log(error.config);
      });
  }

  const onProjectChange = value => {
    setProjectId(value);
  };

  const onChangeWeek = (date, dateString) => {
    if (date !== null && dateString !== null) {
      let weekDt = dateString.split('-');
      setYearno(weekDt[0]);
      setWeekno(weekDt[1].match(/\d+/)[0]);
    } else {
      setYearno(0);
      setWeekno(0);
    }
  }

  const onFinish = values => {
    if (projectId !== 0 && weekno !== 0 && yearno !== 0) {
      const frmValues = form.getFieldsValue();
      const newData = [{
        projectId: projectId,
        year: +yearno,
        weekNumber: +weekno,
        schedule: frmValues.Schedule,
        budget: frmValues.Budget,
        resource: frmValues.Resource,
        scope: frmValues.Scope,
        quality: frmValues.Quality,
        changeMang: frmValues.ChangeMang,
        scheduleDesc: frmValues.Schedule1,
        budgetDesc: frmValues.Budget1,
        resourceDesc: frmValues.Resource1,
        scopeDesc: frmValues.Scope1,
        qualityDesc: frmValues.Quality1,
        changeMangDesc: frmValues.ChangeMang1,
        comments: frmValues.comments,
      }];
      axios.post(API_URL + '/api/project_health/create', newData, 
      {headers: {
        'x-access-token': `${token}`, 
      'Access-Control-Allow-Origin': '*'}
     })
        .then(response => {
          message.success("Submitted Successfully");
          history.push("/weeklystatus/manage");
        })
        .catch(error => {
          // Error
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log('Error', error.message);
          }
          message.error("Error in submitting WSR", error)
        });
      form.resetFields();
    } else {
      if (projectId === 0)
        message.error("Please select the project");
      if (weekno === 0)
        message.error("Please select the week");
      if (yearno === 0)
        message.error("Please select the year");
    }
  }

  useEffect(() => {
    getProjectList();
  }, [])

  return (
    <Auxiliary>
      <Form
        form={form}
        initialValues={{ remember: true }}
        name="wsr"
        onFinish={onFinish}
        layout="inline"
        align="center"
      >
        <Row gutter={24}>
          <Col lg={16} md={12} sm={24} xs={24}  >
          </Col>
          <Col lg={8} md={12} sm={24} xs={24}  >
            <div className="gx-form-row0" >
              <Form.Item
                name="prjname"
                // label="Project Name"
                rules={[
                  {
                    required: true,
                    message: 'Enter project name',
                  },
                ]}
              >

                <Select
                  showSearch
                  style={{ width: '100%' }}
                  placeholder="Projects"
                  optionFilterProp="children"
                  onChange={onProjectChange}
                  filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                >
                  {
                    projects.length ? projects.map((project, index) => (
                      <Option key={index} value={project.id}>{project.name}</Option>
                    )) : null
                  }
                </Select>
              </Form.Item>
            </div>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col lg={24} md={24} sm={24} xs={24} >
            <Widget>
              <Row>
                <Col lg={5} md={12} sm={12} xs={24}>
                  <Form.Item
                    name="yearweek"
                    // label="Project Name"
                    rules={[
                      {
                        required: true,
                        message: 'Enter Year & Week',
                      },
                    ]}
                  >
                    <DatePicker picker="week" onChange={onChangeWeek} bordered={true} />
                  </Form.Item>
                </Col>
              </Row>
              <Row className="align_text">
                <Col lg={3} md={12} sm={12} xs={24}>
                  <h5 align="left">Schedule</h5>
                </Col>
                <Col lg={5} md={12} sm={12} xs={24}>
                  <Form.Item
                    style={{ marginBottom: "0px" }}
                    name="Schedule"
                    rules={[
                      {
                        required: true,
                        message: 'Enter client name',
                      },
                    ]}
                  >
                    <Radio.Group onChange={onChange}>
                      <Tooltip placement="topLeft" title="Red">  <Radio className="red_status1" value={1}></Radio>  </Tooltip>
                      <Tooltip placement="topLeft" title="Orange">   <Radio className="orange_status1" value={3}></Radio> </Tooltip>
                      <Tooltip placement="topLeft" title="Blue">   <Radio className="skyBlue_status1" value={4}></Radio> </Tooltip>
                      <Tooltip placement="topLeft" title="Green">   <Radio className="green_status1" value={2}></Radio> </Tooltip>
                    </Radio.Group>
                  </Form.Item>
                </Col>
                <Col lg={16} md={12} sm={12} xs={24}>
                  <Form.Item
                    style={{ marginBottom: "0px" }}
                    name="Schedule1"
                    // label="Schedule1"
                    rules={[
                      {
                        required: true,
                        message: 'Enter client name',
                      },
                    ]}
                  >
                    <Input /* style={{ width: '1000%' }} */ />
                  </Form.Item>
                </Col>
              </Row>
              <Row className="align_text">
                <Col lg={3} md={12} sm={12} xs={24}>
                  <h5 align="left">Budget</h5>
                </Col>
                <Col lg={5} md={12} sm={12} xs={24}>
                  <Form.Item
                    style={{ marginBottom: "0px" }}
                    name="Budget"
                    //label="Budget"
                    rules={[
                      {
                        required: true,
                        message: 'Enter client name',
                      },
                    ]}
                  >
                    <Radio.Group onChange={onChange}>
                      <Tooltip placement="topLeft" title="Red">  <Radio className="red_status1" value={1}></Radio>  </Tooltip>
                      <Tooltip placement="topLeft" title="Orange">   <Radio className="orange_status1" value={3}></Radio> </Tooltip>
                      <Tooltip placement="topLeft" title="Blue">   <Radio className="skyBlue_status1" value={4}></Radio> </Tooltip>
                      <Tooltip placement="topLeft" title="Green">   <Radio className="green_status1" value={2}></Radio> </Tooltip>
                    </Radio.Group>
                  </Form.Item>
                </Col>
                <Col lg={16} md={12} sm={12} xs={24}>
                  <Form.Item
                    style={{ marginBottom: "0px" }}
                    name="Budget1"
                    // label="Schedule1"
                    rules={[
                      {
                        required: true,
                        message: 'Enter client name',
                      },
                    ]}
                  >
                    <Input /* style={{ width: '1000%' }} */ />
                  </Form.Item>
                </Col>
              </Row>
              <Row className="align_text">
                <Col lg={3} md={12} sm={12} xs={24}>
                  <h5 align="left">Resource</h5>
                </Col>
                <Col lg={5} md={12} sm={12} xs={24}>
                  <Form.Item
                    style={{ marginBottom: "0px" }}
                    name="Resource"
                    //label="Resource"
                    rules={[
                      {
                        required: true,
                        message: 'Enter client name',
                      },
                    ]}
                  >
                    <Radio.Group onChange={onChange}>
                      <Tooltip placement="topLeft" title="Red">  <Radio className="red_status1" value={1}></Radio>  </Tooltip>
                      <Tooltip placement="topLeft" title="Orange">   <Radio className="orange_status1" value={3}></Radio> </Tooltip>
                      <Tooltip placement="topLeft" title="Blue">   <Radio className="skyBlue_status1" value={4}></Radio> </Tooltip>
                      <Tooltip placement="topLeft" title="Green">   <Radio className="green_status1" value={2}></Radio> </Tooltip>
                    </Radio.Group>
                  </Form.Item>
                </Col>
                <Col lg={16} md={12} sm={12} xs={24}>
                  <Form.Item
                    style={{ marginBottom: "0px" }}
                    name="Resource1"
                    // label="Schedule1"
                    rules={[
                      {
                        required: true,
                        message: 'Enter client name',
                      },
                    ]}
                  >
                    <Input /* style={{ width: '1000%' }} */ />
                  </Form.Item>
                </Col>
              </Row>
              <Row className="align_text">
                <Col lg={3} md={12} sm={12} xs={24}>
                  <h5 align="left">Scope</h5>
                </Col>
                <Col lg={5} md={12} sm={12} xs={24}>
                  <Form.Item
                    style={{ marginBottom: "0px" }}
                    name="Scope"
                    //label="Scope"
                    rules={[
                      {
                        required: true,
                        message: 'Enter client name',
                      },
                    ]}
                  >
                    <Radio.Group onChange={onChange}>
                      <Tooltip placement="topLeft" title="Red">  <Radio className="red_status1" value={1}></Radio>  </Tooltip>
                      <Tooltip placement="topLeft" title="Orange">   <Radio className="orange_status1" value={3}></Radio> </Tooltip>
                      <Tooltip placement="topLeft" title="Blue">   <Radio className="skyBlue_status1" value={4}></Radio> </Tooltip>
                      <Tooltip placement="topLeft" title="Green">   <Radio className="green_status1" value={2}></Radio> </Tooltip>
                    </Radio.Group>
                  </Form.Item>
                </Col>
                <Col lg={16} md={12} sm={12} xs={24}>
                  <Form.Item
                    style={{ marginBottom: "0px" }}
                    name="Scope1"
                    // label="Schedule1"
                    rules={[
                      {
                        required: true,
                        message: 'Enter client name',
                      },
                    ]}
                  >
                    <Input /* style={{ width: '1000%' }} */ />
                  </Form.Item>
                </Col>
              </Row>
              <Row className="align_text">
                <Col lg={3} md={12} sm={12} xs={24}>
                  <h5 align="left">Quality</h5>
                </Col>
                <Col lg={5} md={12} sm={12} xs={24}>
                  <Form.Item
                    style={{ marginBottom: "0px" }}
                    name="Quality"
                    //label="Quality"
                    rules={[
                      {
                        required: true,
                        message: 'Enter client name',
                      },
                    ]}
                  >
                    <Radio.Group onChange={onChange}>
                      <Tooltip placement="topLeft" title="Red">  <Radio className="red_status1" value={1}></Radio>  </Tooltip>
                      <Tooltip placement="topLeft" title="Orange">   <Radio className="orange_status1" value={3}></Radio> </Tooltip>
                      <Tooltip placement="topLeft" title="Blue">   <Radio className="skyBlue_status1" value={4}></Radio> </Tooltip>
                      <Tooltip placement="topLeft" title="Green">   <Radio className="green_status1" value={2}></Radio> </Tooltip>
                    </Radio.Group>
                  </Form.Item>
                </Col>
                <Col lg={16} md={12} sm={12} xs={24}>
                  <Form.Item
                    style={{ marginBottom: "0px" }}
                    name="Quality1"
                    // label="Schedule1"
                    rules={[
                      {
                        required: true,
                        message: 'Enter client name',
                      },
                    ]}
                  >
                    <Input /* style={{ width: '1000%' }} */ />
                  </Form.Item>
                </Col>
              </Row>
              <Row className="align_text">
                <Col lg={3} md={12} sm={12} xs={24}>
                  <h5 align="left">Change Mgnt.</h5>
                </Col>
                <Col lg={5} md={12} sm={12} xs={24}>
                  <Form.Item
                    style={{ marginBottom: "0px" }}
                    name="ChangeMang"
                    //label="Change Mang"
                    rules={[
                      {
                        required: true,
                        message: 'Enter client name',
                      },
                    ]}
                  >
                    <Radio.Group onChange={onChange}>
                      <Tooltip placement="topLeft" title="Red">  <Radio className="red_status1" value={1}></Radio>  </Tooltip>
                      <Tooltip placement="topLeft" title="Orange">   <Radio className="orange_status1" value={3}></Radio> </Tooltip>
                      <Tooltip placement="topLeft" title="Blue">   <Radio className="skyBlue_status1" value={4}></Radio> </Tooltip>
                      <Tooltip placement="topLeft" title="Green">   <Radio className="green_status1" value={2}></Radio> </Tooltip>
                    </Radio.Group>
                  </Form.Item>
                </Col>
                <Col lg={16} md={12} sm={12} xs={24}>
                  <Form.Item
                    style={{ marginBottom: "0px" }}
                    name="ChangeMang1"
                    // label="Schedule1"
                    rules={[
                      {
                        required: true,
                        message: 'Enter client name',
                      },
                    ]}
                  >
                    <Input /* style={{ width: '1000%' }} */ />
                  </Form.Item>
                </Col>

              </Row>

              <Row >
                <Col lg={24} md={24} sm={12} xs={24} >
                  <div className="gx-form-row0">
                    <h6 align="left">Comments</h6>
                    <Form.Item
                      name="comments"
                      // label="Project Name"
                      rules={[
                        {
                          required: true,
                          message: 'Enter comments',
                        },
                      ]}
                    >
                      <TextArea rows={4} />
                    </Form.Item>
                  </div>
                </Col>
              </Row>

              <Row >
                <Col lg={24} md={24} sm={24} xs={24} >
                  <div className="gx-form-row0">
                    <Form.Item
                      name="submit"
                    >
                      <Button type="primary" className="add-btn" htmlType="submit" >Submit</Button>
                      <Button type="primary" className="add-btn gx-mr-2" onClick={() => history.push('/weeklystatus/manage')}> Back </Button>
                    </Form.Item>
                  </div>
                </Col>
              </Row>
            </Widget>
          </Col>
        </Row>

      </Form>
    </Auxiliary>
    // </Card>
  );
};
export default NewHealth;



