import React, { useEffect, useState } from "react";
import { Form, Row, Col, message, Table, Select, Radio, Button, Modal, DatePicker, Tag, Breadcrumb, Avatar, Tooltip, Input, Divider } from "antd";
import axios from 'axios';
import { API_URL } from 'constants/APIConfig';
import moment from 'moment';
import 'moment/min/locales';
import Auxiliary from "util/Auxiliary";
import { CheckOutlined, CloseOutlined, DeleteOutlined } from '@ant-design/icons';
import Widget from "../../../components/Widget/index";

const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;
const { TextArea } = Input;
const { Option } = Select;

const TravelDeskInfraAdminApprove = () => {
    const [form] = Form.useForm();
    const userDetails = JSON.parse(sessionStorage.getItem('user_details'));
    const token = sessionStorage.getItem('token');
    const [loading, setLoading] = useState(false);
    const [year, setYear] = useState(moment().weekYear());
    const [updateRecord, setUpdateRecord] = useState(null);
    const [approveTextAreaCount, setApproveTextAreaCount] = React.useState(0);
    const [rejectTextAreaCount, setRejectTextAreaCount] = React.useState(0);
    const [travelRequeststatus, setTravelRequestStatus] = useState('Pending');
    const [approveComments, setApproveComments] = useState("");
    const [rejectComments, setRejectComments] = useState("");
    const [newTicket, setNewTicket] = useState(false);
    const [employees, setEmployees] = useState([]);
    const [projects, setProjects] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [textAreaCount, setTextAreaCount] = React.useState(0);
    const [purposeCount, setPurposeCount] = React.useState(0);
    const [goalCount, setGoalCount] = React.useState(0);
    const [expectedOutcomeCount, setExpectedOutcomeCount] = React.useState(0);

    const purposeRecalculate = e => {
        setPurposeCount(e.target.value.length);
    }
    const goalRecalculate = e => {
        setGoalCount(e.target.value.length);
    }
    const expectedOutcomeRecalculate = e => {
        setExpectedOutcomeCount(e.target.value.length);
    }
    const recalculate = e => {
        setTextAreaCount(e.target.value.length);
    }

    const onChangeYear = (date, yearString) => {
        if (date != null && yearString != null) {
            setYear(yearString)
        }
    }

    const onOkModal = () => {
        let values = form.getFieldsValue();
        values['journeyDate'] = values['journeyDate'].format('YYYY-MM-DD')
        if (values['returnJourneyDate']) {
            values['returnJourneyDate'] = values['returnJourneyDate'].format('YYYY-MM-DD')
        }
        values['userId'] = values['employeeId']
        values['infraAdminId'] = userDetails.id
        projects.forEach(p => {
            if (p['id'] === values['projectId']) {
                values['projectManagerId'] = p['project_manager_id']
                values['reportingManagerId'] = p['reporting_manager_nm']
            }
        })
        if (updateRecord) {
            values['id'] = updateRecord['id']
            setUpdateRecord(null)
        }

        setIsModalOpen(false);
        createOrUpdateTravelRequest(values)
        setNewTicket(false);
    }

    const onFinish = values => {
        setIsModalOpen(true)
    };

    const onApprove = () => {
        if (approveComments.trim().length == 0) {
            message.error('Please enter the comments')
            return
        }
        setApprovePop(false)
        updateTravelRequestByInfraAdmin('Approved', approveComments)
        setApproveComments('')
        setApproveTextAreaCount(0)
    }

    const onReject = () => {
        if (rejectComments.trim().length == 0) {
            message.error('Please enter the comments')
            return
        }
        setRejectPop(false)
        updateTravelRequestByInfraAdmin('Rejected', rejectComments)
        setRejectComments('')
        setRejectTextAreaCount(0)
    }

    const handleApproveComments = (e) => {
        setApproveComments(e.target.value);
        setApproveTextAreaCount(e.target.value.length);
    }
    const handleRejectComments = (e) => {
        setRejectComments(e.target.value);
        setRejectTextAreaCount(e.target.value.length);
    }

    function onChange(e) {
        setTravelRequestStatus(e.target.value)
        getInfraAdminTravelRequestList(e.target.value, year);
    }

    const handleApprove = (record) => {
        setUpdateRecord(record)
        setApprovePop(true);
        setApproveComments('')
        setApproveTextAreaCount(0)
    }

    const handleReject = (record) => {
        setUpdateRecord(record)
        setRejectPop(true);
        setRejectComments('')
        setRejectTextAreaCount(0)
    }

    const createOrUpdateTravelRequest = (data) => {
        axios.post(API_URL + `/api/travel_desk/createOrUpdateTravelRequest`, data,
            {
                headers: {
                    'x-access-token': `${token}`,
                    'Access-Control-Allow-Origin': '*'
                }
            })
            .then(response => {
                message.success(response.data.data);
                getInfraAdminTravelRequestList(travelRequeststatus, year)
            })
            .catch(error => {
                message.error("Unexpected error occurred.");
            });
    }

    const getProjectsAssignedToUserId = (userId) => {
        setLoading(true)
        axios.get(API_URL + `/api/travel_desk/getProjectsAssignedToUserId?userId=${userId}`,
            {
                headers: {
                    'x-access-token': `${token}`,
                    'Access-Control-Allow-Origin': '*'
                }
            })
            .then(response => {
                setLoading(false)
                if (response.data && response.data.data) {
                    response.data.data.forEach((d, i) => { d['key'] = i })
                    setProjects(response.data.data)
                }
            })
            .catch(error => {
                setLoading(false);
            });
    }

    const getAllUsersForTravelDesk = () => {
        setLoading(true)
        axios.get(API_URL + `/api/travel_desk/getAllUsersForTravelDesk`,
            {
                headers: {
                    'x-access-token': `${token}`,
                    'Access-Control-Allow-Origin': '*'
                }
            })
            .then(response => {
                setLoading(false)
                if (response.data && response.data.data) {
                    response.data.data.forEach((d, i) => { d['key'] = i })
                    setEmployees(response.data.data)
                }
            })
            .catch(error => {
                setLoading(false);
            });
    }

    const [travelRequestDataList, setTravelRequestDataList] = useState([]);

    const travelRequestColList = [
        {
            title: 'Employee', dataIndex: 'employeeId', width: 25, 
            render: (text, record) => {
                return (
                    <div className="gx-d-flex">
                        {(() => {
                            try {
                                return <Tooltip placement="topLeft" title={record.displayNameTx ? record.displayNameTx : ""}> <Avatar size={{ xs: 24, sm: 32, md: 40, lg: 64, xl: 80, xxl: 100 }} src={require(`../../../../src/assets/images/avatar/${text.toLowerCase()}.png`)} /></Tooltip>
                            } catch (err) {
                                try {
                                    return <Tooltip placement="topLeft" title={record.displayNameTx ? record.displayNameTx : ""}> <Avatar size={{ xs: 24, sm: 32, md: 40, lg: 64, xl: 80, xxl: 100 }} src={require(`../../../../src/assets/images/avatar/profile.png`)} /></Tooltip>
                                } catch (err) {
                                    return <Tooltip placement="topLeft" title={record.displayNameTx ? record.displayNameTx : ""}><span className="ant-avatar gx-size-40 gx-border gx-border-grey gx-bg-transparent gx-text-grey gx-align-items-center gx-justify-content-center"><i
                                        className="icon icon-add" /></span></Tooltip>
                                }
                            }
                        })()}
                    </div>
                );
            },
        },
        {
            title: 'Emp ID', dataIndex: 'employeeId', key: 'displayNameTx', width: 25,
            render: (text, record) => {
                return (<div><span>{record.employeeId}</span></div>)
            }
        },
        {
            title: 'Name', dataIndex: 'displayNameTx', key: 'displayNameTx', width: 25,
            render: (text, record) => {
                return (<div><span>{record.displayNameTx}</span></div>)
            }
        },
        {
            title: 'Project', dataIndex: 'project_name', width: 100, align: 'center',
            render: (text, record) => {
                return (
                    <div>
                        <span> {record.project.name}</span>
                    </div>
                );
            },
        },
        {
            title: 'Admin Created', dataIndex: 'isAdminCreated', width: 70, align: 'center',
            render: (text, record) => {
                return (
                    <div>
                        <span> {record.isAdminCreated ? 'Yes' : 'No'}</span>
                    </div>
                );
            },
        },
        {
            title: 'Action', dataIndex: 'action', width: 25, align: 'left',
            render: (text, record) =>
                <div className="gx-d-flex">
                    {(() => {
                        if (record.infraAdminAction === "Pending") {
                            return (<>
                                <Button className="approved_btn" title="Approve" shape="circle" htmlType="submit" icon={<CheckOutlined />} onClick={() => handleApprove(record)}> </Button>
                                <Button className="reject_btn" title="Reject" shape="circle" htmlType="submit" icon={<CloseOutlined />} onClick={() => handleReject(record)}> </Button>
                            </>);
                        } else {
                            if (record.infraAdminAction === "Approved")
                                return <Tag color="green" >{record.infraAdminAction ? record.infraAdminAction : ""}</Tag>
                            if (record.infraAdminAction === "Rejected")
                                return <Tag color="red" >{record.infraAdminAction ? record.infraAdminAction : ""}</Tag>
                        }
                    })()}
                </div>
        }
    ];

    const getInfraAdminTravelRequestList = async (status, year) => {
        setLoading(true);
        const url = API_URL + `/api/travel_desk/getInfraAdminTravelRequestList?status=${status}&year=${year}`;
        try {
            axios.get(url,
                {
                    headers: {
                        'x-access-token': `${token}`,
                        'Access-Control-Allow-Origin': '*'
                    }
                })
                .then(response => {
                    setLoading(false)
                    if (response.data && response.data.data) {
                        response.data.data.forEach((d, i) => {
                            d['key'] = i;
                            d['employeeId'] = d['user']['employeeId']
                            d['displayNameTx'] = d['user']['displayNameTx']
                        })
                        setTravelRequestDataList(response.data.data)
                    } else {
                        setTravelRequestDataList([])
                    }
                })
                .catch(error => {
                    setLoading(false);
                    message.error(`Error occurred.`);
                });
        } catch (ex) {
            setLoading(false)
            message.error(`${ex.message}:get data`);
        }
    }

    const updateTravelRequestByInfraAdmin = async (status, comments) => {
        setLoading(true);
        const url = API_URL + `/api/travel_desk/updateTravelRequestByInfraAdmin`;
        try {
            axios.post(url,
                {
                    id: updateRecord['id'],
                    status: status,
                    infraAdminComment: comments,
                    infraAdminId: userDetails.id
                },
                {
                    headers: {
                        'x-access-token': `${token}`,
                        'Access-Control-Allow-Origin': '*'
                    }
                })
                .then(response => {
                    setLoading(false)
                    message.success(response.data.data)
                    getInfraAdminTravelRequestList(travelRequeststatus, year)
                })
                .catch(error => {
                    setLoading(false);
                    message.error(`Request failed`);
                });
        } catch (ex) {
            setLoading(false)
            message.error(`Request failed`);
        }
    }

    const selectedEmployee = async (value, option) => {
        setProjects([])
        form.setFieldsValue({
            projectId: undefined
        })
        getProjectsAssignedToUserId(value)
    }
    const [approvePop, setApprovePop] = useState(false);
    const [rejectPop, setRejectPop] = useState(false);

    useEffect(() => {
        getAllUsersForTravelDesk();
        getInfraAdminTravelRequestList(travelRequeststatus, year)
    }, [year])

    return (
        <Auxiliary>
            <Row className="m_0 gx-pt-2">
                <Col xl={6} lg={6} md={6} sm={24} xs={24} className="flex_style gx-pl-0 ant-row-start">
                    <Breadcrumb>
                        <Breadcrumb.Item>Travel Desk</Breadcrumb.Item>
                        <Breadcrumb.Item className="text-helight"><i className="icon icon-check-circle-o" /> Infra Admin Approve</Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
                <Col xl={14} lg={14} md={14} sm={24} xs={24} className="flex_style gx-pr-0">
                    {!newTicket && <div className="float-right">
                        <span>
                            <Button type="link" disabled={newTicket} onClick={() => { setNewTicket(true); setUpdateRecord(null); form.resetFields(); }}><i className="icon icon-hotel-booking" /> &nbsp; New Request</Button>
                        </span>
                    </div>
                    }
                </Col>
                <Col xl={4} lg={4} md={4} sm={24} xs={24} className="flex_style gx-pr-0 dateRangeBlock">
                    <DatePicker picker="year" onChange={onChangeYear} defaultValue={moment()} bordered={true} allowClear={false} />
                </Col>
            </Row>
            {newTicket && <Widget title={"Create New Request"}>
                <Form
                    form={form}
                    name="newTravelRequest"
                    onFinish={onFinish}
                    layout="inline"
                    align="center"
                >
                    <Row gutter={24}>
                        <Col lg={8} md={8} sm={12} xs={24} >
                            <div className="gx-form-row0">
                                <h6 align="left">On Behalf Of Employee</h6>
                                <Form.Item
                                    name="employeeId"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Select the employee',
                                        },
                                    ]}
                                >
                                    <Select
                                        showSearch
                                        style={{ width: '100%' }}
                                        placeholder="Select the employee"
                                        filterOption={(input, option) => option.name.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                        onChange={(value, option) => selectedEmployee(value, option)}
                                    >
                                        {
                                            employees.length > 0 && employees.map((employee, index) => (
                                                <Option key={index} value={employee.id} name={employee.name}>{employee.name}</Option>
                                            ))
                                        }
                                    </Select>
                                </Form.Item>
                            </div>
                        </Col>

                        <Col lg={8} md={8} sm={12} xs={24} >
                            <div className="gx-form-row0">
                                <h6 align="left">Project</h6>
                                <Form.Item
                                    name="projectId"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Select the project',
                                        },
                                    ]}
                                >
                                    <Select
                                        showSearch
                                        style={{ width: '100%' }}
                                        placeholder="Select the project"
                                        filterOption={(input, option) => option.name.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                    >
                                        {
                                            projects.length > 0 && projects.map((project, index) => (
                                                <Option key={index} value={project.id} name={project.project_name}>{project.project_name}</Option>
                                            ))
                                        }
                                    </Select>
                                </Form.Item>
                            </div>
                        </Col>

                        <Col lg={8} md={8} sm={12} xs={24} >
                            <div className="gx-form-row0">
                                <h6 align="left">Source</h6>
                                <Form.Item
                                    name="fromPlace"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Enter the source name',
                                        },
                                    ]}
                                >
                                    <Input placeholder="Source Place" />
                                </Form.Item>
                            </div>
                        </Col>
                        <Col lg={8} md={8} sm={12} xs={24} >
                            <div className="gx-form-row0">
                                <h6 align="left">Destination</h6>
                                <Form.Item
                                    name="toPlace"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Enter the destination name',
                                        },
                                    ]}
                                >
                                    <Input placeholder="Destination Place" />
                                </Form.Item>
                            </div>
                        </Col>
                        <Col lg={8} md={8} sm={12} xs={24} >
                            <div className="gx-form-row0">
                                <h6 align="left">Date of Journey</h6>
                                <Form.Item
                                    name="journeyDate"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Enter the Date of Journey',
                                        },
                                    ]}
                                >
                                    <DatePicker picker="date" bordered={true} allowClear={false}
                                        disabledDate={(current) => { return current && current < moment().subtract(1, 'days'); }} />
                                </Form.Item>
                            </div>
                        </Col>

                        <Col lg={8} md={8} sm={12} xs={24} >
                            <div className="gx-form-row0">
                                <h6 align="left">Date of Return Journey</h6>
                                <Form.Item
                                    name="returnJourneyDate"
                                    rules={[
                                        ({ getFieldValue }) => ({
                                            validator(_, value) {
                                                if (value && getFieldValue('journeyDate') != null && moment(getFieldValue('journeyDate')).isAfter(value)) {
                                                    return Promise.reject("Please enter the return date, after or equal to journey date");
                                                }
                                                return Promise.resolve();
                                            },
                                        })]}
                                >
                                    <DatePicker picker="date" bordered={true}
                                        disabledDate={(current) => { return current && current < moment().subtract(1, 'days'); }} />
                                </Form.Item>
                            </div>
                        </Col>

                        <Col lg={24} md={24} sm={24} xs={24} >
                            <div className="gx-form-row0">
                                <h6 align="center"><b>Purpose of Business Travel</b></h6>
                                <h6 align="left">Purpose:</h6>
                                <Form.Item
                                    name="purpose"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter the Purpose',
                                        },
                                    ]}
                                >
                                  <TextArea placeholder="Briefly explain why this trip is necessary (e.g., project alignment meeting, client pitch,Project Deliverables)." rows={4} maxLength={255} onChange={purposeRecalculate} />
                                </Form.Item>
                                <p style={{ textAlign: 'right' }}> {`${purposeCount} / 255`} </p>
                            </div>

                            <div className="gx-form-row0">
                            <h6 align="left">Goals:</h6>
                                <Form.Item
                                    name="goals"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter the Goals',
                                        },
                                    ]}
                                >
                                    <TextArea placeholder="List 2-3 concrete goals for the trip (e.g., finalize project timelines, meet key stakeholders, Project Assignment at client place)." rows={4} maxLength={255} onChange={goalRecalculate} />
                                </Form.Item>
                                <p style={{ textAlign: 'right' }}> {`${goalCount} / 255`} </p>
                            </div>

                            <div className="gx-form-row0">
                                <h6 align="left">Expected Outcome:</h6>
                                <Form.Item
                                    name="expectedOutcome"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter the Expected Outcome',
                                        },
                                    ]}
                                >
                                    <TextArea placeholder="Outline the expected benefits (e.g., improved project coordination, signed contract)." rows={4} maxLength={255} onChange={expectedOutcomeRecalculate} />
                                </Form.Item>
                                <p style={{ textAlign: 'right' }}> {`${expectedOutcomeCount} / 255`} </p>
                            </div>
                        </Col>

                        <Col lg={24} md={24} sm={24} xs={24} >
                            <div className="gx-form-row0">
                                <h6 align="left">Description</h6>
                                <Form.Item
                                    name="adminComment"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Enter the description',
                                        },
                                    ]}
                                >
                                    <TextArea placeholder="Please enter the comments" rows={4} maxLength={255} onChange={recalculate} />
                                </Form.Item>
                                <p style={{ textAlign: 'right' }}> {`${textAreaCount} / 255`} </p>
                            </div>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col lg={24} md={24} sm={24} xs={24} className="gx-p-0 buttonGroup">
                            <div className="gx-form-row0">
                                <Form.Item >
                                    <Button type="primary" className="add-btn" htmlType="submit">Submit & Approve</Button>
                                    <Button type="primary" className="add-btn gx-mr-2" onClick={() => { form.resetFields(); setNewTicket(false); }}>Cancel</Button>
                                </Form.Item>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </Widget>
            }
            <Modal closable={false} centered title="Confirmation" visible={isModalOpen} onOk={onOkModal}
                onCancel={() => { setIsModalOpen(false); }}
                wrapClassName="modal-ticket-create">
                <p>Are you sure to submit the new request? Once submitted you will not be able to edit.</p>
            </Modal>
            <Row className="m_0">
                <Col xl={24} lg={24} md={24} sm={24} xs={24} className="gx-p-0">
                    <div className="float-right" >
                        <RadioGroup onChange={onChange} buttonStyle="solid" defaultValue={travelRequeststatus} disabled={loading}>
                            <RadioButton value="Pending">Pending</RadioButton>
                            <RadioButton value="Approved">Approved</RadioButton>
                            <RadioButton value="Rejected">Rejected</RadioButton>
                        </RadioGroup>
                    </div>
                </Col>
            </Row>
            <Table loading={loading} className="gx-table-responsive" columns={travelRequestColList} dataSource={travelRequestDataList} bordered={false}
                rowClassName={(record, index) => {
                    return record.isAdminCreated === true ? 'admin-row' : ''
                }}
                scroll={{ x: 900 }}
                expandedRowClassName={() => "travel-request-list"}
                expandable={{
                    expandedRowRender: (record) => <>
                    <div className="emp_section">
                        <Row xl={24} lg={24} md={24} sm={24} xs={24}>
                            <Col xl={6} lg={6} md={6} sm={6} xs={6}><b>Project</b> <p>{record.project.name}</p></Col>
                            <Col xl={6} lg={6} md={6} sm={6} xs={6}><b>From</b> <p>{record.fromPlace}</p></Col>
                            <Col xl={6} lg={6} md={6} sm={6} xs={6}><b>To</b> <p>{record.toPlace}</p></Col>
                            <Col xl={6} lg={6} md={6} sm={6} xs={6}><b>Mobile No</b> <p>{record.mobileNumber}</p></Col>
                        </Row>
                        <Row xl={24} lg={24} md={24} sm={24} xs={24}>
                            <Col xl={6} lg={6} md={6} sm={6} xs={6}><b>Journey Date</b> <p>{record.journeyDate && moment(record.journeyDate).format('ll')}</p></Col>
                            <Col xl={6} lg={6} md={6} sm={6} xs={6}><b>Return Journey Date</b> <p>{record.returnJourneyDate && moment(record.returnJourneyDate).format('ll')}</p></Col>
                            <Col xl={6} lg={6} md={6} sm={6} xs={6}><b>Requested Date</b> <p>{record.created_date && moment(record.created_date).format('ll')}</p></Col>
                            <Col xl={6} lg={6} md={6} sm={6} xs={6}><b>is Ad-hoc Request</b> <p>{record.isAdhoc ? 'Yes' : 'No'}</p></Col>
                            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                            <b>Purpose: </b> <p>{
                                (() => {
                                    return record.purpose.split('\n').map((subStr) => {
                                        return (
                                          <>
                                            {subStr}
                                            <br />
                                          </>
                                        );
                                      })
                                })()
                            }
                            </p>
                        </Col>
                        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                            <b>Goals: </b> <p>{
                            (() => {
                                    return record.goals.split('\n').map((subStr) => {
                                        return (
                                          <>
                                            {subStr}
                                            <br />
                                          </>
                                        );
                                      })
                                })()}
                            </p>
                        </Col>
                        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                            <b>Expected Outcome: </b> <p>{
                            (() => {
                                    return record.expectedOutcome.split('\n').map((subStr) => {
                                        return (
                                          <>
                                            {subStr}
                                            <br />
                                          </>
                                        );
                                      })
                                })()}
                            </p>
                        </Col>
                        </Row>
                        </div>
                        <Divider orientation="left"><b>Project Manager</b></Divider>
                        <div className="pm_section">
                        <Row xl={24} lg={24} md={24} sm={24} xs={24}>
                            <Col xl={6} lg={6} md={6} sm={6} xs={6}><b>Name</b> <p>{record.projectManager.displayNameTx}</p></Col>
                            <Col xl={6} lg={6} md={6} sm={6} xs={6}><b>Action Date</b> <p>{record.pmActionDate && moment(record.pmActionDate).format('lll')}</p></Col>
                            <Col xl={12} lg={12} md={12} sm={12} xs={12}><b>Purpose of Business Travel</b> <p>{record.pmComment}</p></Col>
                        </Row>
                        </div>
                        <Divider orientation="left"><b>Reporting Manager</b></Divider>
                        <div className="rm_section">
                        <Row xl={24} lg={24} md={24} sm={24} xs={24}>
                            <Col xl={6} lg={6} md={6} sm={6} xs={6}><b>Name</b> <p>{record.reportingManager.displayNameTx}</p></Col>
                            <Col xl={6} lg={6} md={6} sm={6} xs={6}><b>Action Date</b> <p>{record.rmActionDate && moment(record.rmActionDate).format('lll')}</p></Col>
                            <Col xl={12} lg={12} md={12} sm={12} xs={12}><b>Purpose of Business Travel</b> <p>{record.rmComment}</p></Col>
                        </Row>
                        </div>
                        { record.infraAdmin &&
                        <>
                        <Divider orientation="left"><b>Approved By Infra Admin</b></Divider>
                        <div className="infra_admin_section">
                        <Row xl={24} lg={24} md={24} sm={24} xs={24}>
                            <Col xl={6} lg={6} md={6} sm={6} xs={6}><b>Name</b> <p>{record.infraAdmin.displayNameTx}</p></Col>
                            <Col xl={6} lg={6} md={6} sm={6} xs={6}><b>Action Date</b> <p>{record.infraAdminActionDate && moment(record.infraAdminActionDate).format('lll')}</p></Col>
                            <Col xl={12} lg={12} md={12} sm={12} xs={12}><b>Purpose of Business Travel</b> <p>{record.infraAdminComment}</p></Col>
                        </Row>
                        </div>
                        </>
                        }
                        { record.admin &&
                        <>
                        <Divider orientation="left"><b>Approved By Admin</b></Divider>
                        <div className="admin_section">
                        <Row xl={24} lg={24} md={24} sm={24} xs={24}>
                            <Col xl={6} lg={6} md={6} sm={6} xs={6}><b>Name</b> <p>{record.admin.displayNameTx}</p></Col>
                            <Col xl={6} lg={6} md={6} sm={6} xs={6}><b>Action Date</b> <p>{record.adminActionDate && moment(record.adminActionDate).format('lll')}</p></Col>
                            <Col xl={12} lg={12} md={12} sm={12} xs={12}><b>Purpose of Business Travel</b> <p>{record.adminComment}</p></Col>
                        </Row>
                        </div>
                        </>
                        }
                    </>
                }}
            />

            <Modal
                title="Approve Comments"
                centered
                visible={approvePop}
                width={600}
                closable={false}
                destroyOnClose={true}
                footer={null}
            >
                <Auxiliary>
                    <Row className="m_0 buttonGroup">
                        <Col xl={8} lg={8} md={8} sm={8} xs={8} className="gx-p-0">
                            <p className="gx-mb-1 gx-mt-1">Purpose of Business Travel:</p>
                        </Col>
                        <Col xl={16} lg={16} md={16} sm={16} xs={16} className="gx-p-0">
                            <TextArea placeholder="Justify your comments" onChange={e => handleApproveComments(e)} rows={4} value={approveComments} maxLength={255} />
                            <p style={{ textAlign: 'right' }}> {`${approveTextAreaCount} / 255`} </p>
                        </Col>
                    </Row>
                    <Row className="m_0 buttonGroup">
                        <Col xl={24} lg={24} md={24} sm={24} xs={24} className="gx-p-0">
                            <Button type="primary" className="save_btn" htmlType="submit" onClick={onApprove}>Approve</Button>
                            <Button type="primary" className="add-btn gx-mr-2" onClick={() => { setApproveComments(""); setApproveTextAreaCount(0); setApprovePop(false); }}>Cancel</Button>
                        </Col>
                    </Row>
                </Auxiliary>
            </Modal>

            <Modal
                title="Reject Comments"
                centered
                visible={rejectPop}
                closable={false}
                width={600}
                destroyOnClose={true}
                footer={null}
            >
                <Auxiliary>
                    <Row className="m_0 buttonGroup">
                        <Col xl={8} lg={8} md={8} sm={8} xs={8} className="gx-p-0">
                            <p className="gx-mb-1 gx-mt-1">Purpose of Business Travel:</p>
                        </Col>
                        <Col xl={16} lg={16} md={16} sm={16} xs={16} className="gx-p-0">
                            <TextArea placeholder="Justify your comments" onChange={e => handleRejectComments(e)} rows={4} value={rejectComments} maxLength={255} />
                            <p style={{ textAlign: 'right' }}> {`${rejectTextAreaCount} / 255`} </p>
                        </Col>
                    </Row>
                    <Row className="m_0 buttonGroup">
                        <Col xl={24} lg={24} md={24} sm={24} xs={24} className="gx-p-0">
                            <Button type="primary" className="save_btn" htmlType="submit" onClick={onReject}>Reject</Button>
                            <Button type="primary" className="add-btn gx-mr-2" onClick={() => { setRejectComments(""); setRejectTextAreaCount(0); setRejectPop(false); }}>Cancel</Button>
                        </Col>
                    </Row>
                </Auxiliary>
            </Modal>

        </Auxiliary>
    );
};

export default TravelDeskInfraAdminApprove;