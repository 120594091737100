import React, { useEffect, useState } from "react";
import {
  Button,
  Row,
  Col,
  Form,
  Table,
  Input,
  Select,
  InputNumber,
  DatePicker,
  message,
  Card,
} from "antd";
import { API_URL } from "constants/APIConfig";
import axios from "axios";
import moment from "moment";
import { FormOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";

const { Option } = Select;
const InputGroup = Input.Group;
const { TextArea } = Input;
const { RangePicker } = DatePicker;

const token = sessionStorage.getItem("token");
function handleChange(value) {
  console.log(`selected ${value}`);
}

function handleBlur() {
  console.log("blur");
}

function handleFocus() {
  console.log("focus");
}

const handleResourceType = (e) => {};

const handleModules = (e) => {};
let engageType = "";
const handleEnagementType = (e) => {};

const handleExperience = (e) => {};

const EditPlannedEfforts = (props) => {
  let history = useHistory();
  const [projEfforts, setProjEfforts] = useState([]);
  const [projId, setProjId] = useState([]);
  const [modules, setModules] = useState([]);

  const getModulesList = () => {
    axios
      .get(API_URL + "/api/masters/modules/getall", {
        headers: {
          "x-access-token": `${token}`,
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((response) => {
        console.log("Modules List", response.data.data);
        setModules(response.data.data);
      })
      .catch((error) => {
        // Error
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log("Error", error.message);
        }
        console.log(error.config);
      });
  };

  const getProjEffortsById = () => {
    axios
      .get(
        API_URL +
          "/api/project_initiation/plannedefforts/byId/" +
          props.match.params.id,
        {
          headers: {
            "x-access-token": `${token}`,
            "Access-Control-Allow-Origin": "*",
          },
        }
      )
      .then((response) => {
        console.log("Planned Efforts By Id", response.data.data);
        setProjId(response.data.data.projectId);
        setProjEfforts([
          ...projEfforts,
          {
            id: response.data.data.id,
            projectId: response.data.data.projectId,
            engagementType: response.data.dataengagementType,
            resourceType: response.data.data.resourceType,
            module: response.data.data.module,
            experience: response.data.data.experience,
            efforts: response.data.data.efforts,
            startDate: moment(response.data.data.startDate).format(
              "DD-MM-YYYY"
            ),
            endDate: moment(response.data.dataendDate).format("DD-MM-YYYY"),
          },
        ]);

        form.setFieldsValue({
          effortDuration: [
            response.data.data.startDate
              ? moment(response.data.data.startDate)
              : null,
            response.data.data.endDate
              ? moment(response.data.data.endDate)
              : null,
          ],
          engageType: response.data.data.engagementType
            ? response.data.data.engagementType
            : null,
          effortHours: response.data.data.efforts
            ? response.data.data.efforts
            : null,
          moduleType: response.data.data.module
            ? response.data.data.module
            : null,
          experience: response.data.data.experience
            ? response.data.data.experience
            : null,
          resourcetype: response.data.data.resourceType
            ? response.data.data.resourceType
            : null,
        });
      })
      .catch((error) => {
        // Error
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log("Error", error.message);
        }
        console.log(error.config);
      });
    // }
  };

  const [form] = Form.useForm();

  const onFinish = (values) => {
    const dates = form.getFieldValue("effortDuration");
    axios
      .put(
        API_URL +
          `/api/project_initiation/plannedefforts/update/${props.match.params.id}`,{
            projectId: projId,
            module: form.getFieldValue("moduleType"),
            experience: form.getFieldValue("experience"),
            resourceType: form.getFieldValue("resourcetype"),
            engagementType: form.getFieldValue("engageType"),
            efforts: form.getFieldValue("effortHours"),
            startDate: dates[0].format("YYYY-MM-DD"),
            endDate: dates[1].format("YYYY-MM-DD")
          },
         {
          headers: {
            "x-access-token": `${token}`,
            "Access-Control-Allow-Origin": "*",
          },
        }
      )
      .then((response) => {
        message.success("Project efforts are added Successfully");
        form.resetFields();
         history.push("/master/projects/efforts/manage");
      })
      .catch((error) => {
        // Error
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log("Error", error.message);
        }
        console.log(error.config);
        message.error("Error In creating Schedule", error);
      });
  };
  useEffect(() => {
    getModulesList();
    getProjEffortsById();
  }, []);
  return (
    <Form
      form={form}
      initialValues={{ remember: true }}
      name="proj_efforts"
      onFinish={onFinish}
      layout="inline"
      align="center"
    >
      <Card title="Planned Efforts" size='small' bordered={false} style={{ width: 1300 }}>
        <Row>
          <Col lg={4} md={4} sm={12} xs={24}>
            <div className="gx-form-row0">
              <h6 align="left">Engagement Type</h6>
              <Form.Item
                name="engageType"
                // label="Project Name"
                rules={[
                  {
                    required: true,
                    message: "Enter name",
                  },
                ]}
              >
                <Select
                  showSearch
                  style={{ width: "100%" }}
                  placeholder="Select a Value"
                  optionFilterProp="children"
                  onChange={handleEnagementType}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  <Option value={"Onsite"}>Onsite</Option>
                  <Option value={"Offshore"}>Offshore</Option>
                </Select>
              </Form.Item>
            </div>
          </Col>
          <Col lg={4} md={4} sm={12} xs={24}>
            <div className="gx-form-row0">
              <h6 align="left">Resource Type</h6>
              <Form.Item
                name="resourcetype"
                rules={[
                  {
                    required: true,
                    message: "Enter Resource Type",
                  },
                ]}
              >
                <Select
                  showSearch
                  style={{ width: "100%" }}
                  placeholder="Select a Value"
                  optionFilterProp="children"
                  onChange={handleResourceType}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  <Option value={"Junior"}>Junior</Option>
                  <Option value={"Senior"}>Senior</Option>
                </Select>
              </Form.Item>
            </div>
          </Col>
          <Col lg={8} md={8} sm={8} xs={8}>
            <div className="gx-form-row0">
              <h6 align="left">Module</h6>
              <Form.Item
                name="moduleType"
                //label="Billable"
                rules={[
                  {
                    required: true,
                    message: "Enter project billable",
                  },
                ]}
              >
                <Select
                  showSearch
                  style={{ width: "100%" }}
                  placeholder="Select a Value"
                  optionFilterProp="children"
                  onChange={handleModules}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {modules.length
                    ? modules.map((module, index) => (
                        <Option key={index} value={module.name}>
                          {module.name}
                        </Option>
                      ))
                    : null}
                </Select>
              </Form.Item>
            </div>
          </Col>
          <Col lg={4} md={4} sm={12} xs={24}>
            <div className="gx-form-row0">
              <h6 align="left">Experience Level</h6>
              <Form.Item
                name="experience"
                // label="Project Name"
                rules={[
                  {
                    required: true,
                    message: "Enter Experience",
                  },
                ]}
              >
                <Select
                  showSearch
                  style={{ width: "100%" }}
                  placeholder="Select a Value"
                  optionFilterProp="children"
                  onChange={handleExperience}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  <Option value={"1"}>1+</Option>
                  <Option value={"2"}>2+</Option>
                  <Option value={"3"}>3+</Option>
                  <Option value={"4"}>4+</Option>
                </Select>
              </Form.Item>
            </div>
          </Col>

          <Col lg={4} md={4} sm={12} xs={24}>
            <div className="gx-form-row0">
              <h6 align="left">Est. Hours</h6>
              <Form.Item
                name="effortHours"
                //label="Budget"
                rules={[
                  {
                    required: true,
                    message: "Please maintain total hours",
                  },
                ]}
              >
                <InputNumber placeholder="hours" />
              </Form.Item>
            </div>
          </Col>
          <Col lg={8} md={8} sm={12} xs={24}>
            <div className="gx-form-row0">
              <h6 align="left">Duration</h6>
              <Form.Item
                name="effortDuration"
                rules={[
                  {
                    required: true,
                    message: "Please maintain total hours",
                  },
                ]}
              >
                <RangePicker className="gx-mb-3 gx-w-100" />
              </Form.Item>
            </div>
          </Col>
          <Col lg={24} md={24} sm={12} xs={24}>
            <div className="gx-form-row0">
              <h6 align="left">Remarks</h6>
              <Form.Item
                name="remarks"
                rules={[
                  {
                    required: true,
                    message: "Enter Remarks",
                  },
                ]}
              >
                <TextArea rows={4} />
              </Form.Item>
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg={24} md={24} sm={24} xs={24}>
            {/*   <div className="newprojectBtn"> */}
            {/* <Button type="text" onClick={handleAddEfforts} className="gx-mt-3 addBtn"><i class="fa fa-plus" aria-hidden="true"></i>Save</Button> */}
            <Button type="primary" className="add-btn" onClick={onFinish}>
              Submit
            </Button>
            {/*  </div> */}
          </Col>
        </Row>
      </Card>
    </Form>
  );
};
export default EditPlannedEfforts;
