import React, { useEffect, useState } from "react";
import { Button, Row, Col, Form, Input, Select, Radio, Table, DatePicker, message, InputNumber, Popconfirm } from "antd";
import axios from 'axios';
import moment from "moment";
import { API_URL } from 'constants/APIConfig';
import Auxiliary from "util/Auxiliary";
import { useHistory } from "react-router-dom";
import Widget from "components/Widget/index";

const { Option } = Select;
const { RangePicker } = DatePicker;
const { TextArea } = Input;


function handleChange(value) {
  console.log(`selected ${value}`);
}

function handleBlur() {
  console.log('blur');
}

function handleFocus() {
  console.log('focus');
}

const ProjPlan = (props) => {
  let history = useHistory();
  const userDetails = JSON.parse(sessionStorage.getItem('user_details'));
  const token = sessionStorage.getItem('token');
  const [projects, setProjects] = useState([]);
  const [projectId, setProjectId] = useState(0);
  const [users, setUsers] = useState([]);


  const [form] = Form.useForm();


  const onProjectChange = value => {
    setProjectId(value);
  };

  const getProjectList = () => {
    axios.get(API_URL + `/api/project/v2/projectsByManager?userId=${userDetails.id}`, 
    {headers: {
      'x-access-token': `${token}`, 
    'Access-Control-Allow-Origin': '*'}
   })
      .then(response => {
        if (Object.keys(response.data.data).length > 0) {
          setProjects(response.data.data);
        }
      })
      .catch(error => {
        // Error
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log('Error', error.message);
        }
        console.log(error.config);
      });
  }
  useEffect(() => {
    getProjectList();
  }, [])


  const onFinish = () => {
    if (projectId != 0) {
    const dates = form.getFieldValue('duration');
    const newData = {
      projectIdNm: +projectId,
      duration: form.getFieldValue('days'),
      assignedTo: form.getFieldValue('assignedto'),
      titleTx: form.getFieldValue('taskname'),
      milestoneType: 'DELIVERY',
      deliveryMilestoneStatus: form.getFieldValue('status'),
      startDateDt: dates[0].format("YYYY-MM-DD"),
      endDateDt: dates[1].format("YYYY-MM-DD"),
      comments: form.getFieldValue('comments'),
    };
    axios.post(API_URL + '/api/project_milestones/create', [newData], 
    {headers: {
      'x-access-token': `${token}`, 
    'Access-Control-Allow-Origin': '*'}
   })
      .then(response => {
        message.success("Schedule Created Successfully");
        history.push("/plan/manage");
      })
      .catch(error => {
        // Error
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log('Error', error.message);
        }
        console.log(error.config);
        message.error("Error in creating Plan", error)
      });

    form.resetFields();
  } else if(projectId == 0) {
      message.error("Please select the project");
  }
  }
  const getUserList = () => {
    axios.get(API_URL + '/api/users/getall', 
    {headers: {
      'x-access-token': `${token}`, 
    'Access-Control-Allow-Origin': '*'}
   })
      .then(response => {
        console.log('User List', response.data.data)
        setUsers(response.data.data);
      })
      .catch(error => {
        console.log(error)
      });
  }

  useEffect(() => {
    getUserList();
  }, [])

  return (
    <Auxiliary>
      <Form
        form={form}
        initialValues={{ remember: true }}
        name="prjplan"
        onFinish={onFinish}
        //onFinishFailed={this.onFinishFailed}
        layout="inline"
        align="center"
      >
      <Row gutter={24} style={{width:"100%"}}>
        <Col lg={16} md={12} sm={24} xs={24}  >
        </Col>
        <Col lg={8} md={12} sm={24} xs={24}  >
          <div className="gx-form-row0" >
            <Form.Item
              name="prjname"
              // label="Project Name"
              rules={[
                {
                  required: true,
                  message: 'Enter project name',
                },
              ]}
            >
              <Select
                showSearch
                style={{ width: '100%' }}
                placeholder="Projects"
                optionFilterProp="children"
                onChange={onProjectChange}
                // size="small"
                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              >
                {
                  projects.length ? projects.map((project, index) => (
                    <Option key={index} value={project.id}>{project.name}</Option>
                  )) : null
                }
              </Select>
            </Form.Item>
          </div>
        </Col>
      </Row>
        <Row gutter={24}>
          <Col lg={24} md={24} sm={24} xs={24}  >
            <Widget>
              <Row gutter={24}>
                <Col lg={8} md={8} sm={12} xs={24} >
                  <div className="gx-form-row0">
                    <h6 align="left">Name</h6>
                    <Form.Item
                      name="taskname"
                      // label="Project Name"
                      rules={[
                        {
                          required: true,
                          message: 'Enter task name',
                        },
                      ]}
                    >
                      <Input placeholder="Name" />

                    </Form.Item>
                  </div>
                </Col>
                <Col lg={8} md={8} sm={12} xs={24} >
                  <div className="gx-form-row0">
                    <h6 align="left">Parent</h6>
                    <Form.Item
                      name="parent"
                      //label="Billable"
                      rules={[
                        {
                          required: true,
                          message: 'Enter parent',
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        style={{ width: '100%' }}
                        placeholder="Users"
                        optionFilterProp="children"
                        onChange={handleChange}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        mode="multiple"
                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                      >
                        {users.length ? users.map((user, index) => (
                          <Option key={index} value={user.id}>{user.displayNameTx}</Option>
                        )) : null}
                      </Select>
                    </Form.Item>
                  </div>
                </Col>
                <Col lg={8} md={8} sm={12} xs={24} >
                  <div className="gx-form-row0">
                    <h6 align="left">Assigned To</h6>
                    <Form.Item
                      name="assignedto"
                      //label="Billable"
                      rules={[
                        {
                          required: true,
                          message: 'Assign users',
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        style={{ width: '100%' }}
                        placeholder="Users"
                        optionFilterProp="children"
                        onChange={handleChange}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        mode="multiple"
                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                      >
                        {users.length ? users.map((user, index) => (
                          <Option key={index} value={user.id}>{user.displayNameTx}</Option>
                        )) : null}
                      </Select>
                    </Form.Item>
                  </div>
                </Col>
                <Col lg={8} md={8} sm={12} xs={24} >
                  <div className="gx-form-row0">
                    <h6 align="left">Class</h6>
                    <Form.Item
                      name="pclass"
                      //label="Billable"
                      rules={[
                        {
                          required: true,
                          message: 'Class',
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        style={{ width: '100%' }}
                        placeholder="Users"
                        optionFilterProp="children"
                        onChange={handleChange}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        mode="multiple"
                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                      >
                        {users.length ? users.map((user, index) => (
                          <Option key={index} value={user.id}>{user.displayNameTx}</Option>
                        )) : null}
                      </Select>
                    </Form.Item>
                  </div>
                </Col>
                <Col lg={8} md={8} sm={12} xs={24} >
                  <div className="gx-form-row0">
                    <h6 align="left">Dependent</h6>
                    <Form.Item
                      name="depend"
                      //label="Billable"
                      rules={[
                        {
                          required: true,
                          message: 'Dependent',
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        style={{ width: '100%' }}
                        placeholder="Users"
                        optionFilterProp="children"
                        onChange={handleChange}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        mode="multiple"
                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                      >
                        {users.length ? users.map((user, index) => (
                          <Option key={index} value={user.id}>{user.displayNameTx}</Option>
                        )) : null}
                      </Select>
                    </Form.Item>
                  </div>
                </Col>
    
                <Col lg={8} md={8} sm={12} xs={24} >
                  <div className="gx-form-row0">
                    <h6 align="left">Planned Dates</h6>
                    <Form.Item
                      name="planduration"
                      //label="Duration"
                      rules={[
                        {
                          required: true,
                          message: 'Enter planned dates',
                        },
                      ]}
                    >
                      <RangePicker className="gx-mb-3 gx-w-100" />
                    </Form.Item>
                  </div>
                </Col>
                <Col lg={8} md={8} sm={12} xs={24} >
                  <div className="gx-form-row0">
                    <h6 align="left">Actual Dates</h6>
                    <Form.Item
                      name="actualduration"
                      //label="Duration"
                      rules={[
                        {
                          required: true,
                          message: 'Enter actual dates',
                        },
                      ]}
                    >
                      <RangePicker className="gx-mb-3 gx-w-100" />
                    </Form.Item>
                  </div>
                </Col>
                <Col lg={8} md={8} sm={12} xs={24} >
                  <div className="gx-form-row0">
                    <h6 align="left">Duration (Hours)</h6>
                    <Form.Item
                      name="days"
                      //label="Billable"
                      rules={[
                        {
                          required: true,
                          message: 'Enter duration in hours',
                        },
                      ]}
                    >
                      <InputNumber placeholder="Duration" />
                    </Form.Item>
                  </div>
                </Col>
                <Col lg={8} md={8} sm={12} xs={24} >
                  <div className="gx-form-row0">
                    <h6 align="left">Completion (%)</h6>
                    <Form.Item
                      name="completion"
                      //label="Billable"
                      rules={[
                        {
                          required: true,
                          message: 'Enter duration in hours',
                        },
                      ]}
                    >
                      <InputNumber placeholder="Duration" />
                    </Form.Item>
                  </div>
                </Col>
                <Col lg={8} md={8} sm={12} xs={24} >
                  <div className="gx-form-row0">
                    <h6 align="left">Caption</h6>
                    <Form.Item
                      name="caption"
                      //label="Billable"
                      rules={[
                        {
                          required: true,
                          message: 'Enter caption',
                        },
                      ]}
                    >
                      <Input placeholder="Caption" />
                    </Form.Item>
                  </div>
                </Col>
                <Col lg={8} md={8} sm={12} xs={24} >
                  <h6 align="left">Status</h6>
                  <div className="gx-form-row0">
                    <Form.Item
                      name="status"
                      //label="Billable"
                      rules={[
                        {
                          required: true,
                          message: 'Enter Status',
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        style={{ width: '100%' }}
                        placeholder="Select a Value"
                        optionFilterProp="children"
                        onChange={handleChange}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                      >
                        <Option value="New">New</Option>
                        <Option value="Progress">In Progress</Option>
                        <Option value="Hold">On Hold</Option>
                        <Option value="Completed">Completed</Option>
                      </Select>
                    </Form.Item>
                  </div>
                </Col>
                <Col lg={24} md={24} sm={24} xs={24} >
                  <div className="gx-form-row0">
                    <h6 align="left">Comments</h6>
                    <Form.Item
                      name="comments"
                      // label="Project Name"
                      rules={[
                        {
                          required: true,
                          message: 'Enter Comments',
                        },
                      ]}
                    >
                      <TextArea rows={4} />
                    </Form.Item>
                  </div>
                </Col>
                <Col lg={24} md={24} sm={24} xs={24} >
                  <div className="gx-form-row0">
                    <h6 align="left">Notes</h6>
                    <Form.Item
                      name="notes"
                      // label="Project Name"
                      rules={[
                        {
                          required: true,
                          message: 'Enter Notes',
                        },
                      ]}
                    >
                      <TextArea rows={4} />
                    </Form.Item>
                  </div>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col lg={24} md={24} sm={24} xs={24} >
                  <Button type="primary" className="add-btn" htmlType="submit">Submit</Button>
                  <Button type="primary" className="add-btn gx-mr-2" onClick={() => history.push('/plan/manage')}>Back</Button>
                </Col>
              </Row>
            </Widget>
          </Col>
        </Row>
      </Form>
    </Auxiliary>
  );
};
export default ProjPlan;
