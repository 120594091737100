import React, { useEffect, useState } from "react";
import { Col, Row, Form, Table, Breadcrumb, Cascader, Button, Tabs, Card, message, Switch, Avatar } from "antd";
import Auxiliary from "util/Auxiliary";
import axios from 'axios';
import { API_URL } from 'constants/APIConfig';
import moment from "moment";

const { Column, ColumnGroup } = Table;
const TabPane = Tabs.TabPane;

const RMDashboardProjects = (props) => {
    const [form] = Form.useForm();
    const userDetails = JSON.parse(sessionStorage.getItem('user_details'))
    const token = sessionStorage.getItem('token')
    const [allReportees, setAllReportees] = useState([])

    const [previousUserId, setPreviousUserId] = useState([])

    const [fourWeeksResourceWise, setFourWeeksResourceWise] = useState([])
    const [threeMonthsResourceWise, setThreeMonthsResourceWise] = useState([])
    const [sixMonthsResourceWise, setSixMonthsResourceWise] = useState([])
    const [oneYearResourceWise, setOneYearResourceWise] = useState([])

    const [fourWeeksProjectDetailsData, setFourWeeksProjectDetailsData] = useState([])
    const [threeMonthsProjectDetailsData, setThreeMonthsProjectDetailsData] = useState([])
    const [sixMonthsProjectDetailsData, setSixMonthsProjectDetailsData] = useState([])
    const [oneYearProjectDetailsData, setOneYearProjectDetailsData] = useState([])

    const [loader, setLoader] = useState(false)
    const [selectedCard, setSelectedCard] = useState('4weeks')

    const fourWeeks = [{
        y: moment().subtract(1, 'weeks').year(), w: moment().subtract(1, 'weeks').format('wo'),
        s: moment().subtract(1, 'weeks').startOf('week').format('YYYY-MMM-DD'),
        e: moment().subtract(1, 'weeks').endOf('week').format('YYYY-MMM-DD')
    },
    {
        y: moment().subtract(2, 'weeks').year(), w: moment().subtract(2, 'weeks').format('wo'),
        s: moment().subtract(2, 'weeks').startOf('week').format('YYYY-MMM-DD'),
        e: moment().subtract(2, 'weeks').endOf('week').format('YYYY-MMM-DD')
    },
    {
        y: moment().subtract(3, 'weeks').year(), w: moment().subtract(3, 'weeks').format('wo'),
        s: moment().subtract(3, 'weeks').startOf('week').format('YYYY-MMM-DD'),
        e: moment().subtract(3, 'weeks').endOf('week').format('YYYY-MMM-DD')
    },
    {
        y: moment().subtract(4, 'weeks').year(), w: moment().subtract(4, 'weeks').format('wo'),
        s: moment().subtract(4, 'weeks').startOf('week').format('YYYY-MMM-DD'),
        e: moment().subtract(4, 'weeks').endOf('week').format('YYYY-MMM-DD')
    }]

    const threeMonths = [{
        s: moment().startOf('month').subtract(1, 'months').format('YYYY-MMM-DD'),
        e: moment().startOf('month').subtract(1, 'months').endOf('month').format('YYYY-MMM-DD')
    },
    {
        s: moment().startOf('month').subtract(2, 'months').format('YYYY-MMM-DD'),
        e: moment().startOf('month').subtract(2, 'months').endOf('month').format('YYYY-MMM-DD')
    },
    {
        s: moment().startOf('month').subtract(3, 'months').format('YYYY-MMM-DD'),
        e: moment().startOf('month').subtract(3, 'months').endOf('month').format('YYYY-MMM-DD')
    }]

    const sixMonths = [{
        s: moment().startOf('month').subtract(6, 'months').format('YYYY-MMM-DD'),
        e: moment().startOf('month').subtract(1, 'months').endOf('month').format('YYYY-MMM-DD')
    },
    {
        s: moment().startOf('month').subtract(12, 'months').format('YYYY-MMM-DD'),
        e: moment().startOf('month').subtract(7, 'months').endOf('month').format('YYYY-MMM-DD')
    }]

    const oneYear = [{
        s: moment().startOf('month').subtract(12, 'months').format('YYYY-MMM-DD'),
        e: moment().startOf('month').subtract(1, 'months').endOf('month').format('YYYY-MMM-DD')
    }]

    const calculateDays = (s, e) => {
        let s1 = moment(s)
        let e1 = moment(e)
        let d1 = 0
        while (s1.isBefore(e1) || s1.isSame(e1)) {
            if (s1.weekday() !== 0 && s1.weekday() !== 6) {
                d1++
            }
            s1 = s1.add(1, 'day')
        }
        d1 = d1 * 8 * 60
        return d1
    }

    const getAllReportees = (val) => {
        axios.get(API_URL + `/api/rm_dashboard/getAllReportees/${userDetails.id}?inActive=${val}&t=${new Date().getTime()}`,
            {
                headers: {
                    'x-access-token': `${token}`,
                    'Access-Control-Allow-Origin': '*'
                }
            })
            .then(response => {
                let gData = {}
                if (Object.keys(response.data.data).length > 0) {
                    let gNo = 0
                    response.data.data.forEach(d => {
                        d.key = d.value
                        if (gData[d.levelNo]) {
                            gData[d.levelNo].push(d)
                        } else {
                            gData[d.levelNo] = [d]
                        }
                        if (d.levelNo > gNo) {
                            gNo = d.levelNo
                        }
                        if (d.activate_yn_nm === 0) {
                            d.label = (<span style={{ color: "red" }} title={d.label + ' - Resigned'}>
                                <Avatar className="gx-size-20 gx-align-self-end" src={require("assets/images/resignation.png")} alt="Resigned" />
                                &nbsp;{d.label}</span>)
                        }
                    })

                    for (let i = gNo; i > 0; i--) {
                        gData[i].forEach(c => {
                            gData[i - 1].forEach(p => {
                                if (p.value == c.reporting_manager_nm) {
                                    if (p.children) {
                                        p.children.push(c)
                                    } else {
                                        p.children = [c]
                                    }
                                }
                            })
                        })
                    }
                }
                setAllReportees([gData[0][0]])
            })
            .catch(error => {
                console.log(error);
            });
    }

    const convertHoursToMinutes = (strTime) => {
        let [h, m] = strTime.split(":");
        return Number(h) * 60 + Number(m);
    }

    const convertMinutesToHours = (val) => {
        let m = val % 60;
        let h = (val - m) / 60;
        h = h < 10 ? "0" + h : h;
        m = m < 10 ? "0" + m : m;
        return `${h}:${m}`;
    }

    const get4WeeksResourceWiseSummary = (id) => {
        setLoader(true)
        axios.get(API_URL + `/api/rm_dashboard/get4WeeksResourceWiseSummary/${id}?t=${new Date().getTime()}`,
            {
                headers: {
                    'x-access-token': `${token}`,
                    'Access-Control-Allow-Origin': '*'
                }
            })
            .then(response => {
                let empData = {}
                if (Object.keys(response.data.data).length > 0) {
                    let data0 = response.data.data[0].data
                    data0.forEach(d => {
                        let t = empData[d.employee_id] || {}
                        t.empId = d.employee_id
                        t.name = d.name
                        if (d.project === 'Project') {
                            t.projectHours1 = d.hours.substr(0, 5)
                        } else if (d.project === 'Non-Project') {
                            t.nonProjectHours1 = d.hours.substr(0, 5)
                        } else {
                            t.othersHours1 = d.hours.substr(0, 5)
                        }
                        empData[d.employee_id] = t
                    })

                    let data1 = response.data.data[1].data
                    data1.forEach(d => {
                        let t = empData[d.employee_id] || {}
                        t.empId = d.employee_id
                        t.name = d.name
                        if (d.project === 'Project') {
                            t.projectHours2 = d.hours.substr(0, 5)
                        } else if (d.project === 'Non-Project') {
                            t.nonProjectHours2 = d.hours.substr(0, 5)
                        } else {
                            t.othersHours2 = d.hours.substr(0, 5)
                        }
                        empData[d.employee_id] = t
                    })

                    let data2 = response.data.data[2].data
                    data2.forEach(d => {
                        let t = empData[d.employee_id] || {}
                        t.empId = d.employee_id
                        t.name = d.name
                        if (d.project === 'Project') {
                            t.projectHours3 = d.hours.substr(0, 5)
                        } else if (d.project === 'Non-Project') {
                            t.nonProjectHours3 = d.hours.substr(0, 5)
                        } else {
                            t.othersHours3 = d.hours.substr(0, 5)
                        }
                        empData[d.employee_id] = t
                    })

                    let data3 = response.data.data[3].data
                    data3.forEach(d => {
                        let t = empData[d.employee_id] || {}
                        t.empId = d.employee_id
                        t.name = d.name
                        if (d.project === 'Project') {
                            t.projectHours4 = d.hours.substr(0, 5)
                        } else if (d.project === 'Non-Project') {
                            t.nonProjectHours4 = d.hours.substr(0, 5)
                        } else {
                            t.othersHours4 = d.hours.substr(0, 5)
                        }
                        empData[d.employee_id] = t
                    })

                    Object.values(empData).forEach(e => {
                        e.key = e.empId
                        if (!e.nonProjectHours1) {
                            e.nonProjectHours1 = '00:00'
                        }
                        if (!e.projectHours1) {
                            e.projectHours1 = '00:00'
                        }
                        if (!e.othersHours1) {
                            e.othersHours1 = '00:00'
                        }
                        e.total1 = convertHoursToMinutes(e.nonProjectHours1) + convertHoursToMinutes(e.projectHours1) + convertHoursToMinutes(e.othersHours1)
                        let u1 = ((convertHoursToMinutes(e.projectHours1) / (40 * 60)) * 100)
                        e.uilization1 = (isNaN(u1) ? '0' : u1 === 0 ? '0' : u1.toFixed(2)) + '%'
                        e.total1 = convertMinutesToHours(e.total1)

                        if (!e.nonProjectHours2) {
                            e.nonProjectHours2 = '00:00'
                        }
                        if (!e.projectHours2) {
                            e.projectHours2 = '00:00'
                        }
                        if (!e.othersHours2) {
                            e.othersHours2 = '00:00'
                        }
                        e.total2 = convertHoursToMinutes(e.nonProjectHours2) + convertHoursToMinutes(e.projectHours2) + convertHoursToMinutes(e.othersHours2)
                        let u2 = ((convertHoursToMinutes(e.projectHours2) / (40 * 60)) * 100)
                        e.uilization2 = (isNaN(u2) ? '0' : u2 === 0 ? '0' : u2.toFixed(2)) + '%'
                        e.total2 = convertMinutesToHours(e.total2)

                        if (!e.nonProjectHours3) {
                            e.nonProjectHours3 = '00:00'
                        }
                        if (!e.projectHours3) {
                            e.projectHours3 = '00:00'
                        }
                        if (!e.othersHours3) {
                            e.othersHours3 = '00:00'
                        }
                        e.total3 = convertHoursToMinutes(e.nonProjectHours3) + convertHoursToMinutes(e.projectHours3) + convertHoursToMinutes(e.othersHours3)
                        let u3 = ((convertHoursToMinutes(e.projectHours3) / (40 * 60)) * 100)
                        e.uilization3 = (isNaN(u3) ? '0' : u3 === 0 ? '0' : u3.toFixed(2)) + '%'
                        e.total3 = convertMinutesToHours(e.total3)

                        if (!e.nonProjectHours4) {
                            e.nonProjectHours4 = '00:00'
                        }
                        if (!e.projectHours4) {
                            e.projectHours4 = '00:00'
                        }
                        if (!e.othersHours4) {
                            e.othersHours4 = '00:00'
                        }
                        e.total4 = convertHoursToMinutes(e.nonProjectHours4) + convertHoursToMinutes(e.projectHours4) + convertHoursToMinutes(e.othersHours4)
                        let u4 = ((convertHoursToMinutes(e.projectHours4) / (40 * 60)) * 100)
                        e.uilization4 = (isNaN(u4) ? '0' : u4 === 0 ? '0' : u4.toFixed(2)) + '%'
                        e.total4 = convertMinutesToHours(e.total4)

                        Object.keys(e).forEach(k => {
                            let v = e[k]
                            if (v.replaceAll && v.replaceAll(':', '').replaceAll('%', '') == 0) {
                                e[k] = ''
                            }
                        })
                    })
                }
                setFourWeeksResourceWise(Object.values(empData))
                setLoader(false)
            })
            .catch(error => {
                setFourWeeksResourceWise([])
                setLoader(false)
                message.error('Error occured')
                console.log(error)
            });
    }

    const get4WeeksProjectWiseDetails = (id) => {
        setLoader(true)
        axios.get(API_URL + `/api/rm_dashboard/get4WeeksProjectWiseDetails/${id}?t=${new Date().getTime()}`,
            {
                headers: {
                    'x-access-token': `${token}`,
                    'Access-Control-Allow-Origin': '*'
                }
            })
            .then(response => {
                let empData = {}
                if (Object.keys(response.data.data).length > 0) {
                    let data0 = response.data.data[0].data
                    data0.forEach(d => {
                        let t = empData[d.employee_id + '-' + d.project_id] || {
                            empId: d.employee_id,
                            name: d.name,
                            projectType: d.projectType,
                            project: d.project,
                            projectId: d.project_id,
                            key: d.employee_id + '-' + d.project_id
                        }
                        t.w0hours = d.hours.substr(0, 5)
                        empData[d.employee_id + '-' + d.project_id] = t
                    })

                    let data1 = response.data.data[1].data
                    data1.forEach(d => {
                        let t = empData[d.employee_id + '-' + d.project_id] || {
                            empId: d.employee_id,
                            name: d.name,
                            projectType: d.projectType,
                            project: d.project,
                            projectId: d.project_id,
                            key: d.employee_id + '-' + d.project_id
                        }
                        t.w1hours = d.hours.substr(0, 5)
                        empData[d.employee_id + '-' + d.project_id] = t
                    })

                    let data2 = response.data.data[2].data
                    data2.forEach(d => {
                        let t = empData[d.employee_id + '-' + d.project_id] || {
                            empId: d.employee_id,
                            name: d.name,
                            projectType: d.projectType,
                            project: d.project,
                            projectId: d.project_id,
                            key: d.employee_id + '-' + d.project_id
                        }
                        t.w2hours = d.hours.substr(0, 5)
                        empData[d.employee_id + '-' + d.project_id] = t
                    })

                    let data3 = response.data.data[3].data
                    data3.forEach(d => {
                        let t = empData[d.employee_id + '-' + d.project_id] || {
                            empId: d.employee_id,
                            name: d.name,
                            projectType: d.projectType,
                            project: d.project,
                            projectId: d.project_id,
                            key: d.employee_id + '-' + d.project_id
                        }
                        t.w3hours = d.hours.substr(0, 5)
                        empData[d.employee_id + '-' + d.project_id] = t
                    })

                    Object.values(empData).forEach(e => {
                        if (!e.w0hours) {
                            e.w0hours = '00:00'
                        }
                        if (!e.w1hours) {
                            e.w1hours = '00:00'
                        }
                        if (!e.w2hours) {
                            e.w2hours = '00:00'
                        }
                        if (!e.w3hours) {
                            e.w3hours = '00:00'
                        }
                        e.total = convertHoursToMinutes(e.w0hours) + convertHoursToMinutes(e.w1hours) + convertHoursToMinutes(e.w2hours) + convertHoursToMinutes(e.w3hours)
                        e.total = convertMinutesToHours(e.total)

                        Object.keys(e).forEach(k => {
                            let v = e[k]
                            if (v.replaceAll && v.replaceAll(':', '').replaceAll('%', '') == 0) {
                                e[k] = ''
                            }
                        })
                    })
                }
                setFourWeeksProjectDetailsData(Object.values(empData))
                setLoader(false)
            })
            .catch(error => {
                setFourWeeksProjectDetailsData([])
                setLoader(false)
                message.error('Error occured')
                console.log(error)
            });
    }

    const get3MonthsResourceWiseSummary = (id) => {
        setLoader(true)
        axios.get(API_URL + `/api/rm_dashboard/get3MonthsResourceWiseSummary/${id}?t=${new Date().getTime()}`,
            {
                headers: {
                    'x-access-token': `${token}`,
                    'Access-Control-Allow-Origin': '*'
                }
            })
            .then(response => {
                let empData = {}
                if (Object.keys(response.data.data).length > 0) {
                    let data0 = response.data.data[0]

                    let d1 = calculateDays(threeMonths[0].s, threeMonths[0].e)

                    data0.forEach(d => {
                        let t = empData[d.employee_id] || {}
                        t.empId = d.employee_id
                        t.name = d.name
                        t.t1 = d1
                        if (d.project === 'Project') {
                            t.projectHours1 = d.hours.substr(0, 5)
                        } else if (d.project === 'Non-Project') {
                            t.nonProjectHours1 = d.hours.substr(0, 5)
                        } else {
                            t.othersHours1 = d.hours.substr(0, 5)
                        }
                        empData[d.employee_id] = t
                    })

                    let d2 = calculateDays(threeMonths[1].s, threeMonths[1].e)

                    let data1 = response.data.data[1]
                    data1.forEach(d => {
                        let t = empData[d.employee_id] || {}
                        t.empId = d.employee_id
                        t.name = d.name
                        t.t2 = d2
                        if (d.project === 'Project') {
                            t.projectHours2 = d.hours.substr(0, 5)
                        } else if (d.project === 'Non-Project') {
                            t.nonProjectHours2 = d.hours.substr(0, 5)
                        } else {
                            t.othersHours2 = d.hours.substr(0, 5)
                        }
                        empData[d.employee_id] = t
                    })

                    let d3 = calculateDays(threeMonths[2].s, threeMonths[2].e)

                    let data2 = response.data.data[2]
                    data2.forEach(d => {
                        let t = empData[d.employee_id] || {}
                        t.empId = d.employee_id
                        t.name = d.name
                        t.t3 = d3
                        if (d.project === 'Project') {
                            t.projectHours3 = d.hours.substr(0, 5)
                        } else if (d.project === 'Non-Project') {
                            t.nonProjectHours3 = d.hours.substr(0, 5)
                        } else {
                            t.othersHours3 = d.hours.substr(0, 5)
                        }
                        empData[d.employee_id] = t
                    })

                    Object.values(empData).forEach(e => {
                        e.key = e.empId
                        if (!e.nonProjectHours1) {
                            e.nonProjectHours1 = '00:00'
                        }
                        if (!e.projectHours1) {
                            e.projectHours1 = '00:00'
                        }
                        if (!e.othersHours1) {
                            e.othersHours1 = '00:00'
                        }
                        e.total1 = convertHoursToMinutes(e.nonProjectHours1) + convertHoursToMinutes(e.projectHours1) + convertHoursToMinutes(e.othersHours1)
                        let u1 = ((convertHoursToMinutes(e.projectHours1) / e.t1) * 100)
                        e.uilization1 = (isNaN(u1) || u1 === 0 ? '0' : u1.toFixed(2)) + '%'
                        e.total1 = convertMinutesToHours(e.total1)

                        if (!e.nonProjectHours2) {
                            e.nonProjectHours2 = '00:00'
                        }
                        if (!e.projectHours2) {
                            e.projectHours2 = '00:00'
                        }
                        if (!e.othersHours2) {
                            e.othersHours2 = '00:00'
                        }
                        e.total2 = convertHoursToMinutes(e.nonProjectHours2) + convertHoursToMinutes(e.projectHours2) + convertHoursToMinutes(e.othersHours2)
                        let u2 = ((convertHoursToMinutes(e.projectHours2) / e.t2) * 100)
                        e.uilization2 = (isNaN(u2) || u2 === 0 ? '0' : u2.toFixed(2)) + '%'
                        e.total2 = convertMinutesToHours(e.total2)

                        if (!e.nonProjectHours3) {
                            e.nonProjectHours3 = '00:00'
                        }
                        if (!e.projectHours3) {
                            e.projectHours3 = '00:00'
                        }
                        if (!e.othersHours3) {
                            e.othersHours3 = '00:00'
                        }
                        e.total3 = convertHoursToMinutes(e.nonProjectHours3) + convertHoursToMinutes(e.projectHours3) + convertHoursToMinutes(e.othersHours3)
                        let u3 = ((convertHoursToMinutes(e.projectHours3) / e.t3) * 100)
                        e.uilization3 = (isNaN(u3) || u3 === 0 ? '0' : u3.toFixed(2)) + '%'
                        e.total3 = convertMinutesToHours(e.total3)

                        Object.keys(e).forEach(k => {
                            let v = e[k]
                            if (v.replaceAll && v.replaceAll(':', '').replaceAll('%', '') == 0) {
                                e[k] = ''
                            }
                        })
                    })
                }
                setThreeMonthsResourceWise(Object.values(empData))
                setLoader(false)
            })
            .catch(error => {
                setThreeMonthsResourceWise([])
                setLoader(false)
                message.error('Error occured')
                console.log(error)
            });
    }

    const get3MonthsProjectWiseDetails = (id) => {
        setLoader(true)
        axios.get(API_URL + `/api/rm_dashboard/get3MonthsProjectWiseDetails/${id}?t=${new Date().getTime()}`,
            {
                headers: {
                    'x-access-token': `${token}`,
                    'Access-Control-Allow-Origin': '*'
                }
            })
            .then(response => {
                let empData = {}
                if (Object.keys(response.data.data).length > 0) {
                    let data0 = response.data.data[0]
                    data0.forEach(d => {
                        let t = empData[d.employee_id + '-' + d.project_id] || {
                            empId: d.employee_id,
                            name: d.name,
                            projectType: d.projectType,
                            project: d.project,
                            projectId: d.project_id,
                            key: d.employee_id + '-' + d.project_id
                        }
                        t.w0hours = d.hours.substr(0, 5)
                        empData[d.employee_id + '-' + d.project_id] = t
                    })

                    let data1 = response.data.data[1]
                    data1.forEach(d => {
                        let t = empData[d.employee_id + '-' + d.project_id] || {
                            empId: d.employee_id,
                            name: d.name,
                            projectType: d.projectType,
                            project: d.project,
                            projectId: d.project_id,
                            key: d.employee_id + '-' + d.project_id
                        }
                        t.w1hours = d.hours.substr(0, 5)
                        empData[d.employee_id + '-' + d.project_id] = t
                    })

                    let data2 = response.data.data[2]
                    data2.forEach(d => {
                        let t = empData[d.employee_id + '-' + d.project_id] || {
                            empId: d.employee_id,
                            name: d.name,
                            projectType: d.projectType,
                            project: d.project,
                            projectId: d.project_id,
                            key: d.employee_id + '-' + d.project_id
                        }
                        t.w2hours = d.hours.substr(0, 5)
                        empData[d.employee_id + '-' + d.project_id] = t
                    })

                    Object.values(empData).forEach(e => {
                        if (!e.w0hours) {
                            e.w0hours = '00:00'
                        }
                        if (!e.w1hours) {
                            e.w1hours = '00:00'
                        }
                        if (!e.w2hours) {
                            e.w2hours = '00:00'
                        }
                        e.total = convertHoursToMinutes(e.w0hours) + convertHoursToMinutes(e.w1hours) + convertHoursToMinutes(e.w2hours)
                        e.total = convertMinutesToHours(e.total)

                        Object.keys(e).forEach(k => {
                            let v = e[k]
                            if (v.replaceAll && v.replaceAll(':', '').replaceAll('%', '') == 0) {
                                e[k] = ''
                            }
                        })
                    })
                }
                setThreeMonthsProjectDetailsData(Object.values(empData))
                setLoader(false)
            })
            .catch(error => {
                setThreeMonthsProjectDetailsData([])
                setLoader(false)
                message.error('Error occured')
                console.log(error)
            });
    }

    const get6MonthsResourceWiseSummary = (id) => {
        setLoader(true)
        axios.get(API_URL + `/api/rm_dashboard/get6MonthsResourceWiseSummary/${id}?t=${new Date().getTime()}`,
            {
                headers: {
                    'x-access-token': `${token}`,
                    'Access-Control-Allow-Origin': '*'
                }
            })
            .then(response => {
                let empData = {}
                if (Object.keys(response.data.data).length > 0) {
                    let data0 = response.data.data[0]

                    let d1 = calculateDays(sixMonths[0].s, sixMonths[0].e)

                    data0.forEach(d => {
                        let t = empData[d.employee_id] || {}
                        t.empId = d.employee_id
                        t.name = d.name
                        t.t1 = d1
                        if (d.project === 'Project') {
                            t.projectHours1 = d.hours.substr(0, 5)
                        } else if (d.project === 'Non-Project') {
                            t.nonProjectHours1 = d.hours.substr(0, 5)
                        } else {
                            t.othersHours1 = d.hours.substr(0, 5)
                        }
                        empData[d.employee_id] = t
                    })

                    let data1 = response.data.data[1]
                    let d2 = calculateDays(sixMonths[1].s, sixMonths[1].e)
                    data1.forEach(d => {
                        let t = empData[d.employee_id] || {}
                        t.empId = d.employee_id
                        t.name = d.name
                        t.t2 = d2
                        if (d.project === 'Project') {
                            t.projectHours2 = d.hours.substr(0, 5)
                        } else if (d.project === 'Non-Project') {
                            t.nonProjectHours2 = d.hours.substr(0, 5)
                        } else {
                            t.othersHours2 = d.hours.substr(0, 5)
                        }
                        empData[d.employee_id] = t
                    })

                    Object.values(empData).forEach(e => {
                        e.key = e.empId
                        if (!e.nonProjectHours1) {
                            e.nonProjectHours1 = '00:00'
                        }
                        if (!e.projectHours1) {
                            e.projectHours1 = '00:00'
                        }
                        if (!e.othersHours1) {
                            e.othersHours1 = '00:00'
                        }
                        e.total1 = convertHoursToMinutes(e.nonProjectHours1) + convertHoursToMinutes(e.projectHours1) + convertHoursToMinutes(e.othersHours1)
                        let u1 = ((convertHoursToMinutes(e.projectHours1) / e.t1) * 100)
                        e.uilization1 = (isNaN(u1) || u1 === 0 ? '0' : u1.toFixed(2)) + '%'
                        e.total1 = convertMinutesToHours(e.total1)

                        if (!e.nonProjectHours2) {
                            e.nonProjectHours2 = '00:00'
                        }
                        if (!e.projectHours2) {
                            e.projectHours2 = '00:00'
                        }
                        if (!e.othersHours2) {
                            e.othersHours2 = '00:00'
                        }
                        e.total2 = convertHoursToMinutes(e.nonProjectHours2) + convertHoursToMinutes(e.projectHours2) + convertHoursToMinutes(e.othersHours2)
                        let u2 = ((convertHoursToMinutes(e.projectHours2) / e.t2) * 100)
                        e.uilization2 = (isNaN(u2) || u2 === 0 ? '0' : u2.toFixed(2)) + '%'
                        e.total2 = convertMinutesToHours(e.total2)

                        Object.keys(e).forEach(k => {
                            let v = e[k]
                            if (v.replaceAll && v.replaceAll(':', '').replaceAll('%', '') == 0) {
                                e[k] = ''
                            }
                        })
                    })
                }
                setSixMonthsResourceWise(Object.values(empData))
                setLoader(false)
            })
            .catch(error => {
                setSixMonthsResourceWise([])
                setLoader(false)
                message.error('Error occured')
                console.log(error)
            });
    }

    const get6MonthsProjectWiseDetails = (id) => {
        setLoader(true)
        axios.get(API_URL + `/api/rm_dashboard/get6MonthsProjectWiseDetails/${id}?t=${new Date().getTime()}`,
            {
                headers: {
                    'x-access-token': `${token}`,
                    'Access-Control-Allow-Origin': '*'
                }
            })
            .then(response => {
                let empData = {}
                if (Object.keys(response.data.data).length > 0) {
                    let data0 = response.data.data[0]
                    data0.forEach(d => {
                        let t = empData[d.employee_id + '-' + d.project_id] || {
                            empId: d.employee_id,
                            name: d.name,
                            projectType: d.projectType,
                            project: d.project,
                            projectId: d.project_id,
                            key: d.employee_id + '-' + d.project_id
                        }
                        t.w0hours = d.hours.substr(0, 5)
                        empData[d.employee_id + '-' + d.project_id] = t
                    })

                    let data1 = response.data.data[1]
                    data1.forEach(d => {
                        let t = empData[d.employee_id + '-' + d.project_id] || {
                            empId: d.employee_id,
                            name: d.name,
                            projectType: d.projectType,
                            project: d.project,
                            projectId: d.project_id,
                            key: d.employee_id + '-' + d.project_id
                        }
                        t.w1hours = d.hours.substr(0, 5)
                        empData[d.employee_id + '-' + d.project_id] = t
                    })

                    Object.values(empData).forEach(e => {
                        if (!e.w0hours) {
                            e.w0hours = '00:00'
                        }
                        if (!e.w1hours) {
                            e.w1hours = '00:00'
                        }

                        e.total = convertHoursToMinutes(e.w0hours) + convertHoursToMinutes(e.w1hours)
                        e.total = convertMinutesToHours(e.total)

                        Object.keys(e).forEach(k => {
                            let v = e[k]
                            if (v.replaceAll && v.replaceAll(':', '').replaceAll('%', '') == 0) {
                                e[k] = ''
                            }
                        })
                    })
                }
                setSixMonthsProjectDetailsData(Object.values(empData))
                setLoader(false)
            })
            .catch(error => {
                setSixMonthsProjectDetailsData([])
                setLoader(false)
                message.error('Error occured')
                console.log(error)
            });
    }

    const get1YearResourceWiseSummary = (id) => {
        setLoader(true)
        axios.get(API_URL + `/api/rm_dashboard/get1YearResourceWiseSummary/${id}?t=${new Date().getTime()}`,
            {
                headers: {
                    'x-access-token': `${token}`,
                    'Access-Control-Allow-Origin': '*'
                }
            })
            .then(response => {
                let empData = {}
                if (Object.keys(response.data.data).length > 0) {
                    let data0 = response.data.data[0]
                    let d1 = calculateDays(oneYear[0].s, oneYear[0].e)
                    data0.forEach(d => {
                        let t = empData[d.employee_id] || {}
                        t.empId = d.employee_id
                        t.name = d.name
                        t.t1 = d1
                        if (d.project === 'Project') {
                            t.projectHours1 = d.hours.substr(0, 5)
                        } else if (d.project === 'Non-Project') {
                            t.nonProjectHours1 = d.hours.substr(0, 5)
                        } else {
                            t.othersHours1 = d.hours.substr(0, 5)
                        }
                        empData[d.employee_id] = t
                    })

                    Object.values(empData).forEach(e => {
                        e.key = e.empId
                        if (!e.nonProjectHours1) {
                            e.nonProjectHours1 = '00:00'
                        }
                        if (!e.projectHours1) {
                            e.projectHours1 = '00:00'
                        }
                        if (!e.othersHours1) {
                            e.othersHours1 = '00:00'
                        }
                        e.total1 = convertHoursToMinutes(e.nonProjectHours1) + convertHoursToMinutes(e.projectHours1) + convertHoursToMinutes(e.othersHours1)
                        let u1 = ((convertHoursToMinutes(e.projectHours1) / e.t1) * 100)
                        e.uilization1 = (isNaN(u1) || u1 === 0 ? '0' : u1.toFixed(2)) + '%'
                        e.total1 = convertMinutesToHours(e.total1)

                        Object.keys(e).forEach(k => {
                            let v = e[k]
                            if (v.replaceAll && v.replaceAll(':', '').replaceAll('%', '') == 0) {
                                e[k] = ''
                            }
                        })
                    })
                }
                setOneYearResourceWise(Object.values(empData))
                setLoader(false)
            })
            .catch(error => {
                setOneYearResourceWise([])
                setLoader(false)
                message.error('Error occured')
                console.log(error)
            });
    }

    const get1YearProjectWiseDetails = (id) => {
        setLoader(true)
        axios.get(API_URL + `/api/rm_dashboard/get1YearProjectWiseDetails/${id}?t=${new Date().getTime()}`,
            {
                headers: {
                    'x-access-token': `${token}`,
                    'Access-Control-Allow-Origin': '*'
                }
            })
            .then(response => {
                let empData = {}
                if (Object.keys(response.data.data).length > 0) {
                    let data0 = response.data.data[0]
                    data0.forEach(d => {
                        let t = empData[d.employee_id + '-' + d.project_id] || {
                            empId: d.employee_id,
                            name: d.name,
                            projectType: d.projectType,
                            project: d.project,
                            projectId: d.project_id,
                            key: d.employee_id + '-' + d.project_id
                        }
                        t.w0hours = d.hours.substr(0, 5)
                        empData[d.employee_id + '-' + d.project_id] = t
                    })

                    Object.values(empData).forEach(e => {
                        if (!e.w0hours) {
                            e.w0hours = '00:00'
                        }

                        e.total = convertHoursToMinutes(e.w0hours)
                        e.total = convertMinutesToHours(e.total)

                        Object.keys(e).forEach(k => {
                            let v = e[k]
                            if (v.replaceAll && v.replaceAll(':', '').replaceAll('%', '') == 0) {
                                e[k] = ''
                            }
                        })
                    })
                }
                setOneYearProjectDetailsData(Object.values(empData))
                setLoader(false)
            })
            .catch(error => {
                setOneYearProjectDetailsData([])
                setLoader(false)
                message.error('Error occured')
                console.log(error)
            });
    }

    const download4WeeksResourceWiseSummary = () => {
        let csvHeader1 = "Emp Id,Name,W1 Non Project,W1 Project,W1 Leave/Others,W1 Total,W1 Utilization,W2 Non Project,W2 Project,W2 Leave/Others,W2 Total,W2 Utilization,W3 Non Project,W3 Project,W3 Leave/Others,W3 Total,W3 Utilization,W4 Non Project,W4 Project,W4 Leave/Others,W4 Total,W4 Utilization\n";
        let csvData1 = ""
        fourWeeksResourceWise.forEach(emp => {
            csvData1 += (emp.empId + "," + emp.name + "," +
                emp.nonProjectHours1 + "," + emp.projectHours1 + "," + emp.othersHours1 + "," + emp.total1 + "," + emp.uilization1 + "," +
                emp.nonProjectHours2 + "," + emp.projectHours2 + "," + emp.othersHours2 + "," + emp.total2 + "," + emp.uilization2 + "," +
                emp.nonProjectHours3 + "," + emp.projectHours3 + "," + emp.othersHours3 + "," + emp.total3 + "," + emp.uilization3 + "," +
                emp.nonProjectHours4 + "," + emp.projectHours4 + "," + emp.othersHours4 + "," + emp.total4 + "," + emp.uilization4 + "\n");
        })

        let anchor1 = document.createElement('a')
        anchor1.href = 'data:text/csv;charset=utf-8,' + encodeURI(csvHeader1 + csvData1)
        anchor1.download = "Four Weeks - Resource Wise Summary"
        anchor1.click()
    }

    const download4WeeksProjectWiseSummary = () => {
        let csvHeader2 = 'Emp Id,Name,Project,Project Type,' + fourWeeks[0]['y'] + ' - ' + fourWeeks[0]['w'] + ' week' + ',' + fourWeeks[1]['y'] + ' - ' + fourWeeks[1]['w'] + ' week' + ',' + fourWeeks[2]['y'] + ' - ' + fourWeeks[2]['w'] + ' week' + ',' + fourWeeks[3]['y'] + ' - ' + fourWeeks[3]['w'] + ' week' + ',Total\n';
        let csvData2 = ""
        fourWeeksProjectDetailsData.forEach(emp => {
            csvData2 += (emp.empId + "," + emp.name + "," + emp.project + "," + emp.projectType + "," +
                emp.w0hours + "," + emp.w1hours + "," + emp.w2hours + "," + emp.w3hours + "," + emp.total
                + "\n")
        })

        let anchor2 = document.createElement('a')
        anchor2.href = 'data:text/csv;charset=utf-8,' + encodeURI(csvHeader2 + csvData2)
        anchor2.download = "Four Weeks - Project Wise Summary"
        anchor2.click();
    }

    const download3MonthsResourceWiseSummary = () => {
        let csvHeader1 = "Emp Id,Name,3M1 Non Project,3M1 Project,3M1 Leave/Others,3M1 Total,3M1 Utilization,3M2 Non Project,3M2 Project,3M2 Leave/Others,3M2 Total,3M2 Utilization,3M3 Non Project,3M3 Project,3M3 Leave/Others,3M3 Total,3M3 Utilization\n";
        let csvData1 = ""
        threeMonthsResourceWise.forEach(emp => {
            csvData1 += (emp.empId + "," + emp.name + "," +
                emp.nonProjectHours1 + "," + emp.projectHours1 + "," + emp.othersHours1 + "," + emp.total1 + "," + emp.uilization1 + "," +
                emp.nonProjectHours2 + "," + emp.projectHours2 + "," + emp.othersHours2 + "," + emp.total2 + "," + emp.uilization2 + "," +
                emp.nonProjectHours3 + "," + emp.projectHours3 + "," + emp.othersHours3 + "," + emp.total3 + "," + emp.uilization3 + "\n");
        })

        let anchor1 = document.createElement('a')
        anchor1.href = 'data:text/csv;charset=utf-8,' + encodeURI(csvHeader1 + csvData1)
        anchor1.download = "Three Months - Resource Wise Summary"
        anchor1.click()
    }

    const download3MonthsProjectWiseSummary = () => {
        let csvHeader2 = 'Emp Id,Name,Project,Project Type,' + threeMonths[0]['s'] + ' - ' + threeMonths[0]['e'] + ' month' + ',' + threeMonths[1]['s'] + ' - ' + threeMonths[1]['e'] + ' month' + ',' + threeMonths[2]['s'] + ' - ' + threeMonths[2]['e'] + ' month' + ',Total\n';
        let csvData2 = ""
        threeMonthsProjectDetailsData.forEach(emp => {
            csvData2 += (emp.empId + "," + emp.name + "," + emp.project + "," + emp.projectType + "," +
                emp.w0hours + "," + emp.w1hours + "," + emp.w2hours + "," + emp.total
                + "\n")
        })

        let anchor2 = document.createElement('a')
        anchor2.href = 'data:text/csv;charset=utf-8,' + encodeURI(csvHeader2 + csvData2)
        anchor2.download = "Three Months - Project Wise Summary"
        anchor2.click();
    }

    const download6MonthsResourceWiseSummary = () => {
        let csvHeader1 = "Emp Id,Name,6M1 Non Project,6M1 Project,6M1 Leave/Others,6M1 Total,6M1 Utilization,6M2 Non Project,6M2 Project,6M2 Leave/Others,6M2 Total\n";
        let csvData1 = ""
        sixMonthsResourceWise.forEach(emp => {
            csvData1 += (emp.empId + "," + emp.name + "," +
                emp.nonProjectHours1 + "," + emp.projectHours1 + "," + emp.othersHours1 + "," + emp.total1 + "," + emp.uilization1 + "," +
                emp.nonProjectHours2 + "," + emp.projectHours2 + "," + emp.othersHours2 + "," + emp.total2 + "," + emp.uilization2 + "\n");
        })

        let anchor1 = document.createElement('a')
        anchor1.href = 'data:text/csv;charset=utf-8,' + encodeURI(csvHeader1 + csvData1)
        anchor1.download = "Six Months - Resource Wise Summary"
        anchor1.click()
    }

    const download6MonthsProjectWiseSummary = () => {
        let csvHeader2 = 'Emp Id,Name,Project,Project Type,' + sixMonths[0]['s'] + ' - ' + sixMonths[0]['e'] + ' month' + ',' + sixMonths[1]['s'] + ' - ' + sixMonths[1]['e'] + ' month' + ',Total\n';
        let csvData2 = ""
        sixMonthsProjectDetailsData.forEach(emp => {
            csvData2 += (emp.empId + "," + emp.name + "," + emp.project + "," + emp.projectType + "," +
                emp.w0hours + "," + emp.w1hours + "," + emp.total
                + "\n")
        })

        let anchor2 = document.createElement('a')
        anchor2.href = 'data:text/csv;charset=utf-8,' + encodeURI(csvHeader2 + csvData2)
        anchor2.download = "Six Months - Project Wise Summary"
        anchor2.click();
    }

    const download1YearResourceWiseSummary = () => {
        let csvHeader1 = "Emp Id,Name,W1 Non Project,Y1 Project,Y1 Leave/Others,Y1 Total,Y1 Utilization\n";
        let csvData1 = ""
        oneYearResourceWise.forEach(emp => {
            csvData1 += (emp.empId + "," + emp.name + "," +
                emp.nonProjectHours1 + "," + emp.projectHours1 + "," + emp.othersHours1 + "," + emp.total1 + "," + emp.uilization1 + "\n");
        })

        let anchor1 = document.createElement('a')
        anchor1.href = 'data:text/csv;charset=utf-8,' + encodeURI(csvHeader1 + csvData1)
        anchor1.download = "One Year - Resource Wise Summary"
        anchor1.click()
    }

    const download1YearProjectWiseSummary = () => {
        let csvHeader2 = 'Emp Id,Name,Project,Project Type,' + oneYear[0]['s'] + ' - ' + oneYear[0]['e'] + ' year' + ',Total\n';
        let csvData2 = ""
        oneYearProjectDetailsData.forEach(emp => {
            csvData2 += (emp.empId + "," + emp.name + "," + emp.project + "," + emp.projectType + "," +
                emp.w0hours + "," + emp.total
                + "\n")
        })

        let anchor2 = document.createElement('a')
        anchor2.href = 'data:text/csv;charset=utf-8,' + encodeURI(csvHeader2 + csvData2)
        anchor2.download = "One Year - Project Wise Summary"
        anchor2.click();
    }

    const downloadAllData = () => {
        if (selectedCard === '4weeks') {
            download4WeeksResourceWiseSummary()
            download4WeeksProjectWiseSummary()
        } else if (selectedCard === '3months') {
            download3MonthsResourceWiseSummary()
            download3MonthsProjectWiseSummary()
        } else if (selectedCard === '6months') {
            download6MonthsResourceWiseSummary()
            download6MonthsProjectWiseSummary()
        } else if (selectedCard === '1year') {
            download1YearResourceWiseSummary()
            download1YearProjectWiseSummary()
        }
    }

    const fetchReporteeUtilizationData = (card) => {
        let reportee = form.getFieldValue('resourceId') || [];

        if (reportee.length) {
            let v = card ? card : selectedCard
            if (v === '4weeks') {
                get4WeeksResourceWiseSummary(reportee[reportee.length - 1])
                get4WeeksProjectWiseDetails(reportee[reportee.length - 1])
            } else if (v === '3months') {
                get3MonthsResourceWiseSummary(reportee[reportee.length - 1])
                get3MonthsProjectWiseDetails(reportee[reportee.length - 1])
            } else if (v === '6months') {
                get6MonthsResourceWiseSummary(reportee[reportee.length - 1])
                get6MonthsProjectWiseDetails(reportee[reportee.length - 1])
            } else if (v === '1year') {
                get1YearResourceWiseSummary(reportee[reportee.length - 1])
                get1YearProjectWiseDetails(reportee[reportee.length - 1])
            }
        }
    }

    const fourWeeksProjectDetails = [
        {
            title: 'Emp Id', dataIndex: 'empId', width: 100
        },
        {
            title: 'Name', dataIndex: 'name', width: 150
        },
        {
            title: 'Project', dataIndex: 'project', width: 150
        },
        {
            title: 'Project Category', dataIndex: 'projectType', width: 100
        },
        {
            title: fourWeeks[0]['y'] + ' - ' + fourWeeks[0]['w'] + ' week', dataIndex: 'w0hours', width: 70
        },
        {
            title: fourWeeks[1]['y'] + ' - ' + fourWeeks[1]['w'] + ' week', dataIndex: 'w1hours', width: 70
        },
        {
            title: fourWeeks[2]['y'] + ' - ' + fourWeeks[2]['w'] + ' week', dataIndex: 'w2hours', width: 70
        },
        {
            title: fourWeeks[3]['y'] + ' - ' + fourWeeks[3]['w'] + ' week', dataIndex: 'w3hours', width: 70
        },
        {
            title: 'Total', dataIndex: 'total', width: 70
        },
    ]

    const threeMonthsProjectDetails = [
        {
            title: 'Emp Id', dataIndex: 'empId', width: 100
        },
        {
            title: 'Name', dataIndex: 'name', width: 150
        },
        {
            title: 'Project', dataIndex: 'project', width: 150
        },
        {
            title: 'Project Category', dataIndex: 'projectType', width: 100
        },
        {
            title: threeMonths[0]['s'] + ' To ' + threeMonths[0]['e'], dataIndex: 'w0hours', width: 200
        },
        {
            title: threeMonths[1]['s'] + ' To ' + threeMonths[1]['e'], dataIndex: 'w1hours', width: 200
        },
        {
            title: threeMonths[2]['s'] + ' To ' + threeMonths[2]['e'], dataIndex: 'w2hours', width: 200
        },
        {
            title: 'Total', dataIndex: 'total', width: 100
        },
    ]

    const sixMonthsProjectDetails = [
        {
            title: 'Emp Id', dataIndex: 'empId', width: 100
        },
        {
            title: 'Name', dataIndex: 'name', width: 150
        },
        {
            title: 'Project', dataIndex: 'project', width: 150
        },
        {
            title: 'Project Category', dataIndex: 'projectType', width: 100
        },
        {
            title: sixMonths[0]['s'] + ' To ' + sixMonths[0]['e'], dataIndex: 'w0hours', width: 150
        },
        {
            title: sixMonths[1]['s'] + ' To ' + sixMonths[1]['e'], dataIndex: 'w1hours', width: 150
        },
        {
            title: 'Total', dataIndex: 'total', width: 100
        },
    ]

    const oneYearProjectDetails = [
        {
            title: 'Emp Id', dataIndex: 'empId', width: 100
        },
        {
            title: 'Name', dataIndex: 'name', width: 150
        },
        {
            title: 'Project', dataIndex: 'project', width: 150
        },
        {
            title: 'Project Category', dataIndex: 'projectType', width: 100
        },
        {
            title: oneYear[0]['s'] + ' To ' + oneYear[0]['e'], dataIndex: 'w0hours', width: 150
        },
        {
            title: 'Total', dataIndex: 'total', width: 100
        },
    ]

    const cardSelect = (v) => {
        setSelectedCard(v)
        fetchReporteeUtilizationData(v)
    }

    useEffect(() => {
        getAllReportees(true)
    }, [])

    const displayRender = (labels, selectedOptions) =>
        labels.map((label, i) => {
            const option = selectedOptions[i];
            if (i === labels.length - 1) {
                return (
                    <span key={option.value}>
                        <b>{label}</b>
                    </span>
                );
            }
            return <span key={option.value}>{label} / </span>;
        });

    return (
        <Auxiliary>
            <Breadcrumb>
                <Breadcrumb.Item>Resource Manager</Breadcrumb.Item>
                <Breadcrumb.Item className={'text-helight'}><i className="icon icon-check-circle-o" /> Dashboard - Projects</Breadcrumb.Item>
            </Breadcrumb>

            <Row gutter={24}>
                <Col lg={4} md={4} sm={4} xs={4} className="scale-x">
                    <Card className={`gx-card-widget rmCardPointer ${selectedCard === '4weeks' ? 'rmCard' : ''}`}
                        onClick={() => { selectedCard !== '4weeks' && cardSelect('4weeks') }} >
                        <div className="gx-media gx-align-items-center gx-flex-nowrap" >
                            <div className="gx-media-body">
                                <div><b>Past</b></div>
                                <h1 className="gx-fs-xxxl gx-font-weight-medium gx-mb-1">4 Weeks</h1>
                            </div>
                        </div>
                    </Card>
                </Col>
                <Col lg={4} md={4} sm={4} xs={4} className="scale-x">
                    <Card className={`gx-card-widget rmCardPointer ${selectedCard === '3months' ? 'rmCard' : ''}`}
                        onClick={() => { selectedCard !== '3months' && cardSelect('3months') }} >
                        <div className="gx-media gx-align-items-center gx-flex-nowrap" >
                            <div className="gx-media-body">
                                <div><b>Past</b></div>
                                <h1 className="gx-fs-xxxl gx-font-weight-medium gx-mb-1">3 Months</h1>
                            </div>
                        </div>
                    </Card>
                </Col>
                <Col lg={4} md={4} sm={4} xs={4} className="scale-x">
                    <Card className={`gx-card-widget rmCardPointer ${selectedCard === '6months' ? 'rmCard' : ''}`}
                        onClick={() => { selectedCard !== '6months' && cardSelect('6months') }} >
                        <div className="gx-media gx-align-items-center gx-flex-nowrap" >
                            <div className="gx-media-body">
                                <div><b>Past</b></div>
                                <h1 className="gx-fs-xxxl gx-font-weight-medium gx-mb-1">6 Months</h1>
                            </div>
                        </div>
                    </Card>
                </Col>
                <Col lg={4} md={4} sm={4} xs={4} className="scale-x">
                    <Card className={`gx-card-widget rmCardPointer ${selectedCard === '1year' ? 'rmCard' : ''}`}
                        onClick={() => { selectedCard !== '1year' && cardSelect('1year') }} >
                        <div className="gx-media gx-align-items-center gx-flex-nowrap" >
                            <div className="gx-media-body">
                                <div><b>Past</b></div>
                                <h1 className="gx-fs-xxxl gx-font-weight-medium gx-mb-1">1 Year</h1>
                            </div>
                        </div>
                    </Card>
                </Col>
                <Col lg={8} md={8} sm={8} xs={8} >
                    <Form
                        form={form}
                        layout="inline"
                        align="center"
                    >
                        <Row>
                            <Col lg={24} md={24} sm={24} xs={24}>
                                <Form.Item name="active" label="Display InActive Resources: " valuePropName="checked" initialValue={false}>
                                    <Switch onChange={(v) => { getAllReportees(!v) }} className="switchSpace" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={24} md={24} sm={24} xs={24}>
                                <Form.Item
                                    name="resourceId">
                                    <Cascader
                                        options={allReportees}
                                        onPopupVisibleChange={v => {
                                            if (v === false) {
                                                let reportee = form.getFieldValue('resourceId') || [0];
                                                if (reportee.toString() !== previousUserId.toString()) {
                                                    setPreviousUserId(reportee)
                                                    fetchReporteeUtilizationData()
                                                }
                                            }
                                        }}
                                        displayRender={displayRender}
                                        placeholder="Select The Resource"
                                        allowClear={false}
                                        changeOnSelect={true}
                                    />
                                </Form.Item>
                                <b>Note:</b> To select Parent Resource, Double click on it.
                                <br /><br /><Button onClick={downloadAllData}
                                    disabled={
                                        (() => {
                                            if (selectedCard === '4weeks' && fourWeeksResourceWise.length > 0) {
                                                return false
                                            } else if (selectedCard === '3months' && threeMonthsResourceWise.length > 0) {
                                                return false
                                            } else if (selectedCard === '6months' && sixMonthsResourceWise.length > 0) {
                                                return false
                                            } else if (selectedCard === '1year' && oneYearResourceWise.length > 0) {
                                                return false
                                            } else {
                                                return true
                                            }
                                        })()
                                    }
                                    style={{ float: 'right' }}>Download As CSV</Button>
                            </Col>
                        </Row>

                    </Form>
                </Col>
            </Row>

            <Tabs tabPosition='top'>
                <TabPane tab="Resource Wise Summary" key="1">
                    <Row gutter={24}>
                        <Col lg={24} md={24} sm={24} xs={24}  >
                            {selectedCard === '4weeks' &&
                                <Table dataSource={fourWeeksResourceWise} scroll={{ x: 1000, y: 350, }} id="rmDashboardId" bordered={true} loading={loader}>
                                    <Column title="Emp Id" dataIndex="empId" key="age" fixed="left" width={100} />
                                    <Column title="Name" dataIndex="name" key="address" fixed="left" width={150} />

                                    <ColumnGroup title={(() => {
                                        return <>{fourWeeks[0]['y'] + ' - ' + fourWeeks[0]['w'] + ' week'} <br /> {fourWeeks[0]['s'] + ' - ' + fourWeeks[0]['e']}</>
                                    })()
                                    }
                                        className="week1">
                                        <Column title="Non Project" dataIndex="nonProjectHours1" key="nonProjectHours1" className="week1" width={100} render={(t, r) => {
                                            return r.nonProjectHours1
                                        }} />
                                        <Column title="Project" dataIndex="projectHours1" key="projectHours1" className="week1" width={100} render={(t, r) => {
                                            return r.projectHours1
                                        }} />
                                        <Column title="Leave/Holiday/Others" dataIndex="othersHours1" key="othersHours1" className="week1" width={100} />
                                        <Column title="Total" dataIndex="total1" key="total1" className="week1" width={100} />
                                        <Column title="Utilization %" dataIndex="uilization1" key="uilization1" className="week1" width={100} />
                                    </ColumnGroup>

                                    <ColumnGroup
                                        title={(() => {
                                            return <>{fourWeeks[1]['y'] + ' - ' + fourWeeks[1]['w'] + ' week'} <br /> {fourWeeks[1]['s'] + ' - ' + fourWeeks[1]['e']}</>
                                        })()
                                        }
                                        className="week2">
                                        <Column title="Non Project" dataIndex="nonProjectHours2" key="nonProjectHours2" className="week2" width={100} render={(t, r) => {
                                            return r.nonProjectHours2
                                        }} />
                                        <Column title="Project" dataIndex="projectHours2" key="projectHours2" className="week2" width={100} render={(t, r) => {
                                            return r.projectHours2
                                        }} />
                                        <Column title="Leave/Holiday/Others" dataIndex="othersHours2" key="othersHours2" className="week2" width={100} />
                                        <Column title="Total" dataIndex="total2" key="total2" className="week2" width={100} />
                                        <Column title="Utilization %" dataIndex="uilization2" key="uilization2" className="week2" width={100} />
                                    </ColumnGroup>

                                    <ColumnGroup
                                        title={(() => {
                                            return <>{fourWeeks[2]['y'] + ' - ' + fourWeeks[2]['w'] + ' week'} <br /> {fourWeeks[2]['s'] + ' - ' + fourWeeks[2]['e']}</>
                                        })()
                                        }
                                        className="week3">
                                        <Column title="Non Project" dataIndex="nonProjectHours3" key="nonProjectHours3" className="week3" width={100} render={(t, r) => {
                                            return r.nonProjectHours3
                                        }} />
                                        <Column title="Project" dataIndex="projectHours3" key="projectHours3" className="week3" width={100} render={(t, r) => {
                                            return r.projectHours3
                                        }} />
                                        <Column title="Leave/Holiday/Others" dataIndex="othersHours3" key="othersHours3" className="week3" width={100} />
                                        <Column title="Total" dataIndex="total3" key="total3" className="week3" width={100} />
                                        <Column title="Utilization %" dataIndex="uilization3" key="uilization3" className="week3" width={100} />
                                    </ColumnGroup>

                                    <ColumnGroup
                                        title={(() => {
                                            return <>{fourWeeks[3]['y'] + ' - ' + fourWeeks[3]['w'] + ' week'} <br /> {fourWeeks[3]['s'] + ' - ' + fourWeeks[3]['e']}</>
                                        })()
                                        }
                                        className="week4">
                                        <Column title="Non Project" dataIndex="nonProjectHours4" key="nonProjectHours4" className="week4" width={100} render={(t, r) => {
                                            return r.nonProjectHours4
                                        }} />
                                        <Column title="Project" dataIndex="projectHours4" key="projectHours4" className="week4" width={100} render={(t, r) => {
                                            return r.projectHours4
                                        }} />
                                        <Column title="Leave/Holiday/Others" dataIndex="othersHours4" key="othersHours4" className="week4" width={100} />
                                        <Column title="Total" dataIndex="total4" key="total4" className="week4" width={100} />
                                        <Column title="Utilization %" dataIndex="uilization4" key="uilization4" className="week4" width={100} />
                                    </ColumnGroup>
                                </Table>}
                            {selectedCard === '3months' &&
                                <Table dataSource={threeMonthsResourceWise} scroll={{ x: 1000, y: 350, }} id="rmDashboardId" bordered={true} loading={loader}>
                                    <Column title="Emp Id" dataIndex="empId" key="age" fixed="left" width={100} />
                                    <Column title="Name" dataIndex="name" key="address" fixed="left" width={150} />

                                    <ColumnGroup title={threeMonths[0]['s'] + ' To ' + threeMonths[0]['e']} className="week1">
                                        <Column title="Non Project" dataIndex="nonProjectHours1" key="nonProjectHours1" className="week1" width={100} render={(t, r) => {
                                            return r.nonProjectHours1
                                        }} />
                                        <Column title="Project" dataIndex="projectHours1" key="projectHours1" className="week1" width={100} render={(t, r) => {
                                            return r.projectHours1
                                        }} />
                                        <Column title="Leave/Holiday/Others" dataIndex="othersHours1" key="othersHours1" className="week1" width={100} />
                                        <Column title="Total" dataIndex="total1" key="total1" className="week1" width={100} />
                                        <Column title="Utilization %" dataIndex="uilization1" key="uilization1" className="week1" width={100} />
                                    </ColumnGroup>

                                    <ColumnGroup title={threeMonths[1]['s'] + ' To ' + threeMonths[1]['e']} className="week2">
                                        <Column title="Non Project" dataIndex="nonProjectHours2" key="nonProjectHours2" className="week2" width={100} render={(t, r) => {
                                            return r.nonProjectHours2
                                        }} />
                                        <Column title="Project" dataIndex="projectHours2" key="projectHours2" className="week2" width={100} render={(t, r) => {
                                            return r.projectHours2
                                        }} />
                                        <Column title="Leave/Holiday/Others" dataIndex="othersHours2" key="othersHours2" className="week2" width={100} />
                                        <Column title="Total" dataIndex="total2" key="total2" className="week2" width={100} />
                                        <Column title="Utilization %" dataIndex="uilization2" key="uilization2" className="week2" width={100} />
                                    </ColumnGroup>

                                    <ColumnGroup title={threeMonths[2]['s'] + ' To ' + threeMonths[2]['e']} className="week3">
                                        <Column title="Non Project" dataIndex="nonProjectHours3" key="nonProjectHours3" className="week3" width={100} render={(t, r) => {
                                            return r.nonProjectHours3
                                        }} />
                                        <Column title="Project" dataIndex="projectHours3" key="projectHours3" className="week3" width={100} render={(t, r) => {
                                            return r.projectHours3
                                        }} />
                                        <Column title="Leave/Holiday/Others" dataIndex="othersHours3" key="othersHours3" className="week3" width={100} />
                                        <Column title="Total" dataIndex="total3" key="total3" className="week3" width={100} />
                                        <Column title="Utilization %" dataIndex="uilization3" key="uilization3" className="week3" width={100} />
                                    </ColumnGroup>
                                </Table>}
                            {selectedCard === '6months' &&
                                <Table dataSource={sixMonthsResourceWise} scroll={{ x: 1000, y: 350, }} id="rmDashboardId" bordered={true} loading={loader}>
                                    <Column title="Emp Id" dataIndex="empId" key="age" fixed="left" width={100} />
                                    <Column title="Name" dataIndex="name" key="address" fixed="left" width={150} />

                                    <ColumnGroup title={sixMonths[0]['s'] + ' To ' + sixMonths[0]['e']} className="week1">
                                        <Column title="Non Project" dataIndex="nonProjectHours1" key="nonProjectHours1" className="week1" width={100} render={(t, r) => {
                                            return r.nonProjectHours1
                                        }} />
                                        <Column title="Project" dataIndex="projectHours1" key="projectHours1" className="week1" width={100} render={(t, r) => {
                                            return r.projectHours1
                                        }} />
                                        <Column title="Leave/Holiday/Others" dataIndex="othersHours1" key="othersHours1" className="week1" width={100} />
                                        <Column title="Total" dataIndex="total1" key="total1" className="week1" width={100} />
                                        <Column title="Utilization %" dataIndex="uilization1" key="uilization1" className="week1" width={100} />
                                    </ColumnGroup>

                                    <ColumnGroup title={sixMonths[1]['s'] + ' To ' + sixMonths[1]['e']} className="week2">
                                        <Column title="Non Project" dataIndex="nonProjectHours2" key="nonProjectHours2" className="week2" width={100} render={(t, r) => {
                                            return r.nonProjectHours2
                                        }} />
                                        <Column title="Project" dataIndex="projectHours2" key="projectHours2" className="week2" width={100} render={(t, r) => {
                                            return r.projectHours2
                                        }} />
                                        <Column title="Leave/Holiday/Others" dataIndex="othersHours2" key="othersHours2" className="week2" width={100} />
                                        <Column title="Total" dataIndex="total2" key="total2" className="week2" width={100} />
                                        <Column title="Utilization %" dataIndex="uilization2" key="uilization2" className="week2" width={100} />
                                    </ColumnGroup>
                                </Table>}
                            {selectedCard === '1year' &&
                                <Table dataSource={oneYearResourceWise} scroll={{ x: 1000, y: 350, }} id="rmDashboardId" bordered={true} loading={loader}>
                                    <Column title="Emp Id" dataIndex="empId" key="age" fixed="left" width={100} />
                                    <Column title="Name" dataIndex="name" key="address" fixed="left" width={150} />

                                    <ColumnGroup title={oneYear[0]['s'] + ' - ' + oneYear[0]['e']} className="week1">
                                        <Column title="Non Project" dataIndex="nonProjectHours1" key="nonProjectHours1" className="week1" width={100} render={(t, r) => {
                                            return r.nonProjectHours1
                                        }} />
                                        <Column title="Project" dataIndex="projectHours1" key="projectHours1" className="week1" width={100} render={(t, r) => {
                                            return r.projectHours1
                                        }} />
                                        <Column title="Leave/Holiday/Others" dataIndex="othersHours1" key="othersHours1" className="week1" width={100} />
                                        <Column title="Total" dataIndex="total1" key="total1" className="week1" width={100} />
                                        <Column title="Utilization %" dataIndex="uilization1" key="uilization1" className="week1" width={100} />
                                    </ColumnGroup>
                                </Table>}
                        </Col>
                    </Row>
                </TabPane>
                <TabPane tab="Project Wise Details" key="2">
                    <Row gutter={24}>
                        <Col lg={24} md={24} sm={24} xs={24}  >
                            {selectedCard === '4weeks' &&
                                <Table dataSource={fourWeeksProjectDetailsData} scroll={{ x: 1200, y: 350, }} columns={fourWeeksProjectDetails} bordered={true} loading={loader}>
                                </Table>}
                            {selectedCard === '3months' &&
                                <Table dataSource={threeMonthsProjectDetailsData} scroll={{ x: 1200, y: 350, }} columns={threeMonthsProjectDetails} bordered={true} loading={loader}>
                                </Table>}
                            {selectedCard === '6months' &&
                                <Table dataSource={sixMonthsProjectDetailsData} scroll={{ x: 1200, y: 350, }} columns={sixMonthsProjectDetails} bordered={true} loading={loader}>
                                </Table>}
                            {selectedCard === '1year' &&
                                <Table dataSource={oneYearProjectDetailsData} scroll={{ x: 1200, y: 350, }} columns={oneYearProjectDetails} bordered={true} loading={loader}>
                                </Table>}
                        </Col>
                    </Row>
                </TabPane>
            </Tabs>

        </Auxiliary>
    );
};
export default RMDashboardProjects;