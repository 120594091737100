import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import axios from 'axios';
import { Col, Row } from "antd";
import { Table, Select, Form, Tooltip, Button } from "antd";
import Auxiliary from "util/Auxiliary";
import { API_URL } from 'constants/APIConfig';
import { FormOutlined, EyeOutlined, ConsoleSqlOutlined } from '@ant-design/icons';
import moment from "moment";
//import JSGanttComponent from 'jsgantt-improved-prime'
import JSGanttComponent from 'react-jsgantt';
import Widget from "components/Widget/index";

const { Option } = Select;


const ManageProjectPlan = () => {
  
  let history = useHistory();

  const userDetails = JSON.parse(sessionStorage.getItem('user_details'));
  const token = sessionStorage.getItem('token');

  const [projectPlan, setProjectPlan] = useState([]);
  const [projectPlanGantt, setProjectPlanGantt] = useState([]);
  const [projects, setProjects] = useState([]);

  const [form] = Form.useForm();



  const getProjectList = () => {
    axios.get(API_URL + `/api/project/v2/projectsByManager?userId=${userDetails.id}`, 
    {headers: {
      'x-access-token': `${token}`, 
    'Access-Control-Allow-Origin': '*'}
   })
      .then(response => {
        if (Object.keys(response.data.data).length > 0) {
          setProjects(response.data.data);
          form.setFieldsValue({
            prjname: response.data.data[0].id,
          })
          getProjectPlan(response.data.data[0].id);
        }
      })
      .catch(error => {
        // Error
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log('Error', error.message);
        }
        console.log(error.config);
      });
  }
  const onProjectChange = value => {
    getProjectPlan(value)
  };

  const [planCols, setPlanCols] = useState([
    {
      title: 'Name', dataIndex: 'pName', fixed: 'left', width: 200,
      sorter: (a, b) => String(a.pName).localeCompare(String(b.pName)),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Start Date', dataIndex: 'pPlanStart', width: 120,
      sorter: (a, b) => String(a.pStart).localeCompare(String(b.pStart)),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'End Date', dataIndex: 'pPlanEnd', width: 120,
      sorter: (a, b) => String(a.pEnd).localeCompare(String(b.pEnd)),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Duration', dataIndex: 'pID', width: 100,
      sorter: (a, b) => String(a.duration).localeCompare(String(b.duration)),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Assigned To', dataIndex: 'pRes', width: 150,
      sorter: (a, b) => String(a.pRes).localeCompare(String(b.pRes)),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Status', dataIndex: 'pRes', width: 100,
      sorter: (a, b) => String(a.pRes).localeCompare(String(b.pRes)),
      sortDirections: ['descend', 'ascend'],
    },
/*     {
      title: 'Comments', dataIndex: 'comments', width: 200,
      sorter: (a, b) => String(a.comments).localeCompare(String(b.comments)),
      sortDirections: ['descend', 'ascend'],
    }, */
    {
      title: 'Action', fixed: 'right', width: 100, dataIndex: 'pID', render: text => <div><span> <a href={'/plan/manage/' + text}><FormOutlined style={{ padding: '10px' }} /></a></span> </div>
    },
  ]);

  const editorOptions = {
    vCaptionType: 'Complete',  // Set to Show Caption : None,Caption,Resource,Duration,Complete,
    vQuarterColWidth: 36,
    vDateTaskDisplayFormat: 'day dd month yyyy', // Shown in tool tip box
    vDayMajorDateDisplayFormat: 'mon yyyy - Week ww', // Set format to display dates in the "Major" header of the "Day" view
    vWeekMinorDateDisplayFormat: 'dd mon',
  };

  let devMilestones = [];
  const getProjectPlan = (projId) => {
   axios.get(API_URL + `/api/project_milestones/project/${projId}/type/DELIVERY`, 
   {headers: {
     'x-access-token': `${token}`, 
   'Access-Control-Allow-Origin': '*'}
  })
      .then(response => {
        if (Object.keys(response.data.data).length > 0) {
          console.log("Plan",response.data.data);
          response.data.data.map((item, index) => {
            devMilestones.push({
              key: index+1,
              pID: item.id,
              pName: item.titleTx,
              pStart: new Date(moment(item.startDateDt).format('YYYY'), moment(item.startDateDt).format('M'), moment(item.startDateDt).format('D')),
              pEnd: new Date(moment(item.endDateDt).format('YYYY'), moment(item.endDateDt).format('M'), moment(item.endDateDt).format('D')),
              pPlanStart: '2017-03-03',
              pPlanEnd: '2017-03-09',
              pClass: 'gtaskred',
              pLink: '',
              pMile: 0,
              pRes: item.assignedUser && item.assignedUser.displayNameTx,
              pComp: 60,
              pGroup: 0,
              pParent: 12,
              pOpen: 1,
              pDepend: 121,
              pCaption: '',
              pNotes: item.comments, 
             /* pID: "1",
              pName: "Define Chart <strong>API</strong>",
              pStart: "2017-02-25",
              pEnd: "2017-03-17",
              pPlanStart: "2017-04-01",
              pPlanEnd: "2017-04-15 12:00",
              pClass: "ggroupblack",
              pLink: "",
              pMile: 0,
              pRes: "Brian",
              pComp: 0,
              pGroup: 0,
              pParent: 0,
              pOpen: 1,
              pDepend: "",
              pCaption: "",
              pCost: 1000,
              pNotes: "Some Notes text",
              category: "My Category",
              sector: "Finance" */
            })
          })
         // setProjectPlan(response.data.data);
          setProjectPlanGantt(devMilestones);
        }
      }).catch(error => {
        // Error
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log('Error', error.message);
        }
        console.log(error.config);
      });
  }
  useEffect(() => {
    getProjectList();
  }, [])

  return (
    <Auxiliary>
      <Form
        form={form}
        initialValues={{ remember: true }}
        name="plan"
        //onFinish={onFinish}
        layout="inline"
        align="center"
      >
        <Row gutter={24}>
          <Col xl={16} lg={16} md={16} sm={24} xs={24}>
            <div className="gx-form-row0" >
              <Form.Item
                name="prjname"
                // label="Project Name"
                rules={[
                  {
                    required: true,
                    message: 'Enter project name',
                  },
                ]}
              >
                <Select
                  //className="mFull-width"
                  showSearch
                  style={{ width: '100%' }}
                  placeholder="Projects"
                  optionFilterProp="children"
                  onChange={onProjectChange}
                  // size="small"
                  filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                >
                  {
                    projects.length ? projects.map((project, index) => (
                      <Option key={index} value={project.id}>{project.name}</Option>
                    )) : null
                  }
                </Select>
              </Form.Item>
            </div>
          </Col>
          <Col xl={8} lg={8} md={8} sm={24} xs={24}>
            <Button type="primary" className="add-btn" onClick={() => history.push('/plan/new')}> New Plan </Button>
          </Col>
        </Row>
      </Form>
      <Row gutter={24}>
        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
          <Table className="remove-mFixCell2" columns={planCols} dataSource={projectPlanGantt} pagination={{ pageSize: 10 }} scroll={{ x: 1300 }}
          expandable={{
            expandedRowRender: record => <p style={{ margin: 0 }}>{record.pNotes}</p>,
          }}
          />
        </Col>
      </Row>
      <Row gutter={24}>
        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
        <Widget>
         {(() => {
            if (projectPlanGantt.length > 0) {
              return <JSGanttComponent data={projectPlanGantt} options={editorOptions} /> 
            }
          })()} 
        </Widget>
        </Col>
      </Row>
    </Auxiliary>
  );
};
export default ManageProjectPlan;
