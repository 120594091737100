import React, { useEffect, useState } from "react";
import axios from 'axios';
import {Col, Row} from "antd";
import {Anchor,Card, Button, Table} from "antd";
import Auxiliary from "util/Auxiliary";
import { API_URL } from 'constants/APIConfig';

import IconWithTextCard from "components/Metrics/IconWithTextCard";
import { FormOutlined, EyeOutlined } from '@ant-design/icons';

const ClientStats = () => {
  const userDetails = JSON.parse(sessionStorage.getItem('user_details'));
  const token = sessionStorage.getItem('token');
 
    const [clientStats, setClientStats] = useState([]);
    
    const getClientStats = () => {
       axios.get(API_URL+'/api/invoice_customers/get/dashboard/info/v2?userId=1', 
       {headers: {
         'x-access-token': `${token}`, 
       'Access-Control-Allow-Origin': '*'}
      })
          .then(response => {
               console.log('Client Stats', response.data.data);
              setClientStats(response.data.data);
          })
          .catch(error => {
              console.log(error)
          });
        }   

  useEffect(() => {
    getClientStats();
  }, []) 
  return (
    <Auxiliary>
    <Row>
      <Col xl={6} lg={12} md={12} sm={12} xs={12} className="gx-col-full">
        <IconWithTextCard icon="orders" iconColor="geekblue" title={clientStats.Total}  subTitle="Clients"/>
      </Col>
      <Col xl={6} lg={12} md={12} sm={12} xs={12} className="gx-col-full">
        <IconWithTextCard icon="revenue-new" iconColor="primary" title={clientStats.Domestic} subTitle="Domestic"/>
      </Col>
      <Col xl={6} lg={12} md={12} sm={12} xs={12} className="gx-col-full">
        <IconWithTextCard icon="visits" iconColor="geekblue" title={clientStats.Active}  subTitle="MiddleEast"/>
      </Col>
      <Col xl={6} lg={12} md={12} sm={12} xs={12} className="gx-col-full">
        <IconWithTextCard icon="queries" iconColor="geekblue" title={clientStats.Other} subTitle="USA"/>
      </Col>
      </Row>      
    </Auxiliary>
  );
};
export default ClientStats;
