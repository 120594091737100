import React from "react";
import { Input, Button } from "antd";
import Highlighter from "react-highlight-words";
import axios from 'axios';
import { API_URL } from 'constants/APIConfig';
import { FormOutlined, FileAddTwoTone } from '@ant-design/icons';
import { SearchOutlined } from '@ant-design/icons';
import Auxiliary from "util/Auxiliary";
import get from "lodash.get";
import isequal from "lodash.isequal";
import moment from "moment";
import { Table } from "ant-table-extensions";

class ProjectsResouces extends React.Component {

    state = {
        searchText: "",
        searchedColumn: "",
        projects: [],
        loading: false,
        userDetails: JSON.parse(sessionStorage.getItem('user_details')),
        token: sessionStorage.getItem('token'),
    };

    getAllprojectsResouces = (statusFilter, typeFilter) => {
        this.setState({ loading: true });
        axios.get(API_URL + `/api/project_resources/getAllProjectResources?userId=1`,
            {
                headers: {
                    'x-access-token': `${this.state.token}`,
                    'Access-Control-Allow-Origin': '*'
                }
            })
            .then(response => {
                if (Object.keys(response.data.data).length > 0) {
                    //console.log("ProjectList", response.data.data)
                    let data = [];
                    response.data.data.map((item, index) => {
                        data.push({
                            key: index,
                            id: item.id,
                            //name: item.shortName,
                            name: item.name,
                            shortName: item.shortName,
                            projectCode: item.projectCode,
                            client: item.client,
                            //sdt: item.startDate,
                            estimatedHours: item.estimatedHours,
                            startDate: moment(item.startDate).format('DD-MM-YYYY'),
                            endDate: moment(item.endDate).format('DD-MM-YYYY'),
                            progress: item.progress && item.progress,
                            AccountManager: item.AccountManager && item.AccountManager,
                            ProjectManager: item.ProjectManager && item.ProjectManager,
                            ProjectCoordinator: item.ProjectCoordinator,
                            Initiator:item.Initiator,
                            BillableType: item.BillableType && item.BillableType,
                            User: item.User,
                            employeeId: item.employeeId,

                        })
                    })
                    this.setState({ projects: data });
                    this.setState({ loading: false });
                }
            })
            .catch(error => {
                // Error
                if (error.response) {
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                } else if (error.request) {
                    console.log(error.request);
                } else {
                    console.log('Error', error.message);
                }
                console.log(error.config);
            });
    }

    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters
        }) => (
            <div className="custom-filter-dropdown">
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e =>
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                    }
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}

                    style={{ width: 188, marginBottom: 8, display: "block" }}
                />
                <Button
                    type="primary"
                    onClick={() => this.handleSearch(selectedKeys, confirm)}
                    icon="search"
                    size="small"
                    style={{ width: 70, marginRight: 8, float: 'left' }}
                >
                    {/*   Search */}
                </Button>
                <Button
                    onClick={() => this.handleReset(clearFilters)}
                    size="small"
                    style={{ width: 70, float: 'right' }}
                >
                    Reset
                </Button>
            </div>
        ),
        filterIcon: filtered => (
            <SearchOutlined />
        ),
        /*     onFilter: (value, record) =>
              record[dataIndex]
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase()), */
        onFilter: (value, record) => {
            return get(record, dataIndex)
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase());
        },

        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select());
            }
        },
        /*     render: text => (
              <Highlighter
                highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
                searchWords={[this.state.searchText]}
                autoEscape
                textToHighlight={text.toString()}
              />
            ) */
        render: text => {
            return isequal(this.state.searchedColumn, dataIndex) ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
                    searchWords={[this.state.searchText]}
                    autoEscape
                    textToHighlight={text.toString()}
                />
            ) : (
                text
            );
        }
    });

    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex
        });
        //this.setState({ searchText: selectedKeys[0] });
    };

    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: "" });
    };

    componentDidMount() {
        this.getAllprojectsResouces();
    }

    render() {
        //const { history } = this.props;
        const columns = [
            {
                title: 'Name', dataIndex: 'name', key: 'name', fixed: 'left', width: 200,
                sorter: (a, b) => String(a.name).localeCompare(String(b.name)),
                sortDirections: ['descend', 'ascend'],
                ...this.getColumnSearchProps("name")
            },
            {
                title: 'Short Name', dataIndex: 'shortName', key: 'shortName', width: 120,
                sorter: (a, b) => String(a.shortName).localeCompare(String(b.shortName)),
                sortDirections: ['descend', 'ascend'],
                ...this.getColumnSearchProps("shortName")
            },
            {
                title: 'Project Code', dataIndex: 'projectCode', key: 'projectCode', width: 120,
                sorter: (a, b) => String(a.projectCode).localeCompare(String(b.projectCode)),
                sortDirections: ['descend', 'ascend'],
            },
            {
                title: 'Start Date', dataIndex: 'startDate', key: 'startDate', width: 120,
                sorter: (a, b) => String(a.startDate).localeCompare(String(b.startDate)),
                sortDirections: ['descend', 'ascend'],
            },
            {
                title: 'End Date', dataIndex: 'endDate', key: 'endDate', width: 120,
                sorter: (a, b) => String(a.endDate).localeCompare(String(b.endDate)),
                sortDirections: ['descend', 'ascend'],
            },
            {
                title: 'Est Hours', dataIndex: 'estimatedHours', key: 'estimatedHours', width: 120,
                sorter: (a, b) => String(a.estimatedHours).localeCompare(String(b.estimatedHours)),
                sortDirections: ['descend', 'ascend'],
            },
            {
                title: 'Client', dataIndex: 'client', key: 'client', width: 150,
                sorter: (a, b) => String(a.client).localeCompare(String(b.client)),
                sortDirections: ['descend', 'ascend'],
                ...this.getColumnSearchProps("client")
            },
            {
                title: 'Billable Type', dataIndex: 'BillableType', key: 'BillableType', width: 120,
                sorter: (a, b) => String(a.BillableType).localeCompare(String(b.BillableType)),
                sortDirections: ['descend', 'ascend'],
                ...this.getColumnSearchProps("BillableType")
            },
            {
                title: 'Acct Mgr', dataIndex: 'AccountManager', key: 'AccountManager', width: 150,
                sorter: (a, b) => String(a.AccountManager).localeCompare(String(b.AccountManager)),
                sortDirections: ['descend', 'ascend'],
                ...this.getColumnSearchProps("AccountManager")
            },
            {
                title: 'Proj Mgr', dataIndex: 'ProjectManager', key: 'ProjectManager', width: 150,
                sorter: (a, b) => String(a.ProjectManager).localeCompare(String(b.ProjectManager)),
                sortDirections: ['descend', 'ascend'],
                ...this.getColumnSearchProps("projmgr")
            },
            {
                title: 'Initiator', dataIndex: 'Initiator', key: 'Initiator', width: 150,
                sorter: (a, b) => String(a.Initiator).localeCompare(String(b.Initiator)),
                sortDirections: ['descend', 'ascend'],
                ...this.getColumnSearchProps("projmgr")
            },
            {
                title: 'ProjectCoordinator', dataIndex: 'ProjectCoordinator', key: 'ProjectCoordinator', width: 160,
                sorter: (a, b) => String(a.ProjectCoordinator).localeCompare(String(b.ProjectCoordinator)),
                sortDirections: ['descend', 'ascend'],
            },

            {
                title: 'User', dataIndex: 'User', key: 'User', width: 150,
                sorter: (a, b) => String(a.User).localeCompare(String(b.User)),
                sortDirections: ['descend', 'ascend'],
            },
            {
                title: 'Emp Id', fixed: 'right', dataIndex: 'employeeId', key: 'employeeId', width: 120,
                sorter: (a, b) => String(a.employeeId).localeCompare(String(b.employeeId)),
                sortDirections: ['descend', 'ascend'],
            },
            // { title: 'Action', fixed: 'right', width: 100, dataIndex: 'id', render: text => <div><span> <a href={'/master/projects/manage/' + text}><FormOutlined style={{ padding: '10px' }} /></a></span> </div> },
        ];
        return (
            <Auxiliary>
                <Table className="PmoProjectResource-table" columns={columns} dataSource={this.state.projects} pagination={{ pageSize: 10 }} scroll={{ x: 1300 }}
                    loading={this.state.loading}
                    exportable
                    exportableProps={{ showColumnPicker: true }}
                />
            </Auxiliary>
        );
    }
}
export default ProjectsResouces;
