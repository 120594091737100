import React, { useEffect, useState } from "react";
import { Col, Row, Modal, Select, Table, Tabs } from "antd";
import Auxiliary from "util/Auxiliary";
import Widget from "components/Widget/index";
import axios from 'axios';
import { API_URL } from 'constants/APIConfig';
import IconWithTextCard from "components/dashboard/CRM/IconWithTextCard";
import TimeLog from "../../timesheets/submit/index";

const TabPane = Tabs.TabPane;

const EmployeeDashboard = () => {
  const [taskEditPop, setTaskEditPop] = useState(false);
  const [taskEditId, setTaskEditId] = useState(0);
  const { Option } = Select;
  const [treeData, setTreeData] = useState([]);
  const [taskKanbanList, setTaskKanbanList] = useState([]);
  const [projectList, setProjectList] = useState([]);
  const [userBoard, setUserBoard] = useState([]);

  const userDetails = JSON.parse(sessionStorage.getItem('user_details'));
  const token = sessionStorage.getItem('token');
  

  const getUserBoard = () => {
    axios.get(API_URL + '/api/task/userStats/'+userDetails.id, 
    {headers: {
      'x-access-token': `${token}`, 
    'Access-Control-Allow-Origin': '*'}
   })
      .then(response => {
        if (Object.keys(response.data.data).length > 0) {
            setUserBoard(response.data.data)
        }
      })
      .catch(error => {
        console.log(error);
      });
  }
  
  const getProjectList = () => {
    axios.get(API_URL + '/api/project/v2/get/assigned/projects/' + userDetails.id, 
    {headers: {
      'x-access-token': `${token}`, 
    'Access-Control-Allow-Origin': '*'}
   })
      .then(response => {
        if (Object.keys(response.data.data).length > 0) {
          response.data.data.map((project, index) => (
            setProjectList(project.projects)
          ))
        }
      })
      .catch(error => {
        console.log(error);
      });
  }

  const taskCols = [
    { title: 'Name', dataIndex: 'task',
    sorter: (a, b) => String(a.task).localeCompare(String(b.task)),
    sortDirections: ['descend', 'ascend'],
    },
    { title: 'Start Date', dataIndex: 'startDateDt',
    sorter: (a, b) => String(a.startDateDt).localeCompare(String(b.startDateDt)),
    sortDirections: ['descend', 'ascend'],
    },
    { title: 'End Date', dataIndex: 'dueDateDt',
    sorter: (a, b) => String(a.dueDateDt).localeCompare(String(b.dueDateDt)),
    sortDirections: ['descend', 'ascend'],
    },
    { title: 'Actual Hours', dataIndex: 'spendHours',
    sorter: (a, b) => String(a.spentHoursNm).localeCompare(String(b.spentHoursNm)),
    sortDirections: ['descend', 'ascend'],
    },
    { title: 'Estd. Hours', dataIndex: 'estimatedHoursNm',
    sorter: (a, b) => String(a.estimatedHoursNm).localeCompare(String(b.estimatedHoursNm)),
    sortDirections: ['descend', 'ascend'],
    },
    { title: 'Status', dataIndex: 'taskstatus',
    sorter: (a, b) => String(a.taskstatus).localeCompare(String(b.taskstatus)),
    sortDirections: ['descend', 'ascend'],
    },
    { title: 'Utilization %', dataIndex: 'completion',
    sorter: (a, b) => String(a.completion).localeCompare(String(b.completion)),
    sortDirections: ['descend', 'ascend'],
    },
    { title: 'Comments', dataIndex: 'comments',
    sorter: (a, b) => String(a.comments).localeCompare(String(b.comments)),
    sortDirections: ['descend', 'ascend'],
    },
  ];
  const getTaskLists = (projID) => {
    axios.get(API_URL+'/api/task/getUsersTasksList?userId='+userDetails.id+'&projectId='+ projID+'&limit=100&offset=0', 
    {headers: {
      'x-access-token': `${token}`, 
    'Access-Control-Allow-Origin': '*'}
   })
      .then(response => {
        const data1 = [];
        if (Object.keys(response.data.data).length > 0) {
          response.data.data.map((item, index) => {
            data1.push({
              id: item.id,
              task: item.taskNameTx,
              startDateDt: item.startDateDt,
              dueDateDt: item.dueDateDt,
              estimatedHoursNm: item.estimatedHoursNm,
              taskstatus: item.taskstatus && item.taskstatus.statusValueTx,
              spendHours: item.spentHoursNm,
              completion: item.completion + "%",
              comments: item.comments,
              key: index

            })
          })
        }
        setTreeData(data1);
      })
      .catch(error => {
        console.log(error);
      });
  }

  const [timeSheetList, setTimeSheetList] = useState([]);
  const timeSheetListCols = [
    { title: 'Year', dataIndex: 'year',
    sorter: (a, b) => String(a.year).localeCompare(String(b.year)),
    sortDirections: ['descend', 'ascend'],
    },
    { title: 'Week', dataIndex: 'weekNumber',
    sorter: (a, b) => String(a.weekNumber).localeCompare(String(b.weekNumber)),
    sortDirections: ['descend', 'ascend'],
    },
    { title: 'Reporting Manager', dataIndex: 'rmanager',
    sorter: (a, b) => String(a.rmanager).localeCompare(String(b.rmanager)),
    sortDirections: ['descend', 'ascend'],
    },
    { title: 'RM Comments', dataIndex: 'rmcomments',
    sorter: (a, b) => String(a.rmcomments).localeCompare(String(b.rmcomments)),
    sortDirections: ['descend', 'ascend'],
    },
    { title: 'Status', dataIndex: 'status',
    sorter: (a, b) => String(a.status).localeCompare(String(b.status)),
    sortDirections: ['descend', 'ascend'],
    },
  ];

  let tSheetList = [];
  const getTSList = () => {
       axios.get(API_URL+`/api/timesheets/getAllUserTimesheets?userId=${userDetails.id}&year=2021`, 
       {headers: {
         'x-access-token': `${token}`, 
       'Access-Control-Allow-Origin': '*'}
      })
      .then(response => {
        if (Object.keys(response.data.data).length > 0) {
          response.data.data.map((item, index) => {
            tSheetList.push({
              year: item.year,
              weekNumber: item.weekNumber,
              rmanager: item.reportingManager && item.reportingManager.displayNameTx,
              rmcomments: item.reportingManagerComment,
              status: item.reportingManagerAction
            })
          })
          setTimeSheetList(tSheetList);
        } 
      })
      .catch(error => {
        console.log(error);
      });
  }

  const [timeLogPop, setTimeLogPop] = useState(false);
  const [timeLog, setTimeLog] = useState([]);
  const timeLogCols = [
    { title: 'User', dataIndex: 'employeeId',
    sorter: (a, b) => String(a.employeeId).localeCompare(String(b.employeeId)),
    sortDirections: ['descend', 'ascend'],
    },
    { title: 'Task', dataIndex: 'taskName',
    sorter: (a, b) => String(a.employeeId).localeCompare(String(b.employeeId)),
    sortDirections: ['descend', 'ascend'],
    },
    { title: 'Billable', dataIndex: 'isBillable',
    sorter: (a, b) => String(a.employeeId).localeCompare(String(b.employeeId)),
    sortDirections: ['descend', 'ascend'],
    },
    { title: 'Estd Hours', dataIndex: 'estimatedHoursNm',
    sorter: (a, b) => String(a.employeeId).localeCompare(String(b.employeeId)),
    sortDirections: ['descend', 'ascend'],
    },
    { title: 'Hours Spent', dataIndex: 'totalHours',
    sorter: (a, b) => String(a.totalHours).localeCompare(String(b.totalHours)),
    sortDirections: ['descend', 'ascend'],
    },
  ];

  let timeLogEntries = [];
  const gettimeLog = (projID) => {
    axios.get(API_URL + `/api/time_log/getTaskTimeLogHistory?userId=${userDetails.id}&projectId=${projID}`, 
    {headers: {
      'x-access-token': `${token}`, 
    'Access-Control-Allow-Origin': '*'}
   })
      .then(response => {
        if (Object.keys(response.data.data).length > 0) {
          response.data.data.map((item, index) => {
            timeLogEntries.push({
              employeeId: item.employeeId,
              taskName: item.taskName,
              isBillable: item.isBillable,
              estimatedHoursNm: item.estimatedHoursNm,
              totalHours: parseFloat(item.totalHours).toFixed(2),
            })
          })
          setTimeLog(timeLogEntries);
        }
      })
      .catch(error => {
        console.log(error);
      });
  }
  const [issues, setIssues] = useState([]);
  const [issuePop, setIssuePop] = useState(false);

  const issuesColumns = [
    { title: 'Type', dataIndex: 'type',
    sorter: (a, b) => String(a.type).localeCompare(String(b.type)),
    sortDirections: ['descend', 'ascend'],
    },
    { title: 'Owner', dataIndex: 'owner',
    sorter: (a, b) => String(a.owner).localeCompare(String(b.owner)),
    sortDirections: ['descend', 'ascend'],
    },
    { title: 'Status', dataIndex: 'status',
    sorter: (a, b) => String(a.status).localeCompare(String(b.status)),
    sortDirections: ['descend', 'ascend'],
    },
    { title: 'Due', dataIndex: 'dueDate',
    sorter: (a, b) => String(a.dueDate).localeCompare(String(b.dueDate)),
    sortDirections: ['descend', 'ascend'],
    },
    { title: 'Description', dataIndex: 'comments',
    sorter: (a, b) => String(a.comments).localeCompare(String(b.comments)),
    sortDirections: ['descend', 'ascend'],
   },
  ];

  let issuesEntries = [];
  const getProjIssueList = (projID) => {
    axios.get(API_URL + `/api/project_issues_risks_actions/getDetails?projectId=${projID}`, 
    {headers: {
      'x-access-token': `${token}`, 
    'Access-Control-Allow-Origin': '*'}
   })
      .then(response => {
        if (Object.keys(response.data.data).length > 0) {
          response.data.data.map((item, index) => {
            issuesEntries.push({
              type: item.type,
              owner: item.assignedUser && item.assignedUser.employeeId,
              status: item.status,
              dueDate: item.dueDate,
              comments: item.comments,
            })
          })
          setIssues(issuesEntries);
        }
      })
      .catch(error => {
        console.log(error);
      });
  }

  const onProjectChange = value => {
    getTaskLists(value);
   // gettimeLog(value);
    //getProjIssueList(value);
  };
  useEffect(() => {
    getProjectList();
    getUserBoard();
    getTSList();
  }, [])

  const editTaskRcrd = (id) => {
    setTaskEditPop(true);
    setTaskEditId(id);
  }
  return (
    <Auxiliary>
      <Row gutter={24} className="">
        <Col xl={8} lg={8} md={8} sm={24} xs={24} className="mb-0">
          <Widget>
            <h2 className="gx-mb-3 gx-mb-sm-4">{userDetails.displayNameTx}</h2>
            <h4 className="gx-mb-3 gx-mb-sm-4">{userDetails.designation}</h4>
          </Widget>
        </Col>
        <Col xl={16} lg={16} md={16} sm={24} xs={24} className="gx-order-sm-1">
          <Row className="">
            <Col xl={6} lg={6} md={6} sm={24} xs={24}>
              <IconWithTextCard cardColor="cyan" icon="diamond" title={userBoard.projects} subTitle="Projects" />
            </Col>
            <Col xl={6} lg={6} md={6} sm={24} xs={24}>
              <IconWithTextCard cardColor="orange" icon="tasks" title={userBoard.tasks} subTitle="My Task" />
            </Col>
            <Col xl={6} lg={6} md={6} sm={24} xs={24}>
              <IconWithTextCard cardColor="teal" icon="team" title={userBoard.completedTasks} subTitle="Completed" />
            </Col>
            <Col xl={6} lg={6} md={6} sm={24} xs={24}>
              <IconWithTextCard cardColor="red" icon="files" title={userBoard.hoursSpent ? userBoard.hoursSpent.substring(0,userBoard.hoursSpent.indexOf(':')) : userBoard.hoursSpent } subTitle="Hours Spent" />
            </Col>
          </Row>
        </Col>
      </Row>

      <Row>
        <Col lg={16} md={12} sm={24} xs={24}  >
        </Col>
        <Col lg={8} md={12} sm={24} xs={24}  >
          <div className="gx-form-row0">
              <Select
                showSearch
                style={{ width: '100%' }}
                placeholder="Select Project"
                optionFilterProp="children"
                onChange={onProjectChange}
                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              >
                {
                  projectList.length ? projectList.map((project, index) => (
                    <Option key={index} value={project.id}>{project.name}</Option>
                  )) : null
                }
              </Select>
          </div>
        </Col>
      </Row>

      <Row>
        <Col lg={24} md={24} sm={24} xs={24}  >
      <Tabs tabPosition='top' className="tab_layout"> 
        <TabPane tab="My Tasks" key="1">
              <Table className="gx-table-responsive" columns={taskCols} dataSource={treeData} bordered={false} />
        </TabPane>
      </Tabs>
      </Col>
      </Row>
      <Modal
        title="Timesheet"
        centered
        visible={timeLogPop}
        onOk={() => setTimeLogPop(false)}
        onCancel={() => setTimeLogPop(false)}
        width={1000}
        destroyOnClose={true}
        footer={null}
      >
        <TimeLog projectid={372} />
      </Modal>

    </Auxiliary>
  );
};
export default EmployeeDashboard;