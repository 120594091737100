import React, { useEffect, useState } from "react";
import { Button, Checkbox, Input, message, Form, Avatar } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import Auxiliary from "util/Auxiliary";

import { UserOutlined, UnlockFilled, LoginOutlined } from '@ant-design/icons'

import {
  hideMessage,
  userSignIn,
} from "../appRedux/actions/Auth"

import IntlMessages from "util/IntlMessages";
import CircularProgress from "components/CircularProgress/index";

const SignIn = () => {

  const dispatch = useDispatch();
  const { loader, alertMessage, showMessage, authUser } = useSelector(({ auth }) => auth);
  const [form] = Form.useForm();
  const history = useHistory();
  const [remember, setRemember] = useState(localStorage.getItem('employeeId'));

  useEffect(() => {
    if (showMessage) {
      setTimeout(() => {
        dispatch(hideMessage());
      }, 500);
    }
    if (authUser !== null) {
      history.push('/');
    }
  });

  const onFinishFailed = errorInfo => {
  };

  const changeUser = () => {
    setRemember(null)
  }

  //let remember = localStorage.getItem('employeeId')
  let empName = localStorage.getItem('employeeName')
  const onFinish = values => {
    if(remember) {
      values.username = remember;
    }
    var res = dispatch(userSignIn(values))
  };
  
  return (
    <Auxiliary>
  {/*       <MyAppsHome/>  */}

    {remember != null ? 
    <div className="gx-app-login-wrap loginBg">
    <div className="gx-app-login-container">
      <div className="gx-app-login-main-content">
        <div className="gx-app-login-content">
        <div className="loginLogo"><img alt="logo3" src={require("../assets/images/Gemini-Consulting-Services-logo.png")} /></div>
         <Form
            // initialValues={{ remember: true }}
            name="basic"
            form={form}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            className="gx-signin-form gx-form-row0" autoComplete="off">

          <div className="center-avatar">
          {(() => {
            try {
              return <Avatar size={105} shape="circle" src={require(`../assets/images/avatar/${remember.toLowerCase()}.png`)} />
            } catch (err) {
              try {
                return <Avatar size={105} shape="circle" src={require(`../assets/images/avatar/profile.png`)} />
              } catch (err) {
                return <li><span className="ant-avatar gx-border gx-border-grey gx-bg-transparent gx-text-grey gx-d-flex gx-align-items-center gx-justify-content-center"><i
                className="icon icon-add" /></span></li>
              }
            }
          })()}
            <div>Hello! <b>{empName}</b><br/>
            <Button className="gx-mb-0" type="link" onClick={changeUser}>Change User</Button>
            </div>
          </div>
            <Form.Item name="username" hidden={true}>
              <Input value={remember}/>
            </Form.Item>
            <Form.Item className="gx-mb-0"
              rules={[{ required: true, message: 'Please input your Password!' },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || value.startsWith(' ') || value.endsWith(' ')) {
                    return Promise.reject(new Error('Trailing spaces are not allowed.'));
                  }
                  return Promise.resolve();
                },
              })]} name="password" hasFeedback>
              <Input type="password" placeholder="Password" prefix={<UnlockFilled/>} autoFocus={true}/>
            </Form.Item>
            <Form.Item>
              <Button type="primary" className="gx-mb-0" htmlType="submit">
              <LoginOutlined /> <IntlMessages id="app.userAuth.signIn" />
              </Button>
            </Form.Item>
            <Form.Item name="remember">
              <Link className="gx-login-form-forgot gx-float-right gx-pr-1" to="/forgotpassword">Forgot password</Link>
            </Form.Item>
          </Form>
        </div>

        {loader ?
          <div className="gx-loader-view">
            <CircularProgress />
          </div> : null}
        {showMessage ?
          message.error(alertMessage.toString()) : null}
      </div>
    </div>
  </div> 
    :
    <div className="gx-app-login-wrap loginBg">
      <div className="gx-app-login-container">
        <div className="gx-app-login-main-content">
          <div className="gx-app-login-content">
          <div className="loginLogo"><img alt="logo3" src={require("assets/images/Gemini-Consulting-Services-logo.png")} /></div>
           <Form
              // initialValues={{ remember: true }}
              name="basic"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              className="gx-signin-form gx-form-row0" autoComplete="off">

              <Form.Item
                // initialValue="demo@example.com"
                rules={[{ required: true, message: 'Please input your username!' },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || value.startsWith(' ') || value.endsWith(' ')) {
                      return Promise.reject(new Error('Trailing spaces are not allowed.'));
                    }
                    return Promise.resolve();
                  },
                })]} name="username" hasFeedback>
                <Input placeholder="User Name" prefix={<UserOutlined/>} autoFocus={true}/>
              </Form.Item>
              <Form.Item
                rules={[{ required: true, message: 'Please input your Password!' },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || value.startsWith(' ') || value.endsWith(' ')) {
                      return Promise.reject(new Error('Trailing spaces are not allowed.'));
                    }
                    return Promise.resolve();
                  },
                })]} name="password" hasFeedback>
                <Input type="password" className="mt-9"
                  placeholder="Password" prefix={<UnlockFilled/>}/>
              </Form.Item>
              <Form.Item name="remember" valuePropName="checked" wrapperCol={{ offset: 8, span: 16 }} className="mt-9">
                <Checkbox>Remember me</Checkbox>
              </Form.Item>
              <Form.Item>
                <Button type="primary" className="gx-mb-0" htmlType="submit">
                <LoginOutlined /> <IntlMessages id="app.userAuth.signIn" />
                </Button>
              </Form.Item>
              <Form.Item name="remember" className="mt-9">
                <Link className="gx-login-form-forgot gx-float-right gx-pr-1" to="/forgotpassword">Forgot password</Link>
              </Form.Item>
            </Form>
          </div>

          {loader ?
            <div className="gx-loader-view">
              <CircularProgress />
            </div> : null}
          {showMessage ?
            message.error(alertMessage.toString()) : null}
        </div>
      </div>
    </div> 
    }

    </Auxiliary>
  );
};

export default SignIn;
