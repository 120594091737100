import React from "react";
import { DatePicker, Button, Row, Col, Select, message, Breadcrumb } from "antd";
import { Table } from "ant-table-extensions";
import axios from 'axios';
import { API_URL } from 'constants/APIConfig';
import moment from 'moment';
import 'moment/min/locales';
import Auxiliary from "util/Auxiliary";

const { RangePicker } = DatePicker;
const { Option } = Select;

class TimeLogs extends React.Component {

  state = {
    loading: false,
    projectSelected: 0,
    userSelected: 0,
    startDate: moment(),
    endDate: moment(),
    loading: false,
    userDetails: JSON.parse(sessionStorage.getItem('user_details')),
    token: sessionStorage.getItem('token'),
    projects: [],
    timeSheetList: [],
    users: [],
  };

  timeFormatting = (time) => {
    return time ? moment(time, 'HH:mm:ss').format('HH:mm') : time
  }

  handleChangeProject = (value) => {
    this.setState({ projectSelected: +value });
    this.getUserList(value);
  }

  handleChangeUser = (value) => {
    this.setState({ userSelected: +value });
  }

  getTimeLog = (dates, dateStrings) => {
    this.setState({
      startDate: dates[0],
      endDate: dates[1],
    });
  }

  fetchTimeLog = () => {
    this.getTSList();
  }

  getProjectList = () => {
    axios.get(API_URL + `/api/project/v2/projectsByManager?userId=${this.state.userDetails.id}`,
      {
        headers: {
          'x-access-token': `${this.state.token}`,
          'Access-Control-Allow-Origin': '*'
        }
      })
      .then(response => {
        if (Object.keys(response.data.data).length > 0) {
          this.setState({ projects: response.data.data });
        }
      })
      .catch(error => {
        console.log(error);
      });
  }

  getUserList = (projId) => {
    axios.get(API_URL + `/api/project_resources/getProjectResources?userId=1&projectId=${projId}`,
      {
        headers: {
          'x-access-token': `${this.state.token}`,
          'Access-Control-Allow-Origin': '*'
        }
      })
      .then(response => {
        let userList = []
        if(response.data.data && response.data.data.length > 0) {
          let setIds = new Set()
          response.data.data.forEach(u => {
            if(!setIds.has(u.userId)) {
              setIds.add(u.userId)
              userList.push(u.assignedUser)
            }
          })
        }
        this.setState({ users: userList });
      })
      .catch(error => {
        console.log(error);
      });
  }

  getTSList = () => {
    if(this.state.startDate === null || this.state.endDate === null) {
      message.error(`Start Date and End Date are mandatory`);
      return;
    }
    let tSheetList = [];
    this.setState({ loading: true })
    axios.post(API_URL + '/api/timesheets/v2/getTimelogReportByDateRange', {
      "startDate": this.state.startDate.format('DD-MM-YYYY'),
      "endDate": this.state.endDate.format('DD-MM-YYYY'),
      "userId": this.state.userDetails.id,
      "projectFilter": this.state.projectSelected ? [this.state.projectSelected] : null,
      "userFilter": this.state.userSelected ? [this.state.userSelected] : null,
    },
      {
        headers: {
          'x-access-token': `${this.state.token}`,
          'Access-Control-Allow-Origin': '*'
        }
      })
      .then(response => {
        this.setState({ loading: false })
        this.setState({ timeSheetList: [] });
        if (Object.keys(response.data.data).length > 0) {
          response.data.data.map((item, index) => {
            tSheetList.push({
              year: item.year,
              weekNumber: item.weekNumber,
              yearweek: item.year + " - " + item.weekNumber,
              empname: item.userName,
              empid: item.employeeId,
              project: item.project,
              taskName: item.taskName,
              startDateDt: item.startDateDt,
              dueDateDt: item.dueDateDt,
              date: item.date,
              // hours: (item.hours).substring(0, 5),
              hours: item.hours,
              comments: item.comments,
              billabilityNm: item.billabilityNm == 0 ? 'IS' : 'CR',
              key: index
            })
          })
          tSheetList.sort((a, b) => ((a.weekNumber && a.year) < (b.weekNumber && b.year)) ? 1 : -1)
          this.setState({ timeSheetList: tSheetList });
        } else {
          message.info('There is no data to display.')
        }
      })
      .catch(error => {
        this.setState({ loading: false })
        this.setState({ timeSheetList: [] });
        message.error(`Something went wrong.`);
      });

  }

  componentDidMount() {
    this.getProjectList();
  }
  render() {
    let timeLoggedCols = [
      {
        title: 'EmpId', dataIndex: 'empid', width: 100, ellipsis: true, align: 'center',
        render: (text, record) => {
          return (
            <div>
              <span> {text} </span>
            </div>
          );
        },
        sorter: (a, b) => String(a.empid).localeCompare(String(b.empid)),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Employee Name', dataIndex: 'empname', width: 150, align: 'left',
        render: (text, record) => {
          return (
            <div className="gx-d-flex">
              <div className="gx-ml-1">
                <div>{record.empname}</div>
              </div>
            </div>
          );
        },
        sorter: (a, b) => String(a.empname).localeCompare(String(b.empname)),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Project', dataIndex: 'project', width: 150, ellipsis: true, align: 'center',
        render: (text, record) => {
          return (
            <div>
              <span> {text} </span>
            </div>
          );
        },
      },
      {
        title: 'Task', dataIndex: 'taskName', width: 200, ellipsis: true, align: 'center',
        render: (text, record) => {
          return (
            <div>
              <span> {text} </span>
            </div>
          );
        },
      },
      {
        title: 'Hours', dataIndex: 'hours', width: 100, align: 'center',
      },
      {
        title: 'Task Type', dataIndex: 'billabilityNm', width: 200, align: 'center',
        render: (text, record) => {
          return (
            <div>
              <span> {text} </span>
            </div>
          );
        },
      },
      {
        title: 'Year-Week', fixed: 'right', dataIndex: 'yearweek', width: 80, align: 'center',
        render: (text, record) => {
          return (
            <div>
              {record.year} -  {record.weekNumber}
            </div>

          );
        },
      },
      {
        title: 'Date', fixed: 'right', dataIndex: 'date', width: 80, align: 'center',
        render: (text, record) => {
          return (
            <div>
              {record.date}
            </div>

          );
        },
      }
    ];
    return (
      <Auxiliary>
        <Breadcrumb>
          <Breadcrumb.Item>Project Manager</Breadcrumb.Item>
          <Breadcrumb.Item className={'text-helight'}><i className="icon icon-check-circle-o" /> Timelog Summary</Breadcrumb.Item>
        </Breadcrumb>
        <Row className="dateRangeBlock">
          <Col xl={8} lg={8} md={8} sm={24} xs={24} className="flex_style gx-pr-0 remove-pr-0">
            <RangePicker className="gx-mb-3 gx-w-100" onChange={this.getTimeLog} 
            defaultValue={[moment(), moment()]} format="DD-MM-YYYY"/>
          </Col>
          <Col xl={7} lg={7} md={7} sm={24} xs={24} className="flex_style gx-pr-0 remove-pr-0">
            <Select
              showSearch
              style={{ width: '100%' }}
              placeholder="Select a Project"
              optionFilterProp="children"
              onChange={this.handleChangeProject}
              filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            >
              {
                this.state.projects.length ? this.state.projects.map((project, index) => (
                  <Option key={index} value={project.id}>{project.name}</Option>
                )) : null
              }
            </Select>
          </Col>
          <Col xl={7} lg={7} md={7} sm={24} xs={24} className="flex_style gx-pr-0 remove-pr-0">
            <Select
              showSearch
              style={{ width: '100%' }}
              placeholder="Select a User"
              optionFilterProp="children"
              onChange={this.handleChangeUser}
              filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            >
              {
                (this.state.users && this.state.users.length) ? this.state.users.map((user, index) => (
                  <Option key={index} value={user.id}>{user.displayNameTx}</Option>
                )) : null
              }
            </Select>
          </Col>
          <Col xl={2} lg={2} md={2} sm={24} xs={24} className="flex_style gx-pr-0 remove-pr-0">
            <Button type="primary" className="add-btn gx-mr-2" onClick={this.fetchTimeLog}>Submit</Button>
          </Col>
        </Row>
        <Table className="gx-table-responsive" columns={timeLoggedCols} dataSource={this.state.timeSheetList} bordered={false} scroll={{ x: 1300 }}
          loading={this.state.loading}
          exportable
          exportableProps={{ showColumnPicker: true }}
        />
      </Auxiliary>
    );
  }
}
export default TimeLogs;
