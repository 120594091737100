import React, { useEffect, useState } from "react";
import LineIndicator from "./LineIndicator";
import axios from 'axios';
import { API_URL } from 'constants/APIConfig';

const ProjectPortfolio = () => {
  const userDetails = JSON.parse(sessionStorage.getItem('user_details'));
  const token = sessionStorage.getItem('token');
  const [projStatus, setProjStatus] = useState([]);

  /* Start of Get the data form the API*/
  const getProjectStatus = () => {
    axios.get(API_URL+'/api/project/v2/all/status', 
    {headers: {
      'x-access-token': `${token}`, 
    'Access-Control-Allow-Origin': '*'}
   })
      .then(response => {
      //console.log('Status', response.data.data)
        setProjStatus(response.data.data);
        })
      .catch(error => {
        console.log(error)
      });
  }
  useEffect(() => {
    getProjectStatus()  
  }, [])
let external = projStatus.type ? (projStatus.type.External ? projStatus.type.External : 0) : 0;
let internal =  projStatus.type ? (projStatus.type.Internal ? projStatus.type.Internal : 0) : 0;
let poc = projStatus.type ? (projStatus.type.POC ? projStatus.type.POC : 0) : 0;
  return (
    <div className="gx-site-dash gx-mb-2 gx-pt-3 gx-pt-sm-0 gx-pt-xl-2">
      <h6 className=" gx-mb-2 gx-mb-sm-4 h4">Project Portfolio</h6>
      <ul className="gx-line-indicator gx-fs-sm">
        <li>
          <LineIndicator width="56%" title="External" color="cyan" value= {external.toString()} />
        </li>
        <li>
          <LineIndicator width="42%" title="Internal" color="geekblue" value={internal.toString()} />
        </li>
        <li>
          <LineIndicator width="20%" title="POC's" color="orange" value= {poc.toString()}/>
        </li>
      </ul>
    </div>
  )
};

export default ProjectPortfolio;
