import React, { useEffect, useState } from "react";
import axios from 'axios';
import { Row, Col, Form, Input, Select, message, Button, Tabs, InputNumber } from "antd";
import Auxiliary from "util/Auxiliary";
import { API_URL } from 'constants/APIConfig';
import { useHistory } from "react-router-dom";
import Widget from "components/Widget/index";

const FormItem = Form.Item;
const { Option } = Select;
const InputGroup = Input.Group;
const TabPane = Tabs.TabPane;
const { TextArea } = Input;

const EditCost = (props) => {
    let history = useHistory();
    const userDetails = JSON.parse(sessionStorage.getItem('user_details'));
    const token = sessionStorage.getItem('token');
    
    const [projects, setProjects] = useState([]);
    const [projCost, setProjCost] = useState([]);

    const getProjCostById = () => {
        axios.get(API_URL + `/api/project_cost/byId/${props.match.params.id}`, 
        {headers: {
          'x-access-token': `${token}`, 
        'Access-Control-Allow-Origin': '*'}
       })
          .then(response => {
            console.log("cost by id", response.data.data);
            if (Object.keys(response.data.data).length > 0) {
                setProjCost(response.data.data);
              let item =  response.data.data;
              let spend=[];
              spend = {...spend,type:item.spendType,amount:item.plannedSpend};  
              form.setFieldsValue({
                aspend:item.actualSpend,
                cost:spend,
               // type:item.type,
               // due:moment(item.dueDate),
               // status:item.status,
                comments:item.comments,
              }); 
            } 
          })
          .catch(error => {
            // Error
            if (error.response) {
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
            } else if (error.request) {
              console.log(error.request);
            } else {
              console.log('Error', error.message);
            }
            console.log(error.config);
          });
      }  
    
      useEffect(() => {
        if(props.match.params.id!=null)
           getProjCostById()
    
      }, [])

    const getProjectList = () => {
        axios.get(API_URL + '/api/project/v2/all?userId=1&limit=500&offset=0', 
        {headers: {
          'x-access-token': `${token}`, 
        'Access-Control-Allow-Origin': '*'}
       })
            .then(response => {
                setProjects(response.data.data);
            })
            .catch(error => {
                console.log(error)
            });
    }

    useEffect(() => {
        getProjectList();
    }, [])

    // UseForm Hook
    const [form] = Form.useForm();


    //UI Functions
    const onFinish = values => {
        let fieldsData = form.getFieldsValue();
        let projCostData = {
                projectId: +projCost.projectId,
                spendType: fieldsData.cost.type,
                plannedSpend: +fieldsData.cost.amount,
                actualSpend: +form.getFieldValue('aspend'),
                variance:  (+fieldsData.cost.amount) - (+form.getFieldValue('aspend')),
                comments: form.getFieldValue('comments')
            }
        console.log("ProjCostData",projCostData)
        axios.put(API_URL + `/api/project_cost/update/${props.match.params.id}`, projCostData, 
        {headers: {
          'x-access-token': `${token}`, 
        'Access-Control-Allow-Origin': '*'}
       })
            .then(response => {
                message.success(`Cost updated sucessfully`);
                history.push("/cost/manage");
            })
            .catch(error => {
                // Error
                if (error.response) {
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                } else if (error.request) {
                    console.log(error.request);
                } else {
                    console.log('Error', error.message);
                }
                console.log(error.config);
                message.error("Error In creating Cost", error)
            });
    }
    return (
        <Auxiliary>
            <Widget>
            <Form
                form={form}
                initialValues={{ remember: true }}
                name="prjCost"
                onFinish={onFinish}
                layout="inline"
                align="center"
            >
                <Row>
                    <Col lg={12} md={12} sm={12} xs={24} >
                        <div className="gx-form-row0">
                            <h6 align="left">Planned Spend</h6>
                            <Form.Item
                                rules={[
                                    {
                                        required: true,
                                        message: 'Enter project cost',
                                    },
                                ]}
                            >
                                <InputGroup compact className="gx-mb-3">
                                    <Form.Item
                                        name={['cost', 'type']}
                                        noStyle
                                        rules={[{ required: true, message: 'Enter cost type' }]}
                                    >
                                        <Select style={{ width: '90%' }}>
                                            <Option value="Resourcing Costs">Resourcing Costs</Option>
                                            <Option value="Software Costs">Software Costs</Option>
                                            <Option value="Hardware Costs">Hardware Costs	</Option>
                                            <Option value="Traning Costs">Traning Costs</Option>
                                            <Option value="Others<">Others</Option>
                                        </Select>
                                    </Form.Item>
                                    <Form.Item
                                        name={['cost', 'amount']}
                                        noStyle
                                        rules={[{ required: true, message: 'Enter amount' }]}
                                    >
                                        <InputNumber style={{ width: '10%' }} />
                                    </Form.Item>
                                </InputGroup>

                            </Form.Item>
                        </div>
                    </Col>
                    <Col lg={12} md={12} sm={12} xs={24} >
                        <div className="gx-form-row0">
                            <h6 align="left">Actual Spend</h6>
                            <Form.Item
                                name="aspend"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Enter actual spend',
                                    },
                                ]}
                            >
                                <InputNumber placeholder="Name" />
                            </Form.Item>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col lg={24} md={12} sm={12} xs={24} >
                        <div className="gx-form-row0">
                            <h6 align="left">Comments</h6>
                            <Form.Item
                                name="comments"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Enter comments',
                                    },
                                ]}
                            >
                                <TextArea rows={4} />
                            </Form.Item>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col lg={24} md={24} sm={24} xs={24} >
                        <div className="gx-form-row0">
                            <Form.Item
                                name="submit"
                            >
                                <Button type="primary" className="add-btn"  htmlType="submit" >Submit</Button>
                                <Button type="primary" className="add-btn gx-mr-2" onClick={() => history.push('/cost/manage')}>Back</Button>
                            </Form.Item>
                        </div>
                    </Col>
                </Row>
            </Form>
            </Widget>
        </Auxiliary>
    );
};
export default EditCost;
