import React, { useEffect, useState } from "react";
import { Col, Row} from "antd";
import Resources from "./Resources";
import WelComeCard from "./Technology";
import Overview from "components/dashboard/CRM/Overview";
import ProjectPortfolio from "./ProjectPortfolio";
import Auxiliary from "util/Auxiliary";
import IconWithTextCard from "components/dashboard/CRM/IconWithTextCard";
import BalanceHistory from "components/dashboard/Crypto/BalanceHistory";
import ProjectReview from "./reports/projects";
import Issues from "./reports/issues";
import RecentWins from "./reports/recentwins";
import Reportees from "./reports/reportees";
import Tickets from "./reports/tickets";
import ProjStats from "./reports/ProjStats";
import axios from 'axios';
import { API_URL } from 'constants/APIConfig';



const ExecDashboard = () => {
  const [metrics, setMetrics] = useState([]);
  const userDetails = JSON.parse(sessionStorage.getItem('user_details'));
  const token = sessionStorage.getItem('token');

  const getMetrics = () => {
    axios.get(API_URL + '/api/common/metricsValues', 
    {headers: {
      'x-access-token': `${token}`, 
    'Access-Control-Allow-Origin': '*'}
   })
      .then(response => {
        console.log('Metrics', response.data.data)
        setMetrics(response.data.data);
      })
      .catch(error => {
        // Error
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log('Error', error.message);
        }
        console.log(error.config);
      });
  }
  useEffect(() => {
    getMetrics();
  }, [])

  return (
    <Auxiliary>
      <Row className="gx-ml-0 gx-mr-0">
        <Col span={24}>
          <div className="gx-card">
            <div className="gx-card-body">
              <Row>
                <Col xl={6} lg={12} md={12} sm={12} xs={24}>
                  <WelComeCard />
                </Col>
                <Col xl={6} lg={12} md={12} sm={12} xs={24} className="gx-audi-col">
                  <ProjectPortfolio />
                </Col>

                <Col xl={12} lg={24} md={24} sm={24} xs={24} className="gx-visit-col">
              <Resources /> 
                </Col>
              </Row>
            </div>
          </div>
        </Col>
      </Row>
      <Row className="gx-ml-0 gx-mr-0">
        <Col xl={6} lg={6} md={6} sm={12} xs={12}>
              <IconWithTextCard cardColor="cyan" icon="diamond" title={metrics.projects} subTitle="Projects" />
            </Col>
            <Col xl={6} lg={6} md={6} sm={12} xs={12}>
              <IconWithTextCard cardColor="orange" icon="tasks" title={metrics.tasks} subTitle="Tasks" />
            </Col>
            <Col xl={6} lg={6} md={6} sm={12} xs={12}>
              <IconWithTextCard cardColor="teal" icon="team" title={metrics.users} subTitle="Users" />
            </Col>
            <Col xl={6} lg={6} md={6} sm={12} xs={12}>
              <IconWithTextCard cardColor="red" icon="files" title={metrics.invoiceCustomers} subTitle="Clients" />
         </Col>
         
       </Row>
      {/* <Row className="gx-ml-0 gx-mr-0">
        <Col xl={24} lg={24} md={24} sm={24} xs={24}> */}
          <ProjStats />
        {/* </Col>
      </Row> */}
      <Row className="gx-ml-0 gx-mr-0">
        <Col xl={16} lg={16} md={24} sm={24} xs={24} className="equalHeight">
          <ProjectReview />
        </Col>
        <Col xl={8} lg={8} md={8} sm={24} xs={24} className="equalHeight newCustomers">
           <Issues /> 
        </Col>
      </Row>
      <Row className="gx-ml-0 gx-mr-0">
        <Col xl={16} lg={16} md={16} sm={24} xs={24} className="equalHeight">
          <Reportees />
        </Col>
        <Col xl={8} lg={12} md={8} sm={24} xs={24} className="equalHeight newCustomers">
          <RecentWins />
        </Col>
      </Row>
      <Row className="gx-ml-0 gx-mr-0">
        <Col xl={16} lg={24} md={24} sm={24} xs={24} className="equalHeight">
          <Overview />
        </Col>
        <Col xl={8} lg={24} md={24} sm={24} xs={24} className="gx-order-sm-2 equalHeight newCustomers">
          {/* <BalanceHistory /> */}
          <Tickets />
        </Col>
      </Row>
    </Auxiliary>
  );
};

export default ExecDashboard;
