import React, { useEffect, useState } from "react";
import axios from 'axios';

import { Row, Col, Form, Input, Select, Radio, DatePicker, Button, message, Divider, Breadcrumb } from "antd";
import { API_URL } from 'constants/APIConfig';
import Widget from "components/Widget/index";
import { useHistory } from "react-router-dom";

const FormItem = Form.Item;
const { Option } = Select;
const RadioGroup = Radio.Group;
const InputGroup = Input.Group;
const { RangePicker } = DatePicker;
const { TextArea } = Input;

function hasErrors(fieldsError) {
    return Object.keys(fieldsError).some(field => fieldsError[field]);
}
function handleChange(value) {
    console.log(`selected ${value}`);
}
function handleBlur() {
    console.log('blur');
}

function onChange(e) {
    console.log(`checked = ${e.target.checked}`);
}

function handleFocus() {
    console.log('focus');
}

const NewResource = () => {

    const userDetails = JSON.parse(sessionStorage.getItem('user_details'));
    const token = sessionStorage.getItem('token');
    
    //const [divisions, setDivisions] = useState([]);
    const [locations, setLocations] = useState([]);
    //const [departments, setDepartments] = useState([]);
    //const [subDepartments, setSubDepartments] = useState([]);
    const [reportingMgrs, setReportingMgrs] = useState([]);
    const [users, setUsers] = useState([]);
    const [isCM, setIsCM] = useState(0);
    const [isRM, setIsRM] = useState(0);
    const [isActive, setIsActive] = useState(1);
    let history = useHistory();
    function onChangeIsCM(e) {
        setIsCM(e.target.value);
      }
      
      function onChangeIsRM(e) {
        setIsRM(e.target.value);
      }

      function onChangeIsActive(e) {
          setIsActive(e.target.value);
        }

    // UseForm Hook
    const [form] = Form.useForm();

    let newResource = [];

    const onFinish = values => {
        //console.log("reoles", form.getFieldValue('roles'))
        newResource = {
            "employeeId": form.getFieldValue('empid'),
            "displayNameTx": form.getFieldValue('empname'),
            "designation": form.getFieldValue('designation'),
            "joinedOn": form.getFieldValue('doj').format("YYYY-MM-DD"),
            "emailTx": form.getFieldValue('email'),
            "mobileTx": form.getFieldValue('mobile'),
            "primarySkill": form.getFieldValue('pskill'),
            "reportingManagerNm": form.getFieldValue('reporting'),
            "locationId": form.getFieldValue('location'),
            //"departmentId": form.getFieldValue('department'),
            //"subDepartmentId": form.getFieldValue('subdepartment'),
            //"divisionId": form.getFieldValue('division'),
            "competency": form.getFieldValue('competency'),
            "isReportingManager": form.getFieldValue('reportingmgr'),
            "isCompetencyMgr": form.getFieldValue('competencymgr'),
            "activateYnNm": form.getFieldValue('active'),
            "roles": form.getFieldValue('roles'),
            "passwordTx": "Gemini@123",            
        }
        console.log("Data from newResource details ", newResource)
        axios.post(API_URL + '/api/users/signup', newResource, 
        {headers: {
          'x-access-token': `${token}`, 
        'Access-Control-Allow-Origin': '*'}
       })
            .then(response => {
                message.success("Resource Created Successfully");
                form.resetFields();
                history.push('/master/orgstructure/manage')
            })
            .catch(error => {
                console.log(error);
                message.error("Error In creating Project", error)
            });
    }

    /*const getDivisions = () => {
        axios.get(API_URL + '/api/division/all', 
        {headers: {
          'x-access-token': `${token}`, 
        'Access-Control-Allow-Origin': '*'}
       })
            .then(response => {
                setDivisions(response.data.data);
            })
            .catch(error => {
                // Error
                if (error.response) {
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                } else if (error.request) {
                    console.log(error.request);
                } else {
                    console.log('Error', error.message);
                }
                console.log(error.config);
            });
    }*/

    const getLocations = () => {
        axios.get(API_URL + '/api/skillbase/locations/getAllLocations', 
        {headers: {
          'x-access-token': `${token}`, 
        'Access-Control-Allow-Origin': '*'}
       })
            .then(response => {
                setLocations(response.data.data);
            })
            .catch(error => {
                // Error
                if (error.response) {
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                } else if (error.request) {
                    console.log(error.request);
                } else {
                    console.log('Error', error.message);
                }
                console.log(error.config);
            });
    }

    const getReportingMgr = () => {
        axios.get(API_URL + '/api/users/getAllReportingManagers', 
        {headers: {
          'x-access-token': `${token}`, 
        'Access-Control-Allow-Origin': '*'}
       })
            .then(response => {
                setReportingMgrs(response.data.data);
            })
            .catch(error => {
                console.log(error);
            });
    }

    /*const getDepartments = () => {
        axios.get(API_URL + '/api/department/all', 
        {headers: {
          'x-access-token': `${token}`, 
        'Access-Control-Allow-Origin': '*'}
       })
            .then(response => {
                setDepartments(response.data.data);
            })
            .catch(error => {
                // Error
                if (error.response) {
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                } else if (error.request) {
                    console.log(error.request);
                } else {
                    console.log('Error', error.message);
                }
                console.log(error.config);
            });
    }*/

    /*const getSubDepartment = () => {
        axios.get(API_URL + '/api/subDepartment/all', 
        {headers: {
          'x-access-token': `${token}`, 
        'Access-Control-Allow-Origin': '*'}
       })
            .then(response => {
                setSubDepartments(response.data.data);
            })
            .catch(error => {
                // Error
                if (error.response) {
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                } else if (error.request) {
                    console.log(error.request);
                } else {
                    console.log('Error', error.message);
                }
                console.log(error.config);
            });
    }*/

    const getUserList = () => {
        axios.get(API_URL + '/api/users/getall', 
        {headers: {
          'x-access-token': `${token}`, 
        'Access-Control-Allow-Origin': '*'}
       })
            .then(response => {
                //console.log('User List', response.data.data)
                setUsers(response.data.data);
            })
            .catch(error => {
                console.log(error);
            });
    }

    useEffect(() => {
        //getDivisions();
        //getDepartments();
        //getSubDepartment();
        getUserList();
        getReportingMgr();
        getLocations();
    }, [])

    return (
        <>
        <Breadcrumb>
            <Breadcrumb.Item>PMO</Breadcrumb.Item>
            <Breadcrumb.Item><i className="icon icon-chart-tree" /> Org Structure &rarr; <span className="text-helight">New Resource</span></Breadcrumb.Item>
        </Breadcrumb>
        <Form
            form={form}
            initialValues={{ remember: true }}
            name="prjdetails"
            onFinish={onFinish}
            layout="inline"
            align="center"
        >

            <Row >
                <Col lg={24} md={24} sm={24} xs={24} >
            <Widget>
            <Divider orientation="left">Resource</Divider>
            <Row >
                <Col lg={8} md={8} sm={12} xs={24} >
                    <div className="gx-form-row0">
                        <h6 align="left">Emp Id</h6>
                        <Form.Item
                            name="empid"
                            rules={[
                                {
                                    required: true,
                                    message: 'Enter Emp ID',
                                },
                            ]}
                        >
                            <Input placeholder="Emp ID" />

                        </Form.Item>
                    </div>
                </Col>
                <Col lg={8} md={8} sm={12} xs={24} >
                    <h6 align="left">Name</h6>
                    <div className="gx-form-row0">
                        <Form.Item
                            name="empname"
                            rules={[
                                {
                                    required: true,
                                    message: 'Enter resource name',
                                },
                            ]}
                        >
                            <Input placeholder="Name" />

                        </Form.Item>
                    </div>
                </Col>
                <Col lg={8} md={8} sm={12} xs={24} >
                    <div className="gx-form-row0">
                        <h6 align="left">Designation</h6>
                        <Form.Item
                            name="designation"
                            rules={[
                                {
                                    required: true,
                                    message: 'Enter Designation',
                                },
                            ]}
                        >
                            <Input  disabled={!Boolean(isActive)} placeholder="Designation" />
                        </Form.Item>
                    </div>
                </Col>
                <Col lg={8} md={8} sm={12} xs={24} >
                    <div className="gx-form-row0 orgStructureDoj">
                        <h6 align="left">DOJ</h6>
                        <Form.Item
                            name="doj"
                            rules={[
                                {
                                    required: true,
                                    message: 'Enter DOJ',
                                },
                            ]}
                        >
                            <DatePicker format="DD-MM-YYYY"/>
                        </Form.Item>
                    </div>
                </Col>
                <Col lg={8} md={8} sm={12} xs={24} >
                    <div className="gx-form-row0">
                        <h6 align="left">Email</h6>
                        <Form.Item
                            name="email"
                            //label="Client"
                            rules={[
                                {
                                    required: true,
                                    message: 'Enter Email',
                                },
                            ]}
                        >
                            <Input placeholder="Email" />
                        </Form.Item>
                    </div>
                </Col>
                <Col lg={8} md={8} sm={12} xs={24} >
                    <div className="gx-form-row0">
                        <h6 align="left">Mobile</h6>
                        <Form.Item
                            name="mobile"
                            //label="Duration"
                            rules={[
                                {
                                    required: false,
                                    message: 'Enter Mobile',
                                },
                            ]}
                        >
                            <Input  disabled={!Boolean(isActive)} placeholder="Mobile" />
                        </Form.Item>
                    </div>
                </Col>
                <Col lg={8} md={8} sm={12} xs={24} >
                    <div className="gx-form-row0">
                        <h6 align="left">Primary Skill</h6>
                        <Form.Item
                            name="pskill"
                            //label="Estd Hours"
                            rules={[
                                {
                                    required: true,
                                    message: 'Enter primary skill',
                                },
                            ]}
                        >

                            <Input placeholder="Primary Skill" />
                        </Form.Item>
                    </div>
                </Col>
                <Col lg={8} md={8} sm={12} xs={24} >
                    <div className="gx-form-row0">
                        <h6 align="left">Reporting To</h6>
                        <Form.Item
                            name="reporting"
                            //label="Project Status"
                            rules={[
                                {
                                    required: true,
                                    message: 'Enter Reporting To',
                                },
                            ]}
                        >

                            <Select
                                showSearch
                                style={{ width: '100%' }}
                                placeholder="Select a Value"
                                optionFilterProp="children"
                                onChange={handleChange}
                                onFocus={handleFocus}
                                onBlur={handleBlur}
                                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            >
                                {
                                    users.length ? users.map((user, index) => (
                                        <Option key={index} value={user.id}>{user.displayNameTx}</Option>
                                    )) : null
                                }
                            </Select>
                        </Form.Item>
                    </div>
                </Col>
                <Col lg={8} md={8} sm={12} xs={24} >
                    <div className="gx-form-row0">
                        <h6 align="left">Location</h6>
                        <Form.Item
                            name="location"
                            //label="Billable"
                            rules={[
                                {
                                    required: (Boolean(isActive)),
                                    message: 'Enter Location',
                                },
                            ]}
                        >
                            <Select
                             disabled={!Boolean(isActive)}
                                showSearch
                                style={{ width: '100%' }}
                                placeholder="Select a Value"
                                optionFilterProp="children"
                                onChange={handleChange}
                                onFocus={handleFocus}
                                onBlur={handleBlur}
                                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            >
                                {locations.length ? locations.map((location, index) => (
                                    <Option key={index} value={location.id}>{location.name}</Option>
                                )) : null}

                            </Select>
                        </Form.Item>
                    </div>
                </Col>
            </Row>
            {/*<Divider orientation="left"> Department</Divider>
            <Row>
                <Col lg={8} md={8} sm={12} xs={24} >
                    <div className="gx-form-row0">
                        <h6 align="left">Department</h6>
                        <Form.Item
                            name="department"
                            //label="Estd Hours"            
                            rules={[
                                {
                                    required: (Boolean(isActive)),
                                    message: 'Enter Department',
                                },
                            ]}
                        >
                            <Select
                             disabled={!Boolean(isActive)}
                                showSearch
                                style={{ width: '100%' }}
                                placeholder="Select a Value"
                                optionFilterProp="children"
                                onChange={handleChange}
                                onFocus={handleFocus}
                                onBlur={handleBlur}
                                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            >
                                {departments.length ? departments.map((department, index) => (
                                    <Option key={index} value={department.id}>{department.nameTx}</Option>
                                )) : null}
                            </Select>
                        </Form.Item>
                    </div>
                </Col>
                <Col lg={8} md={8} sm={12} xs={24} >
                    <div className="gx-form-row0">
                        <h6 align="left">Sub Department</h6>
                        <Form.Item
                            name="subdepartment"
                            // label="Project Name"
                        >
                            <Select
                             disabled={!Boolean(isActive)}
                                showSearch
                                style={{ width: '100%' }}
                                placeholder="Select a Value"
                                optionFilterProp="children"
                                onChange={handleChange}
                                onFocus={handleFocus}
                                onBlur={handleBlur}
                                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            >
                                {subDepartments.length ? subDepartments.map((subDepartment, index) => (
                                    <Option key={index} value={subDepartment.id}>{subDepartment.nameTx}</Option>
                                )) : null}

                            </Select>
                        </Form.Item>
                    </div>
                </Col>
                <Col lg={8} md={8} sm={12} xs={24} >
                    <div className="gx-form-row0">
                        <h6 align="left">Division</h6>
                        <Form.Item
                            name="division"
                            //label="Billable"
                            rules={[
                                {
                                    required: (Boolean(isActive)),
                                    message: 'Enter Division',
                                },
                            ]}
                        >
                            <Select
                             disabled={!Boolean(isActive)}
                                showSearch
                                style={{ width: '100%' }}
                                placeholder="Select a Value"
                                optionFilterProp="children"
                                onChange={handleChange}
                                onFocus={handleFocus}
                                onBlur={handleBlur}
                                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            >
                                {divisions.length ? divisions.map((division, index) => (
                                    <Option key={index} value={division.id}>{division.nameTx}</Option>
                                )) : null}

                            </Select>
                        </Form.Item>
                    </div>
                </Col>
            </Row>*/}

            <Divider orientation="left">Competency</Divider>
            <Row>
                <Col lg={8} md={8} sm={12} xs={24} >
                    <div className="gx-form-row0">
                        <h6 align="left">Competency</h6>
                        <Form.Item
                            name="competency"
                            //label="Project Manager"
                            rules={[
                                {
                                    required: (Boolean(isActive)),
                                    message: 'Enter Competency',
                                },
                            ]}
                        >

{/*                             <Select
                                showSearch
                                style={{ width: '100%' }}
                                placeholder="Select a Value"
                                optionFilterProp="children"
                                onChange={handleChange}
                                onFocus={handleFocus}
                                onBlur={handleBlur}
                                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            >
                                {departments.length ? departments.map((department, index) => (
                                    <Option key={index} value={department.id}>{department.nameTx}</Option>
                                )) : null} 
                           
                            </Select> */}
                            <Input  disabled={!Boolean(isActive)} placeholder="Competency" />
                        </Form.Item>
                    </div>
                </Col>

                <Col lg={8} md={8} sm={12} xs={24} >
                    <div className="gx-form-row0">
                        <h6 align="left">Is Competency Manager?</h6>
                        <Form.Item
                            name="competencymgr"
                        // label="Project Name"
                        >
                        <RadioGroup  disabled={!Boolean(isActive)} name="competencymgr" onChange={(e) => onChangeIsCM(e)} value={isCM}>
                                <Radio value={0}>No</Radio>
                                <Radio value={1}>Yes</Radio>
                        </RadioGroup>
                        </Form.Item>
                    </div>
                </Col>
                
                <Col lg={8} md={8} sm={12} xs={24} >
                    <div className="gx-form-row0">
                        <h6 align="left">Is Reporting Manager?</h6>
                        <Form.Item
                            name="reportingmgr"
                        // label="Project Name"
                        >
                        <RadioGroup  disabled={!Boolean(isActive)} name="reportingmgr" onChange={(e) => onChangeIsRM(e)} value={isRM}>
                                <Radio value={0}>No</Radio>
                                <Radio value={1}>Yes</Radio>
                        </RadioGroup>
                        </Form.Item>
                    </div>
                </Col>
                <Col lg={8} md={8} sm={12} xs={24} >
                  <h6 align="left">Roles</h6>
                  <div className="gx-form-row0">
                    <Form.Item
                      name="roles"
                      //label="Billable"
                      rules={[
                        {
                          required: (Boolean(isActive)),
                          message: 'Roles',
                        },
                      ]}
                    >
                      <Select
                       disabled={!Boolean(isActive)}
                        showSearch
                        style={{ width: '100%' }}
                        placeholder="Select a Value"
                        optionFilterProp="children"
                        mode="multiple"
                        onChange={handleChange}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                      >
                        <Option value={1}>Executive</Option>
                        <Option value={2}>PMO</Option>
                        <Option value={3}>Project Manager</Option>
                        <Option value={4}>Employee</Option>
                        <Option value={5}>Admin</Option>
                        <Option value={6}>Sales</Option>
                        <Option value={7}>Human Resources</Option>
                        {/* <Option value="Yet to assign">Yet to assign</Option> */}

                      </Select>
                    </Form.Item>
                  </div>
                </Col>

            </Row>
            <Divider orientation="left">Status</Divider>
            <Row>
                <Col lg={8} md={8} sm={12} xs={24} >
                    <div className="gx-form-row0">
                        <h6 align="left">Is Active?</h6>
                        <Form.Item
                            name="active"
                        >
                            <RadioGroup name="active" onChange={(e) => onChangeIsActive(e)} value={isActive}>
                                <Radio value={0}>No</Radio>
                                <Radio value={1}>Yes</Radio>
                            </RadioGroup>
                        </Form.Item>
                    </div>
                </Col>
            </Row>

            <Row gutter={24}>
                <Col lg={24} md={24} sm={24} xs={24} >
                    <div className="gx-form-row0">
                        <Form.Item
                            name="submit"
                        >
                            <Button type="primary" className="add-btn" htmlType="submit">Submit</Button>
                            <Button type="primary" className="add-btn gx-mr-2" onClick={() => history.push('/orgstructure/manage')}>Back</Button>
                        </Form.Item>
                    </div>
                </Col>
            </Row>
            </Widget>
            </Col>
            </Row>
        </Form>
        </>
    );
};

export default NewResource;
