import React, { useEffect, useState } from "react";
import axios from 'axios';
import { Button, Table, Row, Col, Input, Form, message } from "antd";
import Auxiliary from "util/Auxiliary";
import { API_URL } from 'constants/APIConfig';

const ManageDepartment = () => {
    const [allDepartment, setAllDepartment] = useState([]);
    const [form] = Form.useForm();
    const token = sessionStorage.getItem('token');

    const getAllDepartment = () => {
        axios.get(`${API_URL}/api/sb_department/all?t=${new Date().getTime()}`,
            {
                headers: {
                    'x-access-token': `${token}`,
                    'Access-Control-Allow-Origin': '*'
                }
            })
            .then(response => {
                setAllDepartment(response.data.data);
            })
            .catch(error => {
                console.log(error)
            });
    }

    const onFinish = values => {
        axios.post(API_URL + '/api/sb_department/create', { nameTx: form.getFieldValue('departmentName').trim() },
            {
                headers: {
                    'x-access-token': `${token}`,
                    'Access-Control-Allow-Origin': '*'
                }
            })
            .then(response => {
                message.success(`Department added sucessfully`);
                getAllDepartment()
                form.resetFields()
            })
            .catch(error => {
                console.log(error);
                message.error("Error In creating Department", error)
            });
    }

    useEffect(() => {
        getAllDepartment();
    }, [])

    const columns = [
        { title: 'Name', dataIndex: 'nameTx', align: 'center' },
    ];
    return (
        <Auxiliary>
            <center><h4>Manage Departments</h4></center>
            <Row gutter={24}>
                <Col lg={24} md={24} sm={24} xs={24} >
                    <div className="add-skills-main content-height-100">
                        <Row className="header-border">
                        </Row>
                        <div className="skill-form">
                            <Form
                                form={form}
                                initialValues={{ remember: true }}
                                name="newDepartment"
                                onFinish={onFinish}
                                layout="inline"
                                align="center"
                            >

                                <Row gutter={24}>
                                    <Col lg={12} md={12} sm={12} xs={24} >
                                        <div className="gx-form-row0">
                                            <h6 align="left">Department Name</h6>
                                            <Form.Item
                                                name="departmentName"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Enter Department Name',
                                                    },
                                                    {
                                                        validator(_, value) {
                                                          let sArr = allDepartment.map(s=> s.nameTx.toLowerCase()).filter(s => s.trim() === (value + '').trim().toLowerCase())
                                                          if(sArr.length > 0) {
                                                            return Promise.reject('Duplicate Department Name')
                                                          }
                                                          return Promise.resolve();
                                                        },
                                                      }
                                                ]}
                                                hasFeedback>
                                                <Input placeholder="Department Name" autoComplete="off" maxLength={250}/>
                                            </Form.Item>
                                        </div>
                                    </Col>
                                    <Col lg={12} md={12} sm={12} xs={24} >
                                        <div className="gx-form-row0" style={{float: "left", marginTop: "20px"}}>
                                            <Form.Item>
                                                <Button type="primary" className="add-btn scale-x" htmlType="submit">Add Department</Button>
                                            </Form.Item>
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        </div>
                    </div >
                </Col>
            </Row>
            <Table columns={columns} dataSource={allDepartment} rowKey={'id'} />
        </Auxiliary>
    );
};

export default ManageDepartment;