import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import axios from 'axios';
import { Col, Row } from "antd";
import { Table, Select, Form, Tooltip, Button } from "antd";
import Auxiliary from "util/Auxiliary";
import { API_URL } from 'constants/APIConfig';
import { FormOutlined, EyeOutlined } from '@ant-design/icons';

const { Option } = Select;


const ManageProjectCost = () => {
  const [projCost, setProjCost] = useState([]);
  const [projects, setProjects] = useState([]);
  const [form] = Form.useForm();

  let history = useHistory();
  const userDetails = JSON.parse(sessionStorage.getItem('user_details'));
  const token = sessionStorage.getItem('token');

  const getProjectList = () => {
    axios.get(API_URL + `/api/project/v2/projectsByManager?userId=${userDetails.id}`, 
    {headers: {
      'x-access-token': `${token}`, 
    'Access-Control-Allow-Origin': '*'}
   })
      .then(response => {
        if (Object.keys(response.data.data).length > 0) {
          setProjects(response.data.data);
          form.setFieldsValue({
            prjname: response.data.data[0].id,
          })
          getProjCost(response.data.data[0].id);
        }
      })
      .catch(error => {
        // Error
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log('Error', error.message);
        }
        console.log(error.config);
      });
  }
  const onProjectChange = value => {
    getProjCost(value)
  };


  const costCols = [
    {
      title: 'Spend Type', dataIndex: 'spendType', fixed: 'left', width: 150,
      sorter: (a, b) => String(a.spendType).localeCompare(String(b.spendType)),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Planned Spend', dataIndex: 'plannedSpend', width: 150,
      sorter: (a, b) => String(a.plannedSpend).localeCompare(String(b.plannedSpend)),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Actual Spend', dataIndex: 'actualSpend', width: 150,
      sorter: (a, b) => String(a.actualSpend).localeCompare(String(b.actualSpend)),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Variance', dataIndex: 'variance', width: 150,
      sorter: (a, b) => String(a.variance).localeCompare(String(b.variance)),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Comments', dataIndex: 'comments', width: 150,
      sorter: (a, b) => String(a.comments).localeCompare(String(b.comments)),
      sortDirections: ['descend', 'ascend'],
    },
    { title: 'Action', fixed: 'right', width: 100, dataIndex: 'id', render: text => <div><span> <a href={'/cost/manage/' + text}><FormOutlined style={{ padding: '10px' }} /></a></span> </div> },
  ];
  const getProjCost = (projId) => {
    axios.get(API_URL + `/api/project_cost/getProjectCost?projectId=${projId}`, 
    {headers: {
      'x-access-token': `${token}`, 
    'Access-Control-Allow-Origin': '*'}
   })
      .then(response => {
        if (Object.keys(response.data.data).length > 0) {
          setProjCost(response.data.data);
        }
        console.log("Project Cost", response.data.data);
      })
      .catch(error => {
        // Error
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log('Error', error.message);
        }
        console.log(error.config);
      });
  }
  useEffect(() => {
    getProjectList();
  }, [])

  return (
    <Auxiliary> 
      <Form
        form={form}
        initialValues={{ remember: true }}
        name="ohealth"
       // onFinish={onFinish}
        layout="inline"
        align="center"
      >
      <Row gutter={24}>
       <Col xl={16} lg={16} md={16} sm={24} xs={24}>
          <div className="gx-form-row0" >
            <Form.Item
              name="prjname"
              // label="Project Name"
              rules={[
                {
                  required: true,
                  message: 'Enter project name',
                },
              ]}
            >
              <Select
                showSearch
                style={{ width: '100%' }}
                placeholder="Projects"
                optionFilterProp="children"
                onChange={onProjectChange}
                // size="small"
                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              >
                {
                  projects.length ? projects.map((project, index) => (
                    <Option key={index} value={project.id}>{project.name}</Option>
                  )) : null
                }
              </Select>
            </Form.Item>
          </div>
        </Col>

        <Col xl={8} lg={8} md={8} sm={24} xs={24}>
          <Button type="primary" className="add-btn" onClick={() => history.push('/cost/new')}> New Cost </Button>
        </Col>
      </Row>
      </Form>
      <Row gutter={24}>
        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
          <Table className="remove-mFixCell3" columns={costCols} dataSource={projCost} pagination={{ pageSize: 10 }} scroll={{ x: 1300 }} />
        </Col>
      </Row>
    </Auxiliary>
  );
};

export default ManageProjectCost;
