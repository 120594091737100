import React, { useEffect, useState } from "react";
import { Table, Tag, Row, Col, Form, Input, Select, message, DatePicker, Button, Modal, Breadcrumb, Divider, Switch } from "antd";
import axios from 'axios';
import { API_URL } from 'constants/APIConfig';
import moment from 'moment';
import 'moment/min/locales';
import Auxiliary from "../../../util/Auxiliary";
import Widget from "../../../components/Widget/index";
import { FormOutlined } from '@ant-design/icons';

const { Option } = Select;
const { TextArea } = Input;

const TravelDeskRequest = () => {

    const [form] = Form.useForm();
    const userDetails = JSON.parse(sessionStorage.getItem('user_details'));
    const token = sessionStorage.getItem('token');
    const [loading, setLoading] = useState(false);
    const [year, setYear] = useState(moment().weekYear());
    const [newTicket, setNewTicket] = useState(false);
    const [projects, setProjects] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [updateRecordId, setUpdateRecordId] = useState(null);
    const [purposeCount, setPurposeCount] = React.useState(0);
    const [goalCount, setGoalCount] = React.useState(0);
    const [expectedOutcomeCount, setExpectedOutcomeCount] = React.useState(0);

    const [adhocReq, setAdhocReq] = React.useState(false);

    const purposeRecalculate = e => {
        setPurposeCount(e.target.value.length);
    }
    const goalRecalculate = e => {
        setGoalCount(e.target.value.length);
    }
    const expectedOutcomeRecalculate = e => {
        setExpectedOutcomeCount(e.target.value.length);
    }
    const onChangeYear = (date, yearString) => {
        if (date != null && yearString != null) {
            setYear(yearString)
        }
    }

    const onOkModal = () => {
        let values = form.getFieldsValue();
        values['journeyDate'] = values['journeyDate'].format('YYYY-MM-DD')
        if (values['returnJourneyDate']) {
            values['returnJourneyDate'] = values['returnJourneyDate'].format('YYYY-MM-DD')
        }
        values['userId'] = userDetails.id
        projects.forEach(p => {
            if (p['id'] === values['projectId']) {
                values['projectManagerId'] = p['project_manager_id']
                values['reportingManagerId'] = p['reporting_manager_nm']
            }
        })
        if (updateRecordId) {
            values['id'] = updateRecordId
            setUpdateRecordId(null)
        }

        setIsModalOpen(false);
        createOrUpdateTravelRequest(values)
        setNewTicket(false);
    }

    const onFinish = values => {
        setIsModalOpen(true)
    };

    const updateTravelRequest = (record) => {
        setNewTicket(true);
        form.resetFields()
        setUpdateRecordId(record['id'])
        if(record['isAdhoc']) {
            setAdhocReq(true)
        }
        form.setFieldsValue({
            'projectId': record['projectId'],
            'fromPlace': record['fromPlace'],
            'toPlace': record['toPlace'],
            'journeyDate': record['journeyDate'] && moment(record['journeyDate']),
            'returnJourneyDate': record['returnJourneyDate'] && moment(record['returnJourneyDate']),
            'purpose': record['purpose'],
            'goals': record['goals'],
            'expectedOutcome': record['expectedOutcome'],
            'mobileNumber': record['mobileNumber'] + '',
            'isAdhoc': record['isAdhoc'] === true
        })
    }

    const [travelRequestDataList, setTravelRequestDataList] = useState([]);
    const travelRequestColList = [
        {
            title: 'Project', dataIndex: 'project_name', width: 100, align: 'center',
            render: (text, record) => {
                return (
                    <div>
                        <span> {record.project.name}</span>
                    </div>
                );
            },
        },
        {
            title: 'PM Status', dataIndex: 'pmAction', width: 50, align: 'center',
            render: (text, record) => {
                return (
                    <div>
                        {(() => {
                            if (record.pmAction === "Pending")
                                return <Tag color="blue" >{"Pending"}</Tag>
                            if (record.pmAction === "Approved")
                                if(record.isAdminCreated) {
                                    return <Tag color="green" >{"Auto Approved"}</Tag>
                                } else {
                                    return <Tag color="green" >{"Approved"}</Tag>
                                }
                            if (record.pmAction === "Rejected")
                                return <Tag color="red" >{"Rejected"}</Tag>
                        })()}
                    </div>
                );
            },
        },
        {
            title: 'RM Status', dataIndex: 'rmAction', width: 50, align: 'center',
            render: (text, record) => {
                return (
                    <div>
                        {(() => {
                            if (record.rmAction === "Pending")
                                return <Tag color="blue" >{"Pending"}</Tag>
                            if (record.rmAction === "Approved")
                                if(record.isAdminCreated) {
                                    return <Tag color="green" >{"Auto Approved"}</Tag>
                                } else {
                                    return <Tag color="green" >{"Approved"}</Tag>
                                }
                            if (record.rmAction === "Rejected")
                                return <Tag color="red" >{"Rejected"}</Tag>
                        })()}
                    </div>
                );
            },
        },
        {
            title: 'Infra Admin Status', dataIndex: 'infraAdminAction', width: 50, align: 'center',
            render: (text, record) => {
                return (
                    <div>
                        {(() => {
                            if(record.isAdhoc) {
                                if (record.infraAdminAction === "Pending")
                                    return <Tag color="blue" >{"Pending"}</Tag>
                                if (record.infraAdminAction === "Approved")
                                    return <Tag color="green" >{"Approved"}</Tag>
                                if (record.infraAdminAction === "Rejected")
                                    return <Tag color="red" >{"Rejected"}</Tag>
                            } else {
                                return 'NA'
                            }
                        })()}
                    </div>
                );
            },
        },
        {
            title: 'Admin Created', dataIndex: 'isAdminCreated', width: 50, align: 'center',
            render: (text, record) => {
                return (
                    <div>
                        <span> {record.isAdminCreated ? 'Yes' : 'No'}</span>
                    </div>
                );
            },
        },
        {
            title: 'Admin Status', dataIndex: 'adminAction', width: 50, align: 'center',
            render: (text, record) => {
                return (
                    <div>
                        {(() => {
                            if (record.adminAction === "Pending")
                                return <Tag color="blue" >{"Pending"}</Tag>
                            if (record.adminAction === "Approved")
                                return <Tag color="green" >{"Approved"}</Tag>
                            if (record.adminAction === "Rejected")
                                return <Tag color="red" >{"Rejected"}</Tag>
                        })()}
                    </div>
                );
            },
        },
        {
            title: 'Action', fixed: 'right', width: 50, dataIndex: 'action',
            render: (text, record) =>
                <div className="gx-d-flex">
                    <span> {(record.pmAction === "Rejected" || record.rmAction === "Rejected" || record.infraAdminAction === "Rejected" || record.adminAction === "Rejected") 
                    && <Button className="approved_btn" shape="circle" onClick={event => updateTravelRequest(record)} htmlType="submit" icon={<FormOutlined style={{ padding: '10px' }} />} > </Button>}</span>
                </div>
        },
    ];

    const getAllTravelRequestsCreatedByUser = () => {
        setLoading(true)
        axios.get(API_URL + `/api/travel_desk/getAllTravelRequestsCreatedByUserId?userId=${userDetails.id}&year=${year}`,
            {
                headers: {
                    'x-access-token': `${token}`,
                    'Access-Control-Allow-Origin': '*'
                }
            })
            .then(response => {
                setLoading(false)
                if (response.data && response.data.data) {
                    response.data.data.forEach((d, i) => {
                         d['key'] = i
                        })
                    setTravelRequestDataList(response.data.data)
                }
            })
            .catch(error => {
                setLoading(false);
            });
    }

    const getProjectsAssignedToUserId = () => {
        setLoading(true)
        axios.get(API_URL + `/api/travel_desk/getProjectsAssignedToUserId?userId=${userDetails.id}`,
            {
                headers: {
                    'x-access-token': `${token}`,
                    'Access-Control-Allow-Origin': '*'
                }
            })
            .then(response => {
                setLoading(false)
                if (response.data && response.data.data) {
                    response.data.data.forEach((d, i) => { d['key'] = i })
                    setProjects(response.data.data)
                }
            })
            .catch(error => {
                setLoading(false);
            });
    }

    const createOrUpdateTravelRequest = (data) => {
        axios.post(API_URL + `/api/travel_desk/createOrUpdateTravelRequest`, data,
            {
                headers: {
                    'x-access-token': `${token}`,
                    'Access-Control-Allow-Origin': '*'
                }
            })
            .then(response => {
                message.success(response.data.data);
                getAllTravelRequestsCreatedByUser();
            })
            .catch(error => {
                message.error("Unexpected error occurred.");
            });
    }

    const adhocRequest = (v) => {
        setAdhocReq(v)
        form.setFieldsValue({'journeyDate': undefined})
    }

    useEffect(() => {
        getProjectsAssignedToUserId()
    }, [token])
    useEffect(() => {
        getAllTravelRequestsCreatedByUser()
    }, [year])
    return (
        <Auxiliary>
            <Row className="m_0 gx-pt-2">
                <Col xl={6} lg={6} md={6} sm={24} xs={24} className="flex_style gx-pl-0 ant-row-start">
                    <Breadcrumb>
                        <Breadcrumb.Item>Travel Desk</Breadcrumb.Item>
                        <Breadcrumb.Item className="text-helight"><i className="icon icon-hotel-booking" /> Travel Request</Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
                <Col xl={14} lg={14} md={14} sm={24} xs={24} className="flex_style gx-pr-0">
                    {!newTicket && 
                        <div className="newprojectBtn">
                            <span>
                                <Button type="link" disabled={newTicket} onClick={() => { setNewTicket(true); setUpdateRecordId(null); form.resetFields(); }}><i className="icon icon-hotel-booking" /> &nbsp; New Request</Button>
                            </span>
                        </div>
                    }
                </Col>
                <Col xl={4} lg={4} md={4} sm={24} xs={24} className="flex_style gx-pr-0 dateRangeBlock">
                    {!newTicket && <DatePicker picker="year" onChange={onChangeYear} defaultValue={moment()} bordered={true} allowClear={false} />}
                </Col>
            </Row>
            
            {newTicket && <Widget title={updateRecordId ? "Update Request" : "Create New Request"} styleName={updateRecordId ? "gx-card-update" : ""}>
                <Form
                    form={form}
                    name="newTravelRequest"
                    onFinish={onFinish}
                    layout="inline"
                    align="center"
                >
                    <Row gutter={24}>
                        <Col lg={24} md={24} sm={24} xs={24} >
                        <p>Note: FOR TRAVEL DATES WITHIN THE NEXT 10 DAYS, KINDLY ACTIVATE THE AD-HOC REQUEST</p>
                            <Form.Item name="isAdhoc" label="Do you want to create Ad-hoc request: " valuePropName="checked" initialValue={false}>
                                <Switch onChange={(v) => { adhocRequest(v) }} className="switchSpace" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col lg={8} md={8} sm={12} xs={24} >
                            <div className="gx-form-row0">
                                <h6 align="left">Project</h6>
                                <Form.Item
                                    name="projectId"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Enter select the project',
                                        },
                                    ]}
                                >
                                    <Select
                                        showSearch
                                        style={{ width: '100%' }}
                                        placeholder="Select the project"
                                        filterOption={(input, option) => option.name.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                    >
                                        {
                                            projects.length > 0 && projects.map((project, index) => (
                                                <Option key={index} value={project.id} name={project.project_name}>{project.project_name}</Option>
                                            ))
                                        }
                                    </Select>
                                </Form.Item>
                            </div>
                        </Col>

                        <Col lg={8} md={8} sm={12} xs={24} >
                            <div className="gx-form-row0">
                                <h6 align="left">Source</h6>
                                <Form.Item
                                    name="fromPlace"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Enter the source name',
                                        },
                                    ]}
                                >
                                    <Input placeholder="Source Place" />
                                </Form.Item>
                            </div>
                        </Col>
                        <Col lg={8} md={8} sm={12} xs={24} >
                            <div className="gx-form-row0">
                                <h6 align="left">Destination</h6>
                                <Form.Item
                                    name="toPlace"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Enter the destination name',
                                        },
                                    ]}
                                >
                                    <Input placeholder="Destination Place" />
                                </Form.Item>
                            </div>
                        </Col>
                        <Col lg={8} md={8} sm={12} xs={24} >
                            <div className="gx-form-row0">
                                <h6 align="left">Date of Journey</h6>
                                <Form.Item
                                    name="journeyDate"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Enter the Date of Journey',
                                        },
                                    ]}
                                >
                                    {adhocReq ? <DatePicker picker="date" bordered={true} allowClear={false}
                                        disabledDate={(current) => {
                                            return current && (current > moment().add(9, 'days').endOf('day') || current < moment().endOf('day')) }} /> 
                                        : <DatePicker picker="date" bordered={true} allowClear={false} defaultPickerValue={moment().add(9, 'days')}
                                        
                                        disabledDate={(current) => { return current && current < moment().add(9, 'days').endOf('day'); }} />}
                                </Form.Item>
                            </div>
                        </Col>

                        <Col lg={8} md={8} sm={12} xs={24} >
                            <div className="gx-form-row0">
                                <h6 align="left">Date of Return Journey</h6>
                                <Form.Item
                                    name="returnJourneyDate"
                                    rules={[
                                        ({ getFieldValue }) => ({
                                            validator(_, value) {
                                                if (value && getFieldValue('journeyDate') != null && moment(getFieldValue('journeyDate')).isAfter(value)) {
                                                    return Promise.reject("Please enter the return date, after or equal to journey date");
                                                }
                                                return Promise.resolve();
                                            },
                                        })]}
                                >
                                    <DatePicker picker="date" bordered={true}
                                        disabledDate={(current) => { return current && current < moment().add(9, 'days').endOf('day'); }} />
                                </Form.Item>
                            </div>
                        </Col>
                        <Col lg={8} md={8} sm={12} xs={24} >
                            <div className="gx-form-row0">
                                <h6 align="left">Mobile Number</h6>
                                <Form.Item
                                    name="mobileNumber"
                                    rules={[
                                        () => ({
                                            validator(_, value) {
                                                if (!value || (value && value.trim().length != 10) || isNaN(value)) {
                                                    return Promise.reject("Please enter the valid mobile number");
                                                }
                                                return Promise.resolve();
                                            },
                                        })]}
                                >
                                    <Input placeholder="Mobile Number" />
                                </Form.Item>
                            </div>
                        </Col>

                        <Col lg={24} md={24} sm={24} xs={24} >
                            <div className="gx-form-row0">
                                <h6 align="center"><b>Purpose of Business Travel</b></h6>
                                <h6 align="left">Purpose:</h6>
                                <Form.Item
                                    name="purpose"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter the Purpose',
                                        },
                                    ]}
                                >
                                  <TextArea placeholder="Briefly explain why this trip is necessary (e.g., project alignment meeting, client pitch,Project Deliverables)." rows={4} maxLength={255} onChange={purposeRecalculate} />
                                </Form.Item>
                                <p style={{ textAlign: 'right' }}> {`${purposeCount} / 255`} </p>
                            </div>

                            <div className="gx-form-row0">
                            <h6 align="left">Goals:</h6>
                                <Form.Item
                                    name="goals"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter the Goals',
                                        },
                                    ]}
                                >
                                    <TextArea placeholder="List 2-3 concrete goals for the trip (e.g., finalize project timelines, meet key stakeholders, Project Assignment at client place)." rows={4} maxLength={255} onChange={goalRecalculate} />
                                </Form.Item>
                                <p style={{ textAlign: 'right' }}> {`${goalCount} / 255`} </p>
                            </div>

                            <div className="gx-form-row0">
                                <h6 align="left">Expected Outcome:</h6>
                                <Form.Item
                                    name="expectedOutcome"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter the Expected Outcome',
                                        },
                                    ]}
                                >
                                    <TextArea placeholder="Outline the expected benefits (e.g., improved project coordination, signed contract)." rows={4} maxLength={255} onChange={expectedOutcomeRecalculate} />
                                </Form.Item>
                                <p style={{ textAlign: 'right' }}> {`${expectedOutcomeCount} / 255`} </p>
                            </div>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col lg={24} md={24} sm={24} xs={24} className="gx-p-0 buttonGroup">
                            <div className="gx-form-row0">
                                <Form.Item >
                                    <Button type={updateRecordId ? "default" : "primary"} className={`add-btn ${updateRecordId ? "reject_btn_default" : ""}`} htmlType="submit">{updateRecordId ? "Update" : "Submit"}</Button>
                                    <Button type="primary" className="add-btn gx-mr-2" onClick={() => { form.resetFields(); setNewTicket(false); }}>Cancel</Button>
                                </Form.Item>
                            </div>

                        </Col>
                    </Row>
                </Form>
            </Widget>
            }
            <Modal closable={false} centered title="Confirmation" visible={isModalOpen} onOk={onOkModal}
                onCancel={() => { setIsModalOpen(false);}}
                wrapClassName={`${updateRecordId ? "modal-ticket-update" : "modal-ticket-create"}`}>
                <p>{updateRecordId ? 'Are you sure to update the request? Once updated you will not be able to edit.'
                    : 'Are you sure to submit the new request? Once submitted you will not be able to edit.'}</p>
            </Modal>
            {!newTicket && <Table loading={loading} className="gx-table-responsive" columns={travelRequestColList} dataSource={travelRequestDataList} bordered={false}
                scroll={{ x: 800 }}
                expandedRowClassName={() => "travel-request-list"}
                expandable={{
                    expandedRowRender: (record) => <>
                    <div className="emp_section">
                        <Row xl={24} lg={24} md={24} sm={24} xs={24}>
                            <Col xl={6} lg={6} md={6} sm={6} xs={6}><b>Project</b> <p>{record.project.name}</p></Col>
                            <Col xl={6} lg={6} md={6} sm={6} xs={6}><b>From</b> <p>{record.fromPlace}</p></Col>
                            <Col xl={6} lg={6} md={6} sm={6} xs={6}><b>To</b> <p>{record.toPlace}</p></Col>
                            <Col xl={6} lg={6} md={6} sm={6} xs={6}><b>Mobile No</b> <p>{record.mobileNumber}</p></Col>
                        </Row>
                        <Row xl={24} lg={24} md={24} sm={24} xs={24}>
                            <Col xl={6} lg={6} md={6} sm={6} xs={6}><b>Journey Date</b> <p>{record.journeyDate && moment(record.journeyDate).format('ll')}</p></Col>
                            <Col xl={6} lg={6} md={6} sm={6} xs={6}><b>Return Journey Date</b> <p>{record.returnJourneyDate && moment(record.returnJourneyDate).format('ll')}</p></Col>
                            <Col xl={6} lg={6} md={6} sm={6} xs={6}><b>Requested Date</b> <p>{record.created_date && moment(record.created_date).format('ll')}</p></Col>
                            <Col xl={6} lg={6} md={6} sm={6} xs={6}><b>is Ad-hoc Request</b> <p>{record.isAdhoc ? 'Yes' : 'No'}</p></Col>
                            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                            <b>Purpose: </b> <p>{
                                (() => {
                                    return record.purpose.split('\n').map((subStr) => {
                                        return (
                                          <>
                                            {subStr}
                                            <br />
                                          </>
                                        );
                                      })
                                })()
                            }
                            </p>
                        </Col>
                        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                            <b>Goals: </b> <p>{
                            (() => {
                                    return record.goals.split('\n').map((subStr) => {
                                        return (
                                          <>
                                            {subStr}
                                            <br />
                                          </>
                                        );
                                      })
                                })()}
                            </p>
                        </Col>
                        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                            <b>Expected Outcome: </b> <p>{
                            (() => {
                                    return record.expectedOutcome.split('\n').map((subStr) => {
                                        return (
                                          <>
                                            {subStr}
                                            <br />
                                          </>
                                        );
                                      })
                                })()}
                            </p>
                        </Col>
                        </Row>
                        </div>
                        <Divider orientation="left"><b>Project Manager</b></Divider>
                        <div className="pm_section">
                        <Row xl={24} lg={24} md={24} sm={24} xs={24}>
                            <Col xl={6} lg={6} md={6} sm={6} xs={6}><b>Name</b> <p>{record.projectManager.displayNameTx}</p></Col>
                            <Col xl={6} lg={6} md={6} sm={6} xs={6}><b>Action Date</b> <p>{record.pmActionDate && moment(record.pmActionDate).format('lll')}</p></Col>
                            <Col xl={12} lg={12} md={12} sm={12} xs={12}><b>Purpose of Business Travel</b> <p>{record.pmComment}</p></Col>
                        </Row>
                        </div>
                        <Divider orientation="left"><b>Resource Manager</b></Divider>
                        <div className="rm_section">
                        <Row xl={24} lg={24} md={24} sm={24} xs={24}>
                            <Col xl={6} lg={6} md={6} sm={6} xs={6}><b>Name</b> <p>{record.reportingManager.displayNameTx}</p></Col>
                            <Col xl={6} lg={6} md={6} sm={6} xs={6}><b>Action Date</b> <p>{record.rmActionDate && moment(record.rmActionDate).format('lll')}</p></Col>
                            <Col xl={12} lg={12} md={12} sm={12} xs={12}><b>Purpose of Business Travel</b> <p>{record.rmComment}</p></Col>
                        </Row>
                        </div>
                        { record.isAdhoc &&
                        <>
                        <Divider orientation="left"><b>Approved By Infra Admin</b></Divider>
                        <div className="infra_admin_section">
                        <Row xl={24} lg={24} md={24} sm={24} xs={24}>
                            <Col xl={6} lg={6} md={6} sm={6} xs={6}><b>Name</b> <p>{record.infraAdmin?.displayNameTx}</p></Col>
                            <Col xl={6} lg={6} md={6} sm={6} xs={6}><b>Action Date</b> <p>{record.infraAdminActionDate && moment(record.infraAdminActionDate).format('lll')}</p></Col>
                            <Col xl={12} lg={12} md={12} sm={12} xs={12}><b>Purpose of Business Travel</b> <p>{record.infraAdminComment}</p></Col>
                        </Row>
                        </div>
                        </>
                        }
                        { record.admin &&
                        <>
                        <Divider orientation="left">Approved By Admin</Divider>
                        <div className="admin_section">
                        <Row xl={24} lg={24} md={24} sm={24} xs={24}>
                            <Col xl={6} lg={6} md={6} sm={6} xs={6}><b>Name</b> <p>{record.admin.displayNameTx}</p></Col>
                            <Col xl={6} lg={6} md={6} sm={6} xs={6}><b>Action Date</b> <p>{record.adminActionDate && moment(record.adminActionDate).format('lll')}</p></Col>
                            <Col xl={12} lg={12} md={12} sm={12} xs={12}><b>Purpose of Business Travel</b> <p>{record.adminComment}</p></Col>
                        </Row>
                        </div>
                        </>
                        }
                        
                    </>
                }}
            />}
        </Auxiliary>
    );
};
export default TravelDeskRequest;