import React, { useEffect, useState } from "react";
import Widget from "components/Widget/index";
import { Button, Col, Row } from "antd";
import LineIndicator from "../LineIndicator";
import Auxiliary from "util/Auxiliary";
import { useHistory } from "react-router-dom";
import axios from 'axios';
import { API_URL } from 'constants/APIConfig';


const ProjStats = () => {
  let history = useHistory();
  //const userDetails = JSON.parse(sessionStorage.getItem('user_details'));
  const token = sessionStorage.getItem('token');
  
  const [projStats, setProjStats] = useState([]);

  const getProjStats = () => {
    axios.get(API_URL + '/api/project/v2/all/v2/status', 
    {headers: {
      'x-access-token': `${token}`, 
    'Access-Control-Allow-Origin': '*'}
   })
      .then(response => {
        //console.log('Project Stats new', response.data.data);
        setProjStats(response.data.data);
      })
      .catch(error => {
        console.log(error)
      });
  }
  let extALL= + (projStats.External && projStats.External.total) ;
  let extArch= + (projStats.External && projStats.External.Archive);
  let ext_total = extALL - extArch;

  let ext_new = + (projStats.External && projStats.External.New);
  let ext_inprogress = + (projStats.External && projStats.External.InProgress);
  let ext_hold = + (projStats.External && projStats.External.Hold);
  let ext_completed = + (projStats.External && projStats.External.Completed);

  let ext_new_percentage = Math.round((ext_new/ext_total)*100);
  let ext_inprogress_percentage = Math.round((ext_inprogress/ext_total)*100);
  let ext_hold_percentage = Math.round((ext_hold/ext_total)*100);
  let ext_completed_percentage = Math.round((ext_completed/ext_total)*100);

  let intALL= + (projStats.Internal && projStats.Internal.total) ;
  let intArch= + (projStats.Internal && projStats.Internal.Archive)
  let int_total = intALL - intArch;

  let int_new = + (projStats.Internal && projStats.Internal.New);
  let int_inprogress = + (projStats.Internal && projStats.Internal.InProgress);
  let int_hold = + (projStats.Internal && projStats.Internal.Hold);
  let int_completed = + (projStats.Internal && projStats.Internal.Completed);

  let int_new_percentage = Math.round((int_new/int_total)*100);
  let int_inprogress_percentage = Math.round((int_inprogress/int_total)*100);
  let int_hold_percentage = Math.round((int_hold/int_total)*100);
  let int_completed_percentage = Math.round((int_completed/int_total)*100);

  useEffect(() => {
    getProjStats();
  }, [])
  return (
    <Auxiliary>
      <Row className="gx-ml-0 gx-mr-0">
        <Col lg={12} md={12} sm={12} xs={24}>
          <Widget>
            <h2 className="h4 gx-mb-3">Internal Projects</h2>
            <Row>
              <Col lg={12} md={12} sm={12} xs={24}>
                <div className="ant-row-flex">
                  <h2 className="gx-mr-2 gx-mb-0 gx-fs-xxxl gx-font-weight-medium">{int_total}</h2>
                  {/* <h4 className="gx-pt-2 gx-chart-up">64% <i className="icon icon-menu-up gx-fs-sm" /></h4> */}
                </div>
                <p className="gx-text-grey">Overall projects</p>
                <div className="ant-row-flex gx-mb-3 gx-mb-md-2">
                  <Button className="gx-mr-2" type="primary" onClick={() => history.push('/reports/wsr')}>Status</Button>
                  <Button className="gx-btn-cyan gx-ml-2" onClick={() => history.push('/reports/project')}>Projects</Button>
                </div>
              </Col>
              <Col lg={12} md={12} sm={12} xs={24}>
                <div className="gx-site-dash">
                  <h5 className="gx-mb-3">Portfolio Distribution</h5>
                  <ul className="gx-line-indicator gx-fs-sm gx-pb-1 gx-pb-sm-0">
                    <li>
                      <LineIndicator width={int_new_percentage} title="New"  title2={projStats.Internal && projStats.Internal.New} color="primary" value={int_new_percentage +"%"} />
                    </li>
                    <li>
                      <LineIndicator width={int_inprogress_percentage} title="InProgress"  title2={projStats.Internal && projStats.Internal.InProgress} color="pink" value={int_inprogress_percentage +"%"} />
                    </li>
                    <li>
                      <LineIndicator width={int_hold_percentage} title="Hold" title2={projStats.Internal && projStats.Internal.Hold} color="orange" value={int_hold_percentage +"%"} />
                    </li>
                    <li>
                      <LineIndicator width={int_completed_percentage} title="Completed"  title2={projStats.Internal && projStats.Internal.Completed} color="orange" value={int_completed_percentage  +"%"} />
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
          </Widget>
        </Col>
        <Col lg={12} md={12} sm={12} xs={24}>
          <Widget>
            <h2 className="h4 gx-mb-3">External Projects</h2>
            <Row>
              <Col lg={12} md={12} sm={12} xs={24}>

                <div className="ant-row-flex">
                  <h2 className="gx-mr-2 gx-mb-0 gx-fs-xxxl gx-font-weight-medium">{ext_total}</h2>
                  {/* <h4 className="gx-pt-2 gx-chart-up">64% <i className="icon icon-menu-up gx-fs-sm" /></h4> */}
                </div>
                <p className="gx-text-grey">Overall projects</p>
                <div className="ant-row-flex gx-mb-3 gx-mb-md-2">
                  <Button className="gx-mr-2" type="primary" onClick={() => history.push('/reports/wsr')}>Status</Button>
                  <Button className="gx-btn-cyan gx-ml-2" onClick={() => history.push('/reports/project')}>Projects</Button>
                </div>
             </Col>
              <Col lg={12} md={12} sm={12} xs={24}>
                <div className="gx-site-dash">
                  <h5 className="gx-mb-3">Portfolio Distribution</h5>
                  <ul className="gx-line-indicator gx-fs-sm gx-pb-1 gx-pb-sm-0">
                  <li>
                      <LineIndicator width={ext_new_percentage}  title="New" title2={projStats.External && projStats.External.New} color="primary" value={ext_new_percentage +"%"} />
                    </li>
                    <li>
                      <LineIndicator width={ext_inprogress_percentage} title="InProgress" title2={projStats.External && projStats.External.InProgress}  color="pink" value={ext_inprogress_percentage +"%"}/>
                    </li>
                    <li>
                      <LineIndicator width={ext_hold_percentage} title="Hold" title2={projStats.External && projStats.External.Hold} color="orange" value={ext_hold_percentage +"%"} />
                    </li>
                    <li>
                      <LineIndicator width={ext_completed_percentage} title="Completed" title2={projStats.External && projStats.External.Completed} color="orange" value={ext_completed_percentage+"%"} />
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
          </Widget>
        </Col>
      </Row>
    </Auxiliary>
  );
};

export default ProjStats;
