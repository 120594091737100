import React, { useEffect, useState } from "react";
import { Row, Col, message, Table, Radio, DatePicker, Tag, Breadcrumb } from "antd";
import axios from 'axios';
import { API_URL } from 'constants/APIConfig';
import moment from 'moment';
import 'moment/min/locales';
import Auxiliary from "util/Auxiliary";
import { FormOutlined } from '@ant-design/icons';
import {Link} from "react-router-dom";

const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;
const { RangePicker } = DatePicker;

const TSApproval = (props) => {
  const userDetails = JSON.parse(sessionStorage.getItem('user_details'));
  const token = sessionStorage.getItem('token');
  const [rangeDateChanged, setRangeDateChanged] = useState(true);
  const [eWeekNumber, setEWeekNumber] = useState(moment().week());
  const [eYear, setEYear] = useState(moment().weekYear());
  const [sWeekNumber, setSWeekNumber] = useState(moment().subtract(1, 'months').week());
  const [sYear, setSYear] = useState(moment().subtract(1, 'months').weekYear());
  const [timeSheetList, setTimeSheetList] = useState([]);
  const [timesheetStatus, setTimesheetStatus] = useState('Pending');
  const [loading, setLoading] = useState(false);

  function onChange(e) {
    setTimesheetStatus(e.target.value)
    getTsApprovals(e.target.value, sYear, sWeekNumber, eYear, eWeekNumber);
  }

  const onChangeWeek = (dateArr, dateStringArr) => {
    if (dateArr != null && dateStringArr != null) {
      let sDt = dateStringArr[0].split('-');
      let eDt = dateStringArr[1].split('-');
      let sYear = Number(sDt[0])
      setSYear(sYear)
      let eYear = Number(eDt[0])
      setEYear(eYear)
      let sWeek = Number(sDt[1].match(/\d+/)[0])
      setSWeekNumber(sWeek)
      let eWeek = Number(eDt[1].match(/\d+/)[0])
      setEWeekNumber(eWeek)
    }
  }

  const minsToStrTime = (val) => {
    let m = val % 60;
    let h = (val - m) / 60;

    h = h < 10 ? "0" + h : h;
    m = m < 10 ? "0" + m : m;

    return `${h}:${m}`;
  }

  const strTimeToMins = (strTime) => {
    let [h, m] = strTime.split(":");
    return Number(h) * 60 + Number(m);
  }

  const getLoggedHours = (tsheet) => {
    let totalHours = 0;
    tsheet.projects.map((projectItem, index) => (
      projectItem.tasks.map((taskItem, index) => (
        totalHours = totalHours + strTimeToMins(taskItem.timelog[0].hours) + strTimeToMins(taskItem.timelog[1].hours) + strTimeToMins(taskItem.timelog[2].hours) + strTimeToMins(taskItem.timelog[3].hours) + strTimeToMins(taskItem.timelog[4].hours) + strTimeToMins(taskItem.timelog[5].hours) + strTimeToMins(taskItem.timelog[6].hours)
      ))
    ))
    return minsToStrTime(totalHours);
  }

  const timeSheetListCols = [
    {

      title: 'Year', dataIndex: 'year', width: 100,
      sorter: (a, b) => String(a.year).localeCompare(String(b.year)),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Week', dataIndex: 'weekNumber', width: 100,
      sorter: (a, b) => String(a.weekNumber).localeCompare(String(b.weekNumber)),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Period', dataIndex: 'period', width: 220,
      sorter: (a, b) => String(a.weekNumber).localeCompare(String(b.weekNumber)),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Employee Id', dataIndex: 'empid', width: 150,
      sorter: (a, b) => String(a.empid).localeCompare(String(b.empid)),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Employee Name', dataIndex: 'empname', width: 200,
      sorter: (a, b) => String(a.empname).localeCompare(String(b.empname)),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Logged Hours', dataIndex: 'thours', width: 200,
      sorter: (a, b) => String(a.thours).localeCompare(String(b.thours)),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Employee Comments', dataIndex: 'empcomments', width: 250, ellipsis: true,
      sorter: (a, b) => String(a.empcomments).localeCompare(String(b.empcomments)),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Reporting Manager Comments', dataIndex: 'rmcomments', width: 250, ellipsis: true,
      sorter: (a, b) => String(a.rmcomments).localeCompare(String(b.rmcomments)),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Action', fixed: 'right', width: 100, dataIndex: 'action',
      render: (text, record) =>
      <div className="gx-d-flex">
            {(() => {
              if(record.status ==="Pending") {
                return( <>
                <span> <Link to={`/timesheets/rmapprove/by-id/${userDetails.id}/${record.userid}/${record.year}/${record.weekNumber}`}><FormOutlined style={{ padding: '10px' }} /></Link></span>
                      </>);
              } else{
                if (record.status ==="Approved")
                  return <Tag color="blue" >{record.status?record.status:""}</Tag>
                  if (record.status ==="Rejected")
                  return <Tag color="red" >{record.status?record.status:""}</Tag>
              }
            })()}

        </div>
    }
  ];

  timeSheetListCols.filter(item => item.chosen).map(item => item.key)

  const getTsApprovals = async (status, sYear, sWeekNumber, eYear, eWeekNumber) => {
    setLoading(true);
    setTimeSheetList([]);
    setRangeDateChanged(false)
    const url = API_URL + `/api/timesheets/getReportingMgrApprovalList?userId=${userDetails.id}&timesheetStatus=${status}&sYear=${sYear}&sWeekNumber=${sWeekNumber}&eYear=${eYear}&eWeekNumber=${eWeekNumber}`;
    try {
      const res = await axios.get(url,
        {
          headers: {
            'x-access-token': `${token}`,
            'Access-Control-Allow-Origin': '*'
          }
        });
      if (res.status === 200) {
        setLoading(false);
        if (Object.keys(res.data.data).length > 0) {
          let tSheetList = [];
          res.data.data.map((item, index) => {
            tSheetList.push({
              key: index,
              year: item.year,
              weekNumber: item.weekNumber,
              userid: item.userId,
              period: `${moment().weekYear(item.year).week(item.weekNumber).startOf('week').format('MMM D, YYYY')} - ${moment().weekYear(item.year).week(item.weekNumber).endOf('week').format('MMM D, YYYY')}`,
              empid: item.user && item.user.employeeId,
              empname: item.user && item.user.displayNameTx,
              empcomments: item.timesheetComment,
              rmcomments: item.reportingManagerComment,
              status: item.reportingManagerAction,
              thours: getLoggedHours(item),
            });
          })
          tSheetList.sort((a, b) => ((parseInt(a.year + '' + a.weekNumber) < parseInt(b.year + '' + b.weekNumber)) ? 1 : -1))
          setTimeSheetList(tSheetList);
        }
      } else {
        setLoading(false);
        return {};
      }
    } catch (ex) {
      message.error(`${ex.message}:get timesheet`);
      return {}
    }
  }

  useEffect(() => {
    if(rangeDateChanged) {
      getTsApprovals(timesheetStatus, sYear, sWeekNumber, eYear, eWeekNumber);
    }
  }, [rangeDateChanged])

  return (
    <Auxiliary>
      <Row className="m_0 gx-pt-2">
        <Col xl={8} lg={8} md={8} sm={24} xs={24} className="flex_style gx-pl-0 ant-row-start">
          <Breadcrumb>
              <Breadcrumb.Item>Resource Manager</Breadcrumb.Item>
              <Breadcrumb.Item className="text-helight"><i className="icon icon-check-circle-o" /> RM Approve</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
        <Col xl={8} lg={8} md={8} sm={24} xs={24} className="gx-text-center gx-pt-1">
          <h5>
            {
              moment().weekYear(sYear).week(sWeekNumber).startOf('week').format('MMM D, YYYY') + " - " + moment().weekYear(eYear).week(eWeekNumber).endOf('week').format('MMM D, YYYY')
            }
          </h5>
        </Col>
        <Col xl={8} lg={8} md={8} sm={24} xs={24} className="gx-p-0">
          <div className="float-right" >
            <RangePicker className="gx-mb-3 gx-w-100" picker="week"
              defaultValue={[moment().weekYear(sYear).week(sWeekNumber), moment()]}
              allowClear={ false }
              disabledDate={current => {
                return current > moment().add(0, 'weeks')
              }}
              onChange={onChangeWeek}
              onOpenChange={(open) => {
                if(open === false) {
                  setRangeDateChanged(true)
                }
              }}
              />
          </div>
        </Col>
      </Row>
      <Row className="m_0">
        <Col xl={24} lg={24} md={24} sm={24} xs={24} className="gx-p-0">
          <div className="float-right" >
            <RadioGroup onChange={onChange} buttonStyle="solid" defaultValue={timesheetStatus} disabled={loading}>
              <RadioButton value="Pending">Pending</RadioButton>
              <RadioButton value="Approved">Approved</RadioButton>
              <RadioButton value="Rejected">Rejected</RadioButton>
            </RadioGroup>
          </div>
        </Col>
      </Row>
      <Table loading={loading} columns={timeSheetListCols} dataSource={timeSheetList} bordered={false} scroll={{ x: 1300 }} />
    </Auxiliary>
  );
};

export default TSApproval;