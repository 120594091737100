import React from "react";

import LineIndicator from "./LineIndicator";
import BubbleMap from "./BubbleMap/index";
import Widget from "components/Widget/index";

const Overview = () => {
  return (
    <Widget title="Client Portfolio">
 {/*    <div className="gx-card-overview"> */}
    <div >
      <div className="gx-overview-row">
        <div className="gx-line-indicator-col">
         {/*  <h2 className="gx-mb-3 gx-mb-sm-4 headings_title">Client's Overview</h2> */}
          <ul className="gx-line-indicator">
            <li>
              <LineIndicator width="25%" title="North America" color="purple" value="45%"/>
            </li>
            <li>
              <LineIndicator width="40%" title="Middle East" color="green" value="15%"/>
            </li>
            <li>
              <LineIndicator width="28%" title="Asia" color="yellow" value="30%"/>
            </li>
            <li>
              <LineIndicator width="18%" title="Others" color="grey" value="5%"/>
            </li>
          </ul>
        </div>
        <div className="gx-overview-description">
          <div className="gx-revenu gx-revenu-total">
            <h1>91</h1>
            <span className="gx-fs-md">Projects</span>
          </div>
          <div className="gx-revenu">
            <div className="gx-revenu-row">
              <div className="gx-revenu-col">
                <h3>50</h3>
                <span className="gx-fs-sm">Clients</span>
              </div>
              <div className="gx-revenu-col">
                <h3>09</h3>
                <span className="gx-fs-sm">Countries</span>
              </div>
            </div>
          </div>
        </div>
        <div className="gx-map-col">
          <BubbleMap/>
        </div>
      </div>
    </div>
    </Widget>
  );
};
export default Overview;
