import React, { useEffect, useState }  from "react";
import { useHistory } from "react-router-dom";
import axios from 'axios';
import {Col, Row} from "antd";
import {Anchor, Button, Table} from "antd";
import Auxiliary from "util/Auxiliary";
import {API_URL} from 'constants/APIConfig';
import { FormOutlined} from '@ant-design/icons';
import QualificationsTabel from "../../utils/tabels/qualifications";
import AddRowClass from "../../utils/tabels/dummyTabel";
import NewQualifications from "../new/index";

const {Link} = Anchor;

const ManageQualifications = () => {
const [allSkills, setAllSkills] = useState([]);

let history = useHistory();
const userDetails = JSON.parse(sessionStorage.getItem('user_details'));
const token = sessionStorage.getItem('token');

const getAllSkills = () => {
    axios.get(API_URL + '/api/skillbase/skills/getAllSkills', 
    {headers: {
      'x-access-token': `${token}`, 
    'Access-Control-Allow-Origin': '*'}
   })
        .then(response => {
          setAllSkills(response.data.data);
          console.log("Skills",response.data.data )
        })
        .catch(error => {
            console.log(error)
        });
}

useEffect(() => {
  getAllSkills();
}, [])


const data = [];
/* if (allProjectData.length != 0) {
    let tabledata = allProjectData.map((item, index) => {
        data.push({
            id: item.id,
            name: item.shortName,
            projtype: item.type && item.type.typeTx,
            client: item.client && item.client.organization,
            sdt: item.startDate,
            edt: item.endDate,
            progress: item.status && item.status.statusTx,
            accmgr: item.AccountManager && item.AccountManager.displayNameTx,
            projmgr: item.ProjectManager && item.ProjectManager.displayNameTx,
            paymode: item.BillableType &&  item.BillableType.name,
         })
    })
} */


/* End of Get the data form the API*/
const columns = [
  {title: 'Name', dataIndex: 'nameTx',  fixed: 'left'},
  {title: 'Id', dataIndex: 'id',},
 /*  {title: 'Acct Mgr', dataIndex: 'accmgr', key: 'accmgr'},
  {title: 'Proj Mgr', dataIndex: 'projmgr', key: 'projmgr'},
  {title: 'Start Date', dataIndex: 'sdt', key: 'sdt'},
  {title: 'End Date', dataIndex: 'edt', key: 'edt'},
  {title: 'Progress', dataIndex: 'progress', key: 'progress'},
  {title: 'Project Type', dataIndex: 'projtype', key: 'projtype'},
  {title: 'Estd. Hours', dataIndex: 'estdhrs', key: 'estdhrs'},
  {title: 'Payment Mode', dataIndex: 'paymode', key: 'paymode'},  
  {title: 'Action', dataIndex: 'id',render: text =><div><span> <a href={'./manage/' + text}><FormOutlined style={{ padding: '10px' }} /></a></span> </div>}, */
 ];
  return (
    <Auxiliary>
      {/* <h4 align="left">Qualifications Dashboard</h4> */}
        {/* <ProjStats/> */}

        <Row gutter={24}>
        <Col lg={24} md={24} sm={24} xs={24} >
{/*           <Button type="primary" className="add-btn" onClick={() => history.push('./new')}>
          Add Qualification
              </Button> */}
              <NewQualifications/>
        </Col>
      </Row>
      <QualificationsTabel />
      
     
    </Auxiliary>

  );
};

export default ManageQualifications;
