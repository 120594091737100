import React, { useEffect, useState } from "react";
import axios from 'axios';
import { Anchor, Card, Select, Table, Row, Col } from "antd";
import Auxiliary from "util/Auxiliary";
import IconWithTextCard from "components/Metrics/IconWithTextCard";
import { DesktopOutlined, GlobalOutlined, ReadOutlined, FormOutlined, EyeOutlined } from '@ant-design/icons';
import { API_URL } from 'constants/APIConfig';

const { Link } = Anchor;
const { Option } = Select;

function handleChange(value) {
  console.log(`selected ${value}`);
}

function handleBlur() {
  console.log('blur');
}

function handleFocus() {
  console.log('focus');
}


// rowSelection objects indicates the need for row selection
const rowSelection = {
  onChange: (selectedRowKeys, selectedRows) => {
    console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
  },
  onSelect: (record, selected, selectedRows) => {
    console.log(record, selected, selectedRows);
  },
  onSelectAll: (selected, selectedRows, changeRows) => {
    console.log(selected, selectedRows, changeRows);
  },
};

const Schedule = () => {
  const [treeData, setTreeData] = useState([]);
  const [projects, setProjects] = useState([]);

  const userDetails = JSON.parse(sessionStorage.getItem('user_details'));
  const token = sessionStorage.getItem('token');

  const getProjectList = () => {
    axios.get(API_URL+'/api/project/v2/all?userId=1&limit=500&offset=0', 
    {headers: {
      'x-access-token': `${token}`, 
    'Access-Control-Allow-Origin': '*'}
   })
      .then(response => {
        setProjects(response.data.data);
      })
      .catch(error => {
        console.log(error)
      });
  }

  const getTaskLists = () => {
      axios.get(API_URL+'/api/task/getTasksWithSubTasksTree?userId=1&projectId=404&limit=100&offset=0', 
      {headers: {
        'x-access-token': `${token}`, 
      'Access-Control-Allow-Origin': '*'}
     })
      .then(response => {
        if (response.data.data.length) {
          setTreeData(response.data.data);
          console.log("Task Data", response.data.data)
        }
      })
  }
  useEffect(() => {
    getProjectList()
    getTaskLists()

  }, [])

  const columns = [{
    title: 'Name',
    dataIndex: 'title',
    key: 'name',
  }, {
    title: 'Description',
    dataIndex: 'descriptionTx',
    key: 'desc',
  }, {
    title: 'Start Date',
    dataIndex: 'startDateDt',
    key: 'sdt',
  }, {
    title: 'End Date',
    dataIndex: 'dueDateDt',
    key: 'edt',
  }, {
    title: 'Owner',
    dataIndex: 'name',
    key: 'name',
  }, {
    title: 'Billable',
    dataIndex: 'billabilityNm',
    key: 'billable',
  }, {
    title: 'Estd. Hours',
    dataIndex: 'estimatedHoursNm',
    key: 'estdhrs',
  }, {
    title: 'Status',
    dataIndex: 'taskstatus',
    key: 'name',
  }, {
    title: 'Action',
    dataIndex: 'name',
    key: 'name',
  }];


  return (
        <Table className="gx-table-responsive" columns={columns} dataSource={treeData} bordered={false}
        size="small"/>
  );
}
  ;

export default Schedule;
