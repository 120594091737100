import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import axios from 'axios';
import { Anchor, Button, Table, Form, Select, Col, Row, Input } from "antd";
import Auxiliary from "util/Auxiliary";
import { API_URL } from 'constants/APIConfig';
import { FormOutlined } from '@ant-design/icons';
import TeamManageTabel from "../../utils/tabels/teamsManage";
import { getAllLocations_api } from "../../actions-caling Apis/api";
import NewTeam from "../new/index"
import Widget from "components/Widget/index";
const FormItem = Form.Item;
const { Option } = Select;


const { Link } = Anchor;

const ManageTeams = () => {
  
  let history = useHistory();
  const userDetails = JSON.parse(sessionStorage.getItem('user_details'));
  const token = sessionStorage.getItem('token');

  const [allTeams, setAllTeams] = useState([]);
  const [form] = Form.useForm();
  const [allLocations, setAllLocations] = useState([]);


  const getAllTeams = () => {
    axios.get(API_URL + '/api/skillbase/teams/getAllTeams', 
    {headers: {
      'x-access-token': `${token}`, 
    'Access-Control-Allow-Origin': '*'}
   })
        .then(response => {
          setAllTeams(response.data.data);
        })
        .catch(error => {
            console.log(error)
        });
  }
  const getAllTeamsById = () => {
    axios.get(API_URL + '/api/skillbase/teams/byId/158', 
    {headers: {
      'x-access-token': `${token}`, 
    'Access-Control-Allow-Origin': '*'}
   })
        .then(response => {
          setAllTeams(response.data.data);
        })
        .catch(error => {
            console.log(error)
        });
  }
  const onFinish = values => {

  }

  useEffect(() => {
    getAllTeams();
    getAllLocations_api()  .then(locations =>  setAllLocations(locations));
  }, [])

  
  const skillCategories = []

  /* End of Get the data form the API*/
  const columns = [
    { title: 'Name', dataIndex: 'nameTx', fixed: 'left' },
    { title: 'Id', dataIndex: 'id', },
  ];
  console.log("Teams data",allTeams )

  return (
    <Auxiliary>
      <NewTeam/>

      <h4>Filter Teams by: </h4>
<Widget>
      <Row>
        <Form
          form={form}
          initialValues={{ remember: true }}
          name="timelog"
          onFinish={onFinish}
          layout="inline"
          align="center"
        >
          <Col lg={5} md={8} sm={12} xs={24} >
            <div className="gx-form-row0">
              <h6 align="left">Location</h6>
              <Form.Item
                name="parentTeam"
                rules={[
                  {
                    required: true,
                    message: 'Enter parent team in the list',
                  },
                ]}
              >
                <Select
                  style={{ width: '100%' }}
                  placeholder="Select a Value"
                  optionFilterProp="children"
                  filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                >
                  {
                    allLocations.length ? allLocations.map((categories, index) => (
                      <Option key={index} value={categories.allLocations}>{categories.name}</Option>
                    )) : null
                  }
                </Select>
              </Form.Item>
            </div>
          </Col>
          <Col lg={5} md={8} sm={12} xs={24} >
            <div className="gx-form-row0">
              <h6 align="left">Team Name</h6>
              <Form.Item
                name="parentTeam"
                rules={[
                  {
                    required: true,
                    message: 'Enter parent team in the list',
                  },
                ]}
              >
                <Select
                  style={{ width: '100%' }}
                  placeholder="Select a Value"
                  optionFilterProp="children"
                  filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                >
                  {
                    skillCategories.length ? skillCategories.map((categories, index) => (
                      <Option key={index} value={categories.id}>{categories.nameTx}</Option>
                    )) : null
                  }
                </Select>
              </Form.Item>
            </div>
          </Col>
          <Col lg={5} md={8} sm={12} xs={24} >
            <div className="gx-form-row0">
              <h6 align="left">Skill Name</h6>
              <Form.Item
                name="parentTeam"
                rules={[
                  {
                    required: true,
                    message: 'Enter parent team in the list',
                  },
                ]}
              >
                <Select
                  style={{ width: '100%' }}
                  placeholder="Select a Value"
                  optionFilterProp="children"
                  filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                >
                  {
                    skillCategories.length ? skillCategories.map((categories, index) => (
                      <Option key={index} value={categories.id}>{categories.nameTx}</Option>
                    )) : null
                  }
                </Select>
              </Form.Item>
            </div>
          </Col>
          <Col lg={5} md={8} sm={12} xs={24} >
            <div className="gx-form-row0">
              <h6 align="left">Security Group</h6>
              <Form.Item
                name="parentTeam"
                rules={[
                  {
                    required: true,
                    message: 'Enter parent team in the list',
                  },
                ]}
              >
                <Select
                  style={{ width: '100%' }}
                  placeholder="Select a Value"
                  optionFilterProp="children"
                  filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                >
                  {
                    skillCategories.length ? skillCategories.map((categories, index) => (
                      <Option key={index} value={categories.id}>{categories.nameTx}</Option>
                    )) : null
                  }
                </Select>
              </Form.Item>
            </div>
          </Col>
          <Col lg={4} md={8} sm={12} xs={24} >
            <div className="gx-form-row0">
              <h6 align="left">Rating</h6>
              <Form.Item
                name="parentTeam"
                rules={[
                  {
                    required: true,
                    message: 'Enter parent team in the list',
                  },
                ]}
              >
                <Select
                  style={{ width: '100%' }}
                  placeholder="Select a Value"
                  optionFilterProp="children"
                  filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                >
                  {
                    skillCategories.length ? skillCategories.map((categories, index) => (
                      <Option key={index} value={categories.id}>{categories.nameTx}</Option>
                    )) : null
                  }
                </Select>
              </Form.Item>
            </div>
          </Col>
          </Form>
          </Row>
          <Row>
            <Col lg={24} md={24} sm={24} xs={24} >
              <div className="gx-form-row0">
                <Form.Item name="submit" >
                  <Button type="primary" className="add-btn" onClick={onFinish}>search</Button>
                  <Button type="primary" className="add-btn" onClick={onFinish}>clear</Button>
                </Form.Item>      
        
              </div>
            </Col>
        </Row>
      </Widget>
      {/* <Table className="gx-table-responsive" columns={columns} dataSource={allSkills} /> */}

      <TeamManageTabel teamDataPassing={allTeams}/>

    </Auxiliary>

  );
};

export default ManageTeams;
