import React, { useEffect, useState } from "react";
import axios from 'axios';
import { Button, Table, Row, Col, Select, Input, Form, message } from 'antd';

import Auxiliary from "util/Auxiliary";
import { API_URL } from 'constants/APIConfig';

const { TextArea } = Input;
const { Option } = Select;

const ManageSkills = () => {
  const [form] = Form.useForm();

  const [allSkillsWithDepartment, setAllSkillsWithDepartment] = useState([]);
  const [allDepartment, setAllDepartment] = useState([]);
  const [allSkills, setAllSkills] = useState([]);
  const [allSubSkills, setAllSubSkills] = useState([]);

  const token = sessionStorage.getItem('token');

  const onFinish = values => {
    let newSkillData = {
      departmentIdNm: + form.getFieldValue('departmentId'),
      skillIdNm: + form.getFieldValue('skillId'),
      nameTx: form.getFieldValue('subSkillName'),
      descriptionTx: form.getFieldValue('description'),
    }
    axios.post(API_URL + '/api/skillbase/subskills/addNewSubSkill', newSkillData,
      {
        headers: {
          'x-access-token': `${token}`,
          'Access-Control-Allow-Origin': '*'
        }
      })
      .then(response => {
        message.success(`Sub Skill added sucessfully`);
        getAllSubSkills()
        form.resetFields()
      })
      .catch(error => {
        console.log(error);
        message.error("Error In creating Skill", error)
      });
  }

  const getSkillWithDepartmentList = () => {
    axios.get(API_URL + `/api/skillbase/skills/getAllSkillsWithDepartments?t=${new Date().getTime()}`,
      {
        headers: {
          'x-access-token': `${token}`,
          'Access-Control-Allow-Origin': '*'
        }
      })
      .then(response => {
        if (Object.keys(response.data.data).length > 0) {
          let departmentSet = new Set()
          let depNameSet = new Set()
          response.data.data.forEach(obj => {
            if(!depNameSet.has(obj.sb_department.nameTx)) {
              departmentSet.add(obj.sb_department)
              depNameSet.add(obj.sb_department.nameTx)
            }
          })
          setAllDepartment(Array.from(departmentSet));
          setAllSkillsWithDepartment(response.data.data)
        }
      })
      .catch(error => {
        console.log(error);
      });
  }

  const getAllSubSkills = () => {
    axios.get(API_URL + `/api/skillbase/subskills/getAllSubSkills?t=${new Date().getTime()}`,
      {
        headers: {
          'x-access-token': `${token}`,
          'Access-Control-Allow-Origin': '*'
        }
      })
      .then(response => {
        setAllSubSkills(response.data.data)
      })
      .catch(error => {
        console.log(error);
      });
  }

  const onChangeDepartment = (id) => {
    let allSkills = []
    allSkillsWithDepartment.forEach(s => {
      if(s.sb_department.id === id) {
        allSkills.push({id: s.id, nameTx: s.nameTx})
      }
    })
    setAllSkills(allSkills)
    form.setFieldsValue({
      skillId: undefined
    })
  }

  useEffect(() => {
    getSkillWithDepartmentList()
    getAllSubSkills()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const columns = [
    { title: 'Department', dataIndex: 'departmentId', render: (text, record) => {
      return record.sb_department.nameTx;
    } },
    { title: 'SKill', dataIndex: 'nameTx', render: (text, record) => {
      return record.sb_skill.nameTx;
    } },
    { title: 'Sub SKill', dataIndex: 'nameTx', render: (text, record) => {
      return record.nameTx;
    } },
    { title: 'Description', dataIndex: 'descriptionTx' },
  ]

  return (
    <Auxiliary>
      <center><h4>Manage Sub Skills</h4></center>
      <Row gutter={24}>
        <Col lg={24} md={24} sm={24} xs={24} >
          <div className="add-skills-main content-height-100">
            <div className="skill-form">
              <Form
                form={form}
                initialValues={{ remember: true }}
                name="newSkill"
                onFinish={onFinish}
                layout="inline"
                align="center"
              >
                <Row gutter={24}>
                  <Col lg={6} md={6} sm={24} xs={24} >
                    <div className="gx-form-row0">
                      <h6 align="left">Department</h6>
                      <Form.Item
                        name="departmentId"
                        rules={[
                          {
                            required: true,
                            message: 'Select the department',
                          },
                        ]}
                      >
                        <Select
                          style={{ width: '100%' }}
                          placeholder="Select the Department"
                          onChange={onChangeDepartment}
                          >
                          {
                            allDepartment.length ? allDepartment.map((department, index) => (
                              <Option key={index} value={department.id}>{department.nameTx}</Option>
                            )) : null
                          }
                        </Select>
                      </Form.Item>
                    </div>
                  </Col>
                  <Col lg={6} md={6} sm={24} xs={24} >
                    <div className="gx-form-row0">
                      <h6 align="left">Skill</h6>
                      <Form.Item
                        name="skillId"
                        rules={[
                          {
                            required: true,
                            message: 'Select the Skill',
                          },
                        ]}
                      >
                        <Select
                          style={{ width: '100%' }}
                          placeholder="Select the Skill"
                        >
                          {
                            allSkills.length ? allSkills.map((skill, index) => (
                              <Option key={index} value={skill.id}>{skill.nameTx}</Option>
                            )) : null
                          }
                        </Select>
                      </Form.Item>
                    </div>
                  </Col>
                  <Col lg={12} md={12} sm={12} xs={24} >
                    <div className="gx-form-row0">
                      <h6 align="left">Sub Skill</h6>
                      <Form.Item
                        name="subSkillName"
                        rules={[
                          {
                            required: true,
                            message: 'Enter Sub Skill',
                          },
                          {
                            validator(_, value) {
                              let sArr = allSubSkills.map(s => s.nameTx.toLowerCase()).filter(s => s === (value + '').trim().toLowerCase())
                              if (sArr.length > 0) {
                                return Promise.reject('Duplicate Sub Skill Name')
                              }
                              return Promise.resolve();
                            },
                          }
                        ]}
                        hasFeedback>
                        <Input placeholder="Sub Skill Name" autoComplete="off" maxLength={250} />
                      </Form.Item>
                    </div>
                  </Col>
                  <Col lg={12} md={12} sm={12} xs={24} >
                    <div className="gx-form-row0">
                      <h6 align="left">Description</h6>
                      <Form.Item
                        name="description"
                        rules={[
                          {
                            required: true,
                            message: 'Enter Description',
                          },
                        ]}
                      >
                        <TextArea rows={2} placeholder="Enter your message" maxLength={250} />
                      </Form.Item>
                    </div>
                  </Col>
                  <Col lg={12} md={12} sm={12} xs={24} >
                    <div className="gx-form-row0" style={{ float: "left", marginTop: "25px" }}>
                      <Form.Item>
                        <Button type="primary" htmlType="submit" className="add-btn scale-x">Add Sub Skill</Button>
                      </Form.Item>
                    </div>
                  </Col>
                </Row>
              </Form>
            </div>
          </div >
        </Col>
      </Row>

      <Table className="gx-table-responsive" columns={columns} dataSource={allSubSkills} rowKey={'id'} />
    </Auxiliary>
  );
};

export default ManageSkills;