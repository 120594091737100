import React, { useEffect, useState } from "react";
import { Tag, Collapse, Button, Row, Input, Col, message, Tabs } from "antd";
import axios from 'axios';
import { API_URL } from 'constants/APIConfig';
import moment from 'moment';
import 'moment/min/locales';
import Auxiliary from "util/Auxiliary";
import { useHistory } from "react-router-dom";

const Panel = Collapse.Panel;
const { TextArea } = Input;
const { TabPane } = Tabs;

const TSApproval = (props) => {
  let history = useHistory();
  const userDetails = JSON.parse(sessionStorage.getItem('user_details'));
  const token = sessionStorage.getItem('token');
  const [disableFlag, setDisableFlag] = useState(false);
  const [empId, setEmpId] = useState("");
  const [empName, setEmpName] = useState("");
  const [empComments, setEmpComments] = useState("");
  const [rmComments, setRMComments] = useState("");
  const [rmApprovalStatus, setRMApprovalStatus] = useState("");
  const [pmComments, setPMComments] = useState("");
  const [inputList, setInputList] = useState([]);
  const [weekNumber, setWeekNumber] = useState(props.match.params.week);

  const timeFormatting = (time) => {
    return moment(time, 'HH:mm:ss').format('HH:mm')
  }

  const minsToStrTime = (val) => {
    let m = val % 60;
    let h = (val - m) / 60;
    h = h < 10 ? "0" + h : h;
    m = m < 10 ? "0" + m : m;
    return `${h}:${m}`;
  }

  const minsToStrApproveTime = (val) => {
    let m = val % 60;
    let h = (val - m) / 60;
    m = m > 30 ? "0" + Number(h) + 1 : h;
    return `${h}`;
  }

  const strTimeToMins = (strTime) => {
    let [h, m] = strTime.split(":");
    return Number(h) * 60 + Number(m);
  }

  const approveTimesheet = () => {
    let taskdata1 = [];
    inputList.map((tsheet, index) => (
      taskdata1.push({
        "projectId": +tsheet.projectid,
        "taskId": +tsheet.taskid,
        "isDefaultTask": false,
        "approvedHours": tsheet.day8,
      })
    ))
    axios.post(API_URL + '/api/timesheets/respondToFullTimesheet', {
      "approverManagerId": +userDetails.id,
      "userId": +props.match.params.id,
      "weekNumber": props.match.params.week,
      "year": props.match.params.year,
      "status": "Approved",
      "comments": rmComments,
      "timelogs": taskdata1
    },
      {
        headers: {
          'x-access-token': `${token}`,
          'Access-Control-Allow-Origin': '*'
        }
      })
      .then(response => {
        message.success("Timesheet Approved Successfully");
        setDisableFlag(true);
        history.push("/timesheets/rmapprove?" + new Date().getTime());
      })
      .catch(error => {
        console.log(error);
        message.error("Error In submitting timesheet", error)
      });
  }

  const rejectTimesheet = () => {
    let taskdata1 = [];
    inputList.map((tsheet, index) => (
      taskdata1.push({
        "projectId": +tsheet.projectid,
        "taskId": +tsheet.taskid,
        "isDefaultTask": false,
      })
    ))

    axios.post(API_URL + '/api/timesheets/respondToFullTimesheet', {
      "approverManagerId": +userDetails.id,
      "userId": +props.match.params.id,
      "weekNumber": props.match.params.week,
      "year": props.match.params.year,
      "status": "Rejected",
      "comments": rmComments,
      "timelogs": taskdata1
    },
      {
        headers: {
          'x-access-token': `${token}`,
          'Access-Control-Allow-Origin': '*'
        }
      })
      .then(response => {
        history.push("/timesheets/rmapprove?" + new Date().getTime());
        message.success("Timesheet rejected for reprocessing");
      })
      .catch(error => {
        console.log(error);
        message.error("Error In submitting timesheet", error)
      });
  }

  const handleComments = (e) => {
    setRMComments(e.target.value);
  }

  const getApprovedHrs = (taskItem) => {
    let approvedHrs = strTimeToMins(taskItem.timelog[0].hours) + strTimeToMins(taskItem.timelog[1].hours) + strTimeToMins(taskItem.timelog[2].hours) + strTimeToMins(taskItem.timelog[3].hours) + strTimeToMins(taskItem.timelog[4].hours) + strTimeToMins(taskItem.timelog[5].hours) + strTimeToMins(taskItem.timelog[6].hours)
    return minsToStrApproveTime(approvedHrs);
  }

  const getTaskStatusColor = (taskstatus) => {
    let color = "#666"
    if (taskstatus == "Pending")
      color = "#666"
    if (taskstatus == "Approved")
      color = "green"
    if (taskstatus == "Rejected")
      color = "red"

    return color;
  }

  const getTsApprovals = async () => {
    const url = API_URL + `/api/timesheets/v2/getApproversTimesheets?approverManagerId=${props.match.params.rmid}&userId=${props.match.params.id}&year=${props.match.params.year}&weekNumber=${props.match.params.week}`;
    setInputList([]);
    try {
      const res = await axios.get(url,
        {
          headers: {
            'x-access-token': `${token}`,
            'Access-Control-Allow-Origin': '*'
          }
        });
      if (res.status === 200) {
        if (Object.keys(res.data.data).length > 0) {
          let tsheets = [];
          let strPMComments = ''
          res.data.data.map((tsheet, index) => (
            setEmpComments(tsheet.timesheetComment),
            setRMComments(tsheet.reportingManagerComment),
            setRMApprovalStatus(tsheet.reportingManagerAction),
            setEmpId(tsheet.user && tsheet.user.employeeId),
            setEmpName(tsheet.user && tsheet.user.displayNameTx),
            tsheet.reportingManagerAction === "Approved" ? setDisableFlag(true) : setDisableFlag(false),
            tsheet.projects.map((projectItem, index) => (
              projectItem.tasks.map((taskItem, index) => {
                if (tsheet.reportingManager.id != projectItem.projectManager.id && taskItem.task && taskItem.task.taskNameTx) {
                  strPMComments = strPMComments + projectItem.project.name + '/' + taskItem.task.taskNameTx + " : \n\t" + (taskItem.projectManagerComment || '') + "\n"
                }

                tsheets = [...tsheets, {
                  "projectid": projectItem.project && projectItem.project.id,
                  "projectname": projectItem.project && projectItem.project.shortName,
                  "taskid": taskItem.task && taskItem.task.id,
                  "taskname": taskItem.task && taskItem.task.taskNameTx,
                  "day1": taskItem.timelog[0].hours,
                  "day2": taskItem.timelog[1].hours,
                  "day3": taskItem.timelog[2].hours,
                  "day4": taskItem.timelog[3].hours,
                  "day5": taskItem.timelog[4].hours,
                  "day6": taskItem.timelog[5].hours,
                  "day7": taskItem.timelog[6].hours,
                  "day8": taskItem.approvedHours ? taskItem.approvedHours : getApprovedHrs(taskItem),
                  "taskstatus": getTaskStatusColor(projectItem.projectManagerAction),
                  "pmcomments": projectItem.projectManagerComment ? projectItem.projectManagerComment : "",
                  "timesheetId": taskItem.timesheetId
                }]
              })
            ))
          ))
          setPMComments(strPMComments)
          tsheets.sort((a, b) => {
            return a.timesheetId - b.timesheetId;
          });
          setInputList(tsheets);
        }
      } else return {};
    } catch (ex) {
      message.error(`${ex.message}:get timesheet`);
      return {}
    }
  }
  useEffect(() => {
    getTsApprovals();
  }, [])
  return (
    <Auxiliary>
      {inputList.length ?
        <div>
          <div className="">
            <Collapse bordered={false} defaultActiveKey={['1']} expandIconPosition='right' className="mainAcc">
              <Panel header="" key="1"
                extra={
                  <ul className="gx-list-inline gx-ml-auto gx-mb-0 gx-text-grey accStyle1">
                    <li>{empName}<span className="subHeading">{empId}</span></li>
                    <li className=""><small>Status:</small><p className="gx-mb-0"><Tag className="statusColor gx-p-0 font600">{rmApprovalStatus}</Tag></p></li>
                    <li className="gx-text-grey">
                      <small>Period:</small>
                      <p className="gx-mb-0 font600">
                        <span>{moment().weekYear(props.match.params.year).week(weekNumber).startOf('week').format('MMM D, YYYY')}</span>
                        <span className="gx-pl-1 gx-pr-1">-</span>
                        <span>{moment().weekYear(props.match.params.year).week(weekNumber).endOf('week').format('MMM D, YYYY')}</span>
                      </p>
                    </li>
                  </ul>
                }
              >
                <div className="timesheetBlock">
                  <Row className="timesheetHeader m_0">
                    <Col xl={6} lg={6} md={6} sm={6} xs={6} className="timesheetHeading upperCaseStyle timeSheetMiddle borderRight">projects/Tasks</Col>
                    <Col xl={2} lg={2} md={2} sm={2} xs={2} className="align_center weekdays_bg">
                      <p className="weekStyle">S</p>
                      <p className="monthDateStyle">{moment().weekYear(props.match.params.year).week(weekNumber).day(0).format('MMM D')}</p>
                    </Col>
                    <Col xl={2} lg={2} md={2} sm={2} xs={2} className="align_center">
                      <p className="weekStyle">M</p>
                      <p className="monthDateStyle">{moment().weekYear(props.match.params.year).week(weekNumber).day(1).format('MMM D')}</p>
                    </Col>
                    <Col xl={2} lg={2} md={2} sm={2} xs={2} className="align_center">
                      <p className="weekStyle">T</p>
                      <p className="monthDateStyle">{moment().weekYear(props.match.params.year).week(weekNumber).day(2).format('MMM D')}</p>
                    </Col>
                    <Col xl={2} lg={2} md={2} sm={2} xs={2} className="align_center">
                      <p className="weekStyle">W</p>
                      <p className="monthDateStyle">{moment().weekYear(props.match.params.year).week(weekNumber).day(3).format('MMM D')}</p>
                    </Col>
                    <Col xl={2} lg={2} md={2} sm={2} xs={2} className="align_center">
                      <p className="weekStyle">T</p>
                      <p className="monthDateStyle">{moment().weekYear(props.match.params.year).week(weekNumber).day(4).format('MMM D')}</p>
                    </Col>
                    <Col xl={2} lg={2} md={2} sm={2} xs={2} className="align_center">
                      <p className="weekStyle">F</p>
                      <p className="monthDateStyle">{moment().weekYear(props.match.params.year).week(weekNumber).day(5).format('MMM D')}</p>
                    </Col>
                    <Col xl={2} lg={2} md={2} sm={2} xs={2} className="align_center weekdays_bg">
                      <p className="weekStyle">S</p>
                      <p className="monthDateStyle">{moment().weekYear(props.match.params.year).week(weekNumber).day(6).format('MMM D')}</p>
                    </Col>
                    <Col xl={4} lg={4} md={4} sm={4} xs={4} className="total_data timeSheetMiddle borderRight">
                      <span className="weekStyle">Total</span>
                    </Col>
                  </Row>
                </div>

                <div className="App timesheetBody rmData">
                  {inputList.map((x, i) => (
                    <div key={i} className="box">
                      <Row className="m_0 spacing_bottom timesheetBodyBlock border_bottom" style={{ color: `${x.taskstatus}` }}>
                        <Col xl={6} lg={6} md={6} sm={6} xs={6}>
                          <span> {x.projectname}/{x.taskname} </span>
                        </Col>
                        <Col xl={2} lg={2} md={2} sm={2} xs={2} className="timesheettableCells align_center weekdays_bg" title={timeFormatting(x.day1)}>
                          <span> {timeFormatting(x.day1)} </span>
                        </Col>
                        <Col xl={2} lg={2} md={2} sm={2} xs={2} className="timesheettableCells align_center" title={timeFormatting(x.day2)}>
                          <span> {timeFormatting(x.day2)} </span>
                        </Col>
                        <Col xl={2} lg={2} md={2} sm={2} xs={2} className="timesheettableCells align_center" title={timeFormatting(x.day3)}>
                          <span> {timeFormatting(x.day3)} </span>
                        </Col>
                        <Col xl={2} lg={2} md={2} sm={2} xs={2} className="timesheettableCells align_center" title={timeFormatting(x.day4)}>
                          <span> {timeFormatting(x.day4)} </span>
                        </Col>
                        <Col xl={2} lg={2} md={2} sm={2} xs={2} className="timesheettableCells align_center" title={timeFormatting(x.day5)}>
                          <span> {timeFormatting(x.day5)} </span>
                        </Col>
                        <Col xl={2} lg={2} md={2} sm={2} xs={2} className="timesheettableCells align_center" title={timeFormatting(x.day6)}>
                          <span> {timeFormatting(x.day6)} </span>
                        </Col>
                        <Col xl={2} lg={2} md={2} sm={2} xs={2} className="timesheettableCells align_center weekdays_bg" title={timeFormatting(x.day7)}>
                          <span> {timeFormatting(x.day7)} </span>
                        </Col>
                        <Col xl={4} lg={4} md={4} sm={4} xs={4} className="align_center rmData" >
                          <span>{
                            minsToStrTime(strTimeToMins(x.day1) + strTimeToMins(x.day2) + strTimeToMins(x.day3) + strTimeToMins(x.day4) + strTimeToMins(x.day5) + strTimeToMins(x.day6) + strTimeToMins(x.day7))
                          }</span>
                        </Col>
                      </Row>
                    </div>
                  ))}
                </div>

                <div className="timesheetBlock">
                  <Row className="timesheetHeader m_0 timeSheetMiddle">
                    <Col xl={6} lg={6} md={6} sm={6} xs={6} className="timesheetHeading upperCaseStyle timeSheetMiddle borderRight font600">Total Hours</Col>
                    <Col xl={2} lg={2} md={2} sm={2} xs={2} className="align_center totalcount">
                      <p>
                        {(() => {
                          let total = 0;
                          inputList.map((x, i) => {
                            total = total + strTimeToMins(x.day1)
                          })
                          return minsToStrTime(total);
                        })()} </p>
                    </Col>
                    <Col xl={2} lg={2} md={2} sm={2} xs={2} className="align_center totalcount">
                      <p>
                        {(() => {
                          let total = 0;
                          inputList.map((x, i) => {
                            total = total + strTimeToMins(x.day2)
                          })
                          return minsToStrTime(total);
                        })()} </p>
                    </Col>
                    <Col xl={2} lg={2} md={2} sm={2} xs={2} className="align_center totalcount">
                      <p>
                        {(() => {
                          let total = 0;
                          inputList.map((x, i) => {
                            total = total + strTimeToMins(x.day3)
                          })
                          return minsToStrTime(total);
                        })()} </p>
                    </Col>
                    <Col xl={2} lg={2} md={2} sm={2} xs={2} className="align_center totalcount">
                      <p>
                        {(() => {
                          let total = 0;
                          inputList.map((x, i) => {
                            total = total + strTimeToMins(x.day4)
                          })
                          return minsToStrTime(total);
                        })()} </p>
                    </Col>
                    <Col xl={2} lg={2} md={2} sm={2} xs={2} className="align_center totalcount">
                      <p>
                        {(() => {
                          let total = 0;
                          inputList.map((x, i) => {
                            total = total + strTimeToMins(x.day5)
                          })
                          return minsToStrTime(total);
                        })()} </p>
                    </Col>
                    <Col xl={2} lg={2} md={2} sm={2} xs={2} className="align_center totalcount">
                      <p>
                        {(() => {
                          let total = 0;
                          inputList.map((x, i) => {
                            total = total + strTimeToMins(x.day6)
                          })
                          return minsToStrTime(total);
                        })()} </p>
                    </Col>
                    <Col xl={2} lg={2} md={2} sm={2} xs={2} className="align_center totalcount">
                      <p>
                        {(() => {
                          let total = 0;
                          inputList.map((x, i) => {
                            total = total + strTimeToMins(x.day7)
                          })
                          return minsToStrTime(total);
                        })()} </p>
                    </Col>
                    <Col xl={4} lg={4} md={4} sm={4} xs={4} className="totalcountMain gx-text-center">
                      <p>
                        {(() => {
                          let total = 0;
                          inputList.map((x, i) => {
                            total = total + (strTimeToMins(x.day1) + strTimeToMins(x.day2) + strTimeToMins(x.day3) + strTimeToMins(x.day4) + strTimeToMins(x.day5) + strTimeToMins(x.day6) + strTimeToMins(x.day7))
                          })
                          return minsToStrTime(total);
                        })()} </p>
                    </Col>
                  </Row>
                </div>

                <Row className="m_0">
                  <Col xl={24} lg={24} md={24} sm={24} xs={24} className=" gx-pl-0 gx-pr-0">
                    <Tabs defaultActiveKey="1" size="small" style={{ marginBottom: 32 }}>
                      <TabPane tab="Resource Manager" key="1">
                        <TextArea placeholder="Please enter the comments" disabled={disableFlag} onChange={e => handleComments(e)} rows={4} value={rmComments} maxLength={255} />
                      </TabPane>

                      <TabPane tab="Project Manager" key="2" >
                        <TextArea readOnly={true} rows={4} value={pmComments} />
                      </TabPane>

                      <TabPane tab="Employee" key="3">
                        <TextArea readOnly={true} rows={4} value={empComments} />
                      </TabPane>
                    </Tabs>
                  </Col>
                </Row>
                <Row className="m_0 ">
                  <Col xl={8} lg={8} md={8} sm={8} xs={8} className="gx-p-0">
                    <Button type="primary" className="gx-mr-2" onClick={() => history.push('/timesheets/rmapprove?' + new Date().getTime())}>Back</Button>
                  </Col>
                  <Col xl={16} lg={16} md={16} sm={16} xs={16} className="gx-p-0 buttonGroup">
                    {(() => {
                      if (!disableFlag) {
                        return (<>
                          <Button type="primary" disabled={disableFlag} className="submit_btn" onClick={approveTimesheet} htmlType="submit"> Approve </Button>
                          <Button disabled={disableFlag} className="reject_btn_default" onClick={rejectTimesheet} htmlType="submit"> Reject </Button>
                        </>)
                      }
                    })()}

                  </Col>
                </Row>
              </Panel>
            </Collapse>
          </div>
        </div>
        : null}
    </Auxiliary>
  );
}
export default TSApproval;