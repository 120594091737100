import React, { useEffect, useState } from "react";
import axios from 'axios';
import { Row, Col, Form, Input, Select, message, Button, Tabs, InputNumber } from "antd";
import Auxiliary from "util/Auxiliary";
import { API_URL } from 'constants/APIConfig';
import { useHistory } from "react-router-dom";
import Widget from "components/Widget/index";

const FormItem = Form.Item;
const { Option } = Select;
const InputGroup = Input.Group;
const TabPane = Tabs.TabPane;
const { TextArea } = Input;



const NewCost = (props) => {
    let history = useHistory();
    const userDetails = JSON.parse(sessionStorage.getItem('user_details'));
    const token = sessionStorage.getItem('token');

    const [projects, setProjects] = useState([]);
    const [projectId, setProjectId] = useState([]);

    // UseForm Hook
    const [form] = Form.useForm();

    const onProjectChange = value => {
        setProjectId(value);
    };

    const getProjectList = () => {
        axios.get(API_URL + `/api/project/v2/projectsByManager?userId=${userDetails.id}`, 
        {headers: {
          'x-access-token': `${token}`, 
        'Access-Control-Allow-Origin': '*'}
       })
            .then(response => {
                if (Object.keys(response.data.data).length > 0) {
                    setProjects(response.data.data);
                }
            })
            .catch(error => {
                // Error
                if (error.response) {
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                } else if (error.request) {
                    console.log(error.request);
                } else {
                    console.log('Error', error.message);
                }
                console.log(error.config);
            });
    }
    useEffect(() => {
        getProjectList();
    }, [])


    //UI Functions
    const onFinish = values => {
        let projCostData = [];
        let fieldsData = form.getFieldsValue();
        projCostData.push({
            projectId: +projectId,
            spendType: fieldsData.cost.type,
            plannedSpend: +fieldsData.cost.amount,
            actualSpend: +form.getFieldValue('aspend'),
            comments: form.getFieldValue('comments')
        })
        console.log("ProjCostData", projCostData)
        axios.post(API_URL + '/api/project_cost/create', projCostData, 
        {headers: {
          'x-access-token': `${token}`, 
        'Access-Control-Allow-Origin': '*'}
       })
            .then(response => {
                message.success(`Cost added sucessfully`);
                history.push("/cost/manage");
            })
            .catch(error => {
                // Error
                if (error.response) {
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                } else if (error.request) {
                    console.log(error.request);
                } else {
                    console.log('Error', error.message);
                }
                console.log(error.config);
                message.error("Error In creating Cost", error)
            });
    }
    return (
        <Auxiliary>
            <Form
                form={form}
                initialValues={{ remember: true }}
                name="prjCost"
                onFinish={onFinish}
                layout="inline"
                align="center"
            >
                <Row gutter={24}>
                    <Col lg={16} md={12} sm={24} xs={24}  >
                    </Col>
                    <Col lg={8} md={12} sm={24} xs={24}  >
                        <div className="gx-form-row0" >
                            <Form.Item
                                name="prjname"
                                // label="Project Name"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Enter project name',
                                    },
                                ]}
                            >
                                <Select
                                    showSearch
                                    style={{ width: '100%' }}
                                    placeholder="Projects"
                                    optionFilterProp="children"
                                    onChange={onProjectChange}
                                    // size="small"
                                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                >
                                    {
                                        projects.length ? projects.map((project, index) => (
                                            <Option key={index} value={project.id}>{project.name}</Option>
                                        )) : null
                                    }
                                </Select>
                            </Form.Item>
                        </div>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col lg={24} md={24} sm={24} xs={24} className="plannedSpend" >
                        <Widget>
                            <Row>
                                <Col lg={12} md={12} sm={12} xs={24} >
                                    <div className="gx-form-row0">
                                        <h6 align="left">Planned Spend</h6>
                                        <Form.Item
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Enter project cost',
                                                },
                                            ]}
                                        >
                                            <InputGroup compact className="gx-mb-3">
                                                <Form.Item
                                                    name={['cost', 'type']}
                                                    noStyle
                                                    rules={[{ required: true, message: 'Enter Cost type' }]}
                                                >
                                                    <Select style={{ width: '90%' }}>
                                                        <Option value="Resourcing Costs">Resourcing Costs</Option>
                                                        <Option value="Software Costs">Software Costs</Option>
                                                        <Option value="Hardware Costs">Hardware Costs</Option>
                                                        <Option value="Training Costs">Training Costs</Option>
                                                        <Option value="Others<">Others</Option>
                                                    </Select>
                                                </Form.Item>
                                                <Form.Item
                                                    name={['cost', 'amount']}
                                                    noStyle
                                                    rules={[{ required: true, message: 'Enter amount' }]}
                                                >
                                                    <InputNumber style={{ width: '10%' }} />
                                                </Form.Item>
                                            </InputGroup>
                                        </Form.Item>
                                    </div>
                                </Col>
                                <Col lg={12} md={12} sm={12} xs={24} >
                                    <div className="gx-form-row0">
                                        <h6 align="left">Actual Spend</h6>
                                        <Form.Item
                                            name="aspend"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Enter actual spend',
                                                },
                                            ]}
                                        >
                                            <InputNumber placeholder="Name" />
                                        </Form.Item>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={24} md={12} sm={12} xs={24} >
                                    <div className="gx-form-row0">
                                        <h6 align="left">Comments</h6>
                                        <Form.Item
                                            name="comments"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Enter comments',
                                                },
                                            ]}
                                        >
                                            <TextArea rows={4} />
                                        </Form.Item>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={24} md={24} sm={24} xs={24} >
                                    <div className="gx-form-row0">
                                        <Form.Item
                                            name="submit"
                                        >
                                            <Button type="primary" className="add-btn"  htmlType="submit" >Submit</Button>
                                            <Button type="primary" className="add-btn gx-mr-2" onClick={() => history.push('/cost/manage')}>Back</Button>
                                        </Form.Item>
                                    </div>
                                </Col>
                            </Row>
                        </Widget>
                    </Col>
                </Row>
            </Form>
        </Auxiliary>
    );
};
export default NewCost;
