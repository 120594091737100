import React, { useEffect, useState } from "react";
import { Col, Row, Progress, Button, Table, Popconfirm, Select, Tabs, Tooltip, Collapse, Breadcrumb } from "antd";
import Auxiliary from "util/Auxiliary";
import Widget from "components/Widget/index";
import axios from 'axios';
import { API_URL } from 'constants/APIConfig';
import IconWithTextCard from "components/dashboard/CRM/IconWithTextCard";
import { PlusCircleOutlined } from '@ant-design/icons';
import moment from "moment";
import { FormOutlined } from '@ant-design/icons';

const { Option } = Select;
const TabPane = Tabs.TabPane;
const { Panel } = Collapse;

const ManagerDashboard = (props) => {
  const userDetails = JSON.parse(sessionStorage.getItem('user_details'))
  const token = sessionStorage.getItem('token')
  const [treeData, setTreeData] = useState([])
  const [payments, setPayments] = useState([])
  const [projectId, setProjectId] = useState(0)
  const [projects, setProjects] = useState([])
  const [selectedProject, setSelectedProject] = useState({})
  const [taskMetrics, setTaskMetrics] = useState([])
  const [costEditId, setCostEditId] = useState(0)
  const [issueEditId, setIssueEditId] = useState(0)
  const [projCost, setProjCost] = useState([])
  const [docuPop, setDocuPop] = useState(false)
  const [issues, setIssues] = useState([])
  const [resourceOnBoardTableList, setResourceOnBoardTableList] = useState([])
  const [plannedEffortsTableList, setPlannedEffortsTableList] = useState([])
  const [projectPlan, setProjectPlan] = useState([])
  const [ganntPlan, setGanntPlan] = useState([])
  const [health, setHealth] = useState([])
  const [timeLog, setTimeLog] = useState([]);

  const getProjectList = () => {
    axios.get(API_URL + `/api/project/v2/projectsByManager?userId=${userDetails.id}&t=${new Date().getTime()}`,
      {
        headers: {
          'x-access-token': `${token}`,
          'Access-Control-Allow-Origin': '*'
        }
      })
      .then(response => {
        if (Object.keys(response.data.data).length > 0) {
          setProjects(response.data.data);
        }
      })
      .catch(error => {
        console.log(error);
      });
  }

  const onProjectChange = value => {
    setProjectId(value);
  }

  const getProjectbyId = () => {
    axios.get(API_URL + `/api/project/v2/get/${projectId}?t=${new Date().getTime()}`,
      {
        headers: {
          'x-access-token': `${token}`,
          'Access-Control-Allow-Origin': '*'
        }
      })
      .then(response => {
        if (Object.keys(response.data.data).length > 0) {
          setSelectedProject(response.data.data);
          let projectDetails = response.data.data;
          if (projectDetails.ProjectPlannedEfforts && projectDetails.ProjectPlannedEfforts.length != 0) {
            projectDetails.ProjectPlannedEfforts.forEach(s => {
              s.key = s.id
            })
            setPlannedEffortsTableList(projectDetails.ProjectPlannedEfforts)
          } else {
            setPlannedEffortsTableList([])  
          }
        } else {
          setSelectedProject({});
          setPlannedEffortsTableList([])
        }
      })
      .catch(error => {
        console.log(error);
      });
  }

  const getTaskLists = (projID) => {
    axios.get(API_URL + `/api/task/getTasksWithSubTasksTree?userId=${userDetails.id}&projectId=${projectId}&t=${new Date().getTime()}`,
      {
        headers: {
          'x-access-token': `${token}`,
          'Access-Control-Allow-Origin': '*'
        }
      })
      .then(response => {
        if (Object.keys(response.data.data).length > 0) {
          setTreeData(response.data.data);
        } else {
          setTreeData([])
        }
      })
      .catch(error => {
        console.log(error);
      });
  }

  const taskCols = [
    {
      title: 'Task ID', dataIndex: 'key', key: 'id', fixed: 'left', width: 100,
      sorter: (a, b) => String(a.title).localeCompare(String(b.title)),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Name', dataIndex: 'title', key: 'name', width: 250,
      sorter: (a, b) => String(a.title).localeCompare(String(b.title)),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Start Date', dataIndex: 'startDateDt', key: 'sdt', width: 120, align: 'center',
      sorter: (a, b) => String(a.startDateDt).localeCompare(String(b.startDateDt)),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'End Date', dataIndex: 'dueDateDt', key: 'edt', width: 120, align: 'center',
      sorter: (a, b) => String(a.dueDateDt).localeCompare(String(b.dueDateDt)),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Estd. Hours', dataIndex: 'estimatedHoursNm', key: 'estdhrs', width: 120, align: 'center',
      sorter: (a, b) => String(a.estimatedHoursNm).localeCompare(String(b.estimatedHoursNm)),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Owner', dataIndex: 'owner', key: 'owner', width: 150,
      sorter: (a, b) => String(a.owner).localeCompare(String(b.owner)),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Status', dataIndex: 'taskstatus', key: 'taskstatus', fixed: 'right', width: 100, align: 'center',
      sorter: (a, b) => String(a.taskstatus).localeCompare(String(b.taskstatus)),
      sortDirections: ['descend', 'ascend'],
    },
    /*     {
          title: 'Action', dataIndex: 'action', fixed: 'right', width: 70,
          render: (text, record) =>
            treeData.length >= 1 ? (<span>
              <Popconfirm title="Sure to edit?" onConfirm={() => editTaskRcrd(record.key)}>  <a><FormOutlined style={{ padding: '10px' }} /></a> </Popconfirm>
            </span>
            ) : null,
        }, */
  ]

  const getTaskMetrics = () => {
    axios.get(API_URL + `/api/task/all/taskStatus/${projectId}?t=${new Date().getTime()}`,
      {
        headers: {
          'x-access-token': `${token}`,
          'Access-Control-Allow-Origin': '*'
        }
      })
      .then(response => {
        if (Object.keys(response.data.data).length > 0) {
          setTaskMetrics(response.data.data);
        }
      })
      .catch(error => {
        console.log(error);
      });
  }

  const financeCols = [
    {
      title: 'Milestone', dataIndex: 'titleTx',
      sorter: (a, b) => String(a.titleTx).localeCompare(String(b.titleTx)),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Allocation', dataIndex: 'percentCompleteNm',
      sorter: (a, b) => String(a.percentCompleteNm).localeCompare(String(b.percentCompleteNm)),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Budget', dataIndex: 'billableAmountNm',
      sorter: (a, b) => String(a.billableAmountNm).localeCompare(String(b.billableAmountNm)),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Remarks', dataIndex: 'comments',
      sorter: (a, b) => String(a.comments).localeCompare(String(b.comments)),
      sortDirections: ['descend', 'ascend'],
    },
  ]

  const getFinanceLists = () => {

    axios.get(API_URL + `/api/project_milestones/project/${projectId}/type/FINANCIAL`,
      {
        headers: {
          'x-access-token': `${token}`,
          'Access-Control-Allow-Origin': '*'
        }
      })
      .then(response => {
        if (Object.keys(response.data.data).length > 0) {
          setPayments(response.data.data);
        }
      })
      .catch(error => {
        console.log(error);
      });
  }

  const editCostRcrd = (id) => {
    setCostEditId(id);
  }

  const getResourceAlloc = () => {
    axios.get(API_URL + `/api/project_resources/getProjectResources?userId=${userDetails.id}&projectId=${projectId}`,
      {
        headers: {
          'x-access-token': `${token}`,
          'Access-Control-Allow-Origin': '*'
        }
      })
      .then(response => {
        if (Object.keys(response.data.data).length > 0) {
          response.data.data.forEach(r => {r.key = r.id})
          setResourceOnBoardTableList(response.data.data)
        } else {
          setResourceOnBoardTableList([])
        }
      })
      .catch(error => {
        console.log(error);
      });
  }

  const getProjectPlan = () => {
    axios.get(API_URL + `/api/project_milestones/project/${projectId}/type/DELIVERY`,
      {
        headers: {
          'x-access-token': `${token}`,
          'Access-Control-Allow-Origin': '*'
        }
      })
      .then(response => {
        if (Object.keys(response.data.data).length > 0) {
          let devMilestones = [];
          response.data.data.map((item, index) => {
            devMilestones.push({
              id: item.id,
              start: new Date(moment(item.startDateDt).format('YYYY'), moment(item.startDateDt).format('M'), moment(item.startDateDt).format('D')),
              end: new Date(moment(item.endDateDt).format('YYYY'), moment(item.endDateDt).format('M'), moment(item.endDateDt).format('D')),
              name: item.titleTx,
              progress: 45,
              isDisabled: true,
              styles: { progressColor: '#ffbb54', progressSelectedColor: '#ff9e0d' },
            })
          })
          setProjectPlan(response.data.data);
          setGanntPlan(devMilestones);
        } else {
          setProjectPlan([]);
          setGanntPlan([]);
        }
      }).catch(error => {
        console.log(error);
      });
  }

  const gettimeLog = () => {
    axios.get(API_URL + `/api/time_log/getTaskTimeLogHistory?userId=${userDetails.id}&projectId=${projectId}`,
      {
        headers: {
          'x-access-token': `${token}`,
          'Access-Control-Allow-Origin': '*'
        }
      })
      .then(response => {
        if (Object.keys(response.data.data).length > 0) {
          let timeLogEntries = [];
          response.data.data.map((item, index) => {
            timeLogEntries.push({
              employeeId: item.employeeId,
              taskName: item.taskName,
              isBillable: item.isBillable,
              estimatedHoursNm: item.estimatedHoursNm,
              totalHours: parseFloat(item.totalHours).toFixed(2),
            })
          })
          setTimeLog(timeLogEntries);
        }
      })
      .catch(error => {
        console.log(error);
      });
  }

  const costColumns = [
    {
      title: 'Spend Type', dataIndex: 'spendType', fixed: 'left', width: 150,
      sorter: (a, b) => String(a.spendType).localeCompare(String(b.spendType)),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Planned Spend', dataIndex: 'plannedSpend', width: 150,
      sorter: (a, b) => String(a.plannedSpend).localeCompare(String(b.plannedSpend)),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Actual Spend', dataIndex: 'actualSpend', width: 150,
      sorter: (a, b) => String(a.actualSpend).localeCompare(String(b.actualSpend)),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Variance', dataIndex: 'variance', width: 150,
      sorter: (a, b) => String(a.variance).localeCompare(String(b.variance)),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Comments', dataIndex: 'comments', width: 150,
      sorter: (a, b) => String(a.comments).localeCompare(String(b.comments)),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Action', dataIndex: 'action', fixed: 'right', width: 70,
      render: (text, record) =>
        projCost.length >= 1 ? (<span>
          <Popconfirm title="Sure to edit?" onConfirm={() => editCostRcrd(record.id)}>  <a><FormOutlined style={{ padding: '10px' }} /></a> </Popconfirm></span>
        ) : null,
    },
  ]

  const getProjCost = () => {
    axios.get(API_URL + `/api/project_cost/getProjectCost?projectId=${projectId}`,
      {
        headers: {
          'x-access-token': `${token}`,
          'Access-Control-Allow-Origin': '*'
        }
      })
      .then(response => {
        if (Object.keys(response.data.data).length > 0) {
          setProjCost(response.data.data);
        } else {
          setProjCost([])
        }
        console.log("Project Cost", response.data.data);
      })
      .catch(error => {
        console.log(error);
      });
  }

  const getProjIssueList = () => {
    axios.get(API_URL + `/api/project_issues_risks_actions/getDetails?projectId=${projectId}`,
      {
        headers: {
          'x-access-token': `${token}`,
          'Access-Control-Allow-Origin': '*'
        }
      })
      .then(response => {
        if (Object.keys(response.data.data).length > 0) {
          let issuesEntries = [];
          response.data.data.map((item, index) => {
            issuesEntries.push({
              id: item.id,
              type: item.type,
              owner: item.assignedUser && item.assignedUser.displayNameTx,
              status: item.status,
              dueDate: item.dueDate,
              comments: item.comments,
            })
          })
          setIssues(issuesEntries);
        } else {
          setIssues([]);
        }
      })
      .catch(error => {
        console.log(error);
      });
    if (issueEditId != 0) {
      setIssueEditId(0);
    }

  }

  const healthColumns = [
    {
      title: 'Year', dataIndex: 'year', fixed: 'left', width: 80,
      sorter: (a, b) => String(a.year).localeCompare(String(b.year)),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Week', dataIndex: 'weekNumber', fixed: 'left', width: 80,
      sorter: (a, b) => String(a.weekNumber).localeCompare(String(b.weekNumber)),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Scope',
      dataIndex: 'scope',
      render(text, record) {

        let color = "";
        if (parseInt(text) == 1) {
          color = "red";
        } else if (parseInt(text) == 2) {
          color = "green";
        } else if (parseInt(text) == 3) {
          color = "orange";
        } else if (parseInt(text) == 4) {
          color = "blue";
        }
        return {
          props: {
            style: {
              color: color,
            }
          },
          children: <div>
            <Tooltip placement="topLeft" title={record.scopeDesc}>
              <i className="icon icon-circle"></i>
            </Tooltip>
          </div>
        };

      },
      sorter: (a, b) => String(a.scope).localeCompare(String(b.scope)),
      sortDirections: ['descend', 'ascend'],

    },
    {
      title: 'Resource',
      dataIndex: 'resource',
      render(text, record) {
        let color = "";
        if (parseInt(text) == 1) {
          color = "red";
        } else if (parseInt(text) == 2) {
          color = "green";
        } else if (parseInt(text) == 3) {
          color = "rgb(248, 149, 66)";
        } else if (parseInt(text) == 4) {
          color = "blue";
        }
        return {
          props: {
            style: {
              color: color,
            }
          },
          children: <div>
            <Tooltip placement="topLeft" title={record.resourceDesc}>
              <i className="icon icon-circle"></i>
            </Tooltip>
          </div>
        };
      },
      sorter: (a, b) => String(a.resource).localeCompare(String(b.resource)),
      sortDirections: ['descend', 'ascend'],
    },
    // { title: 'Schedule', dataIndex: 'schedule', },
    {
      title: 'Schedule',
      dataIndex: 'schedule',
      render(text, record) {
        let color = "";
        if (parseInt(text) == 1) {
          color = "red";
        } else if (parseInt(text) == 2) {
          color = "green";
        } else if (parseInt(text) == 3) {
          color = "rgb(248, 149, 66)";
        } else if (parseInt(text) == 4) {
          color = "blue";
        }
        return {
          props: {
            style: {
              color: color,
            }
          },
          children: <div>
            <Tooltip placement="topLeft" title={record.scheduleDesc}>
              <i className="icon icon-circle"></i>
            </Tooltip>
          </div>
        };
      },
      sorter: (a, b) => String(a.schedule).localeCompare(String(b.schedule)),
      sortDirections: ['descend', 'ascend'],
    },
    // { title: 'Quality', dataIndex: 'quality' },
    {
      title: 'Quality',
      dataIndex: 'quality',
      render(text, record) {
        let color = "";
        if (parseInt(text) == 1) {
          color = "red";
        } else if (parseInt(text) == 2) {
          color = "green";
        } else if (parseInt(text) == 3) {
          color = "rgb(248, 149, 66)";
        } else if (parseInt(text) == 4) {
          color = "blue";
        }
        return {
          props: {
            style: {
              color: color,
            }
          },
          children: <div>
            <Tooltip placement="topLeft" title={record.qualityDesc}>
              <i className="icon icon-circle"></i>
            </Tooltip>
          </div>
        };
      },
      sorter: (a, b) => String(a.quality).localeCompare(String(b.quality)),
      sortDirections: ['descend', 'ascend'],
    },
    // { title: 'Chng Mang', dataIndex: 'changeMang', },
    {
      title: 'ChMang',
      dataIndex: 'changeMang',
      render(text, record) {
        let color = "";
        if (parseInt(text) == 1) {
          color = "red";
        } else if (parseInt(text) == 2) {
          color = "green";
        } else if (parseInt(text) == 3) {
          color = "rgb(248, 149, 66)";
        } else if (parseInt(text) == 4) {
          color = "blue";
        }
        return {
          props: {
            style: {
              color: color,
            }
          },
          children: <div>
            <Tooltip placement="topLeft" title={record.changeMangDesc}>
              <i className="icon icon-circle"></i>
            </Tooltip>
          </div>
        };
      },
      sorter: (a, b) => String(a.changeMang).localeCompare(String(b.changeMang)),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Budget',
      dataIndex: 'budget',
      render(text, record) {
        let color = "";
        if (parseInt(text) == 1) {
          color = "red";
        } else if (parseInt(text) == 2) {
          color = "green";
        } else if (parseInt(text) == 3) {
          color = "rgb(248, 149, 66)";
        } else if (parseInt(text) == 4) {
          color = "blue";
        }
        return {
          props: {
            style: {
              color: color,
            }
          },
          children: <div>
            <Tooltip placement="topLeft" title={record.budgetDesc}>
              <i className="icon icon-circle"></i>
            </Tooltip>
          </div>
        };
      },
      sorter: (a, b) => String(a.budget).localeCompare(String(b.budget)),
      sortDirections: ['descend', 'ascend'],
    },
    /*     {
          title: 'Action', dataIndex: 'action', fixed: 'right', width: 50,
          render: (text, record) =>
            health.length >= 1 ? (<span> <Popconfirm title="Sure to edit?" onConfirm={() => editHealthRcrd(record.id)}>  <a><FormOutlined style={{ padding: '10px' }} /></a>  </Popconfirm> </span>
            ) : null,
        }, */
  ]

  const plannedEffortsTableColumns = [
    {
      title: 'Resource Type', dataIndex: 'resourceType', width: 150,
    },
    {
      title: 'Skill', dataIndex: 'subSkillId', width: 150, render: function(text, record) {
        return record.assignedSkill.nameTx;
      }
    },
    {
      title: 'Engagement', dataIndex: 'engagementType', width: 150,
    },
    {
      title: 'Total Weeks', dataIndex: 'totalWeeks', width: 150,
    },
    {
      title: 'Total Days', dataIndex: 'totalDays', width: 150,
    },
    {
      title: 'Total Hours', dataIndex: 'totalHours', width: 150,
    },
    { title: 'Cost Per Hour', width: 100, dataIndex: 'costPerHour'  },
  ]
  
  const getProjHealthList = () => {
    axios.get(API_URL + `/api/project_health/getProjectHealth?projectId=${projectId}`,
      {
        headers: {
          'x-access-token': `${token}`,
          'Access-Control-Allow-Origin': '*'
        }
      })
      .then(response => {
        if (Object.keys(response.data.data).length > 0) {
          let healthEntries = [];
          response.data.data.map((item, index) => {
            healthEntries.push({
              id: item.id,
              weekNumber: item.weekNumber,
              year: item.year,
              budget: item.budget,
              changeMang: item.changeMang,
              quality: item.quality,
              resource: item.resource,
              schedule: item.schedule,
              scope: item.scope,
              budgetDesc: item.budgetDesc,
              changeMangDesc: item.changeMangDesc,
              qualityDesc: item.qualityDesc,
              resourceDesc: item.resourceDesc,
              scheduleDesc: item.scheduleDesc,
              scopeDesc: item.scopeDesc,
            })
          })
          setHealth(healthEntries);
        } else {
          setHealth([]);
        }
      })
      .catch(error => {
        console.log(error);
      });
  }

  const resourceOnBoardingTableColumns = [
    {
      title: 'Department', width: 150, render: function(text, record) {
        return record.assignedDepartment.nameTx;
      }
    },
    {
      title: 'Skill', width: 150, render: function(text, record) {
        return record.assignedSkill.nameTx;
      }
    },
    {
      title: 'Resource', width: 150, render: function(text, record) {
        return record.assignedUser.displayNameTx;
      }
    },
    {
      title: 'Start Date', dataIndex: 'startDate', width: 150,
    },
    {
      title: 'End Date', dataIndex: 'endDate', width: 150,
    },
    {
      title: 'Planned Hours', dataIndex: 'hours', width: 150,
    },
    { title: 'Requested By', width: 100, render: function(text, record) {
      return record.requestedByUser.displayNameTx;
    }  },
    { title: 'Comments', width: 100, dataIndex: 'comments'  },
  ]

  useEffect(() => {
    getProjectList();
  }, [])

  useEffect(() => {
    if (projectId != 0) {
      getProjectbyId();
      getTaskLists();
      getFinanceLists();
      getResourceAlloc();
      getProjectPlan();
      gettimeLog();
      getTaskMetrics();
      getProjIssueList();
      getProjCost();
      getProjHealthList();
    }
  }, [projectId])

  return (
    <Auxiliary>
    <Breadcrumb className="pmPage">
      <Breadcrumb.Item>Project Manager</Breadcrumb.Item>
      <Breadcrumb.Item className={'text-helight'}><i className="icon icon-check-circle-o" /> Dashboard </Breadcrumb.Item>
    </Breadcrumb>
    {projectId != 0 ?
      <Row gutter={24} className="">
        <Col xl={8} lg={8} md={8} sm={24} xs={24} className="mb-0">
          <Widget>
            <h2 className="gx-mb-3 gx-mb-sm-4 headings_title">Progress</h2>
            <Progress percent={selectedProject.progress ? selectedProject.progress : 0} />
          </Widget>
        </Col>
        <Col xl={16} lg={16} md={16} sm={24} xs={24} className="gx-order-sm-1">
          <Row className="">
            <Col xl={6} lg={6} md={6} sm={12} xs={12}>
              <IconWithTextCard cardColor="cyan" icon="diamond" title={taskMetrics.TOTAL} subTitle="Total Task" />
            </Col>
            <Col xl={6} lg={6} md={6} sm={12} xs={12}>
              <IconWithTextCard cardColor="teal" icon="team" title={taskMetrics.NEW} subTitle="New" />
            </Col>
            <Col xl={6} lg={6} md={6} sm={12} xs={12}>
              <IconWithTextCard cardColor="red" icon="files" title={taskMetrics.INPROGRESS} subTitle="In Progress" />
            </Col>
            <Col xl={6} lg={6} md={6} sm={12} xs={12}>
              <IconWithTextCard cardColor="orange" icon="tasks" title={taskMetrics.CLOSED} subTitle="Complete" />
            </Col>
          </Row>
        </Col>
      </Row>
    : ''}
      <Row gutter={24}>
        <Col lg={16} md={12} sm={24} xs={24} >
        </Col>
        <Col lg={8} md={12} sm={24} xs={24}  >
          <div className="gx-form-row0" >
              <Select
                showSearch
                style={{ width: '100%' }}
                placeholder="Select the Project"
                optionFilterProp="children"
                onChange={onProjectChange}
                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              >
                {
                  projects.length ? projects.map((project, index) => (
                    <Option key={index} value={project.id}>{project.name}</Option>
                  )) : null
                }
              </Select>
          </div>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col lg={24} md={24} sm={24} xs={24}  >
          {projectId == 0 ? <div style={{textAlign: "center"}}><b>Please Select the Project from dropdown</b></div>
          :
          <Tabs tabPosition='top' className="tab_layout">
            <TabPane tab="Scope" key="1">
              <Widget>
                <Row>
                  <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                    <p><span className="text-blue-color">Scope</span><br />
                      {selectedProject.scope} </p>
                    <Row>
                      <Col xl={6} lg={6} md={6} sm={6} xs={6}>
                        <p><span className="text-blue-color">Project Manager</span><br />
                          {selectedProject.ProjectManager && selectedProject.ProjectManager.displayNameTx}</p>
                      </Col>
                      <Col xl={6} lg={6} md={6} sm={6} xs={6}>
                        <p><span className="text-blue-color">Account Manager</span><br />
                          {selectedProject.AccountManager && selectedProject.AccountManager.displayNameTx}</p>
                      </Col>
                      <Col xl={4} lg={6} md={6} sm={6} xs={6}>
                        <p><span className="text-blue-color">Start Date</span><br />
                          {selectedProject.startDate}</p>
                      </Col>
                      <Col xl={4} lg={6} md={6} sm={6} xs={6}>
                        <p><span className="text-blue-color">End Date</span><br />
                          {selectedProject.endDate}</p>
                      </Col>
                      <Col xl={4} lg={6} md={6} sm={6} xs={6}>
                        <p><span className="text-blue-color">Duration</span><br />
                          {selectedProject.estimatedHours}</p>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Widget>
            </TabPane>

            <TabPane tab="Planned Efforts" key="2">
              <div className="spacing1">
                <Row gutter={24} className="gx-m-0">
                  <Col xl={24} lg={24} md={24} sm={24} xs={24} className="gx-p-0">
                    <Table className="gx-table-responsive" columns={plannedEffortsTableColumns} dataSource={plannedEffortsTableList} pagination={{ pageSize: 10 }}  />
                  </Col>
                </Row>
              </div>
            </TabPane>

            <TabPane tab="Tasks" key="3">
              <div className="spacing1">
                <Row gutter={24} className="gx-m-0">
                  <Col xl={24} lg={24} md={24} sm={24} xs={24} className="gx-p-0">
                    <Table className="gx-table-responsive" columns={taskCols} dataSource={treeData} pagination={{ pageSize: 10 }}  />
                  </Col>
                </Row>
              </div>
            </TabPane>

            <TabPane tab="Weekly Status" key="6">
              <div className="spacing1">
                <Row gutter={24} className="gx-m-0">
                  <Col xl={24} lg={24} md={24} sm={24} xs={24} className="gx-p-0">
                    <Collapse defaultActiveKey={['1']}>
                      <Panel header="Weekly Status" key="1">
                        <Table className="gx-table-responsive alignmiddle" columns={healthColumns} dataSource={health} pagination={{ pageSize: 5 }} bordered={false} scroll={{ x: 1300 }} />
                      </Panel>
                    </Collapse>
                  </Col>
                </Row>
              </div>
            </TabPane>

            <TabPane tab="Resources" key="5">
              <div className="spacing1">
                <Row gutter={24} className="gx-m-0">
                  <Col xl={24} lg={24} md={24} sm={24} xs={24} className="gx-p-0">
                    <Table className="gx-table-responsive" columns={resourceOnBoardingTableColumns} dataSource={resourceOnBoardTableList} pagination={{ pageSize: 10 }}  />
                  </Col>
                </Row>
              </div>
            </TabPane>

            <TabPane tab="Project Cost" key="8">
              <div className="spacing1">
                <Row gutter={24} className="gx-m-0">
                  <Col xl={24} lg={24} md={24} sm={24} xs={24} className="gx-p-0">
                    <Collapse defaultActiveKey={['1']}>
                      <Panel header="Project Cost" key="1">
                        <Table className="gx-table-responsive" columns={costColumns} dataSource={projCost} pagination={{ pageSize: 5 }} bordered={false} scroll={{ x: 1300 }} />
                      </Panel>
                    </Collapse>
                  </Col>
                </Row>
              </div>
            </TabPane>

            <TabPane tab="Documents" key="10">
              <Row>
                <Col xl={24} lg={24} md={8} sm={24} xs={24}>
                  <Button className="float-right" type="dashed" icon={<PlusCircleOutlined />} onClick={() => setDocuPop(true)}>Documents</Button>
                </Col>
              </Row>
            </TabPane>

          </Tabs>}
        </Col>
      </Row>
    </Auxiliary>
  );
};
export default ManagerDashboard;