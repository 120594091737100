import React, { useEffect, useState } from "react";
import axios from 'axios';

import { Row, Col, Form, Input, Select, Radio, InputNumber, DatePicker, Breadcrumb, Button, message, Divider } from "antd";
import { API_URL } from 'constants/APIConfig';
import { useHistory } from "react-router-dom";

const { Option } = Select;
const RadioGroup = Radio.Group;
const InputGroup = Input.Group;
const { RangePicker } = DatePicker;
const { TextArea } = Input;

const NewProject = () => {
  let history = useHistory();
  const token = sessionStorage.getItem('token');

  const [users, setUsers] = useState([]);
  const [clients, setClients] = useState([]);
  const [currency, setCurrency] = useState([]);
  const [projecttypes, setProjectTypes] = useState([]);
  const [projectStatusList, setProjectStatusList] = useState([]);
  const [priorityValue, setPriorityValue] = useState(1);
  const [techList, setTechList] = useState([]);
  const [isBillable, setIsBillable] = useState(0);
  const [projecttype, setprojecttype] = useState([]);

  function onChangeBillable(e) {
    setIsBillable(e.target.value);
  }

  function handleProjectCategoryChange(value) {
    if (value == "SAP") {
      setprojecttype(["Implementation", "AMS", "RE", "Roleout", "Upgradation"])
    } else if (value == "Digital") {
      setprojecttype(["Development", "AMS", "Resource Engagement"])
    } else if (value == "Internal") {
      setprojecttype(["AMS"])
    }
  }

  const [form] = Form.useForm();

  const onFinish = values => {
    const dates = form.getFieldValue('duration');
    let fieldsData = form.getFieldsValue();

    let billType = +form.getFieldValue('billtype');
    if (isBillable == 0) {
      billType = 6;
    }
    let prjDetdata = {
      "name": form.getFieldValue('prjname'),
      "shortName": form.getFieldValue('shortname'),
      "projectCode": form.getFieldValue('projectcode'),
      "projectType": form.getFieldValue('prjtype'),
      "invoiceCustomerId": form.getFieldValue('client'),
      "startDate": dates[0],
      "endDate": dates[1],
      "estimatedHours": form.getFieldValue('estdhrs'),
      "billableType": billType,
      "billability": form.getFieldValue('billable'),
      "projectcategory": form.getFieldValue('projectcategory'),
      "projectcategorytype": form.getFieldValue('projectcategorytype'),
      "priority": form.getFieldValue('prjpriority'),
      "projectManager": form.getFieldValue('prjmgr'),
      "accountManager": form.getFieldValue('acctmgr'),
      "statusId": form.getFieldValue('prjstatus'),
      "initiator": form.getFieldValue('prjinit'),
      "industryType": form.getFieldValue('indus'),
      "sector": form.getFieldValue('sector'),
      "users": form.getFieldValue('users'),
      "description": form.getFieldValue('desc'),
      "technologies": form.getFieldValue('tech'),
      "scope": form.getFieldValue('scope'),
      "projectCoordinator": form.getFieldValue('pmo'),
      "costApproved": fieldsData.budget.amount,
      "budget": fieldsData.budget.amount,
      "poNumber": form.getFieldValue('pono'),
      "poDate": form.getFieldValue('podate'),
      "currency": fieldsData.budget.currency,
      "userId": 1,
      "defaultAssign": 1,
    }
    axios.post(API_URL + '/api/project/v2/addNewProject', prjDetdata,
      {
        headers: {
          'x-access-token': `${token}`,
          'Access-Control-Allow-Origin': '*'
        }
      })
      .then(response => {
        message.success("Project Created Successfully");
        form.resetFields();
        history.push("/master/projects/manage");
      })
      .catch(error => {
        console.log(error);
        message.error("Error In creating Project", error)
      });

  }

  const onPriorityChange = (e) => {
    setPriorityValue(e.target.value);
  }

  const sectors = ['Energy', 'Materials', 'Industrials', 'Consumer Discretionary', 'Consumer Staples', 'Health Care', 'Financials', 'Information Technology', 'Telecommunication Services', 'Utilities', 'Real Estate']
  const industries = ['Aerospace Industry', 'Transport Industry', 'Computer Industry', 'Telecommunication industry', 'Agriculture industry', 'Construction Industry', 'Education Industry']

  const getUserList = () => {
    axios.get(API_URL + '/api/users/getall',
      {
        headers: {
          'x-access-token': `${token}`,
          'Access-Control-Allow-Origin': '*'
        }
      })
      .then(response => {
        console.log('User List', response.data.data)
        setUsers(response.data.data);
      })
      .catch(error => {
        console.log(error);
      });
  }
  
  const getClientList = () => {
    axios.get(API_URL + '/api/invoice_customers/all?limit=500&offset=0',
      {
        headers: {
          'x-access-token': `${token}`,
          'Access-Control-Allow-Origin': '*'
        }
      })
      .then(response => {
        console.log('Client List', response.data.data)
        setClients(response.data.data);
      })
      .catch(error => {
        console.log(error);
      });
  }

  const getTechList = () => {
    axios.get(API_URL + '/api/technology/all',
      {
        headers: {
          'x-access-token': `${token}`,
          'Access-Control-Allow-Origin': '*'
        }
      })
      .then(response => {
        console.log('Tech List', response.data.data)
        setTechList(response.data.data);
      })
      .catch(error => {
        console.log(error);
      });
  }

  const getCurrencyList = () => {
    axios.get(API_URL + '/api/currency/all',
      {
        headers: {
          'x-access-token': `${token}`,
          'Access-Control-Allow-Origin': '*'
        }
      })
      .then(response => {
        console.log('Currency List', response.data.data)
        setCurrency(response.data.data);
      })
      .catch(error => {
        console.log(error);
      });
  }

  const getProjectTypeList = () => {
    axios.get(API_URL + '/api/projecttype/all',
      {
        headers: {
          'x-access-token': `${token}`,
          'Access-Control-Allow-Origin': '*'
        }
      })
      .then(response => {
        console.log('Project Type', response.data.data)
        setProjectTypes(response.data.data);
      })
      .catch(error => {
        console.log(error);
      });
  }

  const getProjectStatusList = () => {
    axios.get(API_URL + '/api/projectstatus/all',
      {
        headers: {
          'x-access-token': `${token}`,
          'Access-Control-Allow-Origin': '*'
        }
      })
      .then(response => {
        console.log('Project Status', response.data.data)
        setProjectStatusList(response.data.data);
      })
      .catch(error => {
        console.log(error);
      });
  }

  useEffect(() => {
    getUserList();
    getClientList();
    getProjectTypeList();
    getProjectStatusList()
    getCurrencyList()
    getTechList()
  }, [])

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item>Projects</Breadcrumb.Item>
        <Breadcrumb.Item className={'text-helight'}><i className="icon icon-check-circle-o spaceMr" />New Project</Breadcrumb.Item>
      </Breadcrumb>
      <br />
      <Form
        form={form}
        initialValues={{ remember: true }}
        name="prjdetails"
        onFinish={onFinish}
        layout="inline"
        align="center"
      >
        <Divider orientation="left">Scope</Divider>
        <Row>
          <Col lg={24} md={24} sm={24} xs={24} >
            <div className="gx-form-row0">
              <Form.Item
                name="scope"
                rules={[
                  {
                    required: true,
                    message: 'Enter Description',
                  },
                ]}
              >
                <TextArea placeholder="Scope" rows={8} />
              </Form.Item>
            </div>
          </Col>
        </Row>
        <Divider orientation="left">Project Details</Divider>
        <Row >
          <Col lg={8} md={8} sm={12} xs={24} >
            <div className="gx-form-row0">
              <h6 align="left">Name</h6>
              <Form.Item
                name="prjname"
                rules={[
                  {
                    required: true,
                    message: 'Enter Project Name',
                  },
                ]}
              >
                <Input placeholder="Project Name" />
              </Form.Item>
            </div>
          </Col>

          <Col lg={8} md={8} sm={12} xs={24} >
            <h6 align="left">Short Name</h6>
            <div className="gx-form-row0">
              <Form.Item
                name="shortname"
                rules={[
                  {
                    required: true,
                    message: 'Enter Short Name',
                  },
                ]}
              >
                <Input placeholder="Short Name" />
              </Form.Item>
            </div>
          </Col>

          <Col lg={8} md={8} sm={12} xs={24} >
            <h6 align="left">Project Code</h6>
            <div className="gx-form-row0">
              <Form.Item
                name="projectcode"
                rules={[
                  {
                    required: true,
                    message: 'Enter Project Code',
                  },
                ]}
              >
                <Input placeholder="Project Code" />
              </Form.Item>
            </div>
          </Col>

          <Col lg={8} md={8} sm={12} xs={24} >
            <div className="gx-form-row0">
              <h6 align="left">Type</h6>
              <Form.Item
                name="prjtype"
                rules={[
                  {
                    required: true,
                    message: 'Enter Type',
                  },
                ]}
              >
                <Select
                  showSearch
                  style={{ width: '100%' }}
                  placeholder="Select the Type"
                  optionFilterProp="children"
                  filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                >
                  {projecttypes.length ? projecttypes.map((projecttype, index) => (
                    <Option key={index} value={projecttype.id}>{projecttype.typeTx}</Option>
                  )) : null}
                </Select>
              </Form.Item>
            </div>
          </Col>
          <Col lg={8} md={8} sm={12} xs={24} >
            <div className="gx-form-row0">
              <h6 align="left">Client</h6>
              <Form.Item
                name="client"
                rules={[
                  {
                    required: true,
                    message: 'Enter Client',
                  },
                ]}
              >
                <Select
                  showSearch
                  style={{ width: '100%' }}
                  placeholder="Select the Client"
                  optionFilterProp="children"
                  filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                >
                  {clients.length ? clients.map((client, index) => (
                    <Option key={index} value={client.id}>{client.organization}</Option>
                  )) : null}
                </Select>

              </Form.Item>
            </div>
          </Col>
          <Col lg={8} md={8} sm={12} xs={24} >
            <div className="gx-form-row0">
              <h6 align="left">Duration</h6>
              <Form.Item
                name="duration"
                rules={[
                  {
                    required: true,
                    message: 'Enter Duration',
                  },
                ]}
              >
                <RangePicker className="gx-mb-3 gx-w-100" format="DD-MM-YYYY"/>
              </Form.Item>
            </div>
          </Col>
          <Col lg={8} md={8} sm={12} xs={24} >
            <div className="gx-form-row0">
              <h6 align="left">Estd Hours</h6>
              <Form.Item
                name="estdhrs"
                rules={[
                  {
                    required: true,
                    message: 'Enter Estimated Hours',
                  },
                ]}
              >
                <Input placeholder="Estimated Hours" />
              </Form.Item>
            </div>
          </Col>
          <Col lg={8} md={8} sm={12} xs={24} >
            <div className="gx-form-row0">
              <h6 align="left">Priority</h6>
              <Form.Item
                name="prjpriority"
                rules={[
                  {
                    required: true,
                    message: 'Enter Project Priority',
                  },
                ]}
              >
                <RadioGroup name="priority" onChange={(e) => onPriorityChange(e)} value={priorityValue}>
                  <Radio value={1}>High</Radio>
                  <Radio value={2}>Medium</Radio>
                  <Radio value={3}>Low</Radio>
                </RadioGroup>
              </Form.Item>
            </div>
          </Col>
          <Col lg={8} md={8} sm={12} xs={24} >
            <div className="gx-form-row0">
              <h6 align="left">Status</h6>
              <Form.Item
                name="prjstatus"
                rules={[
                  {
                    required: true,
                    message: 'Enter Project Status',
                  },
                ]}
              >
                <Select
                  showSearch
                  style={{ width: '100%' }}
                  placeholder="Select the Status"
                  optionFilterProp="children"
                  filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                >
                  {
                    projectStatusList.length ? projectStatusList.map((projectStatus, index) => (
                      <Option key={index} value={projectStatus.id}>{projectStatus.statusTx}</Option>
                    )) : null
                  }
                </Select>
              </Form.Item>
            </div>
          </Col>
          <Col lg={8} md={8} sm={12} xs={24} >
            <div className="gx-form-row0">
              <h6 align="left">Billable</h6>
              <Form.Item
                name="billable"
                rules={[
                  {
                    required: true,
                    message: 'Enter Project Billable',
                  },
                ]}
              >
                <RadioGroup name="billable" onChange={(e) => onChangeBillable(e)} value={isBillable}>
                  <Radio value={0}>Non Billable</Radio>
                  <Radio value={1}>Billable</Radio>
                </RadioGroup>
              </Form.Item>
            </div>
          </Col>
          <Col lg={8} md={8} sm={12} xs={24} >
            <div className="gx-form-row0">
              <h6 align="left">Project Category</h6>
              <Form.Item
                name="projectcategory"
                rules={[
                  {
                    required: Boolean(isBillable),
                    message: 'Enter Project Category',
                  },
                ]}
              >
                <Select
                  showSearch
                  style={{ width: '100%' }}
                  placeholder="Select the Category"
                  optionFilterProp="children"
                  onChange={handleProjectCategoryChange}
                  filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                >
                  <Option value={"SAP"}>SAP</Option>
                  <Option value={"Digital"}>Digital</Option>
                  <Option value={"Internal"}>Internal</Option>
                </Select>
              </Form.Item>
            </div>
          </Col>
          <Col lg={8} md={8} sm={12} xs={24} >
            <div className="gx-form-row0">
              <h6 align="left">Project Type</h6>
              <Form.Item
                name="projectcategorytype"
                rules={[
                  {
                    required: Boolean(isBillable),
                    message: 'Enter the Project Type',
                  },
                ]}
              >
                <Select
                  showSearch
                  style={{ width: '100%' }}
                  placeholder="Select the Project Type"
                  optionFilterProp="children"
                  filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                >
                  {projecttype.length ? projecttype.map((projtyp, index) => (
                    <Option key={index} value={projtyp}>{projtyp}</Option>
                  )) : null}
                </Select>
              </Form.Item>
            </div>
          </Col>
        </Row>
        <Divider orientation="left"> Billing Details</Divider>
        <Row>
          <Col lg={8} md={8} sm={12} xs={24} >
            <div className="gx-form-row0">
              <h6 align="left">PO Number</h6>
              <Form.Item
                name="pono"
                rules={[
                  {
                    required: Boolean(isBillable),
                    message: 'Enter PO Number',
                  },
                ]}
              >
                <Input disabled={!Boolean(isBillable)} placeholder="PO Number" />
              </Form.Item>
            </div>
          </Col>
          <Col lg={8} md={8} sm={12} xs={24} >
            <div className="gx-form-row0">
              <h6 align="left">PO Date</h6>
              <Form.Item
                name="podate"
                rules={[
                  {
                    required: Boolean(isBillable),
                    message: 'Enter PO Date',
                  },
                ]}
              >
                <DatePicker disabled={!Boolean(isBillable)} style={{ width: '90%' }} format="DD-MM-YYYY"/>
              </Form.Item>
            </div>
          </Col>
          <Col lg={8} md={8} sm={12} xs={24} >
            <div className="gx-form-row0 mPOheight36">
              <h6 align="left">PO Value</h6>
              <Form.Item
                rules={[
                  {
                    required: Boolean(isBillable),
                    message: 'Enter PO Value',
                  },
                ]}
              >
                <InputGroup compact className="gx-mb-3">
                  <Form.Item
                    name={['budget', 'currency']}
                    noStyle
                    rules={[{ required: Boolean(isBillable), message: 'Currency is required' }]}
                  >
                    <Select
                      showSearch
                      disabled={!Boolean(isBillable)}
                      style={{ width: '40%' }}
                      placeholder="Select a Value"
                      optionFilterProp="children"
                      filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    >
                      {currency.length ? currency.map((curr, index) => (
                        <Option key={index} value={curr.id}>{curr.codeTx}</Option>
                      )) : null}
                    </Select>
                  </Form.Item>

                  <Form.Item
                    name={['budget', 'amount']}
                    noStyle
                    rules={[{ required: Boolean(isBillable), message: 'PO Amount is required' }]}>
                    <InputNumber disabled={!Boolean(isBillable)} style={{ width: '50%' }} />
                  </Form.Item>
                </InputGroup>
              </Form.Item>
            </div>
          </Col>
          <Col lg={8} md={8} sm={12} xs={24} >
            <div className="gx-form-row0">
              <h6 align="left">Billing Type</h6>
              <Form.Item
                name="billtype"
                rules={[
                  {
                    required: Boolean(isBillable),
                    message: 'Enter project billable',
                  },
                ]}
              >
                <Select
                  showSearch
                  disabled={!Boolean(isBillable)}
                  style={{ width: '100%' }}
                  placeholder="Select the Billing Type"
                  optionFilterProp="children"
                  filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                >
                  <Option value={1}>SOW</Option>
                  <Option value={2}>Monthly</Option>
                  <Option value={3}>Quaterly</Option>
                  <Option value={4}>Annually</Option>
                  <Option value={5}>Milestone</Option>
                  <Option value={6}>Internal</Option>
                </Select>
              </Form.Item>
            </div>
          </Col>
        </Row>
        <Divider orientation="left"> Project Stakeholders</Divider>
        <Row>
          <Col lg={8} md={8} sm={12} xs={24} >
            <div className="gx-form-row0">
              <h6 align="left">Project Manager</h6>
              <Form.Item
                name="prjmgr"
                rules={[
                  {
                    required: true,
                    message: 'Enter Project Manager',
                  },
                ]}
              >
                <Select
                  showSearch
                  style={{ width: '100%' }}
                  title="Select the Project Manager"
                  placeholder="Select the Project Manager"
                  optionFilterProp="children"
                  filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                >
                  {users.length ? users.map((user, index) => (
                    <Option key={index} value={user.id}>{user.displayNameTx}</Option>
                  )) : null}
                </Select>
              </Form.Item>
            </div>
          </Col>
          <Col lg={8} md={8} sm={12} xs={24} >
            <div className="gx-form-row0">
              <h6 align="left">Account Manager</h6>
              <Form.Item
                name="acctmgr"
                rules={[
                  {
                    required: true,
                    message: 'Enter Account Manager',
                  },
                ]}
              >
                <Select
                  showSearch
                  style={{ width: '100%' }}
                  title="Select the Account Manager"
                  placeholder="Select the Account Manager"
                  optionFilterProp="children"
                  filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                >
                  {users.length ? users.map((user, index) => (
                    <Option key={index} value={user.id}>{user.displayNameTx}</Option>
                  )) : null}
                </Select>
              </Form.Item>
            </div>
          </Col>
          <Col lg={8} md={8} sm={12} xs={24} >
            <div className="gx-form-row0">
              <h6 align="left">Project Initiator</h6>
              <Form.Item
                name="prjinit"
                rules={[
                  {
                    required: true,
                    message: 'Enter Project Initiator',
                  },
                ]}
              >
                <Select
                  showSearch
                  style={{ width: '100%' }}
                  title="Select the Project Initiator"
                  placeholder="Select the Project Initiator"
                  optionFilterProp="children"
                  filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                >
                  {users.length ? users.map((user, index) => (
                    <Option key={index} value={user.id}>{user.displayNameTx}</Option>
                  )) : null}
                </Select>
              </Form.Item>
            </div>
          </Col>
          <Col lg={8} md={8} sm={12} xs={24} >
            <div className="gx-form-row0">
              <h6 align="left">Project Coordinator</h6>
              <Form.Item
                name="pmo"
                rules={[
                  {
                    required: true,
                    message: 'Enter Project Coordinator',
                  },
                ]}
              >
                <Select
                  showSearch
                  style={{ width: '100%' }}
                  title="Select the Project Coordinator"
                  placeholder="Select the Project Coordinator"
                  optionFilterProp="children"
                  filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                >
                  {users.length ? users.map((user, index) => (
                    <Option key={index} value={user.id}>{user.displayNameTx}</Option>
                  )) : null}
                </Select>
              </Form.Item>
            </div>
          </Col>
        </Row>
        <Divider orientation="left"> Other Information</Divider>
        <Row>
          <Col lg={8} md={8} sm={12} xs={24} >
            <div className="gx-form-row0">
              <h6 align="left">Technology</h6>
              <Form.Item
                name="tech"
                rules={[
                  {
                    required: true,
                    message: 'Enter Technologies',
                  },
                ]}>
                <Select
                  showSearch
                  style={{ width: '100%' }}
                  title="Select the Technology"
                  placeholder="Select the Technology"
                  mode="multiple"
                  optionFilterProp="children"
                  filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                >
                  {
                    techList.length ? techList.map((tech, index) => (
                      <Option key={index} value={tech.id}>{tech.nameTx}</Option>
                    )) : null
                  }
                </Select>
              </Form.Item>
            </div>
          </Col>

          <Col lg={8} md={8} sm={12} xs={24} >
            <div className="gx-form-row0">
              <h6 align="left">Industry</h6>
              <Form.Item
                name="indus"
                rules={[
                  {
                    required: true,
                    message: 'Enter Industry',
                  },
                ]}
              >
                <Select
                  showSearch
                  style={{ width: '100%' }}
                  title="Select the Industry"
                  placeholder="Select the Industry"
                  optionFilterProp="children"
                  filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                >
                  {industries.map((industry, index) => (
                    <Option key={index} value={industry}>{industry}</Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
          </Col>
          <Col lg={8} md={8} sm={12} xs={24} >
            <div className="gx-form-row0">
              <h6 align="left">Sector</h6>
              <Form.Item
                name="sector"
                rules={[
                  {
                    required: true,
                    message: 'Enter sector',
                  },
                ]}
              >
                <Select
                  showSearch
                  style={{ width: '100%' }}
                  title="Select the Sector"
                  placeholder="Select the Sector"
                  optionFilterProp="children"
                  filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                >
                  {sectors.map((sector, index) => (
                    <Option key={index} value={sector}>{sector}</Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
          </Col>
        </Row>
        <Divider orientation="left">Note</Divider>
        <Row>
          <Col lg={24} md={24} sm={24} xs={24} >
            <div className="gx-form-row0">
              <Form.Item
                name="desc"
                rules={[
                  {
                    required: false,
                    message: 'Enter Description',
                  },
                ]}>
                <TextArea rows={8} />
              </Form.Item>
            </div>
          </Col>
        </Row>

        <Row gutter={24}>
          <Col lg={24} md={24} sm={24} xs={24} >
            <div className="gx-form-row0">
              <Form.Item
                name="submit">
                <Button type="primary" className="add-btn" htmlType="submit">Submit</Button>
                <Button type="primary" className="add-btn gx-mr-2" onClick={() => history.push('/master/projects/manage')}>Back</Button>
              </Form.Item>
            </div>
          </Col>
        </Row>
      </Form>
    </>
  );
}
export default NewProject;