import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import axios from 'axios';
import ProjStats from "../../master/projects/utils/ProjStats";
import { Col, Row } from "antd";
import { Avatar, Anchor, Radio, Select, Collapse, List, Tooltip, Descriptions } from "antd";
import Auxiliary from "util/Auxiliary";
import { API_URL } from 'constants/APIConfig';

//import { Col, Row, Progress, Radio, Button, Modal, Table, Popconfirm, Form, Select } from "antd";
const { Link } = Anchor;
const { Option } = Select;
const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;
const Panel = Collapse.Panel;


function handleChange(value) {
  console.log(`selected ${value}`);
}

function handleBlur() {
  console.log('blur');
}

function handleFocus() {
  console.log('focus');
}

const ProjectsReport = () => {

  let history = useHistory();
  const userDetails = JSON.parse(sessionStorage.getItem('user_details'));
  const token = sessionStorage.getItem('token');
  
  const [allProjectData, setallProjectData] = useState([]);
  const [projStats, setProjStats] = useState([]);
  const [typeFilter, setTypeFilter] = useState("External");
  const [statusFilter, setStatusFilter] = useState("InProgress");
  const [projectStatusList, setProjectStatusList] = useState([]);

  let i = 0;



  function onChangeType(e) {
    setTypeFilter(e.target.value);
    getAllprojects(statusFilter, e.target.value);
  }

  function onChangeStatus(value) {
    setStatusFilter(value);
    getAllprojects(value, typeFilter);
  }

  const getProjectStatusList = () => {
    axios.get(API_URL + '/api/projectstatus/all', 
    {headers: {
      'x-access-token': `${token}`, 
    'Access-Control-Allow-Origin': '*'}
   })
      .then(response => {
        console.log('Project Status', response.data.data)
        setProjectStatusList(response.data.data);
      })
      .catch(error => {
        // Error
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log('Error', error.message);
        }
        console.log(error.config);
      });
  }

  const getAllprojects = (statusFilter, typeFilter) => {
    axios.get(API_URL + `/api/project/v2/all?filter=${statusFilter}&typeFilter=${typeFilter}&userId=1&limit=500&offset=0`, 
    {headers: {
      'x-access-token': `${token}`, 
    'Access-Control-Allow-Origin': '*'}
   })
      .then(response => {
        console.log(response.data.data);
        setallProjectData(response.data.data);
      })
      .catch(error => {
        // Error
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log('Error', error.message);
        }
        console.log(error.config);
      });
  }

  useEffect(() => {
    getAllprojects(statusFilter, typeFilter);
    getProjectStatusList();
  }, [])

  return (
    <Auxiliary>
      <ProjStats />

      <Row gutter={24} className="gx-ml-0">
        <Col xl={24} lg={24} md={24} sm={24} xs={24} className="gx-pr-0 rowBlock">
          <div className="gx-form-row0 filterStatus">
            <Select
              showSearch
              style={{ width: '100%' }}
              placeholder="Filter by status"
              optionFilterProp="children"
              onChange={onChangeStatus}
              onFocus={handleFocus}
              onBlur={handleBlur}
              filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            >
              {
                projectStatusList.length ? projectStatusList.map((projectStatus, index) => (
                  <Option key={index} value={projectStatus.statusTx}>{projectStatus.statusTx}</Option>
                )) : null
              }
            </Select>
          </div>
          <div>
            <RadioGroup onChange={onChangeType} defaultValue="External" className="gx-float-right ">
              <RadioButton value="External">External</RadioButton>
              <RadioButton value="Internal">Internal</RadioButton>
              <RadioButton value="POC">POC</RadioButton>
            </RadioGroup>
          </div>
        </Col>
      </Row>

      {/*  //{ allProjectData.map((item, index) => ( */}
      <List
        itemLayout="vertical"
        pagination={{
          onChange: page => {
            console.log(page);
          },
          pageSize: 10,
        }}
        dataSource={allProjectData}
        renderItem={(item, i) => (
          <List.Item key={i + 1} className="ProjectListBlock">
            <Collapse bordered={false} expandIconPosition='right' className="mainAcc">
              <Panel header="" key={i + 1}
                extra={

                  <ul className="gx-list-inline gx-ml-auto gx-mb-0 gx-text-grey reportsPage">
                    <li className="projectBlock">
                      <Row gutter={24} className="alignItemCenter">
                        <Col xl={12} lg={12} md={12} sm={12} xs={12} className="subprojectDetails gx-text-left"><p>{item.name}</p>{item.shortName}</Col>
                        <Col xl={3} lg={3} md={3} sm={3} xs={3} className="subprojectDetails"><p>{item.startDate}</p>Start Date</Col>
                        <Col xl={3} lg={3} md={3} sm={3} xs={3} className="subprojectDetails"><p>{item.endDate}</p>End Date</Col>
                        <Col xl={3} lg={3} md={3} sm={3} xs={3} className="subprojectDetails"><p>{item.status && item.status.statusTx}</p>Status</Col>
                        <Col xl={3} lg={3} md={3} sm={3} xs={3} className="subprojectDetails"><p>{item.progress}</p>Progress</Col>
                      </Row>
                    </li>
                  </ul>
                }
              >
                <Descriptions size="small">
                  <Descriptions.Item label="Scope">{item.scope}</Descriptions.Item>
                </Descriptions>
                <hr></hr>
                <Descriptions size="small">
                  <Descriptions.Item label="Client">{item.client && item.client.organization}</Descriptions.Item>
                  <Descriptions.Item label="Estd Hours">{item.estimatedHours}</Descriptions.Item>
                  <Descriptions.Item label="Type">{item.type && item.type.typeTx}</Descriptions.Item>
                  <Descriptions.Item label="Priority">{item.project_priority && item.project_priority.priority}</Descriptions.Item>
                  <Descriptions.Item label="Billable">{item.name}</Descriptions.Item>
                </Descriptions>
                <hr></hr>
                <Descriptions size="small">
                  <Descriptions.Item label="Project Manager">{item.ProjectManager && item.ProjectManager.displayNameTx}</Descriptions.Item>
                  <Descriptions.Item label="Account Manager">{item.AccountManager && item.AccountManager.displayNameTx}</Descriptions.Item>
                  <Descriptions.Item label="Project Initiator">{item.Initiator && item.Initiator.displayNameTx}</Descriptions.Item>
                  <Descriptions.Item label="Project Coordinator">{item.ProjectCoordinator && item.ProjectCoordinator.displayNameTx}</Descriptions.Item>
                </Descriptions>
                <hr></hr>
                <Descriptions size="small">
                  <Descriptions.Item label="PO Number">{item.poNumber}</Descriptions.Item>
                  <Descriptions.Item label="PO Date">{item.poDate}</Descriptions.Item>
                  <Descriptions.Item label="PO Value">{item.poDate}</Descriptions.Item>
                  {/* <Descriptions.Item label="Billing Type">{item.ProjectCoordinator && item.ProjectCoordinator.displayNameTx}</Descriptions.Item> */}
                </Descriptions>
                <hr></hr>
                <Descriptions size="small">
                  <Descriptions.Item label="Technology">{item.technology && item.technology.nameTx}</Descriptions.Item>
                  <Descriptions.Item label="Industry">{item.industryType}</Descriptions.Item>
                  <Descriptions.Item label="Sector">{item.sector}</Descriptions.Item>
                  {/* <Descriptions.Item label="Billing Type">{item.ProjectCoordinator && item.ProjectCoordinator.displayNameTx}</Descriptions.Item> */}
                </Descriptions>
                <hr></hr>
                <Descriptions size="small" className="gx-list-inline gx-mb-3 gx-mb-lg-4 ">
                  <Descriptions.Item label="Resources">
                    {
                      item.users ? item.users.map((user, index) => (
                        <li>
                          {(() => {
                            try {
                              return <Tooltip placement="topLeft" title={user.displayNameTx}><Avatar src={require(`../../../assets/images/avatar/${user.employeeId.toLowerCase()}.png`)} /></Tooltip>
                            } catch (err) {
                              try {
                                return <Tooltip placement="topLeft" title={user.displayNameTx?user.displayNameTx:""}><Avatar size={{ xs: 24, sm: 32, md: 40, lg: 64, xl: 80, xxl: 100 }} src={require(`../../../assets/images/avatar/profile.png`)} /></Tooltip>
                              } catch (err) {
                                return <Tooltip placement="topLeft" title={user.displayNameTx}><span className="ant-avatar gx-size-40 gx-border gx-border-grey gx-bg-transparent gx-text-grey gx-d-flex gx-align-items-center gx-justify-content-center"><i
                                className="icon icon-add" /></span></Tooltip>
                              }
                            }
                          })()}
                        </li>)) : null
                    }
                  </Descriptions.Item>
                </Descriptions>
                <hr></hr>
                <Descriptions size="small">
                  <Descriptions.Item label="Note">{item.description}</Descriptions.Item>
                </Descriptions>
              </Panel>
            </Collapse>

          </List.Item>
        )}
      />
      {/* ))} */}

    </Auxiliary>
  );
};

export default ProjectsReport;
