import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import axios from 'axios';
import {Col, Row, List, Collapse, Descriptions, Select, Form} from "antd";
import Auxiliary from "util/Auxiliary";
import { API_URL } from 'constants/APIConfig';
import { FormOutlined, EyeOutlined } from '@ant-design/icons';

const Panel = Collapse.Panel;
const { Option } = Select;

const ProjectCostReport = (props) => {
  let history = useHistory();
  const userDetails = JSON.parse(sessionStorage.getItem('user_details'));
  const token = sessionStorage.getItem('token');

  const [projCost, setProjCost] = useState([]);
  const [projects, setProjects] = useState([]);

  const [form] = Form.useForm();


  let i = 0;
  const getProjectList = () => {
    axios.get(API_URL + `/api/project/v2/projectsByManager?userId=${userDetails.id}`, 
    {headers: {
      'x-access-token': `${token}`, 
    'Access-Control-Allow-Origin': '*'}
   })
      .then(response => {
        if (Object.keys(response.data.data).length > 0) {
          setProjects(response.data.data);
          form.setFieldsValue({
            prjname: response.data.data[0].id,
          })
          getProjCost(response.data.data[0].id);
        }
      })
      .catch(error => {
        // Error
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log('Error', error.message);
        }
        console.log(error.config);
      });
  }
  const onProjectChange = value => {
    getProjCost(value)
  };
  
  const getProjCost = (projId) => {
    let url = "";
    if (props.id !== undefined) {
      url = API_URL + `/api/project_cost/getProjectCost?projectId=${props.id}`;
    } else {
      url = API_URL + `/api/project_cost/getProjectCost?projectId=${projId}`;
    }
    axios.get(url)
    .then(response => {
        if (Object.keys(response.data.data).length > 0) {
          setProjCost(response.data.data);
        }
        console.log("Project Cost", response.data.data);
      })
      .catch(error => {
        // Error
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log('Error', error.message);
        }
        console.log(error.config);
      });
  }
  useEffect(() => {
    getProjCost();
    getProjectList();
  }, [])

  return (
    <Auxiliary> 
      <Form
        form={form}
        initialValues={{ remember: true }}
        name="ohealth"
       // onFinish={onFinish}
        layout="inline"
        align="center"
      >
      <Row gutter={24}>
       <Col lg={12} md={12} sm={24} xs={24}  >
          <div className="gx-form-row0" >
            <Form.Item
              name="prjname"
              // label="Project Name"
              rules={[
                {
                  required: true,
                  message: 'Enter project name',
                },
              ]}
            >
              <Select
                showSearch
                style={{ width: '60%' }}
                placeholder="Projects"
                optionFilterProp="children"
                onChange={onProjectChange}
                // size="small"
                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              >
                {
                  projects.length ? projects.map((project, index) => (
                    <Option key={index} value={project.id}>{project.name}</Option>
                  )) : null
                }
              </Select>
            </Form.Item>
          </div>
        </Col>
      </Row>
      </Form>
         <List
        itemLayout="vertical"
        pagination={{
          onChange: page => {
            console.log(page);
          },
          pageSize: 10,
        }}
        dataSource={projCost}
        renderItem={item => (
          <List.Item key={i + 1} className="ProjectListBlock">

            <Collapse bordered={false} expandIconPosition='right' className="mainAcc">
              <Panel header="" key={i + 1}
                extra={
                  <ul className="gx-list-inline gx-ml-auto gx-mb-0 gx-text-grey reportsPage">
                    <li className="projectText projectBlock">{item.spendType}</li>
                    <li className="statusBlock projectBlock">
                      <div>
                        <div><li> {item.plannedSpend}</li></div>
                        <div><li> {item.actualSpend}</li></div>
                        <div><li> {item.variance}</li></div>
                      </div>
                    </li>
                  </ul>
                }
              >
                <Descriptions size="small">
                  <Descriptions.Item label="Comments">{item.comments}</Descriptions.Item>
                </Descriptions>
              </Panel>
            </Collapse>

          </List.Item>
        )}
      />
    </Auxiliary>
  );
};

export default ProjectCostReport;
