import React from "react";
import axios from 'axios';
import { Col, Row, List, Progress, Tag } from "antd";
import Auxiliary from "util/Auxiliary";
import { API_URL } from 'constants/APIConfig';
import { EyeOutlined } from '@ant-design/icons';
import Widget from "components/Widget";

class ProjectsReport extends React.Component {
  state = {
    pid: 0,
    allProjectData: [],
    typeFilter: 'External',
    statusFilter: 'External',
    details: false,
    resources: false,
    plan: false,
    wsr: false,
    issues: false,
    cost: false,
    userDetails: JSON.parse(sessionStorage.getItem('user_details')),
    token: sessionStorage.getItem('token'),
  };

  getAllprojects = () => {
    axios.get(API_URL + `/api/project/v2/all?filter=${this.state.statusFilter}&typeFilter=${this.state.typeFilter}&userId=1&limit=500&offset=0`, 
    {headers: {
      'x-access-token': `${this.state.token}`, 
    'Access-Control-Allow-Origin': '*'}
   })
      .then(response => {
        if (Object.keys(response.data.data).length > 0) {
          console.log("Projects", response.data.data)
          let data = [];
          response.data.data.map((item, index) => {
            data.push({
              id: item.id,
              name: item.name,
              projectmgr: item.ProjectManager && item.ProjectManager.displayNameTx,
              progress: item.progress,
              status: item.status && item.status.statusTx,
            })
          })
          this.setState({ allProjectData: data });
        }
      })
      .catch(error => {
        // Error
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log('Error', error.message);
        }
        console.log(error.config);
      });
  }
  componentDidMount() {
    this.getAllprojects();
  }
  render() {
    return (
      <Auxiliary>
        <Widget className="h4 gx-mb-3" title="Projects">
          <List className="gx-bg-white"
            size="small"
            pagination={{
              onChange: page => {
                console.log(page);
              },
              pageSize: 8,
            }}
            dataSource={this.state.allProjectData}
            renderItem={project => <List.Item className="gx-pl-0 gx-pr-0">
              <Row gutter={24} className="ant-space-align-baseline orgBlock1">
                <Col lg={8} md={8} sm={8} xs={8} className="">{project.name?project.name:""}</Col>
                <Col lg={16} md={16} sm={16} xs={16} className="gx-pl-0 gx-pr-0">
                  <Row gutter={24} className="gx-ml-0 gx-mr-0 ant-space-align-baseline orgBlock1">
                    <Col lg={10} md={10} sm={10} xs={10} className="gx-fs-sm">{project.projectmgr?project.projectmgr:""}</Col>
                    <Col lg={6} md={6} sm={6} xs={6} className="gx-fs-sm">
                      {(() => {
                        if (project.status == "New")
                          return <Tag color="blue" >{project.status?project.status:""}</Tag>
                        if (project.status == "InProgress")
                          return <Tag color="orange">{project.status?project.status:""}</Tag>
                        if (project.status == "Hold")
                          return <Tag color="red">{project.status?project.status:""}</Tag>

                      })()}
                    </Col>
                    <Col lg={6} md={6} sm={6} xs={6} className="gx-fs-sm">
                      <Progress percent={project.progress ? project.progress : 0} steps={5} />
                    </Col>
                    <Col lg={2} md={2} sm={2} xs={2} className="">
                      <a href={`/reports/project/by-id/${project.id}`}><EyeOutlined style={{ padding: '10px' }} /></a>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </List.Item>}
          />
        </Widget>
      </Auxiliary>
    );
  }
}
export default ProjectsReport;
