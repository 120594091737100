import React, { useEffect, useState } from "react";
import { Input, Button, Cascader, Col, Row, DatePicker, message, TimePicker, Form, Select, Breadcrumb } from 'antd';
import { API_URL } from 'constants/APIConfig';
import moment from 'moment';
import 'moment/min/locales';
import axios from 'axios';
import Auxiliary from "util/Auxiliary";
import Widget from "components/Widget/index";

const { TextArea } = Input;
const { Option } = Select;

const TimeSheetStatusChange = (props) => {

const [form] = Form.useForm();

  const token = sessionStorage.getItem('token');

  const [projectTaskList, setProjectTaskList] = useState([]);
  const [employeeList, setEmployeeList] = useState([])
  const [inputList, setInputList] = useState([]);
  const [weekNumber, setWeekNumber] = useState(moment().week());
  const [yearNumber, setYearNumber] = useState(moment().year());
  const [comments, setComments] = useState("");
  const [disableFlag, setDisableFlag] = useState(true);
  const [hideTimesheet, setHideTimesheet] = useState(true);

  const strTimeToMins = (strTime) => {
    let [h, m] = strTime.split(":");
    return Number(h) * 60 + Number(m);
  }

  const minsToStrTime = (val) => {
    let m = val % 60;
    let h = (val - m) / 60;

    h = h < 10 ? "0" + h : h;
    m = m < 10 ? "0" + m : m;

    return `${h}:${m}`;
  }

  const onChangeWeek = (date, dateString) => {
    if (date != null && dateString != null) {
      let weekDt = dateString.split('-');
      if (weekDt[0] != 0 && weekDt[1].match(/\d+/)[0] != 0) {
        setWeekNumber(weekDt[1].match(/\d+/)[0]);
        setYearNumber(weekDt[0]);
        setInputList([]);
        setDisableFlag(true)
        setHideTimesheet(true)
      }
    }
  }

  const saveComments = (value) => {
    setComments(value)
  }

  const submitTimesheet = () => {

    axios.post(API_URL + '/api/timesheets/rejectTimesheetByPMO', {
      "year": yearNumber,
      "weekNumber": weekNumber,
      "userId": form.getFieldValue('employeeId'),
      "comments": comments,
    },
      {
        headers: {
          'x-access-token': `${token}`,
          'Access-Control-Allow-Origin': '*'
        }
      })
      .then(response => {
        message.success("Timesheet Rejected Successfully");
        setInputList([]);
        setDisableFlag(true)
      })
      .catch(error => {
        if (error.response) {
          message.error(error.response.data.error)
        } else {
          message.error("Error In Sumitted timesheet", error)
        }
      })
  }

  const onFinish = () => {
    getWeeklyTS(form.getFieldValue('employeeId'));
  }

  const getWeeklyTS = async (userId) => {
    const url = API_URL + `/api/timesheets/v2/getUserTimesheet?userId=${userId}&year=${yearNumber}&weekNumber=${weekNumber}`;
    try {
      const res = await axios.get(url,
        {
          headers: {
            'x-access-token': `${token}`,
            'Access-Control-Allow-Origin': '*'
          }
        });
      if (res.status === 200) {
        if (Object.keys(res.data.data).length > 0) {
          let strPMComments = ''
          let tsheets = [];
          let timesheetStatus = 0;
          let pData = []
          res.data.data.timesheet.map((tsheet, index) => {

            tsheet.timesheetComment = tsheet.timesheetComment || ''
            timesheetStatus = tsheet.timesheetStatus;
            let rmAction = tsheet.reportingManagerAction;
            
            tsheet.projects.map((projectItem, index) => {
              let tempObj = undefined
              let pData1 = {
                value: projectItem.project.id,
                label: projectItem.project.name,
                children: [],
              }
              pData.push(pData1)
              projectItem.tasks.map((taskItem, index) => {
                pData1.children.push({
                    value: taskItem.task.id,
                    label: taskItem.task.taskNameTx,
                })
                if(tempObj) {
                  tempObj.taskList.push({
                    tName: taskItem.task.taskNameTx,
                    status: taskItem.projectManagerAction
                  })
                }
                if (tsheet.reportingManager.id != projectItem.projectManager.id && taskItem.task && taskItem.task.taskNameTx) {
                  strPMComments = strPMComments + projectItem.project.name + '/' + taskItem.task.taskNameTx + " : \n\t" + (taskItem.projectManagerComment || '') + "\n"
                }

                tsheets = [...tsheets, {
                  "project": projectItem.project && projectItem.project.id,
                  "task": taskItem.task && taskItem.task.id,
                  "timesheetId": taskItem.timesheetId,
                  "taskname": taskItem.task && taskItem.task.taskNameTx,
                  "day1": taskItem.timelog[0].hours,
                  "tlog_day1": taskItem.timelog[0].timelogId,
                  "day2": taskItem.timelog[1].hours,
                  "tlog_day2": taskItem.timelog[1].timelogId,
                  "day3": taskItem.timelog[2].hours,
                  "tlog_day3": taskItem.timelog[2].timelogId,
                  "day4": taskItem.timelog[3].hours,
                  "tlog_day4": taskItem.timelog[3].timelogId,
                  "day5": taskItem.timelog[4].hours,
                  "tlog_day5": taskItem.timelog[4].timelogId,
                  "day6": taskItem.timelog[5].hours,
                  "tlog_day6": taskItem.timelog[5].timelogId,
                  "day7": taskItem.timelog[6].hours,
                  "tlog_day7": taskItem.timelog[6].timelogId,
                  "disabled": timesheetStatus == 0 ? false : taskItem.projectManagerAction !== 'Rejected',
                  "projectManagerAction": taskItem.projectManagerAction,
                  "taskstatus": (rmAction == 'Pending') ? "pm" + taskItem.projectManagerAction.toLowerCase() : rmAction.toLowerCase(),
                  "pmcomments": taskItem.projectManagerComment,
                }]
              })
            })
          })
          tsheets.sort((a, b) => {
            return a.timesheetId - b.timesheetId;
          });
          if(tsheets.length === 0) {
            message.error(`There is no draft/submited timesheet data to display.`);
          } else {
            setInputList(tsheets);
            setProjectTaskList(pData);
            setDisableFlag(false)
            setHideTimesheet(false)
          }
        }
      } 
    } catch (ex) {
      message.error(`${ex.message}:get timesheet`);
    }
  }

  const getAllActiveUsers = async () => {
    const url = API_URL + `/api/users/getAllActiveUsers`;
    try {
      const res = await axios.get(url,
        {
          headers: {
            'x-access-token': `${token}`,
            'Access-Control-Allow-Origin': '*'
          }
        });
      if (res.status === 200) {
        setEmployeeList(res.data.data)
      } 
    } catch (ex) {
      message.error(`${ex.message}:get timesheet`);
    }
  }

  useEffect(() => {
    getAllActiveUsers();
  }, [])

  return (
    <Auxiliary>
      <Breadcrumb>
        <Breadcrumb.Item>PMO</Breadcrumb.Item>
        <Breadcrumb.Item><i className="icon icon-check-circle-o" /> Reject Timesheet</Breadcrumb.Item>
      </Breadcrumb>
      <br/>
      <Widget>
      <Form
        form={form}
        initialValues={{ remember: true }}
        name="tsdetails"
        onFinish={onFinish}
        layout="inline"
        align="center"
      >
        <Row className="dateRangeBlock m_0">
          <Col xl={8} lg={8} md={8} sm={24} xs={24} className="timesheetHeading1 gx-pl-0">
          <Form.Item
                      name="employeeId"
                      rules={[
                        {
                          required: true,
                          message: "Select The Employee",
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        style={{ width: "100%" }}
                        placeholder="Select the Employee"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        onChange={e => setHideTimesheet(true)}
                      >
                        {employeeList.length
                          ? employeeList.map((emp, index) => (
                            <Option key={index} value={emp.id}>
                              {emp.employeeId + ' - ' +  emp.displayNameTx}
                            </Option>
                          ))
                          : null}
                      </Select>
        </Form.Item>
          </Col>
          
          <Col xl={4} lg={4} md={4} sm={24} xs={24} className="gx-text-center rejectTimesheetDatePicker">
            
              <DatePicker picker="week" onChange={onChangeWeek}  defaultValue={moment().weekYear(yearNumber).week(weekNumber)} bordered={true} />
            
          </Col>
          <Col xl={6} lg={6} md={6} sm={24} xs={24} className="gx-text-center rejectTimesheetDateText">
            
              {
                moment().weekYear(yearNumber).week(weekNumber).startOf('week').format('MMM D, YYYY') + " - " + moment().weekYear(yearNumber).week(weekNumber).endOf('week').format('MMM D, YYYY')
              }
            
          </Col>
          <Col xl={6} lg={6} md={6} sm={24} xs={24} className="gx-text-center"><h4>
            <Form.Item
                  name="submit"
                >
                  <Button type="primary" className="add-btn" htmlType="submit">Get Data</Button>
                </Form.Item>
          </h4></Col>
        </Row>
      </Form>
        
        <div className="timesheetBlock" hidden={hideTimesheet}>
          <Row className="timesheetHeader m_0">
            <Col xl={8} lg={8} md={4} sm={4} xs={4} className="timesheetHeading upperCaseStyle timeSheetMiddle borderRight">projects/Tasks</Col>
            <Col xl={2} lg={2} md={2} sm={2} xs={2} className="align_center weekdays_bg ">
              <p className="weekStyle">S</p>
              <p className="monthDateStyle">
                {
                  moment().weekYear(yearNumber).week(weekNumber).day(0).format('MMM D')}
              </p>
            </Col>
            <Col xl={2} lg={2} md={2} sm={2} xs={2} className="align_center">
              <p className="weekStyle">M</p>
              <p className="monthDateStyle">
                {
                  moment().weekYear(yearNumber).week(weekNumber).day(1).format('MMM D')}
              </p>
            </Col>
            <Col xl={2} lg={2} md={2} sm={2} xs={2} className="align_center">
              <p className="weekStyle">T</p>
              <p className="monthDateStyle">
                {
                  moment().weekYear(yearNumber).week(weekNumber).day(2).format('MMM D')}

              </p>
            </Col>
            <Col xl={2} lg={2} md={2} sm={2} xs={2} className="align_center">
              <p className="weekStyle">W</p>
              <p className="monthDateStyle">
                {
                  moment().weekYear(yearNumber).week(weekNumber).day(3).format('MMM D')}
              </p>
            </Col>
            <Col xl={2} lg={2} md={2} sm={2} xs={2} className="align_center">
              <p className="weekStyle">T</p>
              <p className="monthDateStyle">
                {
                  moment().weekYear(yearNumber).week(weekNumber).day(4).format('MMM D')}
              </p>
            </Col>
            <Col xl={2} lg={2} md={2} sm={2} xs={2} className="align_center">
              <p className="weekStyle">F</p>
              <p className="monthDateStyle">
                {
                  moment().weekYear(yearNumber).week(weekNumber).day(5).format('MMM D')}
              </p>
            </Col>
            <Col xl={2} lg={2} md={2} sm={2} xs={2} className="align_center weekdays_bg ">
              <p className="weekStyle">S</p>
              <p className="monthDateStyle">
                {
                  moment().weekYear(yearNumber).week(weekNumber).day(6).format('MMM D')}
              </p>
            </Col>
            <Col xl={2} lg={2} md={2} sm={2} xs={2} className="total_data gx-text-center timeSheetMiddle">
              <span className="weekStyle">Total</span>
            </Col>
          </Row>
        </div>
        <div className="App timesheetBody" hidden={hideTimesheet}>
          {inputList.map((x, i) => {
            return (
              <div className="box" key={i}>
                <Row className="m_0 spacing_bottom timesheetBodyBlock border_bottom">
                  <Col xl={8} lg={8} md={4} sm={4} xs={4} className="borderRight">

                  <Cascader
                      options={projectTaskList}
                      value={[x.project, x.task]}
                      disabled={true}
                    />
                  </Col>
                  <Col xl={2} lg={2} md={2} sm={2} xs={2} className="timesheettableCells weekdays_bg timepicker">
                    <TimePicker className={`${x.taskstatus}`}
                      format="HH:mm"
                      disabled={x.disabled}
                      name="day1"
                      suffixIcon={null}
                      showNow={false}
                      inputReadOnly={true}
                      placeholder="00:00"
                      minuteStep={15}
                      value={moment(x.day1, 'HH:mm')}
                      disabledHours={() => [13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24]}
                    />
                  </Col>
                  <Col xl={2} lg={2} md={2} sm={2} xs={2} className="timesheettableCells timepicker">
                    <TimePicker
                      format="HH:mm"
                      disabled={x.disabled}
                      name="day2"
                      suffixIcon={null}
                      showNow={false}
                      inputReadOnly={true}
                      placeholder="00:00"
                      minuteStep={15}
                      value={moment(x.day2, 'HH:mm')}
                      disabledHours={() => [13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24]}
                    />
                  </Col>
                  <Col xl={2} lg={2} md={2} sm={2} xs={2} className="timesheettableCells timepicker">
                    <TimePicker
                      format="HH:mm"
                      disabled={x.disabled}
                      name="day3"
                      suffixIcon={null}
                      showNow={false}
                      inputReadOnly={true}
                      placeholder="00:00"
                      minuteStep={15}
                      value={moment(x.day3, 'HH:mm')}
                      disabledHours={() => [13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24]}
                    />
                  </Col>
                  <Col xl={2} lg={2} md={2} sm={2} xs={2} className="timesheettableCells timepicker">
                    <TimePicker
                      format="HH:mm"
                      disabled={x.disabled}
                      name="day4"
                      suffixIcon={null}
                      showNow={false}
                      inputReadOnly={true}
                      placeholder="00:00"
                      minuteStep={15}
                      value={moment(x.day4, 'HH:mm')}
                      disabledHours={() => [13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24]}
                    />
                  </Col>
                  <Col xl={2} lg={2} md={2} sm={2} xs={2} className="timesheettableCells timepicker">
                    <TimePicker
                      format="HH:mm"
                      disabled={x.disabled}
                      name="day5"
                      suffixIcon={null}
                      showNow={false}
                      inputReadOnly={true}
                      placeholder="00:00"
                      minuteStep={15}
                      value={moment(x.day5, 'HH:mm')}
                      disabledHours={() => [13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24]}
                    />
                  </Col>
                  <Col xl={2} lg={2} md={2} sm={2} xs={2} className="timesheettableCells timepicker">
                    <TimePicker
                      format="HH:mm"
                      disabled={x.disabled}
                      name="day6"
                      suffixIcon={null}
                      showNow={false}
                      inputReadOnly={true}
                      placeholder="00:00"
                      minuteStep={15}
                      value={moment(x.day6, 'HH:mm')}
                      disabledHours={() => [13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24]}
                    />
                  </Col>
                  <Col xl={2} lg={2} md={2} sm={2} xs={2} className="timesheettableCells weekdays_bg ">
                    <TimePicker
                      format="HH:mm"
                      disabled={x.disabled}
                      name="day7"
                      suffixIcon={null}
                      showNow={false}
                      inputReadOnly={true}
                      placeholder="00:00"
                      minuteStep={15}
                      value={moment(x.day7, 'HH:mm')}
                      disabledHours={() => [13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24]}
                    />
                  </Col>
                  <Col xl={2} lg={2} md={2} sm={2} xs={2} className="total_value timeSheetMiddle">
                    <span>{
                      minsToStrTime(strTimeToMins(x.day1) + strTimeToMins(x.day2) + strTimeToMins(x.day3) + strTimeToMins(x.day4) + strTimeToMins(x.day5) + strTimeToMins(x.day6) + strTimeToMins(x.day7))
                    }</span>
                  </Col>
                </Row>
              </div>
            );
          })}

        </div>
        <div className="timesheetBlock" hidden={hideTimesheet}>
          <Row className="timesheetHeader m_0 timeSheetMiddle">
            <Col xl={8} lg={8} md={8} sm={8} xs={8} className="timesheetHeading upperCaseStyle timeSheetMiddle borderRight font600">Total Hours</Col>
            <Col xl={2} lg={2} md={2} sm={2} xs={2} className="align_center totalcount">
              <p>
                {(() => {
                  let total = 0;
                  inputList.map((x, i) => {
                    total = total + strTimeToMins(x.day1)
                  })
                  return minsToStrTime(total);
                })()} </p>
            </Col>
            <Col xl={2} lg={2} md={2} sm={2} xs={2} className="align_center totalcount">
              <p>
                {(() => {
                  let total = 0;
                  inputList.map((x, i) => {
                    total = total + strTimeToMins(x.day2)
                  })
                  return minsToStrTime(total);
                })()} </p>
            </Col>
            <Col xl={2} lg={2} md={2} sm={2} xs={2} className="align_center totalcount">
              <p>
                {(() => {
                  let total = 0;
                  inputList.map((x, i) => {
                    total = total + strTimeToMins(x.day3)
                  })
                  return minsToStrTime(total);
                })()} </p>
            </Col>
            <Col xl={2} lg={2} md={2} sm={2} xs={2} className="align_center totalcount">
              <p>
                {(() => {
                  let total = 0;
                  inputList.map((x, i) => {
                    total = total + strTimeToMins(x.day4)
                  })
                  return minsToStrTime(total);
                })()} </p>
            </Col>
            <Col xl={2} lg={2} md={2} sm={2} xs={2} className="align_center totalcount">
              <p>
                {(() => {
                  let total = 0;
                  inputList.map((x, i) => {
                    total = total + strTimeToMins(x.day5)
                  })
                  return minsToStrTime(total);
                })()} </p>
            </Col>
            <Col xl={2} lg={2} md={2} sm={2} xs={2} className="align_center totalcount">
              <p>
                {(() => {
                  let total = 0;
                  inputList.map((x, i) => {
                    total = total + strTimeToMins(x.day6)
                  })
                  return minsToStrTime(total);
                })()} </p>
            </Col>
            <Col xl={2} lg={2} md={2} sm={2} xs={2} className="align_center totalcount">
              <p>
                {(() => {
                  let total = 0;
                  inputList.map((x, i) => {
                    total = total + strTimeToMins(x.day7)
                  })
                  return minsToStrTime(total);
                })()} </p>
            </Col>
            <Col xl={2} lg={2} md={2} sm={2} xs={2} className="totalcountMain gx-text-center">
              <p>
                {(() => {
                  let total = 0;
                  inputList.map((x, i) => {
                    total = total + (strTimeToMins(x.day1) + strTimeToMins(x.day2) + strTimeToMins(x.day3) + strTimeToMins(x.day4) + strTimeToMins(x.day5) + strTimeToMins(x.day6) + strTimeToMins(x.day7))
                  })
                  return minsToStrTime(total);
                })()} </p>
            </Col>
          </Row>
        </div>
        <Row className="m_0" hidden={hideTimesheet}>
          <Col xl={24} lg={24} md={24} sm={24} xs={24} className=" gx-pl-0 gx-pr-0">
            <>
              <center><p><b>Comments</b></p></center>
              <TextArea rows={4} placeholder="Please enter comments" onBlur={(event) => saveComments(event.target.value)} />
            </>
          </Col>
        </Row>

        <Row className="m_0 " hidden={hideTimesheet}>
          <Col xl={8} lg={8} md={8} sm={8} xs={8} className="gx-p-0">
          </Col>
          <Col xl={16} lg={16} md={16} sm={16} xs={16} className="gx-p-0 buttonGroup">
          <Button type="primary" className="submit_btn scale-x" disabled={disableFlag} htmlType="save" onClick={submitTimesheet}>Reject </Button>
          </Col>
        </Row>
      </Widget>
    </Auxiliary>
  )
}
export default TimeSheetStatusChange;