import React, { useEffect, useState } from "react";
import axios from 'axios';
import { TreeSelect, Row, Col, Form, Input, Select, Radio, message, DatePicker, Button, InputNumber, Breadcrumb } from "antd";
import Auxiliary from "util/Auxiliary";
import { useHistory } from "react-router-dom";
import { API_URL } from 'constants/APIConfig';
import moment from "moment";

const { Option } = Select;
const RadioGroup = Radio.Group;
const { RangePicker } = DatePicker;
const { TextArea } = Input;

const fprops = {
  name: 'file',
  action: '//jsonplaceholder.typicode.com/posts/',
  headers: {
    authorization: 'authorization-text',
  },
  onChange(info) {
    if (info.file.status !== 'uploading') {
      console.log(info.file, info.fileList);
    }
    if (info.file.status === 'done') {
      message.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === 'error') {
      message.error(`${info.file.name} file upload failed.`);
    }
  },
};

const EditTasks = (props) => {
  const [form] = Form.useForm();
  const history = useHistory();
  const userDetails = JSON.parse(sessionStorage.getItem('user_details'));
  const token = sessionStorage.getItem('token');
  const [treeData, setTreeData] = useState([]);
  const [users, setUsers] = useState([])
  const [projects, setProjects] = useState([]);
  const [selectedTask, setselectedTask] = useState();
  const [isBillable, setIsBillable] = useState(0);

  function onChangeBillable(e) {
    setIsBillable(e.target.value);
  }

  const onProjectChange = value => {
    getTaskLists(value);
    getUserList(value);
  };

  const onFinish = values => {
    const dates = form.getFieldValue('taskduration');
    let taskData = {
      "assignTo": form.getFieldValue('assignTo'),
      "taskNameTx": form.getFieldValue('taskname'),
      "uniqId": 1,
      "descriptionTx": form.getFieldValue('descriptionTx'),
      "projectIdNm": form.getFieldValue('projects'),
      "createdByNm": 1,
      "priorityNm": form.getFieldValue('prjpriority'),
      "startDateDt": dates[0] ? dates[0].format('YYYY-MM-DD') : null,
      "estimatedHoursNm": form.getFieldValue('estimatedHoursNm'),
      "dueDateDt": dates[1] ? dates[1].format('YYYY-MM-DD') : null,
      "taskTypeNm": 1,
      "parentTaskNm": form.getFieldValue('parenttask'),
      "billabilityNm": form.getFieldValue('billable'),
      "projectedDateDt": form.getFieldValue('projecteddt') ? form.getFieldValue('projecteddt').format('YYYY-MM-DD') : null,
      "revisedEffortsNm": form.getFieldValue('refforts'),
      "taskStatusNm": form.getFieldValue('status'),
      "spentHounrsNm": form.getFieldValue('spenthours'),
      "completion": form.getFieldValue('completion'),
      "comments": form.getFieldValue('comments'),
    }
    axios.put(API_URL + '/api/task/update/' + props.match.params.id, taskData,
      {
        headers: {
          'x-access-token': `${token}`,
          'Access-Control-Allow-Origin': '*'
        }
      })
      .then(response => {
        message.success("Task updated successfully");
        let taskState = JSON.parse(sessionStorage.getItem('taskState') || '{}')
        taskState.projectId = form.getFieldValue('projects')
        sessionStorage.setItem('taskState', JSON.stringify(taskState))
        history.push("/master/tasks/manage");
      }).catch(error => {
        console.log(error);
        message.error("Error in updating Task", error)
      });
  };

  const getTaskByID = () => {
    axios.get(API_URL + '/api/task/getById/' + props.match.params.id,
      {
        headers: {
          'x-access-token': `${token}`,
          'Access-Control-Allow-Origin': '*'
        }
      })
      .then(response => {
        if (Object.keys(response.data.data).length > 0) {

          let assignUsers = [];
          response.data.data.assignedUser.map(user => {
            assignUsers.push(user.id)
            return ''
          });

          getTaskLists(response.data.data.projectIdNm);
          getUserList(response.data.data.projectIdNm);

          form.setFieldsValue({
            projects: response.data.data.projectIdNm ? response.data.data.projectIdNm : null,
            taskname: response.data.data.taskNameTx ? response.data.data.taskNameTx : null,
            assignTo: assignUsers,
            descriptionTx: response.data.data.descriptionTx ? response.data.data.descriptionTx : null,
            estimatedHoursNm: response.data.data.estimatedHoursNm ? response.data.data.estimatedHoursNm : null,
            taskduration: [response.data.data.startDateDt ? moment(response.data.data.startDateDt).startOf('day') : null, response.data.data.dueDateDt ? moment(response.data.data.dueDateDt).startOf('day') : null],
            parenttask: response.data.data.parentTaskNm ? response.data.data.parentTaskNm : null,
            prjpriority: response.data.data.priorityNm ? response.data.data.priorityNm : null,
            billable: response.data.data.billabilityNm,
            projecteddt: response.data.data.projectedDateDt ? moment(response.data.data.projectedDateDt).startOf('day') : null,
            refforts: response.data.data.revisedEffortsNm,
            completion: response.data.data.completion,
            status: response.data.data.taskStatusNm,
            spenthours: response.data.data.spentHoursNm,
            comments: response.data.data.comments,
          });
        }
      }).catch(error => {
        console.log(error);
      });
  }

  const getProjectList = () => {
    axios.get(API_URL + `/api/project/v2/allProjectNames?userId=${userDetails.id}`,
      {
        headers: {
          'x-access-token': `${token}`,
          'Access-Control-Allow-Origin': '*'
        }
      })
      .then(response => {
        setProjects(response.data.data);
      })
      .catch(error => {
        console.log(error);
      });
  }

  const getTaskLists = (projid) => {
    axios.get(API_URL + `/api/task/getAssignedTasksByPId?userId=1&projectId=${projid}`,
      {
        headers: {
          'x-access-token': `${token}`,
          'Access-Control-Allow-Origin': '*'
        }
      })
      .then(response => {
        if (response.data.data.length) {
          setTreeData(response.data.data);

        }
      }).catch(error => {
        console.dir(error);
      });
  }

  const getUserList = (projid) => {
    axios.get(API_URL + `/api/project_resources/getProjectResources?userId=1&projectId=${projid}`,
      {
        headers: {
          'x-access-token': `${token}`,
          'Access-Control-Allow-Origin': '*'
        }
      })
      .then(response => {
        let data = [];
        if (Object.keys(response.data.data).length > 0) {
          let userSet = new Set()
          response.data.data.map((item, index) => {
            if(!userSet.has(item.assignedUser.id)) {
              data.push({
                id: item.assignedUser.id,
                name: item.assignedUser.displayNameTx,
              })
              userSet.add(item.assignedUser.id)
            }
          })
        }
        setUsers(data);
      })
      .catch(error => {
        console.log(error);
      });
  }

  useEffect(() => {
    getProjectList()
    getTaskByID()
  }, [])
  return (
    <Auxiliary>

      <Breadcrumb>
        <Breadcrumb.Item>PMO</Breadcrumb.Item>
        <Breadcrumb.Item><i className="icon icon-tasks" /> Tasks &rarr; Edit Task</Breadcrumb.Item>
      </Breadcrumb>
      <br />

      <Form
        form={form}
        initialValues={{ remember: true }}
        name="newTask"
        onFinish={onFinish}
        layout="inline"
        align="center"
      >
        <Row gutter={24}>
          <Col lg={8} md={8} sm={12} xs={24} >
            <div className="gx-form-row0">
              <h6 align="left">Projects</h6>
              <Form.Item
                name="projects"
                rules={[
                  {
                    required: true,
                    message: 'Enter project Status',
                  },
                ]}
              >
                <Select
                  showSearch
                  style={{ width: '100%' }}
                  placeholder="Select The Project"
                  optionFilterProp="children"
                  onChange={onProjectChange}
                  disabled={true}
                  filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                >
                  {
                    projects.length ? projects.map((project, index) => (
                      <Option key={index} value={project.id}>{project.name}</Option>
                    )) : null
                  }
                </Select>
              </Form.Item>
            </div>
          </Col>

          <Col lg={8} md={8} sm={12} xs={24} >
            <div className="gx-form-row0">
              <h6 align="left">Parent Task</h6>
              <Form.Item
                name="parenttask"
                rules={[
                  {
                    required: false,
                    message: 'Select Parent Task',
                  },
                ]}
              >
                <TreeSelect className="gx-w-100"
                  value={selectedTask}
                  dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                  treeData={treeData}
                  placeholder="Select The Parent Task"
                  treeDefaultExpandAll={false}
                />
              </Form.Item>
            </div>
          </Col>
          <Col lg={8} md={8} sm={12} xs={24} >
            <div className="gx-form-row0">
              <h6 align="left">Task Type</h6>
              <Form.Item
                name="billable"
                rules={[
                  {
                    required: true,
                    message: 'Enter project billable',
                  },
                ]}
              >
                <RadioGroup name="billable" onChange={(e) => onChangeBillable(e)} value={isBillable}>
                  <Radio value={0}>In Scope</Radio>
                  <Radio value={1}>Change Request</Radio>
                </RadioGroup>
              </Form.Item>
            </div>
          </Col>

          <Col lg={8} md={8} sm={12} xs={24} >
            <div className="gx-form-row0">
              <h6 align="left">Task Name</h6>
              <Form.Item
                name="taskname"
                rules={[
                  {
                    required: true,
                    message: 'Enter task name',
                  },
                ]}
              >
                <Input disabled={false} placeholder="Task Name" />
              </Form.Item>
            </div>
          </Col>

          <Col lg={8} md={8} sm={12} xs={24} >
            <div className="gx-form-row0">
              <h6 align="left">Task Duration</h6>
              <Form.Item
                name="taskduration"
                rules={[
                  {
                    required: true,
                    message: 'Enter Task Duration',
                  },
                ]}
              >
                <RangePicker className="gx-mb-3 gx-w-100" format="DD-MM-YYYY"/>
              </Form.Item>
            </div>
          </Col>

          <Col lg={8} md={8} sm={12} xs={24} >
            <div className="gx-form-row0">
              <h6 align="left">Task Priority</h6>
              <Form.Item
                name="prjpriority"
                rules={[
                  {
                    required: true,
                    message: 'Enter project priority',
                  },
                ]}
              >
                <RadioGroup name="priority" >
                  <Radio value={1}>High</Radio>
                  <Radio value={2}>Medium</Radio>
                  <Radio value={3}>Low</Radio>
                </RadioGroup>
              </Form.Item>
            </div>
          </Col>

          <Col lg={8} md={8} sm={12} xs={24} >
            <div className="gx-form-row0">
              <h6 align="left">Estd Hours</h6>
              <Form.Item
                name="estimatedHoursNm"
                rules={[
                  {
                    required: true,
                    message: 'Enter estimated hours',
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                        if (!value || getFieldValue('estimatedHoursNm') < 1) {
                            return Promise.reject("Please enter the Enter estimated hours");
                        }
                        return Promise.resolve();
                    },
                })
                ]}
              >
                <InputNumber placeholder="Estimated Hours" min={1} />
              </Form.Item>
            </div>
          </Col>

          <Col lg={8} md={8} sm={12} xs={24} >
            <div className="gx-form-row0">
              <h6 align="left">Assigned Users</h6>
              <Form.Item
                name="assignTo"
                rules={[
                  {
                    required: true,
                    message: 'Assign Users',
                  },
                ]}
              >
                <Select
                  showSearch
                  optionLabelProp="children"
                  mode="multiple"
                  style={{ width: '100%' }}
                  placeholder="Select The Users"
                  filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                >
                  {users.length ? users.map((user, index) => (
                    <Option key={index} value={user.id}>{user.name}</Option>
                  )) : null}

                </Select>
              </Form.Item>
            </div>
          </Col>
          <Col lg={8} md={8} sm={12} xs={24} >
            <h6 align="left">Status</h6>
            <div className="gx-form-row0">
              <Form.Item
                name="status"
                rules={[
                  {
                    required: true,
                    message: 'Status',
                  },
                ]}
              >
                <Select
                  showSearch
                  style={{ width: '100%' }}
                  placeholder="Select The Status"
                  optionFilterProp="children"
                  filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                >
                  <Option value={1}>New</Option>
                  <Option value={2}>InProgress</Option>
                  <Option value={3}>Waiting For Inputs</Option>
                  <Option value={4}>Resolved</Option>
                  <Option value={5}>Closed</Option>
                </Select>
              </Form.Item>
            </div>
          </Col>
          <Col lg={8} md={8} sm={12} xs={24} >
            <div className="gx-form-row0">
              <h6 align="left">Spent Hours</h6>
              <Form.Item
                name="spenthours"
                rules={[
                  {
                    required: false,
                  },
                ]}
              >
                <Input disabled={true} placeholder="" />
              </Form.Item>
            </div>
          </Col>
          <Col lg={24} md={24} sm={24} xs={24} >
            <div className="gx-form-row0">
              <h6 align="left">Task Description</h6>
              <Form.Item
                name="descriptionTx"
                rules={[
                  {
                    required: true,
                    message: 'Enter Description',
                  },
                ]}
              >
                <TextArea rows={4} placeholder="Please enter the Description" />
              </Form.Item>
            </div>
          </Col>
        </Row>

        <Row gutter={24}>

          <Col lg={8} md={8} sm={12} xs={24} >
            <div className="gx-form-row0">
              <h6 align="left">Completion %</h6>
              <Form.Item
                name="completion"
                rules={[
                  {
                    required: true,
                    message: 'Enter completion percentage',
                  },
                ]}
              >
                <InputNumber disabled={true} placeholder="Completion" />
              </Form.Item>
            </div>
          </Col>
          <Col lg={8} md={8} sm={12} xs={24} >
            <div className="gx-form-row0">
              <h6 align="left">Revised Efforts</h6>
              <Form.Item
                name="refforts"
                rules={[
                  {
                    required: true,
                    message: 'Enter efforts',
                  },
                ]}
              >
                <InputNumber placeholder="efforts" />
              </Form.Item>
            </div>
          </Col>
          <Col lg={8} md={8} sm={12} xs={24} >
            <div className="gx-form-row0">
              <h6 align="left">Projected Date</h6>
              <Form.Item
                name="projecteddt"
                rules={[
                  {
                    required: true,
                    message: 'Enter projected date',
                  },
                ]}
              >
                <DatePicker />
              </Form.Item>
            </div>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col lg={24} md={24} sm={24} xs={24} >
            <div className="gx-form-row0">
              <h6 align="left">Comments</h6>
              <Form.Item
                name="comments"
                rules={[
                  {
                    required: true,
                    message: 'Enter Description',
                  },
                ]}
              >
                <TextArea rows={4} placeholder="Please enter the Comments" />
              </Form.Item>
            </div>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col lg={24} md={24} sm={24} xs={24} >
            <div className="gx-form-row0">
              <Form.Item>
                <Button type="primary" className="add-btn scale-x" htmlType="submit">Submit</Button>
                <Button type="primary" className="add-btn gx-mr-2 scale-x" onClick={() => {
                  let taskState = JSON.parse(sessionStorage.getItem('taskState') || '{}')
                  taskState.projectId = form.getFieldValue('projects')
                  sessionStorage.setItem('taskState', JSON.stringify(taskState))
                  history.push('/master/tasks/manage')
                  }}>Back</Button>
              </Form.Item>
            </div>
          </Col>
        </Row>
      </Form>
    </Auxiliary>
  );
};
export default EditTasks;