import React, { useEffect, useState } from "react";
import {Row, Col, Modal, Button, List } from "antd";
import ProjectWidget from "./ProjectWidget"
import axios from 'axios';
import { API_URL } from 'constants/APIConfig';
import NewProjects from "../../master/projects/new/index";
import ProjStats from "../../master/projects/utils/ProjStats";
import Auxiliary from "util/Auxiliary";
import {PlusCircleOutlined } from '@ant-design/icons';

const PMODashboard = () => {
  const userDetails = JSON.parse(sessionStorage.getItem('user_details'));
  const token = sessionStorage.getItem('token');
  const [ProjectList, setProjectList] = useState([]);
  const [projectPop, setProjectPop] = useState(false);

  const getProjects = () => {    
    axios.get(API_URL + `/api/project/v2/projectsByCoordinator?userId=${userDetails.id}`, 
    {headers: {
      'x-access-token': `${token}`, 
    'Access-Control-Allow-Origin': '*'}
   })
      .then(response => {
        setProjectList(response.data.data);
      })
      .catch(error => {
        console.log(error)
      });
  }
  useEffect(() => {
    getProjects();
  }, [])
  return (
    <Auxiliary>
      <Row gutter={24}>
        <Col lg={24} md={24} sm={24} xs={24} >
          <ProjStats />
        </Col>
      </Row>
      <Row gutter={24}>
        <Col lg={24} md={24} sm={24} xs={24} >
          <Button  type="dashed" icon={<PlusCircleOutlined />} onClick={() => setProjectPop(true)}>Onboard New Project</Button>
        </Col>
      </Row>
      <Row gutter={24}>

        <Col lg={24} md={24} sm={24} xs={24} >
          <List
            grid={{
              gutter: 16,
              column: 1
            }}
            pagination={{
              onChange: page => {
                console.log(page);
              },
              pageSize: 12,
            }}
            dataSource={ProjectList}
            renderItem={project => (
              <List.Item>
                <ProjectWidget Project={project.id} />
              </List.Item>
            )}
          />

        </Col>
      </Row>

      <Modal
        title="Onboard New Project"
        centered
        visible={projectPop}
        onOk={() => setProjectPop(false)}
        onCancel={() => setProjectPop(false)}
        width={1000}
        footer={null}
      >
        <NewProjects />
      </Modal>

    </Auxiliary>

  );
};
export default PMODashboard;
