import React, { useEffect, useState } from "react";
import { DatePicker, Tooltip, Avatar } from "antd";
import axios from 'axios';
import { API_URL } from 'constants/APIConfig';
import moment from 'moment';
import 'moment/min/locales';
import Auxiliary from "util/Auxiliary";
import { Table } from "ant-table-extensions";

const TimeLogs = (props) => {

  const userDetails = JSON.parse(sessionStorage.getItem('user_details'));
  const token = sessionStorage.getItem('token');

  const [weekNumber, setWeekNumber] = useState(moment().week());
  const [yearNumber, setYearNumber] = useState(moment().weekYear());
  const [loading, setLoading] = useState(false);
  const onChangeWeek = (date, dateString) => {
    if (date != null && dateString != null) {
      let weekDt = dateString.split('-');
      if (weekDt[0] != 0 && weekDt[1].match(/\d+/)[0] != 0) {
        setWeekNumber(weekDt[1].match(/\d+/)[0]);
        setYearNumber(weekDt[0]);
        getTSList(weekDt[0], weekDt[1].match(/\d+/)[0]);
      }
    }
  }

  const [timeSheetList, setTimeSheetList] = useState([]);

  const timeSheetListCols = [
    {
      title: 'Employee Name', dataIndex: 'empname', width: 100, align: 'left',
      render: (text, record) => {
        return (
          <div className="gx-d-flex">
            {(() => {
              try {
                return <Tooltip placement="topLeft" title=""><Avatar size={{ xs: 24, sm: 32, md: 40, lg: 64, xl: 80, xxl: 100 }} src={require(`../../../../../../src/assets/images/avatar/${record.empid.toLowerCase()}.png`)} /></Tooltip>
              } catch (err) {
                return <Tooltip placement="topLeft" title=""><span className="ant-avatar gx-size-40 gx-border gx-border-grey gx-bg-transparent gx-text-grey gx-align-items-center gx-justify-content-center"><i
                  className="icon icon-add" /></span></Tooltip>
              }
            })()}
            <div className="gx-ml-1">
              <div>{record.empname}</div>
              <small> {record.empid} </small>
            </div>
          </div>
        );
      },
    },
    {
      title: 'Reporting Manager', dataIndex: 'rmanager', width: 100, ellipsis: true, align: 'center',
      render: (text, record) => {
        return (
          <div>
            <span> {text} </span>
          </div>
        );
      },
    },
    {
      title: 'Reporting Manager Status', dataIndex: 'status', width: 100, align: 'center',
      render: (text, record) => {
        return (
          <div>
            <span> {text} </span>
          </div>
        );
      },
    },
    {
      title: 'Logged Hours', dataIndex: 'loggedHours', width: 100, align: 'center',
      render: (text, record) => {
        return (
          <div>
            <span> {text} </span>
          </div>
        );
      },
    },
    {
      title: 'Year-Week', fixed: 'right', dataIndex: 'yearweek', width: 100, align: 'center',
      render: (text, record) => {
        return (
          <div>
            {text}
            <div className="timeSheetBH">
              {record.period}
            </div>
          </div>
        );
      },
    },
  ];

  let tSheetList = [];
  const getTSList = (year, week) => {
    axios.get(API_URL + `/api/timesheets/PMO/getAllTimesheetsByWeek?userId=${userDetails.id}&year=${year}&weekNumber=${week}`,
      {
        headers: {
          'x-access-token': `${token}`,
          'Access-Control-Allow-Origin': '*'
        }
      })
      .then(response => {
        setTimeSheetList([]);
        if (Object.keys(response.data.data).length > 0) {
          response.data.data.map((item, index) => {
            tSheetList.push({
              key: index + 1,
              empname: item.user && item.user.displayNameTx,
              empid: item.user && item.user.employeeId,
              yearweek: item.year + " - " + item.weekNumber,
              year: item.year,
              weekNumber: item.weekNumber,
              rmanager: item.reportingManager && item.reportingManager.displayNameTx,
              status: item.reportingManagerAction,
              loggedHours: getLoggedHours(item)
            })
          })
          setTimeSheetList(tSheetList);
        }
      })
      .catch(error => {
        console.log(error);
      });
  }

  const minsToStrTime = (val) => {
    let m = val % 60;
    let h = (val - m) / 60;

    h = h < 10 ? "0" + h : h;
    m = m < 10 ? "0" + m : m;
    console.log(`timelog data passed ${h}:${m}`)
    return `${h}:${m}`;
  }
  const strTimeToMins = (strTime) => {
    let [h, m] = strTime.split(":");
    return Number(h) * 60 + Number(m);
  }
  const getLoggedHours = (tsheet) => {

    let totalHours = 0;
    tsheet.projects.map((projectItem, index) => (
      projectItem.tasks.map((taskItem, index) => (
        totalHours = totalHours + strTimeToMins(taskItem.timelog[0].hours) + strTimeToMins(taskItem.timelog[1].hours) + strTimeToMins(taskItem.timelog[2].hours) + strTimeToMins(taskItem.timelog[3].hours) + strTimeToMins(taskItem.timelog[4].hours) + strTimeToMins(taskItem.timelog[5].hours) + strTimeToMins(taskItem.timelog[6].hours)
      ))
    ))

    return minsToStrTime(totalHours);
  }

  useEffect(() => {
    getTSList(moment().weekYear(), moment().week());
  }, [])
  return (
    <Auxiliary>
      <div className="dateRangeBlock m_0 datePicker-alignRight">
        <DatePicker picker="week" onChange={onChangeWeek} defaultValue={moment()} bordered={true} />
      </div>
      <Table className="gx-table-responsive remove-mFixCell" columns={timeSheetListCols} dataSource={timeSheetList} bordered={false} scroll={{ x: 1300 }}
        loading={loading}
        exportable
        exportableProps={{ showColumnPicker: true }}
        expandedRowRender={record => <>  <p style={{ margin: 0 }}> Employee Comments: {record.empcomments}</p>   <p style={{ margin: 0 }}> Reporting Manager Comments: {record.rmcomments}</p></>}
      />
    </Auxiliary>
  );
};
export default TimeLogs;