import React from "react";
import {useDispatch} from "react-redux";
import {Avatar, Popover} from "antd";
import {userSignOut} from "appRedux/actions/Auth";

const UserProfile = () => {
  const dispatch = useDispatch();
  const userMenuOptions = (
    <ul className="gx-user-popover">
      <li>My Account</li>
      <li>Connections</li>
      <li>
      <div><span className="reset_pwd"> <a href={'/userAuth/ResetPassword/'}>Reset Password</a></span> </div>
      </li>

      <li onClick={() => dispatch(userSignOut())}>Logout
      </li>
    </ul>
  );

  const userDetails = JSON.parse(sessionStorage.getItem('user_details'))

  return (

    <div className="gx-flex-row gx-align-items-center gx-mb-4 gx-avatar-row">
      <Popover placement="bottomRight" content={userMenuOptions} trigger="click">

      {(() => {
                          try {  
                            return <Avatar className="gx-size-40 gx-pointer gx-mr-3" alt={userDetails.displayNameTx} src={require(`../../assets/images/avatar/${userDetails.employeeId.toLowerCase()}.png`)} />  
                        } catch(err){  
                          return <li><span className="ant-avatar gx-border gx-border-grey gx-bg-transparent gx-text-grey gx-d-flex gx-align-items-center gx-justify-content-center"><i
                          className="icon icon-add" /></span></li>
                        }                
                                                 
                })()}   
        {/* <Avatar src={"https://via.placeholder.com/150"}
                className="gx-size-40 gx-pointer gx-mr-3" alt=""/> */}
        <span className="gx-avatar-name"><i
          className="icon icon-chevron-down gx-fs-xxs gx-ml-2"/>{" "+userDetails.employeeId}</span>
      </Popover>
    </div>

  )
};

export default UserProfile;
