import React, { useEffect, useState } from "react";
import axios from 'axios';
import { Collapse, Col, Row, List, Descriptions, Select, Form, } from "antd";
import Auxiliary from "util/Auxiliary";
import { API_URL } from 'constants/APIConfig';
import moment from "moment";

const Panel = Collapse.Panel;
const { Option } = Select;

const ProjectResources = (props) => {
  const userDetails = JSON.parse(sessionStorage.getItem('user_details'));
  const token = sessionStorage.getItem('token');

  const [resAllocation, setResAllocation] = useState([]);
  const [projects, setProjects] = useState([]);

  const [form] = Form.useForm();
  let i = 0;

  console.log("Props", props.id);
  const getProjectList = () => {
    axios.get(API_URL + `/api/project/v2/projectsByManager?userId=${userDetails.id}`, 
    {headers: {
      'x-access-token': `${token}`, 
    'Access-Control-Allow-Origin': '*'}
   })
      .then(response => {
        if (Object.keys(response.data.data).length > 0) {
          setProjects(response.data.data);
          form.setFieldsValue({
            prjname: response.data.data[0].id,
          })
          getProjResourcesList(response.data.data[0].id);
        }
      })
      .catch(error => {
        // Error
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log('Error', error.message);
        }
        console.log(error.config);
      });
  }
  const onProjectChange = value => {
    getProjResourcesList(value)
  };

  let data = [];

  const getProjResourcesList = (projId) => {
    let url = "";
    if (props.id !== undefined) {
      url = API_URL + `/api/project_resources/getProjectResources?userId=${userDetails.id}&projectId=${props.id}`;
    } else {
      url = API_URL + `/api/project_resources/getProjectResources?userId=${userDetails.id}&projectId=${projId}`;
    }
    axios.get(url, 
      {headers: {
        'x-access-token': `${token}`, 
      'Access-Control-Allow-Origin': '*'}
     })
      .then(response => {
        if (Object.keys(response.data.data).length > 0) {
          response.data.data.map((item, index) => {
            data.push({
              id: item.id,
              resource: item.resourceType + "-" + item.resourceTypeTitle,
              name: item.user && item.user.displayNameTx,
              sDate: item.startDateDt ? moment(item.startDateDt).format("YYYY-MM-DD") : null,
              eDate: item.endDateDt ? moment(item.endDateDt).format("YYYY-MM-DD") : null,
              allocation: item.percentageOfAllocation ? item.percentageOfAllocation : null,
              status: item.status ? item.status : null,
              comments: item.comments ? item.comments : null,
              uid: item.userIdNm ? item.userIdNm : null,
            })
          })
          setResAllocation(data);
          console.log("Resc Alloc", data)
        }
      })
      .catch(error => {
        // Error
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log('Error', error.message);
        }
        console.log(error.config);
      });
  }
  useEffect(() => {
    getProjResourcesList();
    getProjectList();
  }, [])

  return (
    <Auxiliary>
      <Form
        form={form}
        initialValues={{ remember: true }}
        name="ohealth"
        // onFinish={onFinish}
        layout="inline"
        align="center"
      >
        <Row gutter={24}>
          <Col lg={12} md={12} sm={24} xs={24}  >
            <div className="gx-form-row0" >
              <Form.Item
                name="prjname"
                // label="Project Name"
                rules={[
                  {
                    required: true,
                    message: 'Enter project name',
                  },
                ]}
              >
                <Select
                  showSearch
                  style={{ width: '60%' }}
                  placeholder="Projects"
                  optionFilterProp="children"
                  onChange={onProjectChange}
                  // size="small"
                  filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                >
                  {
                    projects.length ? projects.map((project, index) => (
                      <Option key={index} value={project.id}>{project.name}</Option>
                    )) : null
                  }
                </Select>
              </Form.Item>
            </div>
          </Col>
        </Row>
      </Form>
      <List
        itemLayout="vertical"
        pagination={{
          onChange: page => {
            console.log(page);
          },
          pageSize: 10,
        }}
        dataSource={resAllocation}
        renderItem={item => (
          <List.Item key={i + 1} className="ProjectListBlock">

            <Collapse bordered={false} expandIconPosition='right' className="mainAcc">
              <Panel header="" key={i + 1}
                extra={
                  <ul className="gx-list-inline gx-ml-auto gx-mb-0 gx-text-grey reportsPage">
                    <li className="projectText projectBlock">{item.resource}</li>
                    <li className="statusBlock projectBlock">
                      <div>
                        <div><li> {item.name}</li></div>
                        <div><li> {item.status}</li></div>
                      </div>
                    </li>
                  </ul>
                }
              >
                <Descriptions size="small">
                  <Descriptions.Item label="Start Date">{item.sDate}</Descriptions.Item>
                  <Descriptions.Item label="End Date">{item.eDate}</Descriptions.Item>
                  <Descriptions.Item label="% Allocation">{item.allocation}</Descriptions.Item>
                  <Descriptions.Item label="Comments">{item.comments}</Descriptions.Item>
                </Descriptions>
              </Panel>
            </Collapse>

          </List.Item>
        )}
      />
    </Auxiliary>
  );
};

export default ProjectResources;
