import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import axios from 'axios';
import { Col, Row } from "antd";
import { Anchor, Button, Table, Radio, Select, List, } from "antd";
import Auxiliary from "util/Auxiliary";
import { API_URL } from 'constants/APIConfig';
import IconWithTextCard from "components/Metrics/IconWithTextCard";
import { FormOutlined, EyeOutlined } from '@ant-design/icons';
import Widget from "components/Widget/index";

//import { Col, Row, Progress, Radio, Button, Modal, Table, Popconfirm, Form, Select } from "antd";
const { Link } = Anchor;
const { Option } = Select;
const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;

function handleChange(value) {
    console.log(`selected ${value}`);
}

function handleBlur() {
    console.log('blur');
}

function handleFocus() {
    console.log('focus');
}


const ManageResource = () => {
    let history = useHistory();
    const userDetails = JSON.parse(sessionStorage.getItem('user_details'));
    const token = sessionStorage.getItem('token');

    const [resources, setResources] = useState([]);
    const [projStats, setProjStats] = useState([]);

    const [divisions, setDivisions] = useState([]);
    const [departments, setDepartments] = useState([]);
    const [subDepartments, setSubDepartments] = useState([]);
    const [reportingMgrs, setReportingMgrs] = useState([]);
    const[loading,setLoading] = useState(false);

    const [typeFilter, setTypeFilter] = useState("");
    const [statusFilter, setStatusFilter] = useState("");
    const [projectStatusList, setProjectStatusList] = useState([]);



    function onChangeReporting(value) {
        getResourcesbyReporting(value);
    }

    function onChangeDept(value) {
        getResourcesbyDepartment(value);
        getSubDeptsbyDept(value);
    }

    function onChangeSubDept(value) {
        getResourcesbySubDepartment(value);
    }


    function onChangeDivision(value) {
        setStatusFilter(value);
        //getAllprojects(value, typeFilter);
    }

    const getDivisions = () => {
        axios.get(API_URL + '/api/division/all', 
        {headers: {
          'x-access-token': `${token}`, 
        'Access-Control-Allow-Origin': '*'}
       })
            .then(response => {
                setDivisions(response.data.data);
            })
            .catch(error => {
                // Error
                if (error.response) {
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                } else if (error.request) {
                    console.log(error.request);
                } else {
                    console.log('Error', error.message);
                }
                console.log(error.config);
            });
    }
    const getDepartments = () => {
        axios.get(API_URL + '/api/department/all', 
        {headers: {
          'x-access-token': `${token}`, 
        'Access-Control-Allow-Origin': '*'}
       })
            .then(response => {
                setDepartments(response.data.data);
            })
            .catch(error => {
                // Error
                if (error.response) {
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                } else if (error.request) {
                    console.log(error.request);
                } else {
                    console.log('Error', error.message);
                }
                console.log(error.config);
            });
    }

    const getSubDepartment = () => {
        axios.get(API_URL + '/api/subDepartment/all', 
        {headers: {
          'x-access-token': `${token}`, 
        'Access-Control-Allow-Origin': '*'}
       })
            .then(response => {
                setSubDepartments(response.data.data);
            })
            .catch(error => {
                // Error
                if (error.response) {
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                } else if (error.request) {
                    console.log(error.request);
                } else {
                    console.log('Error', error.message);
                }
                console.log(error.config);
            });
    }
    const getSubDeptsbyDept = (value) => {
        axios.get(API_URL + `/api/subDepartment/getSubDepartmentsByDeptId/${value}`, 
        {headers: {
          'x-access-token': `${token}`, 
        'Access-Control-Allow-Origin': '*'}
       })
            .then(response => {
                setSubDepartments(response.data.data);
            })
            .catch(error => {
                // Error
                if (error.response) {
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                } else if (error.request) {
                    console.log(error.request);
                } else {
                    console.log('Error', error.message);
                }
                console.log(error.config);
            });
    }

    const getReportingMgr = () => {
        axios.get(API_URL + '/api/users/getAllReportingManagers', 
        {headers: {
          'x-access-token': `${token}`, 
        'Access-Control-Allow-Origin': '*'}
       })
            .then(response => {
                setReportingMgrs(response.data.data);
                console.log(response.data.data);
            })
            .catch(error => {
                // Error
                if (error.response) {
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                } else if (error.request) {
                    console.log(error.request);
                } else {
                    console.log('Error', error.message);
                }
                console.log(error.config);
            });
    }

/*     const getUserList = () => {
        axios.get(API_URL + '/api/users/getall')
            .then(response => {
                console.log('User List', response.data.data)
                //        setUsers(response.data.data);
            })
            .catch(error => {
                // Error
                if (error.response) {
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                } else if (error.request) {
                    console.log(error.request);
                } else {
                    console.log('Error', error.message);
                }
                console.log(error.config);
            });
    } */

    const getResourcesbyReporting = (value) => {
        console.log('PM', API_URL + `/api/users/getAll?rmgrFltr=${value}`)
        axios.get(API_URL + `/api/users/getAll?rmgrFltr=${value}`, 
        {headers: {
          'x-access-token': `${token}`, 
        'Access-Control-Allow-Origin': '*'}
       })
            .then(response => {
                setResources(response.data.data);
                console.log('Resources', response.data.data)
            })
            .catch(error => {
                // Error
                if (error.response) {
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                } else if (error.request) {
                    console.log(error.request);
                } else {
                    console.log('Error', error.message);
                }
                console.log(error.config);
            });
    }

    const getResourcesbyDepartment = (value) => {
        console.log('PM', API_URL + `/api/users/getAll?rmgrFltr=${value}`)
        axios.get(API_URL + `/api/users/getAll?deptFltr=${value}`, 
        {headers: {
          'x-access-token': `${token}`, 
        'Access-Control-Allow-Origin': '*'}
       })
            .then(response => {
                setResources(response.data.data);
                console.log('Resources', response.data.data)
            })
            .catch(error => {
                // Error
                if (error.response) {
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                } else if (error.request) {
                    console.log(error.request);
                } else {
                    console.log('Error', error.message);
                }
                console.log(error.config);
            });
    }

    const getResourcesbySubDepartment = (value) => {
        axios.get(API_URL + `/api/users/getAll?subDeptFltr=${value}`, 
        {headers: {
          'x-access-token': `${token}`, 
        'Access-Control-Allow-Origin': '*'}
       })
            .then(response => {
                setResources(response.data.data);
                console.log('Resources', response.data.data)
            })
            .catch(error => {
                // Error
                if (error.response) {
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                } else if (error.request) {
                    console.log(error.request);
                } else {
                    console.log('Error', error.message);
                }
                console.log(error.config);
            });
    }


    const getResources = () => {
        setLoading(true);
        axios.get(API_URL + `/api/users/getall`, 
        {headers: {
          'x-access-token': `${token}`, 
        'Access-Control-Allow-Origin': '*'}
       })
            .then(response => {
                setResources(response.data.data);
                console.log('Resources', response.data.data)
            })
            .catch(error => {
                // Error
                if (error.response) {
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                } else if (error.request) {
                    console.log(error.request);
                } else {
                    console.log('Error', error.message);
                }
                console.log(error.config);
            });
            setLoading(false);
    }

    useEffect(() => {
        //getUserList();
        getResources();
        getDepartments();
        getDivisions();
        getSubDepartment();
        getReportingMgr();
    }, [])
    return (
        <Auxiliary>
            <Row gutter={24}>
                <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                    <div className="gx-form-row0">
                        <h5 align="left">Filter by Department</h5>
                        <Select
                            //defaultValue="InProgress"
                            showSearch
                            style={{ width: '100%' }}
                            //placeholder="Filter by Department"
                            optionFilterProp="children"
                            onChange={onChangeDept}
                            onFocus={handleFocus}
                            onBlur={handleBlur}
                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        >
                            {
                                departments.length ? departments.map((department, index) => (
                                    <Option key={index} value={department.id}>{department.nameTx}</Option>
                                )) : null
                            }
                        </Select>
                    </div>
                </Col>
                <Col lg={6} md={6} sm={12} xs={24} >
                    <div className="gx-form-row0">
                        <h5 align="left">Filter by Sub Department</h5>
                        <Select
                            //defaultValue="InProgress"
                            showSearch
                            style={{ width: '100%' }}
                            //placeholder="Filter by Sub Department"
                            optionFilterProp="children"
                            onChange={onChangeSubDept}
                            onFocus={handleFocus}
                            onBlur={handleBlur}
                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        >
                            {
                                subDepartments.length ? subDepartments.map((subDepartment, index) => (
                                    <Option key={index} value={subDepartment.id}>{subDepartment.nameTx}</Option>
                                )) : null
                            }
                        </Select>
                    </div>
                </Col>
                <Col lg={6} md={6} sm={12} xs={24} >
                    <div className="gx-form-row0">
                        <h5 align="left">Filter by Division</h5>
                        <Select
                            //defaultValue="InProgress"
                            showSearch
                            style={{ width: '100%' }}
                            // placeholder="Filter by Division"
                            optionFilterProp="children"
                            onChange={onChangeDivision}
                            onFocus={handleFocus}
                            onBlur={handleBlur}
                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        >
                            {
                                divisions.length ? divisions.map((division, index) => (
                                    <Option key={index} value={division.nameTx}>{division.nameTx}</Option>
                                )) : null
                            }
                        </Select>
                    </div>
                </Col>
                <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                    <div className="gx-form-row0">
                        <h5 align="left">Filter by Reporting Manager</h5>
                        <Select
                            showSearch
                            style={{ width: '100%' }}
                            //placeholder="Filter by Reporting Manager"
                            optionFilterProp="children"
                            onChange={onChangeReporting}
                            onFocus={handleFocus}
                            onBlur={handleBlur}
                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        >
                            {
                                reportingMgrs.length ? reportingMgrs.map((reportingMgr, index) => (
                                    <Option key={index} value={reportingMgr.id}>{reportingMgr.displayNameTx}</Option>
                                )) : null
                            }
                        </Select>
                    </div>
                </Col>
            </Row>
            <List className="gx-bg-white orgBlock"
                grid={{ gutter: 15, column: 3 }}
                size="small"
                // header={<div className="headings_title">Reporting Manager's Team</div>}
                /*    footer={<div>Footer</div>} */
                bordered
                pagination={{
                    onChange: page => {
                        console.log(page);
                    },
                    pageSize: 18,
                }}
                dataSource={resources}
                loading = {loading}
                renderItem={item => <List.Item>
                   
                        {/*   {resources.map((item, i) => ( */}
                        
                            <Widget styleName="gx-card-full gx-dot-arrow-hover">
                                <div className="gx-user-wid-row">
                                    <div className="gx-user-wid gx-mr-3 org_str">
                                        {(() => {
                                            try {
                                                return <img className="gx-object-cover" src={require(`../../../assets/images/avatar/${item.employeeId.toLowerCase()}.png`)} />
                                            } catch (err) {
                                                try {
                                                    return <img className="gx-object-cover" src={require(`../../../assets/images/avatar/profile.png`)} />
                                                  } catch (err) {
                                                    return <span className="ant-avatar gx-size-80 gx-border gx-border-grey gx-bg-transparent gx-text-grey gx-d-flex gx-align-items-center gx-justify-content-center orgpersonimgempty"><i
                                                    className="icon icon-add" /></span>
                                                  }
                                            }
                                        })()}
                                    </div>
                                    <div className="gx-user-wid-body gx-py-3 gx-pr-3">
                                        <div className="ant-row-flex">
                                            <h2 className="h4 gx-mr-1 gx-mb-1">{item.displayNameTx} ({item.employeeId})</h2>
                                        </div>
                                        <p className="gx-mb-1 gx-text-grey gx-fs-sm">{item.designation}</p>
                                        <p className="gx-mb-1 gx-text-grey gx-fs-sm">{item.emailTx}</p>
                                        <p className="gx-mb-1 gx-text-grey gx-fs-sm">Location</p>
                                        <div className="gx-dot-arrow">
                                            <div className="gx-bg-primary gx-hover-arrow">
                                            <Button type="primary" className="add-btn" onClick={() => history.push(`/orgstructure/manage/${item.id}`)}><i className="icon icon-long-arrow-right gx-text-white" /></Button>
                                                
                                            </div>
                                            <div className="gx-dot">
                                                <i className="icon icon-ellipse-v gx-text-primary" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Widget>
                       
                        {/*         */}
                  
                </List.Item>}
            />

         </Auxiliary>
    );
};

export default ManageResource;
