import React, { useEffect, useState } from "react";
import { Row, Col, message, Table, Radio, Button, Modal, DatePicker, Tag, Breadcrumb, Avatar, Tooltip, Input, Divider } from "antd";
import axios from 'axios';
import { API_URL } from 'constants/APIConfig';
import moment from 'moment';
import 'moment/min/locales';
import Auxiliary from "util/Auxiliary";
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';

const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;
const { TextArea } = Input;

const TravelDeskRMApprove = () => {
    const userDetails = JSON.parse(sessionStorage.getItem('user_details'));
    const token = sessionStorage.getItem('token');
    const [loading, setLoading] = useState(false);
    const [year, setYear] = useState(moment().weekYear());
    const [updateRecordId, setUpdateRecordId] = useState(null);
    const [approveTextAreaCount, setApproveTextAreaCount] = React.useState(0);
    const [rejectTextAreaCount, setRejectTextAreaCount] = React.useState(0);
    const [travelRequestStatus, setTravelRequestStatus] = useState('Pending');
    const [approveComments, setApproveComments] = useState("");
    const [rejectComments, setRejectComments] = useState("");

    const onChangeYear = (date, yearString) => {
        if (date != null && yearString != null) {
            setYear(yearString)
        }
    }

    const onApprove = () => {
        if (approveComments.trim().length == 0) {
            message.error('Please enter the comments')
            return
        }
        setApprovePop(false)
        updateTravelRequestByReportingMgr('Approved', approveComments)
        setApproveComments('')
        setApproveTextAreaCount(0)
    }

    const onReject = () => {
        if (rejectComments.trim().length == 0) {
            message.error('Please enter the comments')
            return
        }
        setRejectPop(false)
        updateTravelRequestByReportingMgr('Rejected', rejectComments)
        setRejectComments('')
        setRejectTextAreaCount(0)
    }

    const handleApproveComments = (e) => {
        setApproveComments(e.target.value || '');
        setApproveTextAreaCount(e.target.value.length);
    }
    const handleRejectComments = (e) => {
        setRejectComments(e.target.value || '');
        setRejectTextAreaCount(e.target.value.length);
    }

    function onChange(e) {
        setTravelRequestStatus(e.target.value)
        getReportingMgrTravelRequestList(e.target.value, year);
    }

    const handleApprove = (record) => {
        setUpdateRecordId(record['id'])
        setApprovePop(true);
        setApproveComments('')
        setApproveTextAreaCount(0)
    }

    const handleReject = (record) => {
        setUpdateRecordId(record['id'])
        setRejectPop(true);
        setRejectComments('')
        setRejectTextAreaCount(0)
    }

    const [travelRequestDataList, setTravelRequestDataList] = useState([]);

    const travelRequestColList = [
        {
            title: 'Employee', dataIndex: 'employeeId', width: 25,
            render: (text, record) => {
                return (
                    <div className="gx-d-flex">
                        {(() => {
                            try {
                                return <Tooltip placement="topLeft" title={record.displayNameTx ? record.displayNameTx : ""}> <Avatar size={{ xs: 24, sm: 32, md: 40, lg: 64, xl: 80, xxl: 100 }} src={require(`../../../../src/assets/images/avatar/${text.toLowerCase()}.png`)} /></Tooltip>
                            } catch (err) {
                                try {
                                    return <Tooltip placement="topLeft" title={record.displayNameTx ? record.displayNameTx : ""}> <Avatar size={{ xs: 24, sm: 32, md: 40, lg: 64, xl: 80, xxl: 100 }} src={require(`../../../../src/assets/images/avatar/profile.png`)} /></Tooltip>
                                } catch (err) {
                                    return <Tooltip placement="topLeft" title={record.displayNameTx ? record.displayNameTx : ""}><span className="ant-avatar gx-size-40 gx-border gx-border-grey gx-bg-transparent gx-text-grey gx-align-items-center gx-justify-content-center"><i
                                        className="icon icon-add" /></span></Tooltip>
                                }
                            }
                        })()}
                    </div>
                );
            },
        },
        {
            title: 'Emp ID', dataIndex: 'employeeId', key: 'displayNameTx', width: 25,
            render: (text, record) => {
                return (<div><span>{record.employeeId}</span></div>)
            }
        },
        {
            title: 'Name', dataIndex: 'displayNameTx', key: 'displayNameTx', width: 25,
            render: (text, record) => {
                return (<div><span>{record.displayNameTx}</span></div>)
            }
        },
        {
            title: 'Project', dataIndex: 'project_name', width: 100, align: 'center',
            render: (text, record) => {
                return (
                    <div>
                        <span> {record.project.name}</span>
                    </div>
                );
            },
        },
        {
            title: 'Admin Created', dataIndex: 'isAdminCreated', width: 70, align: 'center',
            render: (text, record) => {
                return (
                    <div>
                        <span> {record.isAdminCreated ? 'Yes' : 'No'}</span>
                    </div>
                );
            },
        },
        {
            title: 'Action', dataIndex: 'action', width: 25, align: 'left',
            render: (text, record) =>
                <div className="gx-d-flex">
                    {(() => {
                        if (record.rmAction === "Pending") {
                            return (<>
                                <Button className="approved_btn" shape="circle" htmlType="submit" title="Approve" icon={<CheckOutlined />} onClick={() => handleApprove(record)}> </Button>
                                <Button className="reject_btn" shape="circle" htmlType="submit" title="Reject" icon={<CloseOutlined />} onClick={() => handleReject(record)}> </Button>
                            </>);
                        } else {
                            if (record.rmAction === "Approved")
                                if(record.isAdminCreated) {
                                    return <Tag color="green" >{"Auto Approved"}</Tag>
                                } else {
                                    return <Tag color="green" >{"Approved"}</Tag>
                                }
                            if (record.rmAction === "Rejected")
                                return <Tag color="red" >{record.rmAction ? record.rmAction : ""}</Tag>
                        }
                    })()}
                </div>
        }
    ];

    const getReportingMgrTravelRequestList = async (status, year) => {
        setLoading(true);
        const url = API_URL + `/api/travel_desk/getReportingMgrTravelRequestList?reportingManagerId=${userDetails.id}&status=${status}&year=${year}`;
        try {
            axios.get(url,
                {
                    headers: {
                        'x-access-token': `${token}`,
                        'Access-Control-Allow-Origin': '*'
                    }
                })
                .then(response => {
                    setLoading(false)
                    if (response.data && response.data.data) {
                        response.data.data.forEach((d, i) => {
                            d['key'] = i;
                            d['employeeId'] = d['user']['employeeId']
                            d['displayNameTx'] = d['user']['displayNameTx']
                        })
                        setTravelRequestDataList(response.data.data)
                    } else {
                        setTravelRequestDataList([])
                    }
                })
                .catch(error => {
                    setLoading(false);
                    message.error(`Error occurred.`);
                });
        } catch (ex) {
            setLoading(false)
            message.error(`${ex.message}:get data`);
        }
    }

    const updateTravelRequestByReportingMgr = async (status, comments) => {
        setLoading(true);
        const url = API_URL + `/api/travel_desk/updateTravelRequestByReportingMgr`;
        try {
            axios.post(url,
                {
                    id: updateRecordId,
                    rmAction: status,
                    rmComment: comments,
                    reportingManagerId : userDetails.id
                },
                {
                    headers: {
                        'x-access-token': `${token}`,
                        'Access-Control-Allow-Origin': '*'
                    }
                })
                .then(response => {
                    setLoading(false)
                    message.success(response.data.data)
                    getReportingMgrTravelRequestList(travelRequestStatus, year)
                })
                .catch(error => {
                    setLoading(false);
                    message.error(`Request failed`);
                });
        } catch (ex) {
            setLoading(false)
            message.error(`Request failed`);
        }
    }

    const [approvePop, setApprovePop] = useState(false);
    const [rejectPop, setRejectPop] = useState(false);

    useEffect(() => {
        getReportingMgrTravelRequestList(travelRequestStatus, year)
    }, [year])

    return (
        <Auxiliary>
            <Row className="m_0 gx-pt-2">
                <Col xl={4} lg={4} md={4} sm={24} xs={24} className="flex_style gx-pl-0 ant-row-start">
                    <Breadcrumb>
                        <Breadcrumb.Item>Travel Desk</Breadcrumb.Item>
                        <Breadcrumb.Item className="text-helight"><i className="icon icon-check-circle-o" /> RM Approve</Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
                <Col xl={16} lg={16} md={16} sm={24} xs={24} className="flex_style gx-pr-0">

                </Col>
                <Col xl={4} lg={4} md={4} sm={24} xs={24} className="flex_style gx-pr-0 dateRangeBlock">
                    <DatePicker picker="year" onChange={onChangeYear} defaultValue={moment()} bordered={true} allowClear={false} />
                </Col>
            </Row>
            
            <Row className="m_0">
                <Col xl={24} lg={24} md={24} sm={24} xs={24} className="gx-p-0">
                    <div className="float-right" >
                        <RadioGroup onChange={onChange} buttonStyle="solid" defaultValue={travelRequestStatus} disabled={loading}>
                            <RadioButton value="Pending">Pending</RadioButton>
                            <RadioButton value="Approved">Approved</RadioButton>
                            <RadioButton value="Rejected">Rejected</RadioButton>
                        </RadioGroup>
                    </div>
                </Col>
            </Row>
            <Table loading={loading} className="gx-table-responsive" columns={travelRequestColList} dataSource={travelRequestDataList} bordered={false} 
            scroll={{ x: 900 }}
            expandedRowClassName={() => "travel-request-list"}
                expandable={{
                    expandedRowRender: (record) => <>
                    <div className="emp_section">
                    <Row xl={24} lg={24} md={24} sm={24} xs={24}>
                        <Col xl={6} lg={6} md={6} sm={6} xs={6}><b>Project</b> <p>{record.project.name}</p></Col>
                        <Col xl={6} lg={6} md={6} sm={6} xs={6}><b>From</b> <p>{record.fromPlace}</p></Col>
                        <Col xl={6} lg={6} md={6} sm={6} xs={6}><b>To</b> <p>{record.toPlace}</p></Col>
                        <Col xl={6} lg={6} md={6} sm={6} xs={6}><b>Mobile No</b> <p>{record.mobileNumber}</p></Col>
                    </Row>
                    <Row xl={24} lg={24} md={24} sm={24} xs={24}>
                        <Col xl={6} lg={6} md={6} sm={6} xs={6}><b>Journey Date</b> <p>{record.journeyDate && moment(record.journeyDate).format('ll')}</p></Col>
                        <Col xl={6} lg={6} md={6} sm={6} xs={6}><b>Return Journey Date</b> <p>{record.returnJourneyDate && moment(record.returnJourneyDate).format('ll')}</p></Col>
                        <Col xl={6} lg={6} md={6} sm={6} xs={6}><b>Requested Date</b> <p>{record.created_date && moment(record.created_date).format('ll')}</p></Col>
                        <Col xl={6} lg={6} md={6} sm={6} xs={6}><b>is Ad-hoc Request</b> <p>{record.isAdhoc ? 'Yes' : 'No'}</p></Col>
                        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                            <b>Purpose: </b> <p>{
                                (() => {
                                    return record.purpose.split('\n').map((subStr) => {
                                        return (
                                          <>
                                            {subStr}
                                            <br />
                                          </>
                                        );
                                      })
                                })()
                            }
                            </p>
                        </Col>
                        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                            <b>Goals: </b> <p>{
                            (() => {
                                    return record.goals.split('\n').map((subStr) => {
                                        return (
                                          <>
                                            {subStr}
                                            <br />
                                          </>
                                        );
                                      })
                                })()}
                            </p>
                        </Col>
                        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                            <b>Expected Outcome: </b> <p>{
                            (() => {
                                    return record.expectedOutcome.split('\n').map((subStr) => {
                                        return (
                                          <>
                                            {subStr}
                                            <br />
                                          </>
                                        );
                                      })
                                })()}
                            </p>
                        </Col>
                    </Row>
                    </div>
                    <Divider orientation="left"><b>Project Manager</b></Divider>
                    <div className="pm_section">
                    <Row xl={24} lg={24} md={24} sm={24} xs={24}>
                        <Col xl={6} lg={6} md={6} sm={6} xs={6}><b>Name</b> <p>{record.projectManager.displayNameTx}</p></Col>
                        <Col xl={6} lg={6} md={6} sm={6} xs={6}><b>Action Date</b> <p>{record.pmActionDate && moment(record.pmActionDate).format('lll')}</p></Col>
                        <Col xl={12} lg={12} md={12} sm={12} xs={12}><b>Purpose of Business Travel</b> <p>{record.pmComment}</p></Col>
                    </Row>
                    </div>
                    <Divider orientation="left"><b>Reporting Manager</b></Divider>
                    <div className="rm_section">
                    <Row xl={24} lg={24} md={24} sm={24} xs={24}>
                        <Col xl={6} lg={6} md={6} sm={6} xs={6}><b>Name</b> <p>{record.reportingManager.displayNameTx}</p></Col>
                        <Col xl={6} lg={6} md={6} sm={6} xs={6}><b>Action Date</b> <p>{record.rmActionDate && moment(record.rmActionDate).format('lll')}</p></Col>
                        <Col xl={12} lg={12} md={12} sm={12} xs={12}><b>Purpose of Business Travel</b> <p>{record.rmComment}</p></Col>
                    </Row>
                    </div>
                    { record.isAdhoc &&
                        <>
                        <Divider orientation="left"><b>Approved By Infra Admin</b></Divider>
                        <div className="infra_admin_section">
                        <Row xl={24} lg={24} md={24} sm={24} xs={24}>
                            <Col xl={6} lg={6} md={6} sm={6} xs={6}><b>Name</b> <p>{record.infraAdmin?.displayNameTx}</p></Col>
                            <Col xl={6} lg={6} md={6} sm={6} xs={6}><b>Action Date</b> <p>{record.infraAdminActionDate && moment(record.infraAdminActionDate).format('lll')}</p></Col>
                            <Col xl={12} lg={12} md={12} sm={12} xs={12}><b>Purpose of Business Travel</b> <p>{record.infraAdminComment}</p></Col>
                        </Row>
                        </div>
                        </>
                    }
                    { record.admin &&
                        <>
                        <Divider orientation="left"><b>Approved By Admin</b></Divider>
                        <div className="admin_section">
                        <Row xl={24} lg={24} md={24} sm={24} xs={24}>
                            <Col xl={6} lg={6} md={6} sm={6} xs={6}><b>Name</b> <p>{record.admin.displayNameTx}</p></Col>
                            <Col xl={6} lg={6} md={6} sm={6} xs={6}><b>Action Date</b> <p>{record.adminActionDate && moment(record.adminActionDate).format('lll')}</p></Col>
                            <Col xl={12} lg={12} md={12} sm={12} xs={12}><b>Purpose of Business Travel</b> <p>{record.adminComment}</p></Col>
                        </Row>
                        </div>
                        </>
                        }
                    </>
                }}
            />

            <Modal
                title="Approve Comments"
                centered
                visible={approvePop}
                width={600}
                closable={false}
                destroyOnClose={true}
                footer={null}
            >
                <Auxiliary>
                    <Row className="m_0 buttonGroup">
                        <Col xl={8} lg={8} md={8} sm={8} xs={8} className="gx-p-0">
                            <p className="gx-mb-1 gx-mt-1">Purpose of Business Travel:</p>
                        </Col>
                        <Col xl={16} lg={16} md={16} sm={16} xs={16} className="gx-p-0">
                            <TextArea placeholder="Justify your comments" onChange={e => handleApproveComments(e)} rows={4} value={approveComments} maxLength={255} />
                            <p style={{ textAlign: 'right' }}> {`${approveTextAreaCount} / 255`} </p>
                        </Col>
                    </Row>
                    <Row className="m_0 buttonGroup">
                        <Col xl={24} lg={24} md={24} sm={24} xs={24} className="gx-p-0">
                            <Button type="primary" className="save_btn" htmlType="submit" onClick={onApprove}>Approve</Button>
                            <Button type="primary" className="add-btn gx-mr-2" onClick={() => { setApproveComments(""); setApproveTextAreaCount(0); setApprovePop(false); }}>Cancel</Button>
                        </Col>
                    </Row>
                </Auxiliary>
            </Modal>

            <Modal
                title="Reject Comments"
                centered
                visible={rejectPop}
                closable={false}
                width={600}
                destroyOnClose={true}
                footer={null}
            >
                <Auxiliary>
                    <Row className="m_0 buttonGroup">
                        <Col xl={8} lg={8} md={8} sm={8} xs={8} className="gx-p-0">
                            <p className="gx-mb-1 gx-mt-1">Purpose of Business Travel:</p>
                        </Col>
                        <Col xl={16} lg={16} md={16} sm={16} xs={16} className="gx-p-0">
                            <TextArea placeholder="Justify your comments" onChange={e => handleRejectComments(e)} rows={4} value={rejectComments} maxLength={255} />
                            <p style={{ textAlign: 'right' }}> {`${rejectTextAreaCount} / 255`} </p>
                        </Col>
                    </Row>
                    <Row className="m_0 buttonGroup">
                        <Col xl={24} lg={24} md={24} sm={24} xs={24} className="gx-p-0">
                            <Button type="primary" className="save_btn" htmlType="submit" onClick={onReject}>Reject</Button>
                            <Button type="primary" className="add-btn gx-mr-2" onClick={() => { setRejectComments(""); setRejectTextAreaCount(0); setRejectPop(false); }}>Cancel</Button>
                        </Col>
                    </Row>
                </Auxiliary>
            </Modal>

        </Auxiliary>
    );
};

export default TravelDeskRMApprove;