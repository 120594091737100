import React,{ useEffect, useState } from "react";
import axios from 'axios';
import ClientStats from "../utils/ClientStats";
import { Row, Col, Form, Input, Select, message, Switch, Divider, Card, Button } from "antd";
import Auxiliary from "util/Auxiliary";
import {API_URL } from 'constants/APIConfig';
import { useHistory } from "react-router-dom";

const { Option } = Select;

function handleChange(value) {
  console.log(`selected ${value}`);
}

function handleBlur() {
  console.log('blur');
}

function handleFocus() {
  console.log('focus');
}


const NewClient = () => {

  let history = useHistory();
  const userDetails = JSON.parse(sessionStorage.getItem('user_details'));
  const token = sessionStorage.getItem('token');

  // const [currency, setCurrency] = useState([]);

  const [form] = Form.useForm();

  const onFinish = values => {
    let clientData = {
      "title": form.getFieldValue('clientname'),
      "organization": form.getFieldValue('shortname'),
      "website": form.getFieldValue('website'),
      "street": form.getFieldValue('address1'),
      "city": form.getFieldValue('city'),
      "state": "state",
      "country": form.getFieldValue('country'),
      "zipcode": form.getFieldValue('postalcode'),
      "region": form.getFieldValue('region'),
      "currency": "USD",
      //"status": form.getFieldValue('parenttask'),
      "firstName": form.getFieldValue('primaryname'),
      "lastName": form.getFieldValue('primarydesign'),
      "email": form.getFieldValue('primaryemail'),
      "phone": form.getFieldValue('primaryphone'),
      "secondaryContactFirstName": form.getFieldValue('secondaryname'),
      "secondaryContactLastName": form.getFieldValue('secondarydesign'),
      "secondaryContactEmail": form.getFieldValue('secondaryemail'),
      "secondaryContactPhone": form.getFieldValue('secondaryphone'),
      "status": "Active"

    }
    axios.post(API_URL + '/api/invoice_customers/add', clientData,
      {
        headers: {
          'x-access-token': `${token}`,
          'Access-Control-Allow-Origin': '*'
        }
      })
      .then(response => {
        message.success("Client Created Successfully");
        history.push('/master/clients/manage')
      })
      .catch(error => {
        // Error
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log('Error', error.message);
        }
        console.log(error.config);
        message.error("Error In creating Client", error)
      });
  };
  // const getCurrency = () => {
  //   axios.get(API_URL + '/api/currency/all',
  //     {
  //       headers: {
  //         'x-access-token': `${token}`,
  //         'Access-Control-Allow-Origin': '*'
  //       }
  //     })
  //     .then(response => {
  //       setCurrency(response.data.data);
  //     })
  //     .catch(error => {
  //       // Error
  //       if (error.response) {
  //         console.log(error.response.data);
  //         console.log(error.response.status);
  //         console.log(error.response.headers);
  //       } else if (error.request) {
  //         console.log(error.request);
  //       } else {
  //         console.log('Error', error.message);
  //       }
  //       console.log(error.config);
  //     });
  // }
  // useEffect(() => {
  //   getCurrency();
  // }, [])



  return (
    // <Card className="gx-card" title="Onboard New Client">

    <Auxiliary>
      <ClientStats />
      <Form
        form={form}
        initialValues={{ remember: true }}
        name="newClient"
        onFinish={onFinish}
        //onFinishFailed={this.onFinishFailed}
        layout="inline"
        align="center"
      >
        <Row gutter={24}>
          <Col lg={8} md={8} sm={12} xs={24} >
            <div className="gx-form-row0">
              <h6 align="left">Name</h6>
              <Form.Item
                name="clientname"
                //label="Estd Hours"
                rules={[
                  {
                    required: true,
                    message: 'Enter client name',
                  },
                ]}
              >
                <Input placeholder="Client Name" />
              </Form.Item>
            </div>
          </Col>

          <Col lg={8} md={8} sm={12} xs={24} >
            <div className="gx-form-row0">
              <h6 align="left">Short Name</h6>
              <Form.Item
                name="shortname"
                //label="Estd Hours"
                rules={[
                  {
                    required: true,
                    message: 'Enter client short name',
                  },
                ]}
              >
                <Input placeholder="Client Short Name" />
              </Form.Item>
            </div>
          </Col>

          <Col lg={8} md={8} sm={12} xs={24} >
            <div className="gx-form-row0">
              <h6 align="left">Website</h6>
              <Form.Item
                name="website"
                //label="Estd Hours"
                rules={[
                  {
                    required: true,
                    message: 'Enter client website',
                  },
                ]}
              >
                <Input placeholder="Client website" />
              </Form.Item>
            </div>
          </Col>
          {/* <Col lg={8} md={8} sm={12} xs={24} >
            <div className="gx-form-row0">
              <h6 align="left">Currency</h6>
              <Form.Item
                name="curr"
                //label="Billable"
                rules={[
                  {
                    required: true,
                    message: 'Enter PO currency',
                  },
                ]}
              >
                <Select
                  showSearch
                  style={{ width: '100%' }}
                  placeholder="Select Currency"
                  optionFilterProp="children"
                  onChange={handleChange}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                >
                 {currency.length ? currency.map((currency, index) => (
                                    <Option key={index} value={currency.id}>{currency.nameTx}</Option>
                                )) : null}
                </Select>
              </Form.Item>
            </div>
          </Col> */}

          <Col lg={8} md={8} sm={12} xs={24} >
            <div className="gx-form-row0">
              <h6 align="left">Active</h6>
              <Form.Item
                name="active"
                //label="Billable"
                rules={[
                  {
                    required: true,
                    message: 'Enter client active',
                  },
                ]}
              >
                <Switch defaultChecked />
              </Form.Item>
            </div>
          </Col>
        </Row>

        <Divider orientation="left">Primary Contact</Divider>
        <Row gutter={24}>
          <Col lg={8} md={8} sm={12} xs={24} >
            <div className="gx-form-row0">
              <h6 align="left">Name</h6>
              <Form.Item
                name="primaryname"
                //label="Estd Hours"
                rules={[
                  {
                    required: true,
                    message: 'Enter primary contact name',
                  },
                ]}
              >
                <Input placeholder="Name" />
              </Form.Item>
            </div>
          </Col>
          <Col lg={8} md={8} sm={12} xs={24} >
            <div className="gx-form-row0">
            <h6 align="left">Designation</h6>
              <Form.Item
                name="primarydesign"
                //label="Estd Hours"
                rules={[
                  {
                    required: true,
                    message: 'Enter primary contact designation',
                  },
                ]}
              >
                <Input placeholder="Designation" />
              </Form.Item>
            </div>
          </Col>

          <Col lg={8} md={8} sm={12} xs={24} >
            <div className="gx-form-row0">
            <h6 align="left">Email</h6>
              <Form.Item
                name="primaryemail"
                //label="Estd Hours"
                rules={[
                  {
                    required: true,
                    message: 'Enter primary contact email',
                  },
                ]}
              >
                <Input placeholder="Email" />
              </Form.Item>
            </div>
          </Col>
          <Col lg={8} md={8} sm={12} xs={24} >
            <div className="gx-form-row0">
            <h6 align="left">Phone</h6>
              <Form.Item
                name="primaryphone"
                //label="Estd Hours"
                rules={[
                  {
                    required: true,
                    message: 'Enter primary contact phone',
                  },
                ]}
              >
                <Input placeholder="Phone" />
              </Form.Item>
            </div>
          </Col>

        </Row>

        <Divider orientation="left">Secondary Contact</Divider>
        <Row gutter={24}>
          <Col lg={8} md={8} sm={12} xs={24} >
            <div className="gx-form-row0">
            <h6 align="left">Name</h6>
              <Form.Item
                name="secondaryname"
                //label="Estd Hours"
                rules={[
                  {
                    required: true,
                    message: 'Enter secondary contact name',
                  },
                ]}
              >
                <Input placeholder="Name" />
              </Form.Item>
            </div>
          </Col>

          <Col lg={8} md={8} sm={12} xs={24} >
            <div className="gx-form-row0">
            <h6 align="left">Designation</h6>
              <Form.Item
                name="secondarydesign"
                //label="Estd Hours"
                rules={[
                  {
                    required: true,
                    message: 'Enter secondary contact designation',
                  },
                ]}
              >
                <Input placeholder="Designation" />
              </Form.Item>
            </div>
          </Col>

          <Col lg={8} md={8} sm={12} xs={24} >
            <div className="gx-form-row0">
            <h6 align="left">Email</h6>
              <Form.Item
                name="secondaryemail"
                //label="Estd Hours"
                rules={[
                  {
                    required: true,
                    message: 'Enter secondary contact email',
                  },
                ]}
              >
                <Input placeholder="Email" />
              </Form.Item>
            </div>
          </Col>
          <Col lg={8} md={8} sm={12} xs={24} >
            <div className="gx-form-row0">
            <h6 align="left">Phone</h6>
              <Form.Item
                name="secondaryphone"
                //label="Estd Hours"
                rules={[
                  {
                    required: true,
                    message: 'Enter secondary contact phone',
                  },
                ]}
              >
                <Input placeholder="Phone" />
              </Form.Item>
            </div>
          </Col>
        </Row>
        <Divider orientation="left">Location</Divider>
        <Row gutter={24}>
          <Col lg={8} md={8} sm={12} xs={24} >
            <div className="gx-form-row0">
            <h6 align="left">Building/Street</h6>
              <Form.Item
                name="address1"
                //label="Estd Hours"
                rules={[
                  {
                    required: true,
                    message: 'Enter Buidling/Street',
                  },
                ]}
              >
                <Input placeholder="Building/Street" />
              </Form.Item>
            </div>
          </Col>

          <Col lg={8} md={8} sm={12} xs={24} >
            <div className="gx-form-row0">
            <h6 align="left">City</h6>
              <Form.Item
                name="city"
                //label="Estd Hours"
                rules={[
                  {
                    required: true,
                    message: 'Enter city',
                  },
                ]}
              >
                <Input placeholder="City" />
              </Form.Item>
            </div>
          </Col>

          <Col lg={8} md={8} sm={12} xs={24} >
            <div className="gx-form-row0">
            <h6 align="left">Country</h6>
              <Form.Item
                name="country"
                //label="Estd Hours"
                rules={[
                  {
                    required: true,
                    message: 'Enter country',
                  },
                ]}
              >
                <Input placeholder="Country" />
              </Form.Item>
            </div>
          </Col>
          <Col lg={8} md={8} sm={12} xs={24} >
            <div className="gx-form-row0">
            <h6 align="left">Region</h6>
              <Form.Item
                name="region"
                //label="Estd Hours"
                rules={[
                  {
                    required: true,
                    message: 'Enter region',
                  },
                ]}
              >
                <Input placeholder="Region" />
              </Form.Item>
            </div>
          </Col>
          <Col lg={8} md={8} sm={12} xs={24} >
            <div className="gx-form-row0">
            <h6 align="left">Postal Code</h6>
              <Form.Item
                name="postalcode"
                //label="Estd Hours"
                rules={[
                  {
                    required: true,
                    message: 'Enter postal code',
                  },
                ]}
              >
                <Input placeholder="Postal Code" />
              </Form.Item>
            </div>
          </Col>
          <Col lg={8} md={8} sm={12} xs={24} >
            <div className="gx-form-row0">
            <h6 align="left">Remarks</h6>
              <Form.Item
                name="remarks"
                //label="Estd Hours"
                rules={[
                  {
                    required: true,
                    message: 'Enter remarks',
                  },
                ]}
              >
                <Input placeholder="Remarks" />
              </Form.Item>
            </div>
          </Col>
          </Row>
          
          <Row gutter={24}>
                    <Col lg={24} md={24} sm={24} xs={24} >
                        <div className="gx-form-row0">
                            <Form.Item
                                name="submit"
                            >
                                <Button type="primary" className="add-btn" htmlType="submit">Submit</Button>
                                <Button type="primary" className="add-btn gx-mr-2" onClick={() => history.push('/master/clients/manage')}>Back</Button>
                            </Form.Item>
                        </div>
                    </Col>
                </Row>
      </Form>
    </Auxiliary>
    // </Card>
  );
};
export default NewClient;
