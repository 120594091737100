import axios from 'axios';
import API_URL from '../../../../constants/APIConfig';
import { message } from 'antd';

const userDetails = JSON.parse(sessionStorage.getItem('user_details'));
const token = sessionStorage.getItem('token');

export const getAllTeams_api = async (respData) => {

   const url = `${API_URL}/api/skillbase/teams/getAllSkills`;
    try {
        const data = await axios.get(url, 
            {headers: {
              'x-access-token': `${token}`, 
            'Access-Control-Allow-Origin': '*'}
           });
        if (data.status === 200) return data.data.data
            // message.warn(data.statusMessage);
        else return {};
    } catch (ex) {
        message.error(`${ex.message}: Unable to connect to Fankick services`);
        return {}
    }
}
export const getAllLocations_api = async (respData) => {
    const url = `${API_URL}/api/skillbase/locations/getAllLocations`;
     try {
         const data = await axios.get(url, 
            {headers: {
              'x-access-token': `${token}`, 
            'Access-Control-Allow-Origin': '*'}
           });
         if (data.status === 200) return data.data.data
             // message.warn(data.statusMessage);
         else return {};
     } catch (ex) {
         message.error(`${ex.message}: Unable to connect to Fankick services`);
         return {}
     }
 }
 export const getAllSkills_api = async (respData) => {
    const url = `${API_URL}/api/skillbase/skills/getAllSkills`;
     try {
         const data = await axios.get(url, 
            {headers: {
              'x-access-token': `${token}`, 
            'Access-Control-Allow-Origin': '*'}
           });
         console.log("resp data", data.data)
         if (data.status === 200) return data.data.data
             // message.warn(data.statusMessage);
         else return {};
     } catch (ex) {
         message.error(`${ex.message}: Unable to connect to Fankick services`);
         return {}
     }
 }
 export const getAllSkillCategories_api = async (id) => {
    const url = `${API_URL}/api/skillbase/skillcategories/getAllSkillCetegories`;
     try {
         const data = await axios.get(url, 
            {headers: {
              'x-access-token': `${token}`, 
            'Access-Control-Allow-Origin': '*'}
           });
         if (data.status === 200) return data.data.data
             // message.warn(data.statusMessage);
         else return {};
     } catch (ex) {
         message.error(`${ex.message}: Unable to connect to Fankick services`);
         return {}
     }
 }
 export const getAllQualifications_api = async (id) => {
    const url = `${API_URL}/api/skillbase/qualifications/getAllQualifications`;
     try {
         const data = await axios.get(url, 
            {headers: {
              'x-access-token': `${token}`, 
            'Access-Control-Allow-Origin': '*'}
           });
         if (data.status === 200) return data.data.data
             // message.warn(data.statusMessage);
         else return {};
     } catch (ex) {
         message.error(`${ex.message}: Unable to connect to Fankick services`);
         return {}
     }
 }
 export const addSkill_api = async (formData) => {
    const url = `${API_URL}/api/skillbase/skills/addNewSkill`;
     try {
         const data = await axios.post(url,formData, 
            {headers: {
              'x-access-token': `${token}`, 
            'Access-Control-Allow-Origin': '*'}
           });
         console.log("resp data",data)
         if (data.status === 200) return data.data.data
             // message.warn(data.statusMessage);
         else return {};
     } catch (ex) {
         message.error(`${ex.message}: Unable to connect to Fankick services`);
         return {}
     }
 }
 export const getLocations_api = async (LocationsResp) => {
    const url = `${API_URL}/api/skillbase/locations/getAllLocations`;
     try {
         const data = await axios.get(url, 
            {headers: {
              'x-access-token': `${token}`, 
            'Access-Control-Allow-Origin': '*'}
           });
         console.log("resp data",data)
         if (data.status === 200) return data.data.data
             // message.warn(data.statusMessage);
         else return {};
     } catch (ex) {
         message.error(`${ex.message}: Unable to connect to Fankick services`);
         return {}
     }
 }