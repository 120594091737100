// Development
//export const HOST = 'localhost';
//export const PORT= '9090';

// Production
//export const HOST = '13.71.25.94';
//export const PORT= '9090';

// export const API_URL = 'http://localhost:9090'
// //export const API_URL = 'http://13.71.25.94:9090'
// export default API_URL


// let environment = process.env.NODE_ENV
// let API_URL = 'http://localhost:9090'

// if (environment && environment == 'production') {
//   API_URL = 'https://api.gcs-apps.com:9090'
// }

//export default API_URL
//module.exports =  API_URL


export const API_URL = 'http://api.gcs-apps.com:9090';
export default API_URL;
