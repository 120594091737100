import React, { useState } from "react";
import { Button, Form, Input, message } from "antd";
import IntlMessages from "util/IntlMessages";
import { Link, useLocation } from "react-router-dom";
import { API_URL } from '../../../constants/APIConfig';
import axios from 'axios';

const FormItem = Form.Item;

const ForgotPassword = (props) => {

  const search = useLocation().search
  const searchParams = new URLSearchParams(search)
  const [form] = Form.useForm();
  const [msg, setMsg] = useState(null)

  const onFinishSendMail = values => {
    axios.post(API_URL + '/api/users/forgotPassword', {
      email: form.getFieldValue('email')
    },
      {
        headers: {
          'Access-Control-Allow-Origin': '*'
        }
      })
      .then(response => {
        setMsg(response.data.data)
        message.success(response.data.data);
      })
      .catch(error => {
        message.error(error.response.data.error)
      })
  }

  const onFinish = values => {

    axios.post(API_URL + '/api/users/forgotPassword', {
      newPassword: form.getFieldValue('password').trim(),
      code: searchParams.get('cd')
    },
      {
        headers: {
          'Access-Control-Allow-Origin': '*'
        }
      })
      .then(response => {
        setMsg('Success')
        message.success('Successfully reset the password.');
      })
      .catch(error => {
        setMsg('Invalid token or token expired')
        message.error('Invalid token or token expired')
      });
  }

  return (
    <div className="gx-app-login-wrap loginBg">
      <div className="gx-login-container">
        <div className="gx-login-content">
          <div className="loginLogo"><img alt="logo3" src={require("assets/images/Gemini-Consulting-Services-logo.png")} /></div>
        {/* Do not change the below <a> tag for sign in.*/}
        <div>{msg == 'Success' ? 
        <><b>Successfully reset the password.</b> <a className="gx-login-form-forgot gx-float-right gx-pr-1" href="/signin">Sign In</a> </> 
        : 
        msg}</div>

          <div hidden={msg != null}>
          {searchParams.get('cd')
            ? <>
              <div className="gx-mb-4">
                <h2>Change the password</h2>
              </div>
              <Form
                form={form}
                initialValues={{ remember: true }}
                name="basic"
                onFinish={onFinish}
                className="gx-signin-form gx-form-row0">

                <b><IntlMessages id="appModule.enterPasswordReset" /></b>
                <FormItem rules={[{ required: true, message: 'Please input new Password!' },
                { min: 8, message: 'Password must have a minimum length of 8' },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || value.startsWith(' ') || value.endsWith(' ')) {
                      return Promise.reject(new Error('Trailing spaces are not allowed.'));
                    }
                    return Promise.resolve();
                  },
                })]} name="password" hasFeedback>
                  <Input className='gx-input-lineheight' type="password" placeholder="New Password" />
                </FormItem>
                <br />
                <FormItem rules={[{ required: true, message: 'Please confirm your new password!' },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || value.startsWith(' ') || value.endsWith(' ')) {
                      return Promise.reject(new Error('Trailing spaces are not allowed.'));
                    }
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('The two passwords that you entered do not match!'));
                  },
                })]} name="confirm" hasFeedback>
                  <Input className='gx-input-lineheight' placeholder="Retype New Password" type="password" />
                </FormItem>
                <br />
                <FormItem>
                  <Button type="primary" htmlType="submit">
                    <IntlMessages id="app.userAuth.reset" />
                  </Button>
                </FormItem>
              </Form>
            </>
            :
            <>
              <div className="gx-mb-4">
                <h2>Forgot Your Password ?</h2>
                <p>Please provide your valid mail id, we will send the new password to login.</p>
              </div>
              <Form
                initialValues={{ remember: true }}
                form={form}
                name="basic"
                onFinish={onFinishSendMail}
                className="gx-signin-form gx-form-row0">
                <FormItem name="email" rules={[{ required: true, message: 'Please input your E-mail!' }]} hasFeedback>
                  <Input className='gx-input-lineheight' type="email" placeholder="E-Mail Address" />
                </FormItem>
                <br />
                <FormItem>
                  <Button type="primary" htmlType="submit">
                    <IntlMessages id="app.userAuth.send" />
                  </Button>
                  <Link className="gx-login-form-forgot gx-float-right gx-pr-1" to="/signin">Sign In</Link>
                </FormItem>
              </Form>
            </>
          }
          </div>
        </div>
      </div>
    </div>
  )
}

export default ForgotPassword
