import React, { useState } from 'react';
import { Link, useHistory } from "react-router-dom";
import { Button, Table, Dropdown, Menu, Icon } from 'antd';
import { MoreOutlined } from '@ant-design/icons';
// import './index.scss';

function QualificationsTabel(props) {
    const [role, setRole] = useState(true)
    // const { sortedInfo, filteredInfo } = this.state;
    // sortedInfo = sortedInfo || {};
    // filteredInfo = filteredInfo || {};


    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            // filteredValue: filteredInfo.name || null,
            onFilter: (value, record) => record.name.includes(value),
            sorter: (a, b) => a.name.length - b.name.length,
            // ellipsis: true,
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            sorter: (a, b) => a.age - b.age,
            // ellipsis: true,
        },
        {
            title: 'Start Date',
            dataIndex: 'startDate',
            key: 'startDate',
            sorter: (a, b) => a.age - b.age,
            // ellipsis: true,
        },
        {
            title: 'End Date',
            dataIndex: 'endDate',
            key: 'endDate',
            sorter: (a, b) => a.age - b.age,
            // ellipsis: true,
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            key: 'actions',
        },
    ];
    const dataSource = [
        {
            key: '1',
            name: 'B.tech',
            status: "Completed",
            startDate: '20-7-2019',
            endDate:'20-12-20',
            actions: <Dropdown getPopupContainer={triggerNode => triggerNode.parentNode}
                overlay={<Menu>
                    <Menu.Item key="0" >
                        {role == true ? <a><Link to={{ pathname: `/skill/self-assessment` }}>View</Link></a> : null}
                    </Menu.Item>
                    <Menu.Item key="1">
                        {role == true ? <a><Link to={{ pathname: `/skill/self-assessment` }} className="edittext">Edit</Link></a> : null}
                    </Menu.Item>
                </Menu >} trigger={['click']} >
                <a className="ant-dropdown-link" href="">
                    <span className="viewMore"><MoreOutlined /></span>
                </a>
            </Dropdown >
        },
        {
            key: '2',
            name: 'M.tech',
            status:'In-progress',
            startDate: "20-12-2017",
            endDate: '20-12-2020',
            actions: <Dropdown getPopupContainer={triggerNode => triggerNode.parentNode}
                overlay={<Menu>
                    <Menu.Item key="0" >
                        {role == true ? <a><Link to={{ pathname: `/skill/self-assessment` }}>View</Link></a> : null}
                    </Menu.Item>
                    <Menu.Item key="1">
                        {role == true ? <a><Link to={{ pathname: `/skill/self-assessment` }} className="edittext">Edit</Link></a> : null}
                    </Menu.Item>
                </Menu >} trigger={['click']} >
                <a className="ant-dropdown-link" href="">
                    <span className="viewMore"><MoreOutlined /></span>
                </a>
            </Dropdown >
        },
    ];
    // const newData = [];
    // if (props.data.length > 0 ){
    //     props.data.map((value, i) => {
    //         var obj = { key: i,
    //         name: value.empname,
    //         securityGroup:value.role,
    //         selfAssesment: 5,
    //         superAssement: 'Over Due two days',
    //         team: value.teamto,
    //         actions: <Dropdown getPopupContainer={triggerNode => triggerNode.parentNode}
    //         overlay={<Menu>
    //             <Menu.Item key="0" >
    //                 {role == true ? <a><Link to={{ pathname: `/skill/self-assessment` }}>View</Link></a> : null}
    //             </Menu.Item>
    //             <Menu.Item key="1">
    //                 {role == true ? <a><Link to={{ pathname: `/skill/self-assessment` }} className="edittext">Edit</Link></a> : null}
    //             </Menu.Item>
    //         </Menu >} trigger={['click']} >
    //         <a className="ant-dropdown-link" href="">
    //             <span className="viewMore"><MoreOutlined /></span>
    //         </a>
    //     </Dropdown >
    //         }
    //         newData.push(obj)
    //     })
    // }

    return (
        <div>
            <Table columns={columns} dataSource={dataSource} />
        </div>
    );
}

export default QualificationsTabel;
