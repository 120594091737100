import React from "react";
import axios from "axios";
import { Select, Row, Col, Button, Form, Input, Card } from "antd";
import { API_URL } from "constants/APIConfig";
import {
  FormOutlined,
  SearchOutlined,
  FileAddTwoTone,
} from "@ant-design/icons";
import get from "lodash.get";
import isequal from "lodash.isequal";
import moment from "moment";
import Highlighter from "react-highlight-words";
//import { TaskForm } from 'antd/lib/form';
import { Table } from "ant-table-extensions";

const { Option } = Select;

function handleBlur() {
  console.log("blur");
}

function handleFocus() {
  console.log("focus");
}

const userRoles = JSON.parse(sessionStorage.getItem("user_Roles"));

class ManageProjEfforts extends React.Component {
  formRef = React.createRef();

  state = {
    searchText: "",
    searchedColumn: "",
    projectId: "",
    projects: [],
    treeData: [],
    taskEditId: 0,
    taskEditPop: false,
    loading: false,
    userID: JSON.parse(sessionStorage.getItem("user_details")).id,
    token: sessionStorage.getItem("token"),
  };

  editTaskRcrd = (id) => {
    this.setState({ taskEditId: id });
  };

  handleChange = (value) => {
    this.getprojectEffortLists(value);
    this.setState({ projectId: value });
  };

  getprojectEffortLists = (projID) => {
    this.setState({ loading: true });
    axios
      .get(
        API_URL +
          "/api/project_initiation/plannedefforts/getAll?projectId=" +
          projID,
        {
          headers: {
            "x-access-token": `${this.state.token}`,
            "Access-Control-Allow-Origin": "*",
          },
        }
      )
      .then((response) => {
        if (Object.keys(response.data.data).length > 0) {
          let data = [];
          response.data.data.map((item, index) => {
            data.push({
              id: item.id,
              projectId: item.projectId,
              phase: item.phase,
              engagementType: item.engagementType,
              resourceType: item.resourceType,
              module: item.module,
              experience: item.experience,
              efforts: item.efforts,
              comments: item.comments,
              startDate: moment(item.startDate).format('DD-MM-YYYY'),
              endDate: moment(item.endDate).format('DD-MM-YYYY')
            })
          })
          this.setState({ treeData: data });
          this.setState({ loading: false });
        }
      })
      .catch((error) => {
        // Error
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log("Error", error.message);
        }
        console.log(error.config);
      });
  };
  getProjectList = () => {
    axios
      .get(
        API_URL +
          `/api/project/v2/allProjectNames?userId=${this.state.userID}&limit=500&offset=0`,
        {
          headers: {
            "x-access-token": `${this.state.token}`,
            "Access-Control-Allow-Origin": "*",
          },
        }
      )
      .then((response) => {
        if (Object.keys(response.data.data).length > 0) {
          this.setState({ projects: response.data.data });
          this.formRef.current.setFieldsValue({
            prjname: response.data.data[0].id,
          });
          this.getTaskLists(response.data.data[0].id);
          this.setState({ projectId: response.data.data[0].id });
        }
      })
      .catch((error) => {
        // Error
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log("Error", error.message);
        }
        console.log(error.config);
      });
  };
  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div className="custom-filter-dropdown">
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon="search"
          size="small"
          style={{ width: 70, marginRight: 8, float: "left" }}
        >
          {/*   Search */}
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="small"
          style={{ width: 70, float: "right" }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined />,
    onFilter: (value, record) => {
      return get(record, dataIndex)
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase());
    },

    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) => {
      return isequal(this.state.searchedColumn, dataIndex) ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      );
    },
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
    //this.setState({ searchText: selectedKeys[0] });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  componentDidMount() {
    this.getProjectList();
  }
  render() {
    const effortstbl = [
      {
        title: "Project Phase",
        dataIndex: "phase",
        width: 150,
        fixed: "left",
        sorter: (a, b) =>
          String(a.actualSpend).localeCompare(String(b.actualSpend)),
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Engagement Type",
        dataIndex: "engagementType",
        width: 150,
        sorter: (a, b) => String(a.actualSpend).localeCompare(String(b.actualSpend)),
          sortDirections: ['descend', 'ascend'],
      },
      {
        title: "Resource Type",
        dataIndex: "resourceType",
        width: 150,
        sorter: (a, b) => String(a.spendType).localeCompare(String(b.spendType)),
          sortDirections: ['descend', 'ascend'], 
      },
      {
        title: "Module",
        dataIndex: "module",
        width: 120,
        sorter: (a, b) => String(a.plannedSpend).localeCompare(String(b.plannedSpend)),
          sortDirections: ['descend', 'ascend'], 
      },
      {
        title: "Experience",
        dataIndex: "experience",
        align: 'center',
        width: 120,
        sorter: (a, b) => String(a.plannedSpend).localeCompare(String(b.plannedSpend)),
            sortDirections: ['descend', 'ascend'], 
      },

      {
        title: "Efforts",
        dataIndex: "efforts",
        align: 'center',
        width: 100,
        sorter: (a, b) => String(a.variance).localeCompare(String(b.variance)),
          sortDirections: ['descend', 'ascend'], 
      },
      {
        title: "Start Date",
        dataIndex: "startDate",
        width: 120,
        sorter: (a, b) => String(a.variance).localeCompare(String(b.variance)),
          sortDirections: ['descend', 'ascend'], 
      },
      {
        title: "End Date",
        dataIndex: "endDate",
        width: 120,
        sorter: (a, b) => String(a.variance).localeCompare(String(b.variance)),
          sortDirections: ['descend', 'ascend'], 
      },
      {
        title: "Comments",
        dataIndex: "comments",
        width: 100,
      },
      {
        title: "Action",
        width: 80,
        fixed: "right",
        dataIndex: "id",
        render: (text) => (
          <div>
            <span>
              {" "}
              <a href={"/master/projects/efforts/manage/" + text}>
                <FormOutlined style={{ padding: "10px" }} />
              </a>
            </span>{" "}
          </div>
        ),
      },
    ];
    return (
      <Form ref={this.formRef} name="taskfrm-ref">
        <Card
          title="Manage Planned Efforts"
          bordered={false}
          size='small'
          style={{ width: 1100 }}
        >
          <Row>
            <Col lg={20} md={20} sm={20} xs={20}>
              <Form.Item
                name="prjname"
                rules={[
                  {
                    required: true,
                    message: "Enter project name",
                  },
                ]}
              >
                <Select
                  showSearch
                  style={{ width: "50%" }}
                  placeholder="Select a Project"
                  optionFilterProp="children"
                  onChange={this.handleChange}
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {this.state.projects.length
                    ? this.state.projects.map((project, index) => (
                        <Option key={index} value={project.id}>
                          {project.name}
                        </Option>
                      ))
                    : null}
                </Select>
              </Form.Item>
            </Col>
            <Col lg={4} md={4} sm={4} xs={4}>
            <Button type="primary" href={"/master/projects/efforts/new"}>Add Planned Efforts</Button>
            </Col>
          </Row>

          <Row>
            <Col lg={24} md={24} sm={24} xs={24}>
              <Table
                columns={effortstbl}
                size='small'
                dataSource={this.state.treeData}
                scroll={{ x: 1000 }}
                loading={this.state.loading}
                exportable
                exportableProps={{ showColumnPicker: true }}
              />
            </Col>
          </Row>
        </Card>
      </Form>
    );
  }
}
export default ManageProjEfforts;
