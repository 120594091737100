import React, { useEffect, useState } from "react";
import {
  Button,
  Row,
  Col,
  Form,
  Table,
  Input,
  Select,
  InputNumber,
  DatePicker,
  message,
  Card,
} from "antd";
import { API_URL } from "constants/APIConfig";
import axios from "axios";
import moment from "moment";
import { FormOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";

const { Option } = Select;
const InputGroup = Input.Group;
const { TextArea } = Input;
const { RangePicker } = DatePicker;

const token = sessionStorage.getItem("token");
function handleChange(value) {
  console.log(`selected ${value}`);
}

function handleBlur() {
  console.log("blur");
}

function handleFocus() {
  console.log("focus");
}

const handleResourceType = (e) => {};

const handleModules = (e) => {};
let engageType = "";
const handleEnagementType = (e) => {};

const handleExperience = (e) => {};

const EditResPlan = (props) => {
  let history = useHistory();
  const [projEfforts, setProjEfforts] = useState([]);
  const [projId, setProjId] = useState([]);
  const [modules, setModules] = useState([]);
  const [users, setUsers] = useState([]);

  const getModulesList = () => {
    axios
      .get(API_URL + "/api/masters/modules/getall", {
        headers: {
          "x-access-token": `${token}`,
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((response) => {
        console.log("Modules List", response.data.data);
        setModules(response.data.data);
      })
      .catch((error) => {
        // Error
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log("Error", error.message);
        }
        console.log(error.config);
      });
  };
  const getUserList = () => {
    axios.get(API_URL + '/api/users/getall',
      {
        headers: {
          'x-access-token': `${token}`,
          'Access-Control-Allow-Origin': '*'
        }
      })
      .then(response => {
        console.log('User List', response.data.data)
        setUsers(response.data.data);
      })
      .catch(error => {
        // Error
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log('Error', error.message);
        }
        console.log(error.config);
      });
  }
  const getProjEffortsById = () => {
    axios
      .get(
        API_URL +
          "/api/project_initiation/resourceloading/byId/" +
          props.match.params.id,
        {
          headers: {
            "x-access-token": `${token}`,
            "Access-Control-Allow-Origin": "*",
          },
        }
      )
      .then((response) => {
        console.log("Planned Efforts By Id", response.data.data);
        setProjId(response.data.data.projectId);
        setProjEfforts([
          ...projEfforts,
          {
            id: response.data.data.id,
            projectId: response.data.data.projectId,
            engagementType: response.data.data.engagementType,
            role: response.data.data.role ? response.data.data.role : null,
            duration: response.data.data.duration ? response.data.data.duration : null,
            resourceType: response.data.data.resourceType,
            resourceName: response.data.data.resourceName ? response.data.data.resourceName : null,
            status: response.data.data.status ? response.data.data.status : null,
            module: response.data.data.module,
            experience: response.data.data.experience,
            comments:response.data.data.comments ? response.data.data.comments : null,
            efforts: response.data.data.efforts,
            startDate: moment(response.data.data.startDate).format(
              "DD-MM-YYYY"
            ),
            endDate: moment(response.data.dataendDate).format("DD-MM-YYYY"),
          },
        ]);

        form.setFieldsValue({
          phase: response.data.data.phase ? response.data.data.phase : null,
          engageType: response.data.data.engagementType ? response.data.data.engagementType : null,
          role: response.data.data.role ? response.data.data.role : null,
          moduleType: response.data.data.module ? response.data.data.module : null,
          resourcetype: response.data.data.resourceType ? response.data.data.resourceType : null,
          duration: response.data.data.duration ? response.data.data.duration : null,
          effortHours: response.data.data.efforts ? response.data.data.efforts : null,
          resourceName: response.data.data.resourceName ? response.data.data.resourceName : null,
          experience: response.data.data.experience ? response.data.data.experience : null,
          status: response.data.data.status ? response.data.data.status : null,
          comments:response.data.data.comments ? response.data.data.comments : null,
          effortDuration: [
            response.data.data.startDate ? moment(response.data.data.startDate) : null,
            response.data.data.endDate ? moment(response.data.data.endDate) : null,
          ],
        });
      })
      .catch((error) => {
        // Error
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log("Error", error.message);
        }
        console.log(error.config);
      });
    // }
  };

  const [form] = Form.useForm();

  const onFinish = (values) => {
    const dates = form.getFieldValue("effortDuration");
    axios
      .put(
        API_URL +
          `/api/project_initiation/resourceloading/update/${props.match.params.id}`,{
            projectId: projId,
            module: form.getFieldValue("moduleType"),
            phase: form.getFieldValue("phase"),
            role: form.getFieldValue("role"),
            status: form.getFieldValue("status"),
            duration: form.getFieldValue("duration"),
            comments: form.getFieldValue("comments"),
            resourceName: form.getFieldValue("resourceName"),
            experience: form.getFieldValue("experience"),
            resourceType: form.getFieldValue("resourcetype"),
            engagementType: form.getFieldValue("engageType"),
            efforts: form.getFieldValue("effortHours"),
            startDate: dates[0].format("YYYY-MM-DD"),
            endDate: dates[1].format("YYYY-MM-DD")
          },
         {
          headers: {
            "x-access-token": `${token}`,
            "Access-Control-Allow-Origin": "*",
          },
        }
      )
      .then((response) => {
        message.success("Project efforts are added Successfully");
        form.resetFields();
         history.push("/master/projects/resourceplan/manage");
      })
      .catch((error) => {
        // Error
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log("Error", error.message);
        }
        console.log(error.config);
        message.error("Error In creating Schedule", error);
      });
  };
  useEffect(() => {
    getModulesList();
    getProjEffortsById();
    getUserList();
  }, []);
  return (
    <Card
    title="Resource Plan"
    size="small"
    bordered={false}
    style={{ width: 1100 }}
  >
    <Form
      form={form}
      initialValues={{ remember: true }}
      name="proj_efforts"
      onFinish={onFinish}
      layout="inline"
      align="center"
    >
      <Row>
        <Col lg={6} md={6} sm={6} xs={6}>
          <div className="gx-form-row0">
            <h6 align="left">Phase</h6>
            <Form.Item
              name="phase"
              // label="Project Name"
              rules={[
                {
                  required: true,
                  message: "Enter project name",
                },
              ]}
            >
              <Select
                showSearch
                style={{ width: "100%" }}
                placeholder="Select a Value"
                optionFilterProp="children"
                onChange={handleEnagementType}
                onFocus={handleFocus}
                onBlur={handleBlur}
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                <Option value={"Implementation"}>Implementation</Option>
                <Option value={"Hypercare"}>Hypercare</Option>
                <Option value={"AnnualMaintenance"}>AnnualMaintenance</Option>
              </Select>
            </Form.Item>
          </div>
        </Col>
        <Col lg={4} md={4} sm={12} xs={24}>
          <div className="gx-form-row0">
            <h6 align="left">Engagement Type</h6>
            <Form.Item
              name="engageType"
              // label="Project Name"
              rules={[
                {
                  required: true,
                  message: "Enter name",
                },
              ]}
            >
              <Select
                showSearch
                style={{ width: "100%" }}
                placeholder="Select a Value"
                optionFilterProp="children"
                onChange={handleEnagementType}
                onFocus={handleFocus}
                onBlur={handleBlur}
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                <Option value={"Onsite"}>Onsite</Option>
                <Option value={"Offshore"}>Offshore</Option>
              </Select>
            </Form.Item>
          </div>
        </Col>
        <Col lg={8} md={8} sm={8} xs={8}>
          <div className="gx-form-row0">
            <h6 align="left">Role</h6>
            <Form.Item
              name="role"
              // label="Project Name"
              rules={[
                {
                  required: true,
                  message: "Enter name",
                },
              ]}
            >
              <Select
                showSearch
                style={{ width: "100%" }}
                placeholder="Select a Value"
                optionFilterProp="children"
                onChange={handleEnagementType}
                onFocus={handleFocus}
                onBlur={handleBlur}
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                <Option value={"Onsite123"}>Onsite123</Option>
                <Option value={"Offshore123"}>Offshore123</Option>
              </Select>
            </Form.Item>
          </div>
        </Col>          
        <Col lg={6} md={6} sm={6} xs={6}>
          <div className="gx-form-row0">
            <h6 align="left">Module</h6>
            <Form.Item
              name="moduleType"
              //label="Billable"
              rules={[
                {
                  required: true,
                  message: "Enter project billable",
                },
              ]}
            >
              <Select
                showSearch
                style={{ width: "100%" }}
                placeholder="Select a Value"
                optionFilterProp="children"
                onChange={handleModules}
                onFocus={handleFocus}
                onBlur={handleBlur}
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {modules.length
                  ? modules.map((module, index) => (
                      <Option key={index} value={module.name}>
                        {module.name}
                      </Option>
                    ))
                  : null}
              </Select>
            </Form.Item>
          </div>
        </Col>
        <Col lg={4} md={4} sm={12} xs={24}>
          <div className="gx-form-row0">
            <h6 align="left">Resource Type</h6>
            <Form.Item
              name="resourcetype"
              rules={[
                {
                  required: true,
                  message: "Enter Resource Type",
                },
              ]}
            >
              <Select
                showSearch
                style={{ width: "100%" }}
                placeholder="Select a Value"
                optionFilterProp="children"
                onChange={handleResourceType}
                onFocus={handleFocus}
                onBlur={handleBlur}
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                <Option value={"Junior"}>Junior</Option>
                <Option value={"Senior"}>Senior</Option>
              </Select>
            </Form.Item>
          </div>
        </Col>
        <Col lg={4} md={4} sm={12} xs={24}>
          <div className="gx-form-row0">
            <h6 align="left">Est. Duration</h6>
            <Form.Item
              name="duration"
              //label="Budget"
              rules={[
                {
                  required: true,
                  message: "Please maintain in weeks",
                },
              ]}
            >
              <InputNumber placeholder="weeks" />
            </Form.Item>
          </div>
        </Col>
        <Col lg={4} md={4} sm={12} xs={24}>
          <div className="gx-form-row0">
            <h6 align="left">Est. Hours</h6>
            <Form.Item
              name="effortHours"
              //label="Budget"
              rules={[
                {
                  required: true,
                  message: "Please maintain total hours",
                },
              ]}
            >
              <InputNumber placeholder="hours" />
            </Form.Item>
          </div>
        </Col>
        <Col lg={8} md={8} sm={12} xs={24} >
          <div className="gx-form-row0">
            <h6 align="left">Resource Name</h6>
            <Form.Item
              name="resourceName"
              rules={[
                {
                  required: true,
                  message: 'Users',
                },
              ]}
            >
              <Select
                //mode="multiple"
                style={{ width: '100%' }}
                placeholder="Please select"
                //disabled={disable}
              >
                {users.length ? users.map((user, index) => (
                  <Option key={index} value={user.id}>{user.displayNameTx}</Option>
                )) : null}

              </Select>
            </Form.Item>
          </div>
        </Col>
        <Col lg={4} md={4} sm={12} xs={24}>
          <div className="gx-form-row0">
            <h6 align="left">Experience Level</h6>
            <Form.Item
              name="experience"
              // label="Project Name"
              rules={[
                {
                  required: true,
                  message: "Enter Experience",
                },
              ]}
            >
              <Select
                showSearch
                style={{ width: "100%" }}
                placeholder="Select a Value"
                optionFilterProp="children"
                onChange={handleExperience}
                onFocus={handleFocus}
                onBlur={handleBlur}
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                <Option value={"1"}>1+</Option>
                <Option value={"2"}>2+</Option>
                <Option value={"3"}>3+</Option>
                <Option value={"4"}>4+</Option>
              </Select>
            </Form.Item>
          </div>
        </Col>
        <Col lg={4} md={4} sm={12} xs={24}>
          <div className="gx-form-row0">
            <h6 align="left">Status</h6>
            <Form.Item
              name="status"
              rules={[
                {
                  required: true,
                  message: "Enter Resource Type",
                },
              ]}
            >
              <Select
                showSearch
                style={{ width: "100%" }}
                placeholder="Select a Value"
                optionFilterProp="children"
                onChange={handleResourceType}
                onFocus={handleFocus}
                onBlur={handleBlur}
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                <Option value={"Deployed"}>Deployed</Option>
                <Option value={"TBD"}>TBD</Option>
              </Select>
            </Form.Item>
          </div>
        </Col>
        <Col lg={8} md={8} sm={12} xs={24}>
          <div className="gx-form-row0">
            <h6 align="left">Duration</h6>
            <Form.Item
              name="effortDuration"
              rules={[
                {
                  required: true,
                  message: "Please maintain total hours",
                },
              ]}
            >
              <RangePicker className="gx-mb-3 gx-w-100" />
            </Form.Item>
          </div>
        </Col>
        <Col lg={24} md={24} sm={12} xs={24}>
          <div className="gx-form-row0">
            <h6 align="left">Remarks</h6>
            <Form.Item
              name="comments"
              rules={[
                {
                  required: true,
                  message: "Enter Remarks",
                },
              ]}
            >
              <TextArea rows={4} />
            </Form.Item>
          </div>
        </Col>
      </Row>
      <Row>
        <Col lg={24} md={24} sm={24} xs={24}>
          <Button type="primary" className="add-btn" onClick={onFinish}>
            Submit
          </Button>
        </Col>
      </Row>
    </Form>
  </Card>
  );
};
export default EditResPlan;
