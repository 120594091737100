import React, { useEffect, useState } from 'react';
import { Button, Row, Col, Select, Input, Form, message } from 'antd';
import axios from 'axios';
import { API_URL } from 'constants/APIConfig';

const { Search,TextArea } = Input;
const { Option } = Select;
const FormItem = Form.Item;

const NewSkill = () => {
    
    const userDetails = JSON.parse(sessionStorage.getItem('user_details'));
    const token = sessionStorage.getItem('token');

    const [form] = Form.useForm();

    const [skillCategories, setSkillCategories] = useState([]);

    const onFinish = values => {
        let newSkillData = [];
        newSkillData.push({
                category_id_nm: +form.getFieldValue('categoryID'),
                name_tx: form.getFieldValue('skillName'),
                description_tx: form.getFieldValue('description'),
            })
        axios.post(API_URL + '/api/skillbase/skills/addNewSkill', newSkillData, 
        {headers: {
          'x-access-token': `${token}`, 
        'Access-Control-Allow-Origin': '*'}
       })
            .then(response => {
                message.success(`Skill added sucessfully`);
            })
            .catch(error => {
                console.log(error);
                message.error("Error In creating Skill", error)
            });
    }
    
   const getSkillCategoriesList = () => {
        axios.get(API_URL + `/api/skillbase/skillcategories/getAllSkillCetegories`, 
        {headers: {
          'x-access-token': `${token}`, 
        'Access-Control-Allow-Origin': '*'}
       })
          .then(response => {
             if (Object.keys(response.data.data).length >0) {
                setSkillCategories(response.data.data);
             }
          })
          .catch(error => {
            console.log(error);
          });
      } 

    useEffect(() => {
        getSkillCategoriesList();
    }, [])

    return (
        <div className="add-skills-main content-height-100">
            <Row className="header-border">
                {/* <h2> Add Skill</h2> */}
            </Row>
            <div className="skill-form">
            <Form
            form={form}
            initialValues={{ remember: true }}
            name="timelog"
            onFinish={onFinish}
            layout="inline"
            align="center"
        >
            
            <Row gutter={24}>
                <Col lg={12} md={12} sm={12} xs={24} >
                    <div className="gx-form-row0">
                        <h6 align="left">Category</h6>
                        <Form.Item
                            name="categoryID"
                            rules={[
                                {
                                    required: true,
                                    message: 'Enter Delegates in the list',
                                },
                            ]}
                        >
                            <Select
                                style={{ width: '100%' }}
                                placeholder="Select a Value"
                                optionFilterProp="children"
                                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            >
                               {
                                skillCategories.length ? skillCategories.map((categories, index) => (
                                    <Option key={index}  value={categories.id}>{categories.nameTx}</Option>
                                )) : null
                            }
                            </Select>
                        </Form.Item>
                    </div>
                </Col>
                <Col lg={12} md={12} sm={12} xs={24} >
                    <div className="gx-form-row0">
                        <h6 align="left">Skill Name</h6>
                        <Form.Item
                            name="skillName"
                            // label="Project Name"
                            rules={[
                                {
                                    required: true,
                                    message: 'Enter Skill Name',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </div>
                </Col>
                <Col lg={24} md={24} sm={12} xs={24} >
                    <div className="gx-form-row0">
                        <h6 align="left">Description</h6>
                        <Form.Item
                            name="description"
                            // label="Project Name"
                            rules={[
                                {
                                    required: true,
                                    message: 'Enter Role Name',
                                },
                            ]}
                        >
                            <TextArea rows={4}/>
                        </Form.Item>
                    </div>
                </Col>
            </Row>
            <Row>
                    <Col lg={24} md={24} sm={24} xs={24} >
                        <div className="gx-form-row0">
                            <Form.Item
                                name="submit"
                            >
                                <Button type="primary" className="add-btn" onClick={onFinish}>Add Skill</Button>
                            </Form.Item>
                        </div>
                    </Col>
                </Row>
            </Form>
                {/* <Button type="primary" onClick={addSkills}> Add</Button> */}
            </div>
        </div >
    );
}

export default NewSkill;
