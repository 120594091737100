import React from "react";
import {Button, Form, Input,message} from "antd";
import IntlMessages from "util/IntlMessages";
import axios from 'axios';
import { API_URL } from 'constants/APIConfig';
import {useDispatch} from "react-redux";
import {userSignOut} from "appRedux/actions/Auth";

const FormItem = Form.Item;

const ResetPassword = () => {
  const userDetails = JSON.parse(sessionStorage.getItem('user_details'));
  const token = sessionStorage.getItem('token');
  
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const onFinishFailed = errorInfo => {
    //console.log('Failed:', errorInfo);
  };

  const onFinish = values => {
    //let fieldsData = form.getFieldsValue();

    let userId = +userDetails.id;
    let currPwd = form.getFieldValue('oldpassword');
    let chngPwd = form.getFieldValue('password');
    let confirmPwd =form.getFieldValue('confirm');
 
    axios.post(API_URL + '/api/users/changePassword',{
      userId : userId, //10,
      currentPassword : currPwd , //'Gemini@1234',
      newPassword : chngPwd, //'Gemini@123',
      verifyPassword : confirmPwd, // 'Gemini@123'    
    }, 
    {headers: {
      'x-access-token': `${token}`, 
    'Access-Control-Allow-Origin': '*'}
   })
        .then(response => {
          //console.log('Login Users', response.data.data)
          message.success(response.data.data);
          dispatch(userSignOut());
        })
        .catch(error => {
            // Error
            /*if (error.response) {
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
            } else if (error.request) {
                console.log(error.request);
            } else {
                console.log('Error', error.message);
            }*/
            //console.log(error.config);
            message.error(error.response.data.error)
        });
  }

  return (
    <div className="gx-login-container">
      <div className="gx-login-content">

        {/*<div className="gx-login-header">
          <img src={require("assets/images/geminiImages/logo.png")} alt="Gemini" title="Gemini"/>
  </div>*/}
        <div className="gx-mb-2">
          <center><h2>Reset Password</h2></center>
        </div>

        <Form
          form={form}
          initialValues={{ remember: true }}
          name="basic"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          className="gx-signin-form gx-form-row0">

          <FormItem rules={ [{required: true, message: 'Please input your Old Password!'},
        ({ getFieldValue }) => ({
          validator(_, value) {
            if (!value || value.startsWith(' ') || value.endsWith(' ')) {
              return Promise.reject(new Error('Trailing spaces are not allowed.'));
            }
            return Promise.resolve();
          },
        })]} name="oldpassword" hasFeedback>
            <Input className='gx-input-lineheight' type="password" placeholder="Current Password"/>
          </FormItem>
          <br/>
          <b><IntlMessages id="appModule.enterPasswordReset"/></b>
          <FormItem rules={ [{required: true, message: 'Please input new Password!'},
        { min: 8, message: 'Password must have a minimum length of 8' },
        ({ getFieldValue }) => ({
          validator(_, value) {
            if (!value || value.startsWith(' ') || value.endsWith(' ')) {
              return Promise.reject(new Error('Trailing spaces are not allowed.'));
            }
            return Promise.resolve();
          },
        })]} name="password" hasFeedback>
            <Input className='gx-input-lineheight' type="password" placeholder="New Password"/>
          </FormItem>
          <br/>
          <FormItem rules={ [{required: true, message: 'Please confirm your new password!'},
        ({ getFieldValue }) => ({
          validator(_, value) {
            if (!value || value.startsWith(' ') || value.endsWith(' ')) {
              return Promise.reject(new Error('Trailing spaces are not allowed.'));
            }
            if (!value || getFieldValue('password') === value) {
              return Promise.resolve();
            }
            return Promise.reject(new Error('The two passwords that you entered do not match!'));
          },
        })]} name="confirm" hasFeedback>
            <Input className='gx-input-lineheight' placeholder="Retype New Password" type="password"/>
          </FormItem>
          <br/>
          <FormItem>
            <Button type="primary" htmlType="submit">
              <IntlMessages id="app.userAuth.reset"/>
            </Button>
          </FormItem>
        </Form>
      </div>
    </div>
  );
};

export default ResetPassword;
