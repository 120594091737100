import React from "react";
import axios from 'axios';
import Highlighter from "react-highlight-words";
import ClientStats from "../utils/ClientStats";
import { Table, Row, Col, Button, Input } from "antd";
import Auxiliary from "util/Auxiliary";
import { FormOutlined, SearchOutlined, FileAddTwoTone } from '@ant-design/icons';
import { API_URL } from 'constants/APIConfig';

import get from "lodash.get";
import isequal from "lodash.isequal";

class ManageClients extends React.Component {
  state = {
    searchText: "",
    searchedColumn: "",
    clients: [],
    loading: false,
    userDetails: JSON.parse(sessionStorage.getItem('user_details')),
    token: sessionStorage.getItem('token'),
  };

  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => (
      <div className="custom-filter-dropdown">
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}

          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon="search"
          size="small"
          style={{ width: 70, marginRight: 8, float: 'left' }}
        >
          {/*   Search */}
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="small"
          style={{ width: 70, float: 'right' }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined />
    ),
    onFilter: (value, record) => {
      return get(record, dataIndex)
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase());
    },

    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text => {
      return isequal(this.state.searchedColumn, dataIndex) ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      );
    }
  });
  getClientLists = () => {
    axios.get(API_URL + '/api/invoice_customers/all?limit=500&offset=0', 
    {headers: {
      'x-access-token': `${this.state.token}`, 
    'Access-Control-Allow-Origin': '*'}
   })
      .then(response => {
        if (response.data.data.length) {
          this.setState({ clients: response.data.data });
        }
      })
  }
  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex
    });
  };
  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  componentDidMount() {
    this.getClientLists();
  }
  render() {
    const columns = [
      {
        title: 'Name', dataIndex: 'organization', key: 'organization', fixed: 'left', width: 250,
        sorter: (a, b) => String(a.organization).localeCompare(String(b.organization)),
        sortDirections: ['descend', 'ascend'],
        ...this.getColumnSearchProps("organization")
      },
      {
        title: 'Contact 1', dataIndex: 'firstName', key: 'firstName', width: 200,
        sorter: (a, b) => String(a.firstName).localeCompare(String(b.firstName)),
        sortDirections: ['descend', 'ascend'],
        ...this.getColumnSearchProps("firstName")
      },
      {
        title: 'Email Id', dataIndex: 'email', key: 'email1', width: 250,
        sorter: (a, b) => String(a.email).localeCompare(String(b.email)),
        sortDirections: ['descend', 'ascend'],
        ...this.getColumnSearchProps("email")
      },
      {
        title: 'Phone', dataIndex: 'phone', key: 'phone1', width: 100,
        sorter: (a, b) => String(a.phone).localeCompare(String(b.phone)),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Contact 2', dataIndex: 'secondaryContactFirstName', key: 'name2', width: 200,
        sorter: (a, b) => String(a.secondaryContactFirstName).localeCompare(String(b.secondaryContactFirstName)),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Email Id', dataIndex: 'secondaryContactEmail', key: 'email2', width: 200,
        sorter: (a, b) => String(a.secondaryContactEmail).localeCompare(String(b.secondaryContactEmail)),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Phone', dataIndex: 'secondaryContactPhone', key: 'name', width: 100,
        sorter: (a, b) => String(a.secondaryContactPhone).localeCompare(String(b.secondaryContactPhone)),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Region', dataIndex: 'region', key: 'region', width: 200,
        sorter: (a, b) => String(a.region).localeCompare(String(b.region)),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Website', dataIndex: 'website', key: 'web', width: 200,
        sorter: (a, b) => String(a.website).localeCompare(String(b.website)),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Action', dataIndex: 'id', fixed: 'right', width: 80,
        render: text => <div><span> <a href={'./manage/' + text}><FormOutlined style={{ padding: '10px' }} /></a></span> </div>,
        fixed: 'right'
      }];

    return (
      <Auxiliary>
        <ClientStats />
        <div className="newprojectBtn"><span> <a href={'./new'}><FileAddTwoTone style={{ padding: '10px' }} /> New Client</a></span> </div>
        <Table columns={columns} dataSource={this.state.clients} size="small" scroll={{ x: 1300 }} />
      </Auxiliary>
    );
  }
}
export default ManageClients;
