import React from "react";
import Icon from '@ant-design/icons';
import { MailOutlined,MessageOutlined,BellOutlined,UnorderedListOutlined   } from '@ant-design/icons';
const WelComeCard = () => {

  return (
    <div className="gx-wel-ema gx-pt-xl-2">
       <h2 className="gx-mb-2 gx-mb-xl-4 h4"> Projects by Technology</h2>
      <ul className="gx-list-group gx-fs-sm">
        <li>
      {/*     <MessageOutlined />
 */}
          <span><span className="text_blue font600">65</span> SAP</span>
        </li>
        <li>
          {/* <MailOutlined /> */}
          <span><span className="text_blue font600">30</span> Web APP</span>
        </li>
        <li>
          {/* <UnorderedListOutlined /> */}
          <span><span className="text_blue font600">12</span> .NET</span>
        </li>
        <li>
          {/* <BellOutlined /> */}
          <span><span className="text_blue font600">30</span> Mobile</span>
        </li>
      </ul>
    </div>

  );
};

export default WelComeCard;
